import dayjs from 'dayjs';
import { USER_COOKIE } from '../App/constants';
import { cookiesExpiresDate } from '../../utilities';
import { __ } from 'react-i18n';
import * as cookies from 'react-cookies';
import { LOGIN_USER, LOGOUT_USER } from './constants';
import { loadCartId } from '../Cart/actions';

export const loginUser = (accessToken) => {
  return async (dispatch, getState, API) => {
    try {
      // call API to get user info
      const {
        token_info: { expires_at_date: expireDate },
        ...user
      } = await API.tokeninfo({ accessToken });

      // check if token is valid ( not expired )
      if (dayjs().isBefore(expireDate)) {
        cookies.save(USER_COOKIE, accessToken, {
          path: '/',
          expires: cookiesExpiresDate(),
        }); // save user's access token to cookies
        API.setToken(accessToken);
        dispatch(loginUserAction(user, accessToken)); // save user to redux
      } else {
        dispatch(logoutUser());
      }
    } catch (e) {
      dispatch(logoutUser());
    }
  };
};

export const loginUserAndLoadCart = (accessToken, cartId) => {
  return async (dispatch, getState, API) => {
    await dispatch(loginUser(accessToken));
    if (cartId) {
      await dispatch(loadCartId(cartId));
    }
  };
};

const loginUserAction = (user, token) => ({
  type: LOGIN_USER,
  payload: {
    user,
    token,
  },
});

export const logoutUser = () => {
  return async (dispatch, getState, API) => {
    if (getState().auth.user && getState().auth.token) {
      try {
        await API.oauthLogout();
      } catch (e) {
        console.log(e);
      } finally {
        try {
          cookies.remove(USER_COOKIE, { path: '/' }); // delete user's access token from cookies
          dispatch(logoutUserAction());
          API.setToken('');
        } catch (e) {
          console.log(e);
        }
      }
    }
    /*
    if (window) {
      dispatch(push(__('routes:prihlasenie')));
    }
    */
  };
};

const logoutUserAction = () => {
  return {
    type: LOGOUT_USER,
  };
};
