import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { getTotal, isSchoolOrTeacher, prop } from '../../utilities';
import { MainBlock } from './Ucet';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadMyOrders } from './actions';
import OrdersParent from './OrdersParent';
import Tabs, { Tab } from '../../components/Tabs/Tabs';
import OrderHistoryList from '../../components/_layout/Account/OrderHistoryList';
import { theme } from '../../theme/theme';
import Pagination from '../../components/Pagination/Pagination';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { push } from 'react-router-redux';
import { ORDER_HISTORY_PER_PAGE } from '../../constants';

enum TabIds {
  SCHOOL_ORDERS = 'Objednávky školy',
  PARENT_ORDERS = 'Objednávky rodičov',
}

interface OrderHistoryProps {
  setTitle: (title: string, link?: string | null) => void;
  dispatch: (a: any) => void;
  user: any;
  myOrders: any;
  myOrdersLoader: boolean;
  currentPath: string;
  location: any;
}

interface OrderHistoryState {
  activeTabIndex: string | null;
  actualPage: number;
}

class OrderHistory extends React.PureComponent<
  OrderHistoryProps,
  OrderHistoryState
> {
  public static async getInitialProps(props) {
    const page = props?.location?.query?.page;
    props.dispatch(
      loadMyOrders(
        props.user.id,
        ((page ? page : 1) - 1) * ORDER_HISTORY_PER_PAGE,
      ),
    );
  }

  constructor(props) {
    super(props);
    const title = __('História objednávok');
    props.setTitle(title);
    props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);
    const page = this.props?.location?.query?.page;

    this.state = {
      activeTabIndex: TabIds.SCHOOL_ORDERS,
      actualPage: page ? page : 1,
    };
  }

  public toggleTab = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  public onPageChange = (pageNumber) => {
    this.setState({ actualPage: pageNumber });
    const location = window.location.pathname + '?' + `page=${pageNumber}`;
    this.props.dispatch(push(location));
  };

  public render() {
    const { myOrders, user } = this.props;

    return (
      <MainBlock>
        {isSchoolOrTeacher(user) ? (
          <>
            <Tabs
              items={Object.values(TabIds).map((value) => {
                return {
                  name: value,
                  id: value,
                };
              })}
              activeId={this.state.activeTabIndex}
              onTabChange={this.toggleTab}
              selectedBackgroundColor={theme.color.secondary}
              selectedTextColor={theme.color.black}
            />

            <Tab visible={this.state.activeTabIndex === TabIds.SCHOOL_ORDERS}>
              <Loader loading={this.props.myOrdersLoader}>
                <OrderHistoryList
                  orders={myOrders}
                  user={user}
                  dispatch={this.props.dispatch}
                />
                <Pagination
                  current={this.state.actualPage}
                  total={getTotal(myOrders)}
                  onPageChanged={this.onPageChange}
                />
              </Loader>
            </Tab>

            <Tab visible={this.state.activeTabIndex === TabIds.PARENT_ORDERS}>
              <OrdersParent orders={myOrders} />
            </Tab>
          </>
        ) : (
          <Loader loading={this.props.myOrdersLoader}>
            <OrderHistoryList
              orders={myOrders}
              user={user}
              dispatch={this.props.dispatch}
            />
            <Pagination
              current={this.state.actualPage}
              total={getTotal(myOrders)}
              onPageChanged={this.onPageChange}
            />
          </Loader>
        )}
      </MainBlock>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    myOrders: state.myAccount.myOrders,
    myOrdersLoader: state.myAccount.myOrdersLoader,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrderHistory' })(OrderHistory),
);
