import * as React from 'react';
import styled from 'styled-components';
import FooterLink from './FooterLink';
import { __ } from 'react-i18n/lib';
import Icon from '../../_helpers/Icon/Icon';

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.gray83};
  padding: 12px 0px;
  padding-bottom: 20px;
`;

const ContentWrapper = styled.footer`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  max-width: 1170px;
  margin: auto;
  padding: 0px 35px;
  @media only screen and (max-width: 390px) {
    padding: 0px 16px;
  }
`;

const LeftFooterSide = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 45%;

  @media only screen and (max-width: 970px) {
    width: 50%;
    width: 100%;
    justify-content: center;
  }
`;

const RightFooterSide = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 55%;

  @media only screen and (max-width: 970px) {
    width: 50%;
    display: none;
  }
`;

const ContactRowWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;

  @media only screen and (max-width: 970px) {
    justify-content: center;
    max-width: 100%;
    margin: auto;
  }
`;

const NeedHelpText = styled.p`
  font-size: 1.5rem;
  margin: 0px;
`;

const QuestionsLink = styled.a`
  color: black;
  text-decoration: underline;
  margin: 0px;
`;

const ChatRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 970px) {
    display: none;
  }
`;

const MobileChatRow = styled.div`
  flex-flow: row;
  align-items: center;
  justify-content: center;
  display: none;

  @media only screen and (max-width: 970px) {
    display: flex;
  }

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media only screen and (max-width: 350px) {
    font-size: 12px;
  }
`;

const ChatIcon = styled.div`
  background: url('/images/chat.svg') no-repeat;
  filter: invert(18%) sepia(93%) saturate(4329%) hue-rotate(340deg)
    brightness(91%) contrast(97%);
  width: 25px;
  height: 25px;
`;

const ChatButtonTrigger = styled.div`
  margin: 0px;
  margin-left: 4px;
  color: black;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 970px) {
    border-right: 0.5px solid #a6a6a6;
    padding-right: 6px;
    font-weight: normal;
  }
`;

const ShopInfoRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const CompanyInfoRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;

const ShopInfoRowItem = styled.a`
  text-decoration: none;
  color: black;
  margin: 0px;
  padding: 0px 6px;
  border-right: 0.5px solid #a6a6a6;

  &:last-child {
    border-right: 0px;
  }
`;

const SocialMediaRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`;

const MediaIcon = styled.div`
  height: ${({ height }) => (height ? height : 46)}px;
  width: ${({ width }) => (width ? width : 46)}px;
  margin-right: 16px;

  &:hover {
    fill: ${({ theme }) => theme.color.primary};
  }
`;

const SocialMediaIcon = styled.a`
  height: ${({ height }) => (height ? height : 46)}px;
  width: ${({ width }) => (width ? width : 46)}px;
  margin-right: 16px;

  &:hover {
    fill: ${({ theme }) => theme.color.primary};
  }
`;

const NeedHelpWrapper = styled.div`
  margin-bottom: 2px;
  @media only screen and (max-width: 970px) {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  height: ${({ height }) => (height ? height : 46)}px;
  width: ${({ width }) => (width ? width : 46)}px;

  &.hover-primary {
    &:hover {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`;

const SaecLogo = styled.div`
  height: ${({ height }) => (height ? height : 46)}px;
  width: ${({ width }) => (width ? width : 46)}px;

  background: url('/images/saec.svg') no-repeat;
`;

interface FooterProps {
  footerNav0: any;
  footerNav1: any;
  footerNav2: any;
  footerPhone: string;
  footerMail: string;
  social?: any;
  setChatVisibility: any;
}

class Footer extends React.PureComponent<FooterProps> {
  public render() {
    const footerPhone = this.props.footerPhone;
    const footerMail = this.props.footerMail;
    const { setChatVisibility } = this.props;

    return (
      <Wrapper>
        <ContentWrapper>
          <LeftFooterSide>
            <ContactRowWrapper>
              {footerPhone ? (
                <FooterLink
                  icon="phone"
                  url={`tel:${footerPhone}`}
                  text={footerPhone}
                />
              ) : (
                ''
              )}
              {footerMail ? (
                <FooterLink
                  icon="mail"
                  url={`mailto:${footerMail}`}
                  text={footerMail}
                />
              ) : (
                ''
              )}
            </ContactRowWrapper>
            <NeedHelpWrapper>
              <NeedHelpText>{__('Potrebujete poradiť?')}</NeedHelpText>
              <p style={{ margin: '0px', marginBottom: '6px' }}>
                {__('Prezrite si ')}
                <QuestionsLink href="https://www.daffer.sk/pomoc-a-podpora/casto-kladene-otazky">
                  {__('Často kladené otázky')}
                </QuestionsLink>
                {__(' alebo nás kontaktujte')}
              </p>
            </NeedHelpWrapper>
            <ChatRow>
              <ChatIcon />
              <ChatButtonTrigger onClick={setChatVisibility}>
                {__(' Online pomocník - chat ')}
              </ChatButtonTrigger>
            </ChatRow>
            <MobileChatRow>
              <ChatIcon />
              <ChatButtonTrigger onClick={setChatVisibility}>
                {__(' Online pomocník - chat ')}
              </ChatButtonTrigger>
              <a
                href={'https://www.daffer.sk/kontakt/o-firme-daffer'}
                style={{
                  paddingLeft: '6px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: 'Daffer spol.&nbsp;s&nbsp;r.o.',
                  }}
                />
              </a>
            </MobileChatRow>
          </LeftFooterSide>
          <RightFooterSide>
            <ShopInfoRow>
              {this.props.footerNav0 && this.props.footerNav0.children.length
                ? this.props.footerNav0.children.map((item) => {
                    return (
                      <ShopInfoRowItem href={item.link}>
                        {__(item.name)}
                      </ShopInfoRowItem>
                    );
                  })
                : null}
            </ShopInfoRow>
            <SocialMediaRow>
              <SocialMediaIcon
                href={'https://www.facebook.com/daffer.sk'}
                aria-label={__('Facebook')}
              >
                <StyledIcon
                  icon={'facebook_footer'}
                  className={'hover-primary'}
                />
              </SocialMediaIcon>
              <SocialMediaIcon
                aria-label={__('Youtube')}
                href={
                  'https://www.youtube.com/channel/UC80eR086bA9QVGPpVrpzfGg'
                }
              >
                <StyledIcon
                  icon={'youtube_footer'}
                  className={'hover-primary'}
                />
              </SocialMediaIcon>
              <SocialMediaIcon
                aria-label={__('LinkedIn')}
                href={'https://www.linkedin.com/company/daffer-spol.-s-r.o./'}
              >
                <StyledIcon
                  icon={'linkedin_footer'}
                  className={'hover-primary'}
                />
              </SocialMediaIcon>
              <SocialMediaIcon
                aria-label={__('Instagram')}
                href={'https://www.instagram.com/daffer_dobrepapiernictvo'}
              >
                <StyledIcon
                  icon={'instagram_footer'}
                  className={'hover-primary'}
                />
              </SocialMediaIcon>
              <MediaIcon width={110} height={46}>
                <SaecLogo width={110} height={46} />
              </MediaIcon>
            </SocialMediaRow>
            <CompanyInfoRow>
              {this.props.footerNav1 && this.props.footerNav1.children.length
                ? this.props.footerNav1.children.map((item) => {
                    let fontWeight;

                    if (item.url === 'zakaznicky-servis/o-firme-daffer') {
                      fontWeight = 'bold';
                    } else {
                      fontWeight = 'normal';
                    }

                    return (
                      <ShopInfoRowItem
                        style={{ fontWeight: fontWeight }}
                        href={item.link}
                      >
                        {__(item.name)}
                      </ShopInfoRowItem>
                    );
                  })
                : null}
            </CompanyInfoRow>
          </RightFooterSide>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default Footer;
