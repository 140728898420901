import * as React from 'react';
import * as cookie from 'react-cookies';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { resolveLangByHostname } from '../../configureTrans';
import API, { ThenArg } from '../../services/API';
import {
  loadFooterData,
  loadGeneralFreeDeliveryInfo,
  loadMainMenu,
  loadOtherDefaultsTexts,
  loadsCategoryGroup,
  setAddToCartModalProduct,
  setAddToCartModalProductOutOfStock,
  setAddToCartModalProductQuantityLimit,
  setCurrency,
  setCurrentProductForPreview,
  setLanguage,
  setSelectVariantModal,
  setDeliveryPointModalPoint,
  setCmsContentModal,
  setArticleModal,
  resetToken,
  setPrevPath,
  loadDefaultSettings,
  setInfoMultipleStoresShow,
  setUndefinedCategory,
  setShopsPersonalPickupModal,
  setUndefinedSkvelkoSet,
  setGiftRemain,
  setCartProtectionModal,
  setCartHadUserModal,
  loadInvalidEmails,
  setSearchInitialLoaded,
  loadSearchedResultsPreview,
  setSearchedResultsPreviewLoader,
  setMcCidCookie,
  loadFavoriteProductsCount,
  setPacketaDeliveryPointModalPoint,
  setAddToCartMaxItemExceededModalProduct,
  createCookieConsentLog,
} from './actions';
import {
  CART_PROTECTION_COOKIE,
  CURRENCY_COOKIE,
  USER_COOKIE,
} from './constants';
import Footer from '../../components/_layout/Footer/Footer';
import Header from '../../components/_layout/Header/Header';
import {
  canShowProtectionModal,
  cookieAgree,
  cookieDisagree,
  debounce,
  inCart,
  inSimpleTemplate,
  isMainMenuVisible,
  isSsr,
  loadSrrCookieOrDefault,
  prop,
} from '../../utilities';
import ProductAddedToCartModal from '../../components/_helpers/Modal/ProductAddedToCartModal';
import {
  createCartClone,
  loadCart,
  setShowRecognizedCustomerCardModal,
  updateCart,
} from '../Cart/actions';
import { push, replace } from 'react-router-redux';
import Cookies from '../../components/_helpers/Cookies/Cookies';
import MetaTags from '../../components/MetaTags/MetaTags';
import { GlobalStyle } from '../../theme/globalStyles';
//import { CustomStyle } from '../../theme/customStyles';
import ProductPreviewModal from '../../components/_helpers/Modal/ProductPreviewModal';
import ProductAddedToCartModalOutOfStock from '../../components/_helpers/Modal/ProductAddedToCartModalOutOfStock';
import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects';
import SelectVariantModal from '../../components/_helpers/Modal/SelectVariantModal';
import RecognizedCustomerCardModal from '../../components/_helpers/Modal/RecognizedCustomerCardModal';
import ProductAddedToCartModalQuantityLimit from '../../components/_helpers/Modal/ProductAddedToCartModalQuantityLimit';
import HeaderSimple, {
  HeaderSimpleType,
} from '../../components/_layout/Header/HeaderSimple';
import DpdDeliveryPointModal from '../../components/_helpers/Modal/DpdDeliveryPointModal';
import CmsContentModal from '../../components/_helpers/Modal/CmsContentModal';
import ArticleModal from '../../components/_helpers/Modal/ArticleModal';
import SmartsuppChat from '../../components/_helpers/SmartsuppChat/SmartsuppChat';
import MultipleStoreInfoModal from '../../components/_helpers/Modal/MultipleStoreInfoModal';
import { WithRouterProps } from 'react-router';
import MailchimpShoolModal from '../../components/_helpers/Modal/MailchimpShoolModal';
import {
  URL_LOGIN,
  URL_SEARCH,
  URL_TYPES_CATEGORY,
  URL_TYPES_PRODUCT,
  URLS_CART,
} from '../../constants';
import { loginUser } from '../Auth/action';
import { isIOS } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import ShopPersonalPickupModal from '../../components/_helpers/Modal/ShopPersonalPickupModal';
import GiftRemainModal from '../../components/_helpers/Modal/GiftRemainModal';
import { loadFilterFromLocation } from '../Home/actions';
import CartProtectionModal from '../../components/_helpers/Modal/CartProtectionModal';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import { __ } from 'react-i18n';
import { forceVisible } from 'react-lazyload';
import PacketaDeliveryPointModal from '../../components/_helpers/Modal/PacketaDeliveryPointModal';
import ConfirmModal from '../../components/_helpers/Modal/ConfirmModal';
import UpsellNewsletter from '../../components/UpsellNewsletter/UpsellNewsletter';
import { CART_COOKIE_ID } from '../Cart/constants';

const [searchDebounce] = debounce(
  (dispatch, query) => dispatch(loadSearchedResultsPreview({ q: query })),
  1000,
);

interface Props {
  footerData: any[];
  footerNav0: any;
  footerNav1: any;
  footerNav2: any;
  footerPhone: string;
  footerMail: string;
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  categoriesGroup: any[];
  dispatch: any;
  mainMenu: any;
  isError: boolean;
  user: object;
  token: string;
  lang: string;
  initLoaded: boolean;
  searchedResultsPreview: any;
  searchedResultsPreviewLoader: boolean;
  cart: ThenArg<typeof API.getCart>;
  addToCartModalProduct: any;
  addToCartModalProductOutOfStock: any;
  addToCartModalProductQuantityLimit: any;
  addToCartMaxItemExceededModalProduct: any;
  selectVariantModal: any;
  otherTexts: any;
  currentProductForPreview: any;
  recognizedCustomerCard: boolean;
  currentPath: string;
  deliveryFreePrice: number;
  dpdDeliveryPointModal: any;
  packetaDeliveryPointModal: any;
  shopPersonalPickupModal: any;
  cmsContentModal: string;
  articleModal: number;
  infoMultipleStoresShow: boolean;
  giftRemain: boolean;
  cartProtectionModal: boolean;
  cartHadUserModal: boolean;
  relatedProductsModal: any;
  relatedProductsModalLoader: boolean;
  protectionSettings: any;
  invalidEmails: any;
  favoriteProductsCount: number;
  currentUniqs: string[];
  settings: any;
  cookies: any;
}

interface State {
  searchDropdownVisible: boolean;
  searchDropdownMobileVisible: boolean;
  searchedQuery: string;
  searchedQueryLast: string;
  searchFocus: boolean;
  cookiesBarHidden: boolean;
  scrolledDown: boolean;
  titleText: string;
  subtitleText: string;
  closePath: string;
  simpleType: HeaderSimpleType;
  chatWindowVisible: boolean;
  clientLoaded: boolean;
}

class App extends React.PureComponent<Props & WithRouterProps, State> {
  public static async getInitialProps(props) {
    try {
      const { token, user, cookies } = props;
      // nacitame usera, ak existuje token
      if ((!token || !user) && props.location.pathname !== URL_LOGIN) {
        const userCookie = loadSrrCookieOrDefault(cookies, USER_COOKIE);
        if (userCookie) {
          await props.dispatch(loginUser(userCookie));
        } else {
          await props.dispatch(resetToken());
        }
      }
    } catch (e) {
      console.log(e);
    }

    try {
      await props.dispatch(checksRedirect(props.location));
    } catch (e) {
      // silence is marvelous
    }

    try {
      const promises: any[] = [];

      if (!props.metaTags || !props.contactInfo || !props.title) {
        promises.push(props.dispatch(loadDefaultSettings()));
      }

      if (!props.categoriesGroup) {
        promises.push(props.dispatch(loadsCategoryGroup()));
      }

      if (!props.otherTexts) {
        promises.push(props.dispatch(loadOtherDefaultsTexts()));
      }

      if (!props.footerData) {
        promises.push(props.dispatch(loadFooterData()));
      }

      if (!props.mainMenu) {
        promises.push(props.dispatch(loadMainMenu()));
      }

      if (!props.deliveryFreePrice) {
        promises.push(props.dispatch(loadGeneralFreeDeliveryInfo()));
      }

      if (!props.invalidEmails) {
        promises.push(props.dispatch(loadInvalidEmails()));
      }

      if (!props.cart && !props.location?.query?.cartcopyid) {
        promises.push(
          props.dispatch(
            loadCart(loadSrrCookieOrDefault(props.cookies, CART_COOKIE_ID)),
          ),
        );
      }

      if (!props.favoriteProductsCount) {
        promises.push(props.dispatch(loadFavoriteProductsCount()));
      }

      await Promise.all(promises);

      props.dispatch(setLanguage(resolveLangByHostname(props.hostname)));
      props.dispatch(
        setCurrency(loadSrrCookieOrDefault(props.cookies, CURRENCY_COOKIE)),
      );

      return {
        initLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  private prev = 0;

  constructor(props) {
    super(props);

    this.state = {
      searchDropdownVisible: false,
      searchDropdownMobileVisible: false,
      searchedQuery: '',
      searchedQueryLast: '',
      searchFocus: false,
      cookiesBarHidden: cookie.load('COOKIES'),
      scrolledDown: false,
      titleText: '',
      subtitleText: '',
      closePath: '',
      simpleType: HeaderSimpleType.BASE,
      chatWindowVisible: false,
      clientLoaded: false,
    };
  }

  public componentDidMount() {
    if (isSsr()) {
      return;
    }
    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleScroll(e));

    // Skopirovanie kosika, ak sa identifikator nachadza v query
    const cartCopyId = this.props.location?.query?.cartcopyid;
    if (cartCopyId) {
      this.props
        .dispatch(createCartClone(cartCopyId))
        .finally(() =>
          this.props.dispatch(replace(this.props.location?.pathname)),
        ); // odstranenie query parametra z URL
    }

    try {
      if (this.props?.location?.query?.mc_cid) {
        this.props.dispatch(setMcCidCookie(this.props.location.query.mc_cid));
      }
    } catch (e) {
      console.log(e);
    }

    if (this.state.clientLoaded === false) {
      this.setState({
        clientLoaded: true,
      });
    }
  }

  public async componentDidUpdate(prevProps) {
    if (URLS_CART.indexOf(prevProps.location.pathname) === -1) {
      this.props.dispatch(setPrevPath(prevProps.location.pathname));
    }
    if (prevProps.currentPath !== this.props.currentPath) {
      if (this.props.currentPath === '/') {
        this.props.dispatch(setUndefinedCategory());
        return;
      }
      try {
        const urlMapData = await API.getUrlMap(
          {
            url: this.props.location.pathname.replace('/preview/', ''),
          },
          {
            xAcceptLanguage: this.props.lang,
          },
        );
        const { type } = urlMapData;
        const isCategoryType = type && URL_TYPES_CATEGORY.includes(type as any);
        const isProductType = type && URL_TYPES_PRODUCT.includes(type as any);
        if (!isCategoryType && !isProductType) {
          this.props.dispatch(setUndefinedCategory());
        }
      } catch (e) {
        this.props.dispatch(setUndefinedCategory());
      }
    }
  }

  public handleScroll(e) {
    if (isSsr()) {
      return;
    }
    const window = e.currentTarget;

    if (window.scrollY > 0) {
      if (this.state.scrolledDown && this.prev > window.scrollY) {
        this.setState({ scrolledDown: false });
      } else if (!this.state.scrolledDown && this.prev < window.scrollY) {
        this.setState({ scrolledDown: true });
      }
    }
    this.prev = window.scrollY;
  }

  public showChatWindow = (e) => {
    this.setState({ chatWindowVisible: !this.state.chatWindowVisible });
  };

  public handleChangeSearchQuery = (query) => {
    this.setState({
      searchedQuery: query,
    });
    if (query !== '') {
      if (!this.state.searchDropdownVisible) {
        this.setState({
          searchDropdownVisible: true,
        });
      }
    } else {
      this.setState({
        searchDropdownVisible: false,
      });
    }

    if (query !== '') {
      if (!this.state.searchDropdownMobileVisible) {
        this.setState({
          searchDropdownMobileVisible: true,
        });
      }
    } else {
      this.setState({
        searchDropdownMobileVisible: false,
      });
    }

    if (this.state.searchedQueryLast !== query) {
      this.setState({
        searchedQueryLast: query,
      });
      this.props.dispatch(setSearchedResultsPreviewLoader(true));
      searchDebounce(this.props.dispatch, query);
    }
  };

  public onSubmitSearchInput = (
    section: string = '0',
    redirectUrl: string | null = null,
  ) => {
    if (this.state.searchedQuery === '') {
      return;
    }

    this.props.dispatch(setSearchInitialLoaded(false));

    /*
    if (this.props.location?.pathname?.startsWith(URL_SEARCH)) {
      this.props.dispatch(setSearchInitialLoaded(false));
    }
    */

    if (redirectUrl) {
      this.props.dispatch(push(redirectUrl));
    } else {
      this.props.dispatch(
        push(
          encodeURI(
            `${URL_SEARCH}?q=${this.state.searchedQuery}&section=${section}`,
          ),
        ),
      );
    }

    if (!isSsr()) {
      window.dataLayer.push({
        event: 'search-botton-click',
      });
    }
    this.setState({
      searchDropdownVisible: false,
      searchDropdownMobileVisible: false,
      searchedQuery: '',
      searchedQueryLast: '',
    });
  };

  public handleCloseSearchMobileDropdown = () => {
    if (this.state.searchDropdownMobileVisible) {
      this.setState({
        searchDropdownMobileVisible: false,
      });
    }
  };

  public handleCloseSearchDropdown = () => {
    if (this.state.searchDropdownVisible) {
      this.setState({
        searchDropdownVisible: false,
      });
    }
    if (this.state.searchDropdownMobileVisible) {
      this.setState({
        searchDropdownMobileVisible: false,
      });
    }
  };

  public handleMenuSearchFn = (url, skvelkoSetId = null) => {
    this.props.dispatch(setUndefinedCategory());
    this.props.dispatch(setUndefinedSkvelkoSet());

    if (this.state.searchedQuery !== '') {
      this.setState({
        searchedQuery: '',
      });
    }

    if (skvelkoSetId) {
      this.props.dispatch(loadFilterFromLocation(skvelkoSetId));
    }

    this.props.dispatch(push(url));
  };

  public onCloseProductAddedToCartModal = () => {
    this.props.dispatch(setAddToCartModalProduct(null));
  };

  public onCloseProductAddedToCartModalOutOfStock = () => {
    this.props.dispatch(setAddToCartModalProductOutOfStock(null));
  };

  public onCloseProductAddedToCartModalQuantityLimit = () => {
    this.props.dispatch(setAddToCartModalProductQuantityLimit(null));
  };

  public onCloseSetVariantModal = () => {
    this.props.dispatch(setSelectVariantModal(null));
  };

  public onCloseCurrentProductPreview = () => {
    this.props.dispatch(setCurrentProductForPreview(null));
  };

  public onCloseRecognizedCard = () => {
    this.props.dispatch(setShowRecognizedCustomerCardModal(false));
  };

  public onCloseDpdDeliveryPointModal = () => {
    this.props.dispatch(setDeliveryPointModalPoint(null));
  };

  public onClosePacketaDeliveryPointModal = () => {
    this.props.dispatch(setPacketaDeliveryPointModalPoint(null));
  };

  public onCloseShopPersonalPickupModal = () => {
    this.props.dispatch(setShopsPersonalPickupModal(null));
  };

  public onCloseCmsModal = () => {
    this.props.dispatch(setCmsContentModal(null));
  };

  public onCloseArticleModal = () => {
    this.props.dispatch(setArticleModal(null));
  };

  public onCloseMultipleStoreInfoModal = () => {
    this.props.dispatch(setInfoMultipleStoresShow(false));
  };

  public onCloseGiftRemainModal = () => {
    this.props.dispatch(setGiftRemain(false));
  };

  public onCloseCartProtectionModal = () => {
    this.props.dispatch(setCartProtectionModal(false));
  };

  public onClickAgreeCookies = () => {
    cookieAgree();
    this.props.dispatch(createCookieConsentLog(true));
    this.setState({ cookiesBarHidden: true });
    if (window && window.location) {
      window.location.href = window.location.href;
    }
  };

  public onClickDisagreeCookies = () => {
    cookieDisagree();
    this.props.dispatch(createCookieConsentLog(false));
    this.setState({ cookiesBarHidden: true });
    if (window && window.location) {
      window.location.href = window.location.href;
    }
  };

  public onSearchFocus = (isFocus: boolean) => {
    this.setState({ searchFocus: isFocus });
  };

  public setTitleText = (text) => {
    this.setState({
      titleText: text,
    });
  };

  public setSubtitleText = (text) => {
    this.setState({
      subtitleText: text,
    });
  };

  public setClosePath = (text: string) => {
    this.setState({
      closePath: text,
    });
  };

  public setHeaderSimpleType = (headerSimpleType) => {
    this.setState({
      simpleType: headerSimpleType,
    });
  };

  public onCartHadUserModalCancelClick = () => {
    this.props.dispatch(updateCart({ ...this.props.cart, had_user: false }));
    this.props.dispatch(setCartHadUserModal(false));
  };

  public onCartMatItemExceededModalClick = () => {
    this.props.dispatch(setAddToCartMaxItemExceededModalProduct(false));
  };

  public onCartHadUserModalLoginClick = () => {
    this.props.dispatch(updateCart({ ...this.props.cart, had_user: false }));
    this.props.dispatch(setCartHadUserModal(false));
    this.props.dispatch(push(URL_LOGIN));
  };

  public isModalShown = (ignoreProps: string[] = []) => {
    const propsToCheck = {
      addToCartModalProduct: this.props.addToCartModalProduct,
      cartProtectionModal: this.props.cartProtectionModal,
      giftRemain: this.props.giftRemain,
      infoMultipleStoresShow: this.props.infoMultipleStoresShow,
      articleModal: this.props.articleModal,
      cmsContentModal: this.props.cmsContentModal,
      dpdDeliveryPointModal: this.props.dpdDeliveryPointModal,
      packetaDeliveryPointModal: this.props.packetaDeliveryPointModal,
      shopPersonalPickupModal: this.props.shopPersonalPickupModal,
      currentProductForPreview: this.props.currentProductForPreview,
      selectVariantModal: this.props.selectVariantModal,
      addToCartModalProductQuantityLimit: this.props
        .addToCartModalProductQuantityLimit,
      addToCartModalProductOutOfStock: this.props
        .addToCartModalProductOutOfStock,
      cartHadUserModal: this.props.cartHadUserModal,
    };

    return Object.entries(propsToCheck)
      .filter(([key]) => !ignoreProps.includes(key))
      .some(([, value]) => !!value);
  };

  public render() {
    const currentPath = prop(this, 'props.router.location.pathname', '/');
    const isSimpleTemplate = inSimpleTemplate(currentPath);

    if (window && typeof window.addEventListener === 'function') {
      window.addEventListener('beforeprint', (event) => {
        forceVisible();
      });
    }

    if (
      canShowProtectionModal(
        this.props.cart,
        this.props.user,
        this.props.protectionSettings,
        this.isModalShown(),
      )
    ) {
      cookie.save(CART_PROTECTION_COOKIE, this.props.cart.id, {
        maxAge: 60 * 5,
      });
      this.props.dispatch(setCartProtectionModal(true));
    }

    return (
      <React.Fragment>
        {isIOS && (
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
            />
          </Helmet>
        )}
        <GlobalStyle
          isCart={inCart(currentPath)}
          isSimple={isSimpleTemplate}
          showMainMenu={isMainMenuVisible(currentPath, this.props.currentUniqs)}
        />
        {/*
        <CustomStyle />
        */}
        {this.props.metaTags && <MetaTags tags={this.props.metaTags} />}

        <UpsellNewsletter
          newsletterCouponActive={
            this.props?.settings?.newsletterCouponActive ? true : false
          }
          newsletterCouponAppearSeconds={
            this.props?.settings?.newsletterCouponAppearSeconds
              ? this.props.settings.newsletterCouponAppearSeconds
              : 0
          }
          user={this.props.user}
        />

        {isSimpleTemplate ? (
          <>
            <HeaderSimple
              titleText={this.state.titleText}
              subtitleText={this.state.subtitleText}
              closePath={this.state.closePath}
              headerType={this.state.simpleType}
              location={this.props.location}
            />
            {React.cloneElement(this.props.children as React.ReactElement, {
              setTitleText: this.setTitleText,
              setSubtitleText: this.setSubtitleText,
              setHeaderSimpleType: this.setHeaderSimpleType,
              setClosePath: this.setClosePath,
            })}
          </>
        ) : (
          <>
            <Header
              visible={
                (this.state.scrolledDown || this.isModalShown()) &&
                !this.state.searchFocus
              }
              mainMenu={this.props.mainMenu && this.props.mainMenu.sitemap_tree}
              dispatch={this.props.dispatch}
              cart={this.props.cart}
              user={this.props.user}
              searchDropdownVisible={this.state.searchDropdownVisible}
              searchDropdownMobileVisible={
                this.state.searchDropdownMobileVisible
              }
              searchedQuery={this.state.searchedQuery}
              searchedResults={this.props.searchedResultsPreview}
              searchedResultsLoader={this.props.searchedResultsPreviewLoader}
              onChangeSearchInput={this.handleChangeSearchQuery}
              onSubmitSearchInput={this.onSubmitSearchInput}
              onClickOutsideSearchForm={this.handleCloseSearchDropdown}
              onClickOutsideSearchMobileForm={
                this.handleCloseSearchMobileDropdown
              }
              onRedirect={this.handleMenuSearchFn}
              currentPath={currentPath}
              categoriesGroup={this.props.categoriesGroup}
              onSearchFocus={this.onSearchFocus}
              favoriteProductsCount={this.props.favoriteProductsCount}
              currentUniqs={this.props.currentUniqs}
              location={this.props.location}
              settings={this.props.settings}
            />

            <div
              style={
                !this.state.searchDropdownMobileVisible
                  ? {}
                  : { display: 'none' }
              }
            >
              {this.props.children}
            </div>

            <Footer
              footerNav0={this.props.footerNav0}
              footerNav1={this.props.footerNav1}
              footerNav2={this.props.footerNav2}
              footerMail={this.props.footerMail}
              footerPhone={this.props.footerPhone}
              setChatVisibility={this.showChatWindow}
            />
          </>
        )}

        {this.state.cookiesBarHidden ||
        !this.state.clientLoaded ||
        !this.props.otherTexts ||
        !this.props.otherTexts.COOKIES ? (
          ''
        ) : (
          <Cookies
            text={this.props.otherTexts.COOKIES}
            onClickAgree={this.onClickAgreeCookies}
            onClickDisAgree={this.onClickDisagreeCookies}
          />
        )}

        {/*
        {this.state.chatWindowVisible ? <SmartsuppChat /> : null}
        */}

        <SmartsuppChat />

        {this.props.addToCartModalProduct && (
          <ProductAddedToCartModal
            product={this.props.addToCartModalProduct.product}
            count={this.props.addToCartModalProduct.count}
            isUpsell={this.props.addToCartModalProduct.isUpsell}
            goodId={this.props.addToCartModalProduct.goodId}
            onCloseButtonClick={this.onCloseProductAddedToCartModal}
            dispatch={this.props.dispatch}
            user={this.props.user}
            cart={this.props.cart}
            freeShippingPrice={this.props.deliveryFreePrice}
            relatedProductsModal={this.props.relatedProductsModal}
            relatedProductsModalLoader={this.props.relatedProductsModalLoader}
          />
        )}

        {this.props.addToCartModalProductOutOfStock && (
          <ProductAddedToCartModalOutOfStock
            product={this.props.addToCartModalProductOutOfStock.product}
            cart={this.props.cart}
            onCloseButtonClick={this.onCloseProductAddedToCartModalOutOfStock}
          />
        )}

        {this.props.addToCartModalProductQuantityLimit && (
          <ProductAddedToCartModalQuantityLimit
            product={this.props.addToCartModalProductQuantityLimit.product}
            goodId={this.props.addToCartModalProductQuantityLimit.goodId}
            cart={this.props.cart}
            onCloseButtonClick={
              this.onCloseProductAddedToCartModalQuantityLimit
            }
          />
        )}

        {this.props.selectVariantModal && (
          <SelectVariantModal
            product={this.props.selectVariantModal}
            dispatch={this.props.dispatch}
            onCloseButtonClick={this.onCloseSetVariantModal}
          />
        )}

        {this.props.currentProductForPreview && (
          <ProductPreviewModal
            product={this.props.currentProductForPreview}
            onCloseButtonClick={this.onCloseCurrentProductPreview}
          />
        )}

        {this.props.recognizedCustomerCard && (
          <RecognizedCustomerCardModal
            cart={this.props.cart}
            onCloseButtonClick={this.onCloseRecognizedCard}
          />
        )}

        {this.props.dpdDeliveryPointModal && (
          <DpdDeliveryPointModal
            deliveryPoint={this.props.dpdDeliveryPointModal}
            onCloseButtonClick={this.onCloseDpdDeliveryPointModal}
          />
        )}

        {this.props.packetaDeliveryPointModal && (
          <PacketaDeliveryPointModal
            packetaDeliveryPoint={this.props.packetaDeliveryPointModal}
            onCloseButtonClick={this.onClosePacketaDeliveryPointModal}
          />
        )}

        {this.props.shopPersonalPickupModal && (
          <ShopPersonalPickupModal
            shopPersonalPickup={this.props.shopPersonalPickupModal}
            onCloseButtonClick={this.onCloseShopPersonalPickupModal}
          />
        )}

        {this.props.cmsContentModal && (
          <CmsContentModal
            dispatch={this.props.dispatch}
            sitemapUid={this.props.cmsContentModal}
            onCloseButtonClick={this.onCloseCmsModal}
          />
        )}

        {this.props.articleModal && (
          <ArticleModal
            articleId={this.props.articleModal}
            onCloseButtonClick={this.onCloseArticleModal}
          />
        )}

        {this.props.infoMultipleStoresShow && (
          <MultipleStoreInfoModal
            dispatch={this.props.dispatch}
            onCloseButtonClick={this.onCloseMultipleStoreInfoModal}
          />
        )}

        {this.props.giftRemain && (
          <GiftRemainModal
            cart={this.props.cart}
            onCloseButtonClick={this.onCloseGiftRemainModal}
          />
        )}

        {this.props.cartProtectionModal &&
          !this.isModalShown(['cartProtectionModal']) && (
            <CartProtectionModal
              dispatch={this.props.dispatch}
              cart={this.props.cart}
              onCloseButtonClick={this.onCloseCartProtectionModal}
            />
          )}

        {this.props.cartHadUserModal && !this.props.addToCartModalProduct ? (
          <ChooseModal
            title="Prihláste sa"
            bodyText={
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props?.otherTexts?.CART_AUTO_LOGOUT?.content
                      ?.json_content?.body,
                  }}
                />
              </>
            }
            button1Text={__('Zrušiť')}
            button2Text={__('Prihlásiť')}
            onButton1Click={this.onCartHadUserModalCancelClick}
            onButton2Click={this.onCartHadUserModalLoginClick}
            notShowClose={true}
          />
        ) : (
          <></>
        )}

        {this.props.addToCartMaxItemExceededModalProduct && (
          <ConfirmModal
            title={__('Produkt sa nevložil do košíka')}
            bodyText={
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props?.otherTexts?.CART_MAX_ITEM_EXCEEDED
                      ?.content?.json_content?.body,
                  }}
                />
              </>
            }
            buttonText={__('OK')}
            onButtonClick={this.onCartMatItemExceededModalClick}
            onCloseButtonClick={this.onCartMatItemExceededModalClick}
          />
        )}

        <MailchimpShoolModal
          dispatch={this.props.dispatch}
          location={this.props.location}
          cookies={this.props.cookies}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    mainMenu: state.general.mainMenu,
    footerData: state.general.footerData,
    footerNav0: prop(state, 'general.footerData.sitemap_tree.0'),
    footerNav1: prop(state, 'general.footerData.sitemap_tree.1'),
    footerNav2: prop(state, 'general.footerData.sitemap_tree.2'),
    footerPhone: prop(state, 'general.contactInfo.contactTel'),
    footerMail: prop(state, 'general.contactInfo.contactEmail'),
    categoriesGroup: state.general.categoriesGroup,
    metaTags: state.general.metaTags,
    contactInfo: state.general.contactInfo,
    protectionSettings: state.general.protectionSettings,
    title: state.general.title,
    lang: state.general.lang,
    searchedResultsPreview: prop(state.general, 'searchedResultsPreview'),
    searchedResultsPreviewLoader: prop(
      state.general,
      'searchedResultsPreviewLoader',
    ),
    cart: state.cart.cart,
    addToCartModalProduct: state.general.addToCartModalProduct,
    addToCartModalProductOutOfStock:
      state.general.addToCartModalProductOutOfStock,
    addToCartModalProductQuantityLimit:
      state.general.addToCartModalProductQuantityLimit,
    addToCartMaxItemExceededModalProduct:
      state.general.addToCartMaxItemExceededModalProduct,
    selectVariantModal: state.general.selectVariantModal,
    dpdDeliveryPointModal: state.general.deliveryPointModalPoint,
    packetaDeliveryPointModal: state.general.packetaDeliveryPointModalPoint,
    shopPersonalPickupModal: state.general.shopPersonalPickupModal,
    otherTexts: state.general.otherTexts,
    currentProductForPreview: state.general.currentProductForPreview,
    recognizedCustomerCard: state.cart.recognizedCustomerCard,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    deliveryFreePrice: state.general.deliveryFreePrice,
    cmsContentModal: state.general.cmsContentModal,
    articleModal: state.general.articleModal,
    infoMultipleStoresShow: state.general.infoMultipleStoresShow,
    giftRemain: state.general.giftRemain,
    cartProtectionModal: state.general.cartProtectionModal,
    cartHadUserModal: state.general.cartHadUserModal,
    relatedProductsModal: state.product.relatedProductsModal,
    relatedProductsModalLoader: state.product.relatedProductsModalLoader,
    invalidEmails: state.general.invalidEmails,
    favoriteProductsCount: state.general.favoriteProductsCount,
    currentUniqs: prop(state, 'general.currentUniqs'),
    settings: state.general.settings,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
