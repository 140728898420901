import * as React from 'react';
import {
  getLoggedInMenu,
  MenuItems,
  URL_ACCOUNT_BULK_ORDER,
  URL_ACCOUNT_PRODUCTS_FOR_SETS,
  URL_ACCOUNT_SETS,
  URL_SKVELKOBOX_BENEFITS,
  URL_SKVELKOBOX_INSTRUCTIONS,
} from '../../constants';
import SidebarContainer from '../../components/_layout/Sidebar/Sidebar';

interface Props {
  currentPage: string;
  user: any;
}

const urlMapping: { original: string; mapped: string }[] = [
  {
    original: URL_ACCOUNT_PRODUCTS_FOR_SETS,
    mapped: URL_ACCOUNT_SETS,
  },
  {
    original: URL_ACCOUNT_BULK_ORDER,
    mapped: URL_ACCOUNT_SETS,
  },
  {
    original: URL_SKVELKOBOX_INSTRUCTIONS,
    mapped: URL_SKVELKOBOX_BENEFITS,
  },
];

const AccountNav = (props: Props) => {
  const { user, currentPage } = props;

  const menuItems: MenuItems = getLoggedInMenu(user);

  const isActive = (item, currentPage) => {
    if (item.url === currentPage || `/${item.url}` === currentPage) return true;
    const mappedUrls = urlMapping.filter(
      (urlMap) => urlMap.original === currentPage,
    );
    for (const mappedUrl of mappedUrls) {
      if (mappedUrl.mapped === item.url) return true;
    }

    return false;
  };

  return (
    <SidebarContainer
      items={menuItems.map((item) => {
        return {
          active: isActive(item, currentPage),
          url: item.url,
          text: item.name,
        };
      })}
    />
  );
};

export default AccountNav;
