/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  description?: string;

  name?: string;

  payload?: {};

  statusCode?: number;

  userinfo?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class API
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class API {
  protected baseUrl: string = '';
  protected token: string = '';
  protected apiKey: string | null = null;

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    if (this.apiKey) {
      headers.append('api-key', this.apiKey);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Create order document
   * @method
   * @name API#generateOrderDocument
   */
  generateOrderDocument(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis' | 'karat_faktura',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/admin/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#updateContent
   */
  updateContent(
    contentId: number,
    parameters: {} = {},
    body: {
      is_top: number;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: number;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;
  }> {
    let path = '/contents/{content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Add article to most read
   * @method
   * @name API#addArticleToMostRead
   */
  addArticleToMostRead(
    contentId: number,
    parameters: {} = {},
    body: {
      date_type: string;

      ordering: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: number;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;
  }> {
    let path = '/contents/{content_id}/most-read';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove article from most read
   * @method
   * @name API#removeArticleFromMostRead
   */
  removeArticleFromMostRead(
    contentId: number,
    dateType: 'week' | 'month' | 'year',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/contents/{content_id}/most-read/{date_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{content_id}', contentId.toString());

    path = path.replace('{date_type}', dateType.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Upload video
   * @method
   * @name API#uploadVideo
   */
  uploadVideo(
    contentId: number,
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/contents/{content_id}/video-upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete video
   * @method
   * @name API#deleteVideo
   */
  deleteVideo(parameters: { fileName: string }): Promise<{}> {
    let path = '/contents/video-delete';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['fileName'] !== 'undefined') {
      queryParameters['file_name'] = parameters['fileName'];
    }

    queryParameters['file_name'] = this.transformParameter(
      queryParameters['file_name'],
    );

    if (typeof parameters['fileName'] === 'undefined') {
      throw new Error('Missing required parameter: fileName');
    }

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about articles
   * @method
   * @name API#loadArticles
   */
  loadArticles(
    parameters: {
      limit?: number;
      offset?: number;
      sitemapId?: number;
      isPublished?: '0' | '1';
      sort?: string;
      sortDir?: string;
      tagId?: number;
      tagExcludeId?: number;
      sitemapUniqueId?: string;
      isTop?: '0' | '1';
      identificator?: string;
      sfForm?: string;
      query?: string;
      brandName?: string;
      onlyActual?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{}>;

    total?: number;
  }> {
    let path = '/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['isPublished'] !== 'undefined') {
      queryParameters['is_published'] = parameters['isPublished'];
    }

    queryParameters['is_published'] = this.transformParameter(
      queryParameters['is_published'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['tagId'] !== 'undefined') {
      queryParameters['tag_id'] = parameters['tagId'];
    }

    queryParameters['tag_id'] = this.transformParameter(
      queryParameters['tag_id'],
    );

    if (typeof parameters['tagExcludeId'] !== 'undefined') {
      queryParameters['tag_exclude_id'] = parameters['tagExcludeId'];
    }

    queryParameters['tag_exclude_id'] = this.transformParameter(
      queryParameters['tag_exclude_id'],
    );

    if (typeof parameters['sitemapUniqueId'] !== 'undefined') {
      queryParameters['sitemap_unique_id'] = parameters['sitemapUniqueId'];
    }

    queryParameters['sitemap_unique_id'] = this.transformParameter(
      queryParameters['sitemap_unique_id'],
    );

    if (typeof parameters['isTop'] !== 'undefined') {
      queryParameters['is_top'] = parameters['isTop'];
    }

    queryParameters['is_top'] = this.transformParameter(
      queryParameters['is_top'],
    );

    if (typeof parameters['identificator'] !== 'undefined') {
      queryParameters['identificator'] = parameters['identificator'];
    }

    queryParameters['identificator'] = this.transformParameter(
      queryParameters['identificator'],
    );

    if (typeof parameters['sfForm'] !== 'undefined') {
      queryParameters['sf_form'] = parameters['sfForm'];
    }

    queryParameters['sf_form'] = this.transformParameter(
      queryParameters['sf_form'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['brandName'] !== 'undefined') {
      queryParameters['brand_name'] = parameters['brandName'];
    }

    queryParameters['brand_name'] = this.transformParameter(
      queryParameters['brand_name'],
    );

    if (typeof parameters['onlyActual'] !== 'undefined') {
      queryParameters['only_actual'] = parameters['onlyActual'];
    }

    queryParameters['only_actual'] = this.transformParameter(
      queryParameters['only_actual'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about article
   * @method
   * @name API#loadArticleDetail
   */
  loadArticleDetail(
    articleId: number,
    parameters: {
      withNextArticle?: '0' | '1';
      withHiddenTags?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: number;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;
  }> {
    let path = '/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{article_id}', articleId.toString());

    if (typeof parameters['withNextArticle'] !== 'undefined') {
      queryParameters['with_next_article'] = parameters['withNextArticle'];
    }

    queryParameters['with_next_article'] = this.transformParameter(
      queryParameters['with_next_article'],
    );

    if (typeof parameters['withHiddenTags'] !== 'undefined') {
      queryParameters['with_hidden_tags'] = parameters['withHiddenTags'];
    }

    queryParameters['with_hidden_tags'] = this.transformParameter(
      queryParameters['with_hidden_tags'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns connected products
   * @method
   * @name API#loadArticleConnections
   */
  loadArticleConnections(
    articleId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<void> {
    let path = '/articles-connections/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>, Samo Sepesi <sepesi@bart.sk>

**Main usage**: Creates new language version of article available for translation

**DESCRIPTION**

Copy articles between two language versions. Source langugage version is determined by CONTENT_ID of article.
Language version of article is full copy of source article. Connections between language version is maintained by
lang_version_id. Endpoint is called after creation of article in admin than administrator can create copy with button.
**!!!WARNING!!! If dest. version already exists it will be first deleted than created again.**

    * @method
    * @name API#copyArticle
    */
  copyArticle(
    articleId: number,
    langId: string,
    parameters: {} = {},
    body: {
      sitemap_id: number;

      name: string;

      url: string;

      is_draft: boolean;
    },
  ): Promise<{}> {
    let path = '/articles/{article_id}/copy/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Link tag from article
   * @method
   * @name API#linkTagToArticle
   */
  linkTagToArticle(
    articleId: number,
    tagId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/articles/{article_id}/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Unlink tag from article
   * @method
   * @name API#unlinkTagFromArticle
   */
  unlinkTagFromArticle(
    articleId: number,
    tagId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/articles/{article_id}/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Change or add article's author
   * @method
   * @name API#changeArticleAuthor
   */
  changeArticleAuthor(
    articleId: number,
    authorId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/articles/{article_id}/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove author from article
   * @method
   * @name API#removeAuthorFromArticle
   */
  removeAuthorFromArticle(
    articleId: number,
    authorId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/articles/{article_id}/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Moves MULTIPLE articles from one section to another sections

**DESCRIPTION**

 Move articles from one section to another sections. Actual version DOES NOT throw any exception or warning
if moving articles between sitemaps with different language versions.

    * @method
    * @name API#moveBulk
    */
  moveBulk(
    parameters: {} = {},
    body: {
      sitemap_id: Array<number>;

      content_ids: Array<number>;
    },
  ): Promise<{}> {
    let path = '/articles/bulk/move';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about authors
   * @method
   * @name API#loadAuthors
   */
  loadAuthors(
    parameters: {
      limit?: number;
      offset?: number;
      query?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    }>;
  }> {
    let path = '/authors';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new author
   * @method
   * @name API#createAuthor
   */
  createAuthor(
    parameters: {} = {},
    body: {
      admin_id?: string;

      name: string;

      url: string;

      image: string;

      json_content: {};
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/authors';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about author
   * @method
   * @name API#loadAuthorDetail
   */
  loadAuthorDetail(
    authorId: number,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    admin_id?: number;

    articles?: Array<{
      author?: {
        author_id?: number;

        image?: {};

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;

    author_id?: number;

    created_at?: string;

    email?: string;

    image?: {};

    json_content?: {};

    name?: string;

    phone?: string;

    total_articles?: number;

    url?: string;

    xml_content?: {};
  }> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update authors's details
   * @method
   * @name API#updateAuthor
   */
  updateAuthor(
    authorId: number,
    parameters: {} = {},
    body: {
      admin_id?: string;

      name?: string;

      url?: string;

      image?: string;

      xml_content?: {};

      json_content?: {};
    },
  ): Promise<{
    admin_id?: number;

    articles?: Array<{
      author?: {
        author_id?: number;

        image?: {};

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;

    author_id?: number;

    created_at?: string;

    email?: string;

    image?: {};

    json_content?: {};

    name?: string;

    phone?: string;

    total_articles?: number;

    url?: string;

    xml_content?: {};
  }> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete author
   * @method
   * @name API#deleteAuthor
   */
  deleteAuthor(authorId: number, parameters: {} = {}): Promise<void> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about author
   * @method
   * @name API#uploadAuthorImage
   */
  uploadAuthorImage(
    authorId: {},
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<{
    path: string;
  }> {
    let path = '/authors/{author_id}/image-upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns connected products
   * @method
   * @name API#loadWebContentProductsConnections
   */
  loadWebContentProductsConnections(
    webContentId: number,
    parameters: {
      limit?: number;
      offset?: number;
      onstock?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    products: Array<{
      name?: string;

      picture?: string;

      product_id?: number;

      type_id?: number;

      url?: string;
    }>;
  }> {
    let path = '/web_content/product_connections/{web_content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{web_content_id}', webContentId.toString());

    if (typeof parameters['onstock'] !== 'undefined') {
      queryParameters['onstock'] = parameters['onstock'];
    }

    queryParameters['onstock'] = this.transformParameter(
      queryParameters['onstock'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns content connections
   * @method
   * @name API#loadContentConnections
   */
  loadContentConnections(
    webContentId: number,
    parameters: {
      limit?: number;
      offset?: number;
      connectionType?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    total: number;

    web_content_connections: Array<{
      author?: {
        author_id?: number;

        image?: {};

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;
  }> {
    let path = '/web_content/connections/{web_content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{web_content_id}', webContentId.toString());

    if (typeof parameters['connectionType'] !== 'undefined') {
      queryParameters['connection_type'] = parameters['connectionType'];
    }

    queryParameters['connection_type'] = this.transformParameter(
      queryParameters['connection_type'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create connection between two web contents
   * @method
   * @name API#createConnection
   */
  createConnection(
    webContentId: number,
    connectedId: number,
    connectionType: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/web_content/connections/{web_content_id}/{connected_id}/{connection_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{web_content_id}', webContentId.toString());

    path = path.replace('{connected_id}', connectedId.toString());

    path = path.replace('{connection_type}', connectionType.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete connection between two web contents
   * @method
   * @name API#deleteConnection
   */
  deleteConnection(
    webContentId: number,
    connectedId: number,
    connectionType: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/web_content/connections/{web_content_id}/{connected_id}/{connection_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{web_content_id}', webContentId.toString());

    path = path.replace('{connected_id}', connectedId.toString());

    path = path.replace('{connection_type}', connectionType.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about web content
   * @method
   * @name API#loadWebContent
   */
  loadWebContent(
    parameters: {
      limit?: number;
      offset?: number;
      q: string;
      sort?: string;
      sortDir?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    web_content?: Array<{}>;

    total?: number;
  }> {
    let path = '/web_content';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['q'] === 'undefined') {
      throw new Error('Missing required parameter: q');
    }

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get availabilities only for products in
specific category

    * @method
    * @name API#loadAvailabilities
    */
  loadAvailabilities(
    parameters: {
      categoryId?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    availabilities?: Array<{
      avail_id?: number;

      avail_uniqid?: string;

      order_available?: number;

      translations?: Array<{
        avail_id: number;

        lang: string;

        avail_name: string;
      }>;
    }>;
  }> {
    let path = '/availabilities';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about article
   * @method
   * @name API#loadBanners
   */
  loadBanners(
    parameters: {
      sitemapId?: string;
      position?: string;
      device?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    banners: Array<{
      id: number;

      sitemap_id: number | null;

      b_position: string;

      b_name: string;

      b_description: string;

      b_type: string;

      b_path: string;

      b_url: string | null;

      b_sort: number | null;

      b_text: string;

      created: string;

      lang: string;

      device: string;
    }>;
  }> {
    let path = '/banners';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['position'] !== 'undefined') {
      queryParameters['position'] = parameters['position'];
    }

    queryParameters['position'] = this.transformParameter(
      queryParameters['position'],
    );

    if (typeof parameters['device'] !== 'undefined') {
      queryParameters['device'] = parameters['device'];
    }

    queryParameters['device'] = this.transformParameter(
      queryParameters['device'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get brands only for products in specific
category

    * @method
    * @name API#loadBrands
    */
  loadBrands(
    parameters: {
      categoryId?: string;
      productType?: string;
      sort?: string;
      sortDir?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    brands: Array<{
      brand_id: number;

      catalog_id: number;

      brand_name: string;

      brand_descr: string;

      brand_weburl: string;

      brand_image: string | null;

      brand_provider: string;

      url: string;

      brand_top: number;

      translations: Array<{
        brand_id: number;

        lang_id: string | null;

        brand_name: string | null;

        brand_descr: string | null;

        brand_weburl: string | null;

        meta_title: string | null;

        meta_description: string | null;

        meta_keywords: string | null;

        opengraph_title: string | null;

        opengraph_type: string | null;

        opengraph_picture: string | null;

        opengraph_url: string | null;

        opengraph_description: string | null;
      }>;
    }>;
  }> {
    let path = '/brands';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['productType'] !== 'undefined') {
      queryParameters['product_type'] = parameters['productType'];
    }

    queryParameters['product_type'] = this.transformParameter(
      queryParameters['product_type'],
    );

    queryParameters['sort'] = 'brand_name';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads brand detail object
   * @method
   * @name API#loadBrandDetail
   */
  loadBrandDetail(
    brandId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    brand_id: number;

    catalog_id: number;

    brand_name: string;

    brand_descr: string;

    brand_weburl: string;

    brand_image: string | null;

    brand_provider: string;

    url: string;

    brand_top: number;

    translations: Array<{
      brand_id: number;

      lang_id: string | null;

      brand_name: string | null;

      brand_descr: string | null;

      brand_weburl: string | null;

      meta_title: string | null;

      meta_description: string | null;

      meta_keywords: string | null;

      opengraph_title: string | null;

      opengraph_type: string | null;

      opengraph_picture: string | null;

      opengraph_url: string | null;

      opengraph_description: string | null;
    }>;
  }> {
    let path = '/brands/{brand_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{brand_id}', brandId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadBrandCategories
   */
  loadBrandCategories(
    brandId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      category_id: number | null;

      catalog_id: number;

      lang_id: string;

      category_name: string;

      category_descr: string;

      cms_content_id: number | null;

      webshow: number;

      category_sort: number;

      image: string | null;

      category_number: string | null;

      url: string;

      searchtype: string | null;

      layout_style: string | null;

      parent_number: string | null;

      consultant_id: string | null;

      layout_id: string | null;

      tree_left: number;

      tree_right: number;

      tree_level: number;

      weburl: string | null;

      last_update: string | null;

      istop: number;

      type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

      heureka_id: string | null;

      content?: Array<{
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;
      }>;
    }>
  > {
    let path = '/brands/{brand_id}/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{brand_id}', brandId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Info about general free delivery price
   * @method
   * @name API#getGeneralFreeDeliveryInfo
   */
  getGeneralFreeDeliveryInfo(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    price?: number;

    currency?: string;
  }> {
    let path = '/cart/free-delivery';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns new empty cart
   * @method
   * @name API#createCart
   */
  createCart(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    id: string;
  }> {
    let path = '/cart';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns all dpd delivery points
   * @method
   * @name API#loadDpdDeliveryPoints
   */
  loadDpdDeliveryPoints(parameters: {} = {}): Promise<{}> {
    let path = '/cart/dpd-delivery-points';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns dpd delivery point by id
   * @method
   * @name API#loadDpdDeliveryPointById
   */
  loadDpdDeliveryPointById(pointId: string, parameters: {} = {}): Promise<{}> {
    let path = '/cart/dpd-delivery-points/{point_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{point_id}', pointId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns products, total count and price and other information about cart
   * @method
   * @name API#getCart
   */
  getCart(
    cartId: string,
    parameters: {
      recalculate?: boolean;
      validate?: boolean;
    } = {},
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['recalculate'] !== 'undefined') {
      queryParameters['recalculate'] = parameters['recalculate'];
    }

    queryParameters['recalculate'] = this.transformParameter(
      queryParameters['recalculate'],
    );

    if (typeof parameters['validate'] !== 'undefined') {
      queryParameters['validate'] = parameters['validate'];
    }

    queryParameters['validate'] = this.transformParameter(
      queryParameters['validate'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set type of delivery and payment, billing information, ...
   * @method
   * @name API#updateCart
   */
  updateCart(
    cartId: string,
    parameters: {} = {},
    body: {
      delivery?: {};

      payment?: {};

      delivery_address?: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      billing_address: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      company: {
        name?: string;

        ico?: string;

        dic?: string;

        ic_dph?: string;
      };

      billing_company: boolean;

      use_delivery_address: boolean;

      terms_accept: boolean;

      newsletter_accept: boolean;

      company_newsletter_accept?: boolean;

      heureka: boolean;

      note: string;

      step: number;

      zasielkovna_id?: string;

      skvelko_class_id?: number;

      awarded_school_id?: number;

      consumed_points: number;

      chosen_gift?: {};
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes cart
   * @method
   * @name API#removeCart
   */
  removeCart(cartId: string, parameters: {} = {}): Promise<{}> {
    let path = '/cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if cart exist
   * @method
   * @name API#isCartExist
   */
  isCartExist(cartId: string, parameters: {} = {}): Promise<boolean> {
    let path = '/cart/{cart_id}/exist';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns cart with items from defined order
   * @method
   * @name API#copyOrder
   */
  copyOrder(
    cartId: string,
    orderId: number | string,
    parameters: {
      currencyId?: string;
    } = {},
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/order/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{order_id}', orderId.toString());

    if (typeof parameters['currencyId'] !== 'undefined') {
      queryParameters['currency_id'] = parameters['currencyId'];
    }

    queryParameters['currency_id'] = this.transformParameter(
      queryParameters['currency_id'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recognize and add customer card to the cart
   * @method
   * @name API#recognizeCustomerCard
   */
  recognizeCustomerCard(
    cartId: string,
    parameters: {
      email: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/apply-customer-card';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Applies giftcard/pointcard/card discount on items in cart
   * @method
   * @name API#addCard
   */
  addCard(
    cartId: string,
    parameters: {} = {},
    body: {
      card_number?: string;

      card_type: 'GIFTCARD' | 'POINTCARD' | 'CARD' | 'GIFTCARD_OR_CUSTOMERCARD';

      pointcards?: Array<{
        count: number;

        id: string;
      }>;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/apply-card';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns delivery and payment methods
   * @method
   * @name API#getDeliveryPaymentInfo
   */
  getDeliveryPaymentInfo(
    cartId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    delivery_id: string;

    delivery_name: string;

    delivery_descr: string | null;

    multistore: 0 | 1;

    delivery_sort: number | null;

    delivery_priority: number;

    min_weight: number | null;

    max_weight: number | null;

    price: {
      currency_id: string;

      price: number | null;
    };

    translations: Array<{
      lang_id: string;

      delivery_id: string;

      delivery_name: string;

      delivery_descr: string;

      delivery_thanks?: string;

      delivery_warning?: string;
    }>;

    payments: Array<{
      payment_id: string;

      payment_name: string;

      payment_descr: string;

      payment_priority: number;

      payment_sort: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_thanks?: string;

        payment_failure?: string;
      }>;
    }>;
  }> {
    let path = '/cart/{cart_id}/delivery-payment';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Info about remaining price needed to get free delivery
   * @method
   * @name API#getFreeDeliveryInfo
   */
  getFreeDeliveryInfo(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    cart_price?: number;

    currency?: string;

    remaining_price?: number;
  }> {
    let path = '/cart/{cart_id}/free-delivery';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * School delivery address for delivery to school
   * @method
   * @name API#getDeliverySchoolBaseInfo
   */
  getDeliverySchoolBaseInfo(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    name?: string;

    street?: string;

    street_number?: string;

    zip?: string;

    city?: string;
  }> {
    let path = '/cart/{cart_id}/delivery-school-base-info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns gifts tied to this concrete cart
   * @method
   * @name API#loadCartGifts
   */
  loadCartGifts(
    cartId: string,
    parameters: {
      sortDir?: string;
    } = {},
  ): Promise<{
    cart_gifts: Array<{
      product_id: string;

      product_name?: string;

      picture?: string;

      price_from: number;

      claimable: boolean;

      price_unlock: number;
    }>;
  }> {
    let path = '/cart/{cart_id}/gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns total count of products and price of cart
   * @method
   * @name API#getCartInfo
   */
  getCartInfo(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    amount?: number;

    currency?: string;

    price?: number;

    step?: number;
  }> {
    let path = '/cart/{cart_id}/info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns clone of cart with products, total count and price and other information about cart
   * @method
   * @name API#createCartClone
   */
  createCartClone(
    cartCopyId: string,
    parameters: {} = {},
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_copy_id}/clone';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_copy_id}', cartCopyId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns updated card
   * @method
   * @name API#updateItemGroup
   */
  updateItemGroup(
    cartId: string,
    itemGroupId: string,
    parameters: {
      count?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/item_group/{item_group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{item_group_id}', itemGroupId.toString());

    if (typeof parameters['count'] !== 'undefined') {
      queryParameters['count'] = parameters['count'];
    }

    queryParameters['count'] = this.transformParameter(
      queryParameters['count'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns updated card
   * @method
   * @name API#removeItemGroup
   */
  removeItemGroup(
    cartId: string,
    itemGroupId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/item_group/{item_group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{item_group_id}', itemGroupId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add items to cart
   * @method
   * @name API#addCartItemsBulk
   */
  addCartItemsBulk(
    cartId: string,
    parameters: {
      currencyId?: string;
    } = {},
    body: Array<{
      good_id: number;

      product_id: number;

      count: number;

      skvelko_set_id?: number;

      skvelko_class_id?: number;

      item_group_id?: string;

      upsell_id?: number;

      is_upsell_main_product?: boolean;
    }>,
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/items_bulk';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['currencyId'] !== 'undefined') {
      queryParameters['currency_id'] = parameters['currencyId'];
    }

    queryParameters['currency_id'] = this.transformParameter(
      queryParameters['currency_id'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove item on given index from cart and returns updated cart
   * @method
   * @name API#deleteCartItem
   */
  deleteCartItem(
    cartId: string,
    productId: number,
    goodId: number,
    parameters: {
      attribHash?: string;
      itemGroupId?: string;
      itemUniqId?: string;
    } = {},
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/items/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    if (typeof parameters['attribHash'] !== 'undefined') {
      queryParameters['attrib_hash'] = parameters['attribHash'];
    }

    queryParameters['attrib_hash'] = this.transformParameter(
      queryParameters['attrib_hash'],
    );

    if (typeof parameters['itemGroupId'] !== 'undefined') {
      queryParameters['item_group_id'] = parameters['itemGroupId'];
    }

    queryParameters['item_group_id'] = this.transformParameter(
      queryParameters['item_group_id'],
    );

    if (typeof parameters['itemUniqId'] !== 'undefined') {
      queryParameters['item_uniq_id'] = parameters['itemUniqId'];
    }

    queryParameters['item_uniq_id'] = this.transformParameter(
      queryParameters['item_uniq_id'],
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add item to cart or update count
   * @method
   * @name API#addCartItem
   */
  addCartItem(
    cartId: string,
    goodId: number,
    productId: number,
    parameters: {
      count?: number;
      currencyId?: string;
      skvelkoSetId?: number;
      skvelkoClassId?: number;
      itemGroupId?: string;
      upsellId?: number;
    } = {},
    body: {
      demand_attribs?: Array<{
        attrib_id?: number;

        attrib_value_id?: number;

        attrib_name?: string;

        attrib_value_name?: string;
      }>;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/items/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['count'] !== 'undefined') {
      queryParameters['count'] = parameters['count'];
    }

    queryParameters['count'] = this.transformParameter(
      queryParameters['count'],
    );

    if (typeof parameters['currencyId'] !== 'undefined') {
      queryParameters['currency_id'] = parameters['currencyId'];
    }

    queryParameters['currency_id'] = this.transformParameter(
      queryParameters['currency_id'],
    );

    if (typeof parameters['skvelkoSetId'] !== 'undefined') {
      queryParameters['skvelko_set_id'] = parameters['skvelkoSetId'];
    }

    queryParameters['skvelko_set_id'] = this.transformParameter(
      queryParameters['skvelko_set_id'],
    );

    if (typeof parameters['skvelkoClassId'] !== 'undefined') {
      queryParameters['skvelko_class_id'] = parameters['skvelkoClassId'];
    }

    queryParameters['skvelko_class_id'] = this.transformParameter(
      queryParameters['skvelko_class_id'],
    );

    if (typeof parameters['itemGroupId'] !== 'undefined') {
      queryParameters['item_group_id'] = parameters['itemGroupId'];
    }

    queryParameters['item_group_id'] = this.transformParameter(
      queryParameters['item_group_id'],
    );

    if (typeof parameters['upsellId'] !== 'undefined') {
      queryParameters['upsell_id'] = parameters['upsellId'];
    }

    queryParameters['upsell_id'] = this.transformParameter(
      queryParameters['upsell_id'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove giftcard/pointcard/card from cart
   * @method
   * @name API#removeCard
   */
  removeCard(
    cartId: string,
    cardType: 'giftcard' | 'pointcard' | 'card',
    cardNumber: string,
    parameters: {} = {},
  ): Promise<{
    id: string;

    copy_id?: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_with_discount?: number;

    total_items_price_with_discount_without_vat?: number;

    total_items_price_sale?: number;

    total_giftcards_price: number;

    total_customer_card_price?: number;

    total_price: number;

    total_price_without_vat?: number;

    total_items_price_without_vat?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    item_groups?: Array<{
      item_group_id?: string;

      primary_name?: string;

      secondary_name?: string;

      count?: number;
    }>;

    items: Array<{
      count: number;

      default_count?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      skvelko_set?: {
        id?: number;

        chematId?: number;

        clubUserId?: number;

        awardedUserId?: number;

        name1?: string;

        name2?: string;

        name3?: string;

        note?: string;

        fullName?: string;

        skvelkoSetAvailabilityId?: number;

        price?: number;

        city?: string;

        school_name?: string;

        schoolFullName?: string;

        school_street?: string;

        school_street_number?: number;

        count?: number;

        updated_at?: string;

        isDeliveryToSchoolActivated?: boolean;

        productGoodsWithCount?: Array<{
          count?: number;

          productGood?: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };
        }>;

        productsWithCount?: Array<{
          count?: number;

          product?: {
            availability_date: string | null;

            is_in_wishlist: boolean;

            product_id: number;

            name: string;

            slogan: string | null;

            is_sale: 0 | 1;

            issaleout: 0 | 1;

            is_new: 0 | 1;

            isnew_valid: string;

            is_top: 0 | 1;

            plu: string | null;

            ean: string | null;

            url: string;

            description: string;

            created_at: string;

            rating: string;

            rating_users: number;

            picture: string | null;

            brand: {};

            name_url_encoded: string;

            qtyDiscounts?: {
              discount_pcn?: number;

              qty_from?: number;

              product_id?: number;

              lang?: string;

              final_price?: number;
            };

            goods: Array<{
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            }>;

            main_good: {
              priceinfo: string;

              valid_from?: string;

              valid_to?: string;

              imagePath: string;

              final_price: number | null;

              unitprice_sale: number | null;

              sale: boolean | null;

              good_id: number;

              product_id: number;

              currency_id: string;

              unitprice: number;

              unitprice_with_discount: number | null;

              unitprice_with_discount_without_vat?: number | null;

              vat_rate: number;

              availability_id: number | null;

              oldprice: number | null;

              units: string | null;

              order_number: string | null;

              ean: string | null;

              internal_code: string | null;

              on_stock_count: number;

              is_on_stock: 0 | 1;

              point_good: 0 | 1;

              points_amount: number | null;

              sale_percentage: number | null;

              price_without_vat: number | null;

              price_without_vat_sale: number | null;

              currency: {
                id: string;

                name: string;
              };

              availability: {
                avail_id?: number;

                avail_uniqid?: string;

                order_available?: number;

                translations?: Array<{
                  avail_id: number;

                  lang: string;

                  avail_name: string;
                }>;
              };

              group_price?: {
                unitprice: number;

                oldprice: number;

                sale_percentage: number;
              };

              group_prices?: Array<{
                skupina_id?: number;

                good_id?: number;

                unitprice?: number;

                oldprice?: number;
              }>;

              discount_pcn?: number;

              order_quantity_limit?: number | null;

              good_sort?: number;

              onstore?: Array<{
                goodId?: number;

                storeId?: string;

                storeQty?: number;
              }>;
            };

            products_in_set?: Array<{
              good_id?: number;

              package_good_id?: number;

              package_quantity?: number;

              package_price?: number;

              product_id?: number;

              package_product_id?: number;

              color?: string;

              pages?: string;

              capacity?: string;
            }>;

            street?: string;

            city?: string;

            zip?: string;

            set_delete_warning?: boolean;

            is_user_favorite?: boolean;
          };
        }>;

        skvelkoSetLogs?: Array<{
          log?: {
            availability?: string | null;

            clubUsersId: number;

            created_at: string;

            created_by?: string | null;

            id: number;

            itemsCount: number;

            login?: string | null;

            price: number;

            skvelkoSetAvailabilitiesId: number;

            skvelkoSetCreatedById: number;

            skvelkoSetsId: number;
          };
        }>;

        awardedUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      product_note?: string;

      item_group_id?: string;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    customer_card_discount?: number;

    customer_card?: string;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string;

        delivery_descr: string;

        delivery_thanks?: string;

        delivery_warning?: string;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string;

          payment_descr: string;

          payment_thanks?: string;

          payment_failure?: string;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_delivery_point_id?: number;

    packeta_delivery_point_id?: string;

    dhl_parcel_shop_code?: string;

    shop_personal_pickup_id?: number;

    chosen_gift?: {};

    is_giftcards_valid?: boolean;

    protection_modal_was_displayed?: boolean;

    had_user?: boolean;

    packeta_delivery_point?: {};
  }> {
    let path = '/cart/{cart_id}/remove-card/{card_type}/{card_number}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{card_type}', cardType.toString());

    path = path.replace('{card_number}', cardNumber.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns skvelko awards available for the cart
   * @method
   * @name API#getSkvelkoAwards
   */
  getSkvelkoAwards(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    delivery_id: string;

    delivery_name: string;

    delivery_descr: string | null;

    multistore: 0 | 1;

    delivery_sort: number | null;

    delivery_priority: number;

    min_weight: number | null;

    max_weight: number | null;

    price: {
      currency_id: string;

      price: number | null;
    };

    translations: Array<{
      lang_id: string;

      delivery_id: string;

      delivery_name: string;

      delivery_descr: string;

      delivery_thanks?: string;

      delivery_warning?: string;
    }>;

    payments: Array<{
      payment_id: string;

      payment_name: string;

      payment_descr: string;

      payment_priority: number;

      payment_sort: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_thanks?: string;

        payment_failure?: string;
      }>;
    }>;
  }> {
    let path = '/cart/{cart_id}/skvelko-awards';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns weather some set in the cart has activated delivery to school
   * @method
   * @name API#someSetsHasDeliveryToSchoolActivated
   */
  someSetsHasDeliveryToSchoolActivated(
    cartId: string,
    parameters: {} = {},
  ): Promise<boolean> {
    let path = '/cart/{cart_id}/delivery-school-activated';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return carts by limit and offset
   * @method
   * @name API#getCarts
   */
  getCarts(parameters: {
    limit: string;
    offset: string;
    id?: string;
    surname?: string;
    ico?: string;
    email?: string;
    organizationName?: string;
    phoneNumber?: string;
    priceFrom?: number;
    priceTo?: number;
    hasOnDemandItems?: boolean;
    lastUpdatedFrom?: string;
    lastUpdatedTo?: string;
  }): Promise<{}> {
    let path = '/carts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['limit'] === 'undefined') {
      throw new Error('Missing required parameter: limit');
    }

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['offset'] === 'undefined') {
      throw new Error('Missing required parameter: offset');
    }

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['surname'] !== 'undefined') {
      queryParameters['surname'] = parameters['surname'];
    }

    queryParameters['surname'] = this.transformParameter(
      queryParameters['surname'],
    );

    if (typeof parameters['ico'] !== 'undefined') {
      queryParameters['ico'] = parameters['ico'];
    }

    queryParameters['ico'] = this.transformParameter(queryParameters['ico']);

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['organizationName'] !== 'undefined') {
      queryParameters['organization_name'] = parameters['organizationName'];
    }

    queryParameters['organization_name'] = this.transformParameter(
      queryParameters['organization_name'],
    );

    if (typeof parameters['phoneNumber'] !== 'undefined') {
      queryParameters['phone_number'] = parameters['phoneNumber'];
    }

    queryParameters['phone_number'] = this.transformParameter(
      queryParameters['phone_number'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['price_from'] = parameters['priceFrom'];
    }

    queryParameters['price_from'] = this.transformParameter(
      queryParameters['price_from'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['price_to'] = parameters['priceTo'];
    }

    queryParameters['price_to'] = this.transformParameter(
      queryParameters['price_to'],
    );

    if (typeof parameters['hasOnDemandItems'] !== 'undefined') {
      queryParameters['has_on_demand_items'] = parameters['hasOnDemandItems'];
    }

    queryParameters['has_on_demand_items'] = this.transformParameter(
      queryParameters['has_on_demand_items'],
    );

    if (typeof parameters['lastUpdatedFrom'] !== 'undefined') {
      queryParameters['last_updated_from'] = parameters['lastUpdatedFrom'];
    }

    queryParameters['last_updated_from'] = this.transformParameter(
      queryParameters['last_updated_from'],
    );

    if (typeof parameters['lastUpdatedTo'] !== 'undefined') {
      queryParameters['last_updated_to'] = parameters['lastUpdatedTo'];
    }

    queryParameters['last_updated_to'] = this.transformParameter(
      queryParameters['last_updated_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return all carts count
   * @method
   * @name API#getCartsCount
   */
  getCartsCount(
    parameters: {
      id?: string;
      surname?: string;
      ico?: string;
      email?: string;
      organizationName?: string;
      phoneNumber?: string;
      priceFrom?: number;
      priceTo?: number;
      notEmpty?: boolean;
      hasOnDemandItems?: boolean;
      lastUpdatedFrom?: string;
      lastUpdatedTo?: string;
    } = {},
  ): Promise<{}> {
    let path = '/carts/count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['surname'] !== 'undefined') {
      queryParameters['surname'] = parameters['surname'];
    }

    queryParameters['surname'] = this.transformParameter(
      queryParameters['surname'],
    );

    if (typeof parameters['ico'] !== 'undefined') {
      queryParameters['ico'] = parameters['ico'];
    }

    queryParameters['ico'] = this.transformParameter(queryParameters['ico']);

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['organizationName'] !== 'undefined') {
      queryParameters['organization_name'] = parameters['organizationName'];
    }

    queryParameters['organization_name'] = this.transformParameter(
      queryParameters['organization_name'],
    );

    if (typeof parameters['phoneNumber'] !== 'undefined') {
      queryParameters['phone_number'] = parameters['phoneNumber'];
    }

    queryParameters['phone_number'] = this.transformParameter(
      queryParameters['phone_number'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['price_from'] = parameters['priceFrom'];
    }

    queryParameters['price_from'] = this.transformParameter(
      queryParameters['price_from'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['price_to'] = parameters['priceTo'];
    }

    queryParameters['price_to'] = this.transformParameter(
      queryParameters['price_to'],
    );

    if (typeof parameters['notEmpty'] !== 'undefined') {
      queryParameters['not_empty'] = parameters['notEmpty'];
    }

    queryParameters['not_empty'] = this.transformParameter(
      queryParameters['not_empty'],
    );

    if (typeof parameters['hasOnDemandItems'] !== 'undefined') {
      queryParameters['has_on_demand_items'] = parameters['hasOnDemandItems'];
    }

    queryParameters['has_on_demand_items'] = this.transformParameter(
      queryParameters['has_on_demand_items'],
    );

    if (typeof parameters['lastUpdatedFrom'] !== 'undefined') {
      queryParameters['last_updated_from'] = parameters['lastUpdatedFrom'];
    }

    queryParameters['last_updated_from'] = this.transformParameter(
      queryParameters['last_updated_from'],
    );

    if (typeof parameters['lastUpdatedTo'] !== 'undefined') {
      queryParameters['last_updated_to'] = parameters['lastUpdatedTo'];
    }

    queryParameters['last_updated_to'] = this.transformParameter(
      queryParameters['last_updated_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove old carts
   * @method
   * @name API#removeOldCarts
   */
  removeOldCarts(
    parameters: {} = {},
    body: {
      date_to: string;
    },
  ): Promise<{}> {
    let path = '/carts/remove-old';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadCategories
   */
  loadCategories(
    parameters: {
      limit?: number;
      offset?: number;
      showTop?: '0' | '1';
      withContent?: '0' | '1';
      webShow?: '0' | '1';
      parentId?: number;
      q?: string;
      parentCategories?: boolean;
      isSchoolCategory?: boolean;
      isNotEmpty?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['showTop'] !== 'undefined') {
      queryParameters['show_top'] = parameters['showTop'];
    }

    queryParameters['show_top'] = this.transformParameter(
      queryParameters['show_top'],
    );

    if (typeof parameters['withContent'] !== 'undefined') {
      queryParameters['with_content'] = parameters['withContent'];
    }

    queryParameters['with_content'] = this.transformParameter(
      queryParameters['with_content'],
    );

    if (typeof parameters['webShow'] !== 'undefined') {
      queryParameters['web_show'] = parameters['webShow'];
    }

    queryParameters['web_show'] = this.transformParameter(
      queryParameters['web_show'],
    );

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['parentCategories'] !== 'undefined') {
      queryParameters['parent_categories'] = parameters['parentCategories'];
    }

    queryParameters['parent_categories'] = this.transformParameter(
      queryParameters['parent_categories'],
    );

    if (typeof parameters['isSchoolCategory'] !== 'undefined') {
      queryParameters['is_school_category'] = parameters['isSchoolCategory'];
    }

    queryParameters['is_school_category'] = this.transformParameter(
      queryParameters['is_school_category'],
    );

    if (typeof parameters['isNotEmpty'] !== 'undefined') {
      queryParameters['is_not_empty'] = parameters['isNotEmpty'];
    }

    queryParameters['is_not_empty'] = this.transformParameter(
      queryParameters['is_not_empty'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns full category tree as an object
   * @method
   * @name API#loadTree
   */
  loadTree(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      searchtype: string;

      category_name: string;

      category_descr: string;

      url: string;

      weburl: string;

      heureka_id: string | null;

      image: string | null;

      category_id: number;

      attribs?: Array<{
        attrib_id: number;

        type_id: number;

        attrib_uid: string | null;

        attrib_type: string | null;

        group_id: number | null;

        attrib_name: string;

        attrib_ismulti: number;

        attrib_sort: number;

        attrib_pbl: number;

        attrib_unit: string;

        attrib_list: number;

        attrib_code: string | null;

        attrib_grid: number;

        min?: number;

        max?: number;

        translations: Array<{
          attrib_id: number;

          lang_id: string;

          attrib_name: string;

          attrib_unit: string | null;
        }>;

        values: Array<{
          amount: number;

          value_id: number;

          attrib_id: number;

          attrib_value: string;

          attrib_pict: string | null;

          group_id: number | null;

          istop: boolean;

          searchfilter: number;

          value_sort: number | null;

          translations: Array<{
            value_id: number;

            lang_id: string;

            attrib_value: string;
          }>;
        }>;
      }>;

      children: Array<{}>;
    }>
  > {
    let path = '/categories/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadCategoriesGroups
   */
  loadCategoriesGroups(
    parameters: {
      active?: '0' | '1';
      limit?: number;
      offset?: number;
      withCategories?: number;
      withDisabledCategoryIds?: boolean;
      id?: number;
      categoryId?: number;
      categoryIdAndParent?: number;
      productId?: number;
      sort?: string;
      sortDir?: string;
      categoryTree?: boolean;
      filterEmpty?: boolean;
      filterDisabled?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    total: number;

    offset: number;

    limit: number;

    catalog_category_groups: {
      searchtype: string;

      category_name: string;

      category_descr: string;

      url: string;

      weburl: string;

      heureka_id: string | null;

      image: string | null;

      category_id: number;

      attribs?: Array<{
        attrib_id: number;

        type_id: number;

        attrib_uid: string | null;

        attrib_type: string | null;

        group_id: number | null;

        attrib_name: string;

        attrib_ismulti: number;

        attrib_sort: number;

        attrib_pbl: number;

        attrib_unit: string;

        attrib_list: number;

        attrib_code: string | null;

        attrib_grid: number;

        min?: number;

        max?: number;

        translations: Array<{
          attrib_id: number;

          lang_id: string;

          attrib_name: string;

          attrib_unit: string | null;
        }>;

        values: Array<{
          amount: number;

          value_id: number;

          attrib_id: number;

          attrib_value: string;

          attrib_pict: string | null;

          group_id: number | null;

          istop: boolean;

          searchfilter: number;

          value_sort: number | null;

          translations: Array<{
            value_id: number;

            lang_id: string;

            attrib_value: string;
          }>;
        }>;
      }>;

      children: Array<{}>;
    };
  }> {
    let path = '/categories/groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withCategories'] !== 'undefined') {
      queryParameters['with_categories'] = parameters['withCategories'];
    }

    queryParameters['with_categories'] = this.transformParameter(
      queryParameters['with_categories'],
    );

    if (typeof parameters['withDisabledCategoryIds'] !== 'undefined') {
      queryParameters['with_disabled_category_ids'] =
        parameters['withDisabledCategoryIds'];
    }

    queryParameters['with_disabled_category_ids'] = this.transformParameter(
      queryParameters['with_disabled_category_ids'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['categoryIdAndParent'] !== 'undefined') {
      queryParameters['category_id_and_parent'] =
        parameters['categoryIdAndParent'];
    }

    queryParameters['category_id_and_parent'] = this.transformParameter(
      queryParameters['category_id_and_parent'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['categoryTree'] !== 'undefined') {
      queryParameters['category_tree'] = parameters['categoryTree'];
    }

    queryParameters['category_tree'] = this.transformParameter(
      queryParameters['category_tree'],
    );

    if (typeof parameters['filterEmpty'] !== 'undefined') {
      queryParameters['filter_empty'] = parameters['filterEmpty'];
    }

    queryParameters['filter_empty'] = this.transformParameter(
      queryParameters['filter_empty'],
    );

    if (typeof parameters['filterDisabled'] !== 'undefined') {
      queryParameters['filter_disabled'] = parameters['filterDisabled'];
    }

    queryParameters['filter_disabled'] = this.transformParameter(
      queryParameters['filter_disabled'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create category group
   * @method
   * @name API#createCategoryGroup
   */
  createCategoryGroup(
    parameters: {} = {},
    body: {
      name: string;

      is_active?: boolean;

      position?: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<void> {
    let path = '/categories/groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns category group by id
   * @method
   * @name API#loadCategoryGroup
   */
  loadCategoryGroup(
    categoryGroupId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/categories/group/{category_group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_group_id}', categoryGroupId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete category group
   * @method
   * @name API#deleteCategoryGroup
   */
  deleteCategoryGroup(
    categoryGroupId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/categories/groups/{category_group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{category_group_id}', categoryGroupId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update category group in bulks
   * @method
   * @name API#updateCategoryGroupsBulk
   */
  updateCategoryGroupsBulk(
    parameters: {} = {},
    body: {
      actions?: Array<{
        id: number;

        name?: string;

        is_active?: 0 | 1;

        lang_id?: string;

        position?: number;

        category_ids?: Array<number>;

        disabled_category_ids?: Array<number>;
      }>;
    },
  ): Promise<void> {
    let path = '/categories/groups/bulk';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns a category
   * @method
   * @name API#loadCategory
   */
  loadCategory(
    categoryId: number,
    parameters: {
      parameters?: Array<string>;
      attribValuesSorter?: 'NAME' | 'LANG_ORDER_NUMBER_SORT' | 'VALUE_SORT';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    category_id: number | null;

    catalog_id: number;

    lang_id: string;

    category_name: string;

    category_descr: string;

    cms_content_id: number | null;

    webshow: number;

    category_sort: number;

    image: string | null;

    category_number: string | null;

    url: string;

    searchtype: string | null;

    layout_style: string | null;

    parent_number: string | null;

    consultant_id: string | null;

    layout_id: string | null;

    tree_left: number;

    tree_right: number;

    tree_level: number;

    weburl: string | null;

    last_update: string | null;

    istop: number;

    type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

    heureka_id: string | null;

    content?: Array<{
      id: string | number;

      created_user_id: string | null;

      sitemap_item_id: string | null;

      name: string | null;

      created_date: string;

      ordering: number | null;

      sf_form: string;

      sf_stylesheet: string;

      sf_xmlcontent: string | null;

      draft: number;

      hint: string | null;

      url: string | null;

      last_update: string;

      rss: number;

      home: number;

      publish: number;

      json_content: string | null;

      documents?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      downloads?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      photogallery?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      videos?: Array<{
        video_id?: number;

        content_id?: number;

        video_name?: string | null;

        video_description?: string | null;

        video_url?: string | null;

        ordering?: number | null;

        created_date?: string | null;

        created_user?: number | null;

        webshow?: number | null;

        video_width?: number | null;

        video_height?: number | null;
      }>;

      products?: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      }>;

      next_article?: {
        name?: string;

        url?: string;

        image?: string;

        annotation?: string;

        id?: number;
      };

      tags?: Array<{
        tag_id: string;

        tag_name?: string;
      }>;
    }>;
  }> {
    let path = '/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['attribValuesSorter'] !== 'undefined') {
      queryParameters['attrib_values_sorter'] =
        parameters['attribValuesSorter'];
    }

    queryParameters['attrib_values_sorter'] = this.transformParameter(
      queryParameters['attrib_values_sorter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadRelatedCategories
   */
  loadRelatedCategories(
    categoryId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      category_id: number | null;

      catalog_id: number;

      lang_id: string;

      category_name: string;

      category_descr: string;

      cms_content_id: number | null;

      webshow: number;

      category_sort: number;

      image: string | null;

      category_number: string | null;

      url: string;

      searchtype: string | null;

      layout_style: string | null;

      parent_number: string | null;

      consultant_id: string | null;

      layout_id: string | null;

      tree_left: number;

      tree_right: number;

      tree_level: number;

      weburl: string | null;

      last_update: string | null;

      istop: number;

      type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

      heureka_id: string | null;

      content?: Array<{
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;
      }>;
    }>
  > {
    let path = '/categories/{category_id}/related';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns full category tree as an object
   * @method
   * @name API#loadSubTree
   */
  loadSubTree(
    categoryId: number,
    parameters: {
      filterEmpty?: boolean;
      disabledCategoryGroupId?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      searchtype: string;

      category_name: string;

      category_descr: string;

      url: string;

      weburl: string;

      heureka_id: string | null;

      image: string | null;

      category_id: number;

      attribs?: Array<{
        attrib_id: number;

        type_id: number;

        attrib_uid: string | null;

        attrib_type: string | null;

        group_id: number | null;

        attrib_name: string;

        attrib_ismulti: number;

        attrib_sort: number;

        attrib_pbl: number;

        attrib_unit: string;

        attrib_list: number;

        attrib_code: string | null;

        attrib_grid: number;

        min?: number;

        max?: number;

        translations: Array<{
          attrib_id: number;

          lang_id: string;

          attrib_name: string;

          attrib_unit: string | null;
        }>;

        values: Array<{
          amount: number;

          value_id: number;

          attrib_id: number;

          attrib_value: string;

          attrib_pict: string | null;

          group_id: number | null;

          istop: boolean;

          searchfilter: number;

          value_sort: number | null;

          translations: Array<{
            value_id: number;

            lang_id: string;

            attrib_value: string;
          }>;
        }>;
      }>;

      children: Array<{}>;
    }>
  > {
    let path = '/categories/{category_id}/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['filterEmpty'] !== 'undefined') {
      queryParameters['filter_empty'] = parameters['filterEmpty'];
    }

    queryParameters['filter_empty'] = this.transformParameter(
      queryParameters['filter_empty'],
    );

    if (typeof parameters['disabledCategoryGroupId'] !== 'undefined') {
      queryParameters['disabled_category_group_id'] =
        parameters['disabledCategoryGroupId'];
    }

    queryParameters['disabled_category_group_id'] = this.transformParameter(
      queryParameters['disabled_category_group_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Clears redis cache
   * @method
   * @name API#clearCache
   */
  clearCache(parameters: {} = {}): Promise<void> {
    let path = '/clear-cache';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns customer card
   * @method
   * @name API#getCustomerCard
   */
  getCustomerCard(
    parameters: {} = {},
  ): Promise<{
    ean?: string;

    discount?: number;

    basicPrice?: string;
  }> {
    let path = '/customer/customer-card';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns customer card discount
   * @method
   * @name API#getCustomerCardDiscount
   */
  getCustomerCardDiscount(parameters: {
    ean: string;
  }): Promise<{
    discount: number;
  }> {
    let path = '/customer/customer-card-discount';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['ean'] === 'undefined') {
      throw new Error('Missing required parameter: ean');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if email exist
   * @method
   * @name API#isEmailExist
   */
  isEmailExist(parameters: {
    email: string;
    isSchoolOrCompany?: boolean;
  }): Promise<{}> {
    let path = '/customer/email-exist';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    if (typeof parameters['isSchoolOrCompany'] !== 'undefined') {
      queryParameters['is_school_or_company'] = parameters['isSchoolOrCompany'];
    }

    queryParameters['is_school_or_company'] = this.transformParameter(
      queryParameters['is_school_or_company'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if login exist
   * @method
   * @name API#isLoginExist
   */
  isLoginExist(parameters: { login: string }): Promise<{}> {
    let path = '/customer/login-exist';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['login'] !== 'undefined') {
      queryParameters['login'] = parameters['login'];
    }

    queryParameters['login'] = this.transformParameter(
      queryParameters['login'],
    );

    if (typeof parameters['login'] === 'undefined') {
      throw new Error('Missing required parameter: login');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns list of customers
   * @method
   * @name API#searchCustomers
   */
  searchCustomers(
    parameters: {
      limit?: number;
      name?: string;
      sort?: string;
      sortDir?: string;
      credibility?: number;
      offset?: number;
      skvelkoCreditFrom?: number;
      dealerId?: Array<string>;
      withParentUser?: boolean;
    } = {},
  ): Promise<{
    customers: Array<{
      id: number;

      meno: string;

      priezvisko: string;

      firma: string | null;

      ico: string;

      dic: string;

      icdph: string;

      platba_dph: number;

      ulica: string;

      cislo: string;

      mesto: string;

      psc: string;

      country: string;

      telefon: string;

      email: string;

      login: string;

      ip: string;

      host: string;

      registrovany: string;

      stav: string;

      poznamka: string;

      nedoveryhodny: number;

      interna_poznamka: string;

      has_ip_access: number;

      individualna_zlava: number;

      lang: string;

      activatecode: string;

      activate_datetime: string | null;

      guid: string | null;

      login_hash: string;

      uniqid: string;

      card_number: string;

      reg_discount: number;

      delivery_addresses: {
        id: number;

        id_user: number;

        name: string;

        surname: string;

        delivery_street: string;

        delivery_number: string;

        delivery_zip: string;

        delivery_city: string;

        delivery_phone: string;

        country: string;
      };

      autoregister: number | null;

      facebook_user_id: string;

      google_user_id: string;

      passwd: string;

      passwd_salt: string;

      trust_points?: number | null;

      highest_trust_points_reached?: number | null;
    }>;
  }> {
    let path = '/customers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    queryParameters['credibility'] = 1;

    if (typeof parameters['credibility'] !== 'undefined') {
      queryParameters['credibility'] = parameters['credibility'];
    }

    queryParameters['credibility'] = this.transformParameter(
      queryParameters['credibility'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['skvelkoCreditFrom'] !== 'undefined') {
      queryParameters['skvelko_credit_from'] = parameters['skvelkoCreditFrom'];
    }

    queryParameters['skvelko_credit_from'] = this.transformParameter(
      queryParameters['skvelko_credit_from'],
    );

    if (typeof parameters['dealerId'] !== 'undefined') {
      queryParameters['dealer_id'] = parameters['dealerId'];
    }

    queryParameters['dealer_id'] = this.transformParameter(
      queryParameters['dealer_id'],
    );

    queryParameters['with_parent_user'] = true;

    if (typeof parameters['withParentUser'] !== 'undefined') {
      queryParameters['with_parent_user'] = parameters['withParentUser'];
    }

    queryParameters['with_parent_user'] = this.transformParameter(
      queryParameters['with_parent_user'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Activate customer account
   * @method
   * @name API#activateUser
   */
  activateUser(parameters: { activationCode: string }): Promise<void> {
    let path = '/customers/activate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['activationCode'] !== 'undefined') {
      queryParameters['activation_code'] = parameters['activationCode'];
    }

    queryParameters['activation_code'] = this.transformParameter(
      queryParameters['activation_code'],
    );

    if (typeof parameters['activationCode'] === 'undefined') {
      throw new Error('Missing required parameter: activationCode');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Create access token to enable reset user's password (token is valid 15
minutes)

    * @method
    * @name API#lostPassword
    */
  lostPassword(
    parameters: {} = {},
    body: {
      emailOrIco: string;
    },
  ): Promise<{
    status?: string;

    email?: string;

    ico?: string;
  }> {
    let path = '/customers/lost-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * Send request email to administrators

    * @method
    * @name API#lostPasswordCompany
    */
  lostPasswordCompany(
    parameters: {} = {},
    body: {
      ico?: string;

      email_manager?: string;

      name?: string;

      email?: string;

      phone?: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/customers/lost-password-company';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * Save new user's password and password salt into Db and remove access
token for password reset

    * @method
    * @name API#newPassword
    */
  newPassword(
    parameters: {},
    body: {
      new_password: string;
    },
    extraHeaders?: {
      xLostPasswordToken: string;
    },
  ): Promise<void> {
    let path = '/customers/new-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xLostPasswordToken'] !== 'undefined'
    ) {
      headers.append(
        'x-lost-password-token',
        extraHeaders['xLostPasswordToken']!,
      );
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer delivery address
   * @method
   * @name API#getDeliveryAddresses
   */
  getDeliveryAddresses(
    customerId: number,
    parameters: {
      addressId?: number;
      limit?: number;
      sort?: string;
      sortDir?: string;
    } = {},
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customerId}/delivery-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId.toString());

    if (typeof parameters['addressId'] !== 'undefined') {
      queryParameters['address_id'] = parameters['addressId'];
    }

    queryParameters['address_id'] = this.transformParameter(
      queryParameters['address_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['sort'] = 'created_at';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new customer's delivery address
   * @method
   * @name API#createNewDeliveryAddress
   */
  createNewDeliveryAddress(
    customerId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;
    },
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customerId}/delivery-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer billing address
   * @method
   * @name API#getBillingAddresses
   */
  getBillingAddresses(
    customerId: number,
    parameters: {
      addressId?: number;
      limit?: number;
      sort?: string;
      sortDir?: string;
    } = {},
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customerId}/billing-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId.toString());

    if (typeof parameters['addressId'] !== 'undefined') {
      queryParameters['address_id'] = parameters['addressId'];
    }

    queryParameters['address_id'] = this.transformParameter(
      queryParameters['address_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['sort'] = 'created_at';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new customer's billing address
   * @method
   * @name API#createNewBillingAddress
   */
  createNewBillingAddress(
    customerId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    },
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customerId}/billing-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get user info by user ID
   * @method
   * @name API#loadDashboard
   */
  loadDashboard(customerId: number, parameters: {} = {}): Promise<void> {
    let path = '/customers/{customer_id}/dashboard';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array with basic informations about product
   * @method
   * @name API#loadFavoriteProducts
   */
  loadFavoriteProducts(
    customerId: number,
    parameters: {
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    total: number;

    offset: number;

    price_min: number | null;

    price_max: number | null;

    limit: number;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      isnew_valid: string;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      set_delete_warning?: boolean;

      is_user_favorite?: boolean;
    }>;
  }> {
    let path = '/customers/{customer_id}/favorite-products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Unfavorite product (as customer)
   * @method
   * @name API#unfavoriteProduct
   */
  unfavoriteProduct(
    customerId: number,
    productId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/customers/{customer_id}/favorite-products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Favorite product (as customer)
   * @method
   * @name API#favoriteProduct
   */
  favoriteProduct(
    customerId: number,
    productId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/customers/{customer_id}/favorite-products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get orders by user ID
   * @method
   * @name API#getOrders
   */
  getOrders(
    customerId: number,
    parameters: {
      onlyActive?: '0' | '1';
      notCancelled?: '0' | '1';
      limit?: number;
      offset?: number;
      includeChildren?: boolean;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      person: {
        name: string;

        surname?: string;

        phone: string;

        email: string;
      };

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name?: string;

        ico?: string;

        dic?: string;

        ic_dph?: string;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      paymentUrl?: string;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;

      awarded_club_user_id?: number;

      awarded_school_id?: number;

      assigned_points_school?: number;

      assigned_points_awarded_person?: number;

      deliver_to_school?: number;

      children_name?: string;

      delivery_class_name?: string;

      delivery_school_id?: number | null;

      delivery_school_ico?: string | null;

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      disable_card_payments?: boolean;
    }>;
  }> {
    let path = '/customers/{customer_id}/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    if (typeof parameters['notCancelled'] !== 'undefined') {
      queryParameters['not_cancelled'] = parameters['notCancelled'];
    }

    queryParameters['not_cancelled'] = this.transformParameter(
      queryParameters['not_cancelled'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['includeChildren'] !== 'undefined') {
      queryParameters['include_children'] = parameters['includeChildren'];
    }

    queryParameters['include_children'] = this.transformParameter(
      queryParameters['include_children'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of transaction objects
   * @method
   * @name API#loadTransactions
   */
  loadTransactions(
    customerId: number,
    parameters: {
      dateStart?: string;
      dateEnd?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    current?: number;

    plus?: number;

    minus?: number;

    transactions?: Array<{
      id: number;

      id_user: number;

      id_order: number | null;

      amount: number;

      created_date: string;

      note: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/points';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateStart'] !== 'undefined') {
      queryParameters['date_start'] = parameters['dateStart'];
    }

    queryParameters['date_start'] = this.transformParameter(
      queryParameters['date_start'],
    );

    if (typeof parameters['dateEnd'] !== 'undefined') {
      queryParameters['date_end'] = parameters['dateEnd'];
    }

    queryParameters['date_end'] = this.transformParameter(
      queryParameters['date_end'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer's details
   * @method
   * @name API#getCustomerDetails
   */
  getCustomerDetails(
    id: number,
    parameters: {} = {},
  ): Promise<{
    id: number;

    meno?: string;

    priezvisko?: string;

    firma?: string;

    ico?: string;

    dic?: string;

    icdph?: string;

    platca_dph?: 0 | 1;

    ulica?: string;

    cislo?: string;

    mesto?: string;

    psc?: string;

    country?: string;

    telefon?: string;

    email?: string;

    login?: string;

    ip?: string;

    host?: string;

    registrovany?: string;

    stav?: string;

    poznamka?: string;

    nedoveryhodny?: 0 | 1;

    interna_poznamka?: string;

    has_ip_access?: 0 | 1;

    individualna_zlava?: number;

    lang?: string;

    activatecode?: string;

    activate_datetime?: string;

    guid?: string;

    login_hash?: string;

    uniqid?: string;

    card_number?: string;

    reg_discount?: 0 | 1;

    facebook_user_id?: string;

    google_user_id?: string;

    trust_points?: number;

    highest_trust_points_reached?: number;

    history_points?: {};

    contact_name?: string;

    contact_phone?: string;

    use_delivery_address?: number;

    school_id?: string;

    school_name?: string;
  }> {
    let path = '/customers/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update customer's details
   * @method
   * @name API#updateCustomerDetails
   */
  updateCustomerDetails(
    id: number,
    parameters: {} = {},
    body: {
      id: number;

      meno?: string;

      priezvisko?: string;

      firma?: string;

      ico?: string;

      dic?: string;

      icdph?: string;

      platca_dph?: 0 | 1;

      ulica?: string;

      cislo?: string;

      mesto?: string;

      psc?: string;

      country?: string;

      telefon?: string;

      email?: string;

      login?: string;

      ip?: string;

      host?: string;

      registrovany?: string;

      stav?: string;

      poznamka?: string;

      nedoveryhodny?: 0 | 1;

      interna_poznamka?: string;

      has_ip_access?: 0 | 1;

      individualna_zlava?: number;

      lang?: string;

      activatecode?: string;

      activate_datetime?: string;

      guid?: string;

      login_hash?: string;

      uniqid?: string;

      card_number?: string;

      reg_discount?: 0 | 1;

      facebook_user_id?: string;

      google_user_id?: string;

      trust_points?: number;

      highest_trust_points_reached?: number;

      history_points?: {};

      contact_name?: string;

      contact_phone?: string;

      use_delivery_address?: number;

      school_id?: string;

      school_name?: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get parent customer's details
   * @method
   * @name API#getParentCustomerDetails
   */
  getParentCustomerDetails(
    id: number,
    parameters: {} = {},
  ): Promise<{
    id: number;

    meno?: string;

    priezvisko?: string;

    firma?: string;

    ico?: string;

    dic?: string;

    icdph?: string;

    platca_dph?: 0 | 1;

    ulica?: string;

    cislo?: string;

    mesto?: string;

    psc?: string;

    country?: string;

    telefon?: string;

    email?: string;

    login?: string;

    ip?: string;

    host?: string;

    registrovany?: string;

    stav?: string;

    poznamka?: string;

    nedoveryhodny?: 0 | 1;

    interna_poznamka?: string;

    has_ip_access?: 0 | 1;

    individualna_zlava?: number;

    lang?: string;

    activatecode?: string;

    activate_datetime?: string;

    guid?: string;

    login_hash?: string;

    uniqid?: string;

    card_number?: string;

    reg_discount?: 0 | 1;

    facebook_user_id?: string;

    google_user_id?: string;

    trust_points?: number;

    highest_trust_points_reached?: number;

    history_points?: {};

    contact_name?: string;

    contact_phone?: string;

    use_delivery_address?: number;

    school_id?: string;

    school_name?: string;
  }> {
    let path = '/customers/parent/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Authorized customer can through this endpoint change his password
   * @method
   * @name API#changePassword
   */
  changePassword(
    id: string,
    parameters: {} = {},
    body: {
      current_password: string;

      new_password: string;

      password_check: string;
    },
    extraHeaders?: {
      xApiKey?: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}/change-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['xApiKey'] !== 'undefined') {
      headers.append('X-API-Key', extraHeaders['xApiKey']!);
    }

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Authorized customer can through this endpoint change his password
   * @method
   * @name API#adminChangePassword
   */
  adminChangePassword(
    id: string,
    parameters: {} = {},
    body: {
      new_password: string;
    },
    extraHeaders?: {
      xApiKey?: string;
    },
  ): Promise<void> {
    let path = '/admin/customers/{id}/change-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['xApiKey'] !== 'undefined') {
      headers.append('X-API-Key', extraHeaders['xApiKey']!);
    }

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Reimport customer prices
   * @method
   * @name API#adminReimportCustomerPrices
   */
  adminReimportCustomerPrices(id: number, parameters: {} = {}): Promise<void> {
    let path = '/admin/customers/{id}/reimport_customer_prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete particular customer's delivery address
   * @method
   * @name API#deleteDeliveryAddress
   */
  deleteDeliveryAddress(
    id: number,
    deliveryAddressId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{id}/delivery-address/{delivery_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{delivery_address_id}', deliveryAddressId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update particular customer's delivery address
   * @method
   * @name API#updateDeliveryAddress
   */
  updateDeliveryAddress(
    id: number,
    deliveryAddressId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}/delivery-address/{delivery_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{delivery_address_id}', deliveryAddressId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete particular customer's billing address
   * @method
   * @name API#deleteBillingAddress
   */
  deleteBillingAddress(
    id: number,
    billingAddressId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{id}/billing-address/{billing_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{billing_address_id}', billingAddressId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update particular customer's billing address
   * @method
   * @name API#updateBillingAddress
   */
  updateBillingAddress(
    id: number,
    billingAddressId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}/billing-address/{billing_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{billing_address_id}', billingAddressId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get user's most frequently purchased products
   * @method
   * @name API#loadMostFrequentPurchased
   */
  loadMostFrequentPurchased(
    customerId: number,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      order_id: number;

      item_idx: number;

      ordernr: number;

      product_name: string;

      units: string | null;

      unitprice: number;

      vat_rate: number;

      quantity: number;

      credit_note_quantity: number | null;

      totalpoints: number;

      totalprice: number;

      currency: string;

      product_id: number;

      good_id: number;

      skladom: number | null;

      date_delivery: string | null;

      from_store_id: string | null;

      product_note: string | null;

      ean: string | null;

      product_picture: string | null;

      product_url: string | null;

      good_details?: string;

      good_details_json: {
        productName: string;

        productNote: string | null;

        units: string | null;

        ordernr: number;

        ean: string | null;

        internalcode: string | null;

        unitprice: {
          price: number;

          currency: string;

          excludeVat: number;

          vatRate: string;
        };

        onstock: number;

        onstockCount: string;

        orderAvailable: number;

        points: number;

        id: string;

        productId: string;

        productTypeId: string;

        hasSales: boolean;

        isFromSale: boolean;

        isFromGroupSale: boolean;

        isPointGood: number;

        pointAmount: number;

        availabilityId: string;

        availability: {
          name: string;

          uniqueid: string;

          id: string;

          lang: string;

          order_available: string;
        };

        attribs: Array<{
          name: string;

          unit: string;

          htmlUnit: string;

          value: string | null;

          customValue: string;

          groupName: string | null;

          attribId: string;

          valueId: number | null;

          valueGroupId: number | null;

          groupId: number | null;

          orderDepend: number;

          hexadecimal: string | null;

          attrib_ordernr: number;

          default_for_cart: number | null;
        }>;

        attribsByAttribId: {
          groupId: number;

          groupName: string;

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;
        };
      };

      is_meta_item?: boolean;
    }>;

    total?: number;
  }> {
    let path = '/customers/{customer_id}/most-frequently-purchased';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    queryParameters['limit'] = 3;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Send Loyality Reminders Emails

    * @method
    * @name API#adminSendLoyalityRemindersEmails
    */
  adminSendLoyalityRemindersEmails(parameters: {
    test: boolean;
  }): Promise<{
    status?: string;
  }> {
    let path = '/customers/send-loyality-reminders-emails';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['test'] = true;

    if (typeof parameters['test'] !== 'undefined') {
      queryParameters['test'] = parameters['test'];
    }

    queryParameters['test'] = this.transformParameter(queryParameters['test']);

    if (typeof parameters['test'] === 'undefined') {
      throw new Error('Missing required parameter: test');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Send Company email after registration

    * @method
    * @name API#adminSendCompanyEmailAfterRegistration
    */
  adminSendCompanyEmailAfterRegistration(
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/customers/send-company-email-after-registration';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Send retail without order first email

    * @method
    * @name API#adminSendRetailWithoutOrderFirstEmail
    */
  adminSendRetailWithoutOrderFirstEmail(
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/customers/send-retail-without-order-first-email';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Send retail without order second email

    * @method
    * @name API#adminSendRetailWithoutOrderSecondEmail
    */
  adminSendRetailWithoutOrderSecondEmail(
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/customers/send-retail-without-order-second-email';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Send not logged company emails

    * @method
    * @name API#adminSendNotLoggedCompanyEmails
    */
  adminSendNotLoggedCompanyEmails(
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/customers/send-not-logged-company-emails';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about ebook
   * @method
   * @name API#loadEbookDetail
   */
  loadEbookDetail(
    ebookId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    connected_ebooks?: {};

    created_date?: string;

    downloadLink?: {};

    downloadSize?: string;

    id?: number;

    image?: string;

    json_content?: {};

    name?: string;

    recommendations?: {};

    sf_form?: {};

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;
  }> {
    let path = '/ebook/{ebook_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{ebook_id}', ebookId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in ElasticSearch
   * @method
   * @name API#updateElasticCategory
   */
  updateElasticCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/elastic/category/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates product in ElasticSearch
   * @method
   * @name API#updateElasticProduct
   */
  updateElasticProduct(productId: number, parameters: {} = {}): Promise<void> {
    let path = '/elastic/products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * List of eshop gifts
   * @method
   * @name API#loadEshopGifts
   */
  loadEshopGifts(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    gifts?: Array<{
      product_id: string;

      product_name?: string;

      product_url?: string;

      picture?: string;

      price_from: number;

      available?: 0 | 1;
    }>;
  }> {
    let path = '/eshop-gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Creates gift in the database
   * @method
   * @name API#createGift
   */
  createGift(
    parameters: {} = {},
    body: {
      product_id: number;

      price_from: number;

      available: 0 | 1;
    },
  ): Promise<{
    available?: '0' | '1';

    picture?: string;

    price_from: number;

    product_id: string;

    product_name?: string;
  }> {
    let path = '/eshop-gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes gift in the database
   * @method
   * @name API#deleteGift
   */
  deleteGift(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load gift detail
   * @method
   * @name API#loadGiftDetail
   */
  loadGiftDetail(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    product_id: string;

    product_name?: string;

    product_url?: string;

    picture?: string;

    price_from: number;

    available?: 0 | 1;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates gift price_from in the database
   * @method
   * @name API#updateGift
   */
  updateGift(
    productId: number,
    parameters: {} = {},
    body: {
      available?: '0' | '1';

      price_from?: number;
    },
  ): Promise<{
    product_id: string;

    product_name?: string;

    product_url?: string;

    picture?: string;

    price_from: number;

    available?: 0 | 1;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadExpeditionFreeDays
   */
  loadExpeditionFreeDays(
    parameters: {
      marketId?: string;
    } = {},
  ): Promise<void> {
    let path = '/expedition-free-days';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['marketId'] !== 'undefined') {
      queryParameters['market_id'] = parameters['marketId'];
    }

    queryParameters['market_id'] = this.transformParameter(
      queryParameters['market_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createExpeditionFreeDay
   */
  createExpeditionFreeDay(
    parameters: {} = {},
    body: {
      description: string;

      free_date: string;

      id: number;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-free-days';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#updateExpeditionFreeDay
   */
  updateExpeditionFreeDay(
    id: string,
    parameters: {} = {},
    body: {
      id: number | null;

      free_date: string;

      description: string;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-free-days/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#deleteExpeditionFreeDay
   */
  deleteExpeditionFreeDay(id: string, parameters: {} = {}): Promise<void> {
    let path = '/expedition-free-days/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadExpeditionHours
   */
  loadExpeditionHours(
    parameters: {
      marketId?: string;
    } = {},
  ): Promise<void> {
    let path = '/expedition-hours';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['marketId'] !== 'undefined') {
      queryParameters['market_id'] = parameters['marketId'];
    }

    queryParameters['market_id'] = this.transformParameter(
      queryParameters['market_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createExpeditionHour
   */
  createExpeditionHour(
    parameters: {} = {},
    body: {
      day: number;

      id: number;

      market_id: string;

      time_from: string;

      time_to: string;
    },
  ): Promise<void> {
    let path = '/expedition-hours';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#updateExpeditionHour
   */
  updateExpeditionHour(
    id: string,
    parameters: {} = {},
    body: {
      id: number | null;

      day: number;

      time_from: string;

      time_to: string;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-hours/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve a file from the server
   * @method
   * @name API#processFile
   */
  processFile(parameters: { query: string }): Promise<{}> {
    let path = '/file';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve a file from the server
   * @method
   * @name API#processFileAttachment
   */
  processFileAttachment(parameters: {
    query: string;
    token: string;
  }): Promise<{}> {
    let path = '/file-attachment';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    if (typeof parameters['token'] !== 'undefined') {
      queryParameters['token'] = parameters['token'];
    }

    queryParameters['token'] = this.transformParameter(
      queryParameters['token'],
    );

    if (typeof parameters['token'] === 'undefined') {
      throw new Error('Missing required parameter: token');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get token to the file in query
   * @method
   * @name API#getFileToken
   */
  getFileToken(parameters: {
    query: string;
  }): Promise<{
    codeToken?: string;
  }> {
    let path = '/file-attachment-token';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Heureka eshop reviews
   * @method
   * @name API#loadHeurekaEshopReviews
   */
  loadHeurekaEshopReviews(
    parameters: {
      limit?: number;
      offset?: number;
      totalRatingMore?: number;
    } = {},
  ): Promise<{
    reviews?: Array<{
      rating_id: string;

      first_imported_at?: string;

      last_imported_at?: string;

      source?: string;

      unix_timestamp?: string;

      total_rating?: number;

      recommends?: number;

      delivery_time?: number;

      transport_quality?: number;

      communication?: number;

      summary?: string;

      order_id?: string;
    }>;
  }> {
    let path = '/heureka/eshop-reviews';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['totalRatingMore'] !== 'undefined') {
      queryParameters['total_rating_more'] = parameters['totalRatingMore'];
    }

    queryParameters['total_rating_more'] = this.transformParameter(
      queryParameters['total_rating_more'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#processImage
   */
  processImage(parameters: {
    query: string;
    webp?: '0' | '1';
    type?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'normal';
    width?: number;
    height?: number;
    color?: string;
  }): Promise<{}> {
    let path = '/image';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    queryParameters['webp'] = '0';

    if (typeof parameters['webp'] !== 'undefined') {
      queryParameters['webp'] = parameters['webp'];
    }

    queryParameters['webp'] = this.transformParameter(queryParameters['webp']);

    queryParameters['type'] = 'contain';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['width'] !== 'undefined') {
      queryParameters['width'] = parameters['width'];
    }

    queryParameters['width'] = this.transformParameter(
      queryParameters['width'],
    );

    if (typeof parameters['height'] !== 'undefined') {
      queryParameters['height'] = parameters['height'];
    }

    queryParameters['height'] = this.transformParameter(
      queryParameters['height'],
    );

    queryParameters['color'] = 'white';

    if (typeof parameters['color'] !== 'undefined') {
      queryParameters['color'] = parameters['color'];
    }

    queryParameters['color'] = this.transformParameter(
      queryParameters['color'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * save product impression
   * @method
   * @name API#saveImpressionProduct
   */
  saveImpressionProduct(
    productId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impressions/product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * save article impression
   * @method
   * @name API#saveImpressionArticle
   */
  saveImpressionArticle(
    articleId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impressions/article/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about most read articles. This route returns list of most read articles composed from impressions table and manual web_content_most_read submissions
   * @method
   * @name API#loadMostReadArticles
   */
  loadMostReadArticles(
    parameters: {
      limit?: number;
      offset?: number;
      type?: 'week' | 'month' | 'year';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{
      author?: {
        author_id?: number;

        image?: string;

        name?: string;

        url?: string;
      };

      created_date?: string;

      created_user_id?: number;

      documents?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      downloads?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      draft?: boolean;

      hint?: string;

      home?: boolean;

      id?: number;

      is_top?: boolean;

      json_content?: {};

      lang_id?: string;

      lang_version_id?: number;

      last_update?: string;

      name?: string;

      next_article?: {
        annotation?: string;

        id?: string;

        image?: string;

        name?: string;

        url?: string;
      };

      ordering?: number;

      original_id?: number;

      photogallery?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      products?: Array<{
        attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        brand?: {
          brand_descr?: string;

          brand_id?: number;

          brand_image?: string;

          brand_name?: string;

          brand_provider?: string;

          brand_top?: boolean;

          brand_weburl?: string;

          catalog_id?: number;

          translations?: Array<{
            brand_descr?: string;

            brand_id?: number;

            brand_name?: string;

            brand_weburl?: string;

            lang_id?: string;
          }>;

          url?: string;
        };

        brand_id?: number;

        category_text?: string;

        created_at?: string;

        created_datetime?: string;

        description?: string;

        disable_discount?: boolean;

        disable_solo_order?: boolean;

        ean?: string;

        goods?: Array<{
          availability?: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id?: number;

              avail_name?: string;

              lang?: string;
            }>;
          };

          availability_date?: string;

          availability_id?: number;

          buy_price?: number;

          currency?: {
            id?: string;

            name?: string;
          };

          currency_id?: string;

          customer_prices?: {};

          discount_pcn?: number;

          ean?: string;

          final_price?: number;

          from_group_sale?: boolean;

          from_sale?: boolean;

          giftcard?: {
            apply_per_unit?: boolean;

            card_number?: string;

            card_status?: string;

            club_user_id?: number;

            created_date?: string;

            created_user_id?: number;

            date_used?: string;

            date_validity?: string;

            discount?: number;

            discount_currency?: string;

            freeshipping?: boolean;

            freeshippingeu?: boolean;

            id?: number;

            idx?: number;

            is_strict_discount?: boolean;

            lang?: string;

            multicard?: boolean;

            order_id?: number;

            price?: number;

            price_currency?: string;

            price_validity?: number;

            sale?: number;

            sale_count?: number;

            valid_product_ids?: Array<number>;

            valid_products_count?: number;
          };

          good_id?: number;

          group_price?: {
            oldprice?: number;

            sale_percentage?: number;

            unitprice?: number;
          };

          group_prices?: Array<{}>;

          idoklad_id?: string;

          imagePath?: string;

          internal_code?: string;

          is_on_stock?: boolean;

          karat_id?: string;

          lang?: string;

          oldprice?: number;

          on_stock_count?: number;

          order_number?: string;

          order_quantity_limit?: number;

          point_good?: boolean;

          points_amount?: number;

          price_without_vat?: number;

          price_without_vat_sale?: number;

          priceinfo?: string;

          product_id?: number;

          product_name?: string;

          product_note?: string;

          product_type?: number;

          purchaseprice?: number;

          qtyDiscounts?: {};

          sale?: boolean;

          sale_percentage?: number;

          sale_valid?: string;

          unit_price_sale?: number;

          unitprice?: number;

          unitprice_sale?: number;

          units?: string;

          valid_from?: string;

          valid_to?: string;

          vat_rate?: number;

          web_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          web_order_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          weight?: number;
        }>;

        heureka?: number;

        heureka_id?: number;

        internal_code?: number;

        is_favorite?: boolean;

        is_new?: boolean;

        is_popular?: boolean;

        is_recommended?: boolean;

        is_sale?: boolean;

        isactive?: number;

        isnew_valid?: string;

        issale_buy3_pay2?: number;

        issaleout?: boolean;

        issaleout_since?: string;

        issaleout_valid?: string;

        istop?: boolean;

        locked?: number;

        main_good?: {
          availability?: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id?: number;

              avail_name?: string;

              lang?: string;
            }>;
          };

          availability_date?: string;

          availability_id?: number;

          buy_price?: number;

          currency?: {
            id?: string;

            name?: string;
          };

          currency_id?: string;

          customer_prices?: {};

          discount_pcn?: number;

          ean?: string;

          final_price?: number;

          from_group_sale?: boolean;

          from_sale?: boolean;

          giftcard?: {
            apply_per_unit?: boolean;

            card_number?: string;

            card_status?: string;

            club_user_id?: number;

            created_date?: string;

            created_user_id?: number;

            date_used?: string;

            date_validity?: string;

            discount?: number;

            discount_currency?: string;

            freeshipping?: boolean;

            freeshippingeu?: boolean;

            id?: number;

            idx?: number;

            is_strict_discount?: boolean;

            lang?: string;

            multicard?: boolean;

            order_id?: number;

            price?: number;

            price_currency?: string;

            price_validity?: number;

            sale?: number;

            sale_count?: number;

            valid_product_ids?: Array<number>;

            valid_products_count?: number;
          };

          good_id?: number;

          group_price?: {
            oldprice?: number;

            sale_percentage?: number;

            unitprice?: number;
          };

          group_prices?: Array<{}>;

          idoklad_id?: string;

          imagePath?: string;

          internal_code?: string;

          is_on_stock?: boolean;

          karat_id?: string;

          lang?: string;

          oldprice?: number;

          on_stock_count?: number;

          order_number?: string;

          order_quantity_limit?: number;

          point_good?: boolean;

          points_amount?: number;

          price_without_vat?: number;

          price_without_vat_sale?: number;

          priceinfo?: string;

          product_id?: number;

          product_name?: string;

          product_note?: string;

          product_type?: number;

          purchaseprice?: number;

          qtyDiscounts?: {};

          sale?: boolean;

          sale_percentage?: number;

          sale_valid?: string;

          unit_price_sale?: number;

          unitprice?: number;

          unitprice_sale?: number;

          units?: string;

          valid_from?: string;

          valid_to?: string;

          vat_rate?: number;

          web_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          web_order_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          weight?: number;
        };

        max_price?: number;

        min_delivery_priority?: number;

        min_payment_priority?: number;

        min_price?: number;

        name?: string;

        name_url_encoded?: string;

        notshow?: number;

        ordered_count?: number;

        picture?: string;

        picture_hash?: string;

        picture_media_script?: string;

        plu?: string;

        product_fts?: string;

        product_id?: number;

        product_weight?: number;

        rating?: number;

        rating_users?: number;

        slogan?: string;

        type?: {
          catalog_id?: number;

          is_hidden?: number;

          store_minqty?: string;

          type_descr?: string;

          type_id?: number;

          type_inherits?: number;

          type_name?: string;
        };

        url?: string;

        valid_from?: string;

        valid_to?: string;
      }>;

      publish?: number;

      rss?: boolean;

      sf_form?: string;

      sf_stylesheet?: string;

      sf_xmlcontent?: string;

      sitemap_item_id?: number;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;

      videos?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        ordering?: number;

        video_description?: string;

        video_height?: number;

        video_id?: number;

        video_name?: string;

        video_url?: string;

        video_width?: number;

        webshow?: number;
      }>;
    }>;
  }> {
    let path = '/impressions/most-visited/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports product to Karat
   * @method
   * @name API#exportProduct
   */
  exportProduct(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idProduct?: string;
  }> {
    let path = '/karat/export-product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports variant to Karat
   * @method
   * @name API#exportVariant
   */
  exportVariant(
    productId: number,
    goodId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    errorCode?: string;

    error?: string;
  }> {
    let path = '/karat/export-variant/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports product to Karat
   * @method
   * @name API#exportProductWithVariants
   */
  exportProductWithVariants(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idProduct?: string;
  }> {
    let path = '/karat/export-product-with-variants/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports category to Karat
   * @method
   * @name API#exportCategory
   */
  exportCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idGroup?: string;
  }> {
    let path = '/karat/export-category/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports order to Karat
   * @method
   * @name API#exportOrder
   */
  exportOrder(
    orderId: string,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idOrder?: string;
  }> {
    let path = '/karat/export-order/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports user to Karat
   * @method
   * @name API#exportUser
   */
  exportUser(
    userId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idUser?: string;
  }> {
    let path = '/karat/export-user/{user_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{user_id}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Imports stock count from Karat
   * @method
   * @name API#importStocks
   */
  importStocks(productId: number, parameters: {} = {}): Promise<{}> {
    let path = '/karat/import-stocks/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Imports invoice from Karat
   * @method
   * @name API#importOrderInvoice
   */
  importOrderInvoice(
    orderId: string,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;
  }> {
    let path = '/karat/import-invoice/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads all available languages
   * @method
   * @name API#loadLangList
   */
  loadLangList(
    parameters: {} = {},
  ): Promise<{
    languages?: Array<{
      lang_default?: '0' | '1';

      lang_id?: string;

      lang_name?: string;

      lang_sort?: number;
    }>;
  }> {
    let path = '/langs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads translations for concrete language
   * @method
   * @name API#loadLangTranslation
   */
  loadLangTranslation(langId: string, parameters: {} = {}): Promise<{}> {
    let path = '/langs/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update lang translations
   * @method
   * @name API#updateLangTranslation
   */
  updateLangTranslation(
    langId: string,
    parameters: {} = {},
    body: {},
  ): Promise<void> {
    let path = '/langs/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns last minutes including array of products
   * @method
   * @name API#searchLastMinutes
   */
  searchLastMinutes(
    parameters: {
      onlyValid?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    lastMinute?: {
      id: number;

      name: string;

      validFrom: string;

      validTo: string;

      products: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      }>;
    };
  }> {
    let path = '/last-minutes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    queryParameters['only_valid'] = 1;

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['only_valid'] = parameters['onlyValid'];
    }

    queryParameters['only_valid'] = this.transformParameter(
      queryParameters['only_valid'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about last minute including array of products
   * @method
   * @name API#getLastMinute
   */
  getLastMinute(
    lastminuteName: string,
    parameters: {} = {},
  ): Promise<{
    id: number;

    name: string;

    validFrom: string;

    validTo: string;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      isnew_valid: string;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      set_delete_warning?: boolean;

      is_user_favorite?: boolean;
    }>;
  }> {
    let path = '/last-minutes/{lastminute_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lastminute_name}', lastminuteName.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if member exists in list
   * @method
   * @name API#newsletterMemberExists
   */
  newsletterMemberExists(parameters: {
    engine: 'mailchimp';
    email: string;
  }): Promise<{
    exists: boolean;
  }> {
    let path = '/newsletter/member-exists';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['engine'] !== 'undefined') {
      queryParameters['engine'] = parameters['engine'];
    }

    queryParameters['engine'] = this.transformParameter(
      queryParameters['engine'],
    );

    if (typeof parameters['engine'] === 'undefined') {
      throw new Error('Missing required parameter: engine');
    }

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Subscribe to nesletter
   * @method
   * @name API#subscribeNewsletter
   */
  subscribeNewsletter(
    parameters: {
      engine: 'mailchimp' | 'smartemailing';
      type?: 'school';
    },
    body: {
      email: string;

      first_name: string;

      last_name: string;

      source: string;

      status: string;
    },
  ): Promise<{}> {
    let path = '/newsletter/subscribe';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['engine'] !== 'undefined') {
      queryParameters['engine'] = parameters['engine'];
    }

    queryParameters['engine'] = this.transformParameter(
      queryParameters['engine'],
    );

    if (typeof parameters['engine'] === 'undefined') {
      throw new Error('Missing required parameter: engine');
    }

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Unsubscribe to nesletter
   * @method
   * @name API#unsubscribeNewsletter
   */
  unsubscribeNewsletter(
    userHash: string,
    parameters: {} = {},
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/newsletter/unsubscribe/{userHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{userHash}', userHash.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exchange code token for token
   * @method
   * @name API#changeCodeForToken
   */
  changeCodeForToken(
    parameters: {} = {},
    body: {
      code?: string;
    },
  ): Promise<{}> {
    let path = '/oauth/change-code';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Impersonate for user
   * @method
   * @name API#impersonate
   */
  impersonate(parameters: { user: string; redirectUri: string }): Promise<{}> {
    let path = '/oauth/impersonate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['user'] !== 'undefined') {
      queryParameters['user'] = parameters['user'];
    }

    queryParameters['user'] = this.transformParameter(queryParameters['user']);

    if (typeof parameters['user'] === 'undefined') {
      throw new Error('Missing required parameter: user');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Prihlasenie pouzivatela - ziskanie tokenu


Tato metoda bud vyhlasi neznamu chybu, alebo redirectne spat na
referrera, alebo redirectne na redirect uri s tokenom alebo inou chybou.


V pripade redirectu na referrera moze nastat nasledovne chyby:


- INVALID_REQUEST (XHR nie je povolene)

- INVALID_CLIENT (client_id je nezname)

- INVALID_REFERER (poziadavka bola odoslana zo blbej URL)

- INVALID_CREDENTIALS (login a heslo nie su vporiadku)

- INVALID_REDIRECT_URI (redirect uri nie je medzi zadanymi v zozname
aplikacii)

- INVALID_RESPONSE_TYPE (napriklad implicit metoda nemusi byt povolena
vsetkym apkam)


Referer je default https://my.sportnet.online/auth/authorize pokial nie
je uvedeny iny referrer ktory sa zhoduje s tymi nastavenymi v aplikacii.


Zaroven je vrateny status 302 a nastavena location hlavicka.

    * @method
    * @name API#oauthAuthorize
    */
  oauthAuthorize(parameters: {
    clientId: string;
    cartId?: string;
    redirectUri: string;
    responseType?: string;
    scope: string;
    state?: string;
    appSpace?: string;
  }): Promise<{}> {
    let path = '/oauth/authorize';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    if (typeof parameters['clientId'] === 'undefined') {
      throw new Error('Missing required parameter: clientId');
    }

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cart_id'] = parameters['cartId'];
    }

    queryParameters['cart_id'] = this.transformParameter(
      queryParameters['cart_id'],
    );

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    queryParameters['response_type'] = 'token';

    if (typeof parameters['responseType'] !== 'undefined') {
      queryParameters['response_type'] = parameters['responseType'];
    }

    queryParameters['response_type'] = this.transformParameter(
      queryParameters['response_type'],
    );

    if (typeof parameters['scope'] !== 'undefined') {
      queryParameters['scope'] = parameters['scope'];
    }

    queryParameters['scope'] = this.transformParameter(
      queryParameters['scope'],
    );

    if (typeof parameters['scope'] === 'undefined') {
      throw new Error('Missing required parameter: scope');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Facebook authentication - Redirects to Facebook server
   * @method
   * @name API#facebook
   */
  facebook(
    parameters: {
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/facebook';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Facebook callback URL called when login form from FB has been confirmed
   * @method
   * @name API#facebookHandler
   */
  facebookHandler(
    parameters: {
      code?: string;
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/facebook-callback';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Google authentication - Redirects to Google server
   * @method
   * @name API#google
   */
  google(
    parameters: {
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/google';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Google callback URL called when login form from Google has been
confirmed

    * @method
    * @name API#googleHandler
    */
  googleHandler(
    parameters: {
      code?: string;
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/google-callback';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Fow now used to remove user ID from his cart
   * @method
   * @name API#oauthLogout
   */
  oauthLogout(parameters: {} = {}): Promise<{}> {
    let path = '/oauth/logout';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * After registration customer recieves activation email
   * @method
   * @name API#registerUser
   */
  registerUser(
    parameters: {
      withAccessToken?: boolean;
    } = {},
    body: {
      email: string;

      login?: string;

      lang?: string;

      password: string;

      password_again: string;

      skvelko_type?: number;

      company_name?: string;

      ico?: string;

      dic?: string;

      icdph?: string;

      street?: string;

      street_number?: string;

      town?: string;

      zip?: string;

      phone?: string;

      contact_name?: string;

      contact_phone?: string;

      customer_card?: boolean;

      customer_card_send?: boolean;

      customer_card_name?: string;

      customer_card_surname?: string;

      customer_card_phone?: string;

      customer_card_birth?: string;

      customer_card_street?: string;

      customer_card_street_number?: string;

      customer_card_town?: string;

      customer_card_zip?: string;

      customer_card_sex?: string;

      customer_card_age_approval?: boolean;

      customer_card_card_approval?: boolean;

      customer_card_email_approval?: boolean;

      customer_card_profile_approval?: boolean;
    },
  ): Promise<{
    accessToken?: string;
  }> {
    let path = '/oauth/register';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['withAccessToken'] !== 'undefined') {
      queryParameters['with_access_token'] = parameters['withAccessToken'];
    }

    queryParameters['with_access_token'] = this.transformParameter(
      queryParameters['with_access_token'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about token
   * @method
   * @name API#tokeninfo
   */
  tokeninfo(parameters: {
    accessToken: string;
  }): Promise<{
    account_verified?: boolean;

    email?: string;

    id?: number;

    lang?: string;

    name?: string;

    surname?: string;

    display_name?: number;

    token_info?: {};

    skvelko_type?: number;

    skvelko_authorized?: boolean;

    skvelko_activated?: boolean;

    type_sale_price?: number;

    customer_card?: string;

    customer_card_discount?: number;

    individualna_zlava?: number;

    fa?: boolean;

    skvelko_sets_count?: number;
  }> {
    let path = '/oauth/tokeninfo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['accessToken'] !== 'undefined') {
      queryParameters['access_token'] = parameters['accessToken'];
    }

    queryParameters['access_token'] = this.transformParameter(
      queryParameters['access_token'],
    );

    if (typeof parameters['accessToken'] === 'undefined') {
      throw new Error('Missing required parameter: accessToken');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * List of orders
   * @method
   * @name API#searchOrders
   */
  searchOrders(
    parameters: {
      limit?: number;
      orderNr?: string;
      status?: string;
      customerName?: string;
      sort?: string;
      sortDir?: string;
      offset?: number;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      person: {
        name: string;

        surname?: string;

        phone: string;

        email: string;
      };

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name?: string;

        ico?: string;

        dic?: string;

        ic_dph?: string;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      paymentUrl?: string;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;

      awarded_club_user_id?: number;

      awarded_school_id?: number;

      assigned_points_school?: number;

      assigned_points_awarded_person?: number;

      deliver_to_school?: number;

      children_name?: string;

      delivery_class_name?: string;

      delivery_school_id?: number | null;

      delivery_school_ico?: string | null;

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      disable_card_payments?: boolean;
    }>;
  }> {
    let path = '/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['orderNr'] !== 'undefined') {
      queryParameters['order_nr'] = parameters['orderNr'];
    }

    queryParameters['order_nr'] = this.transformParameter(
      queryParameters['order_nr'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['customerName'] !== 'undefined') {
      queryParameters['customer_name'] = parameters['customerName'];
    }

    queryParameters['customer_name'] = this.transformParameter(
      queryParameters['customer_name'],
    );

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * List of orders' statuses
   * @method
   * @name API#getStatuses
   */
  getStatuses(
    parameters: {} = {},
  ): Promise<{
    name?: {
      message?: string;

      step?: number;
    };
  }> {
    let path = '/orders/statuses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get order by order public id
   * @method
   * @name API#getOrder
   */
  getOrder(
    id: string,
    parameters: {} = {},
  ): Promise<{
    _id: number;

    order_id: number;

    order_nr: string;

    order_status: string;

    order_status_color: string;

    order_status_readable: string;

    order_type: string;

    order_tprice: number;

    order_tpricecurrency: string;

    created_datetime: string;

    order_cancelled_datetime: string | null;

    order_paid_datetime: string | null;

    order_ship_datetime: string | null;

    order_comments: string;

    shipnr: string | null;

    comments: string | null;

    order_lang: string;

    parent_order: number | null;

    vs: string;

    ac: string | null;

    credit_note: number;

    credit_note_number: number | null;

    credit_note_amount: number | null;

    credit_note_delivery: number;

    delivery_note_number: number | null;

    delivery_note_created_date: string | null;

    reservation_date: string | null;

    partner_id: number | null;

    oo_shop_id: number | null;

    oo_shop_name: string | null;

    oo_shop_address: string | null;

    consumed_points: number;

    assigned_points: number;

    giftcards: number;

    club_user_id: number | null;

    invoice_with_vat: number;

    invoice_number: number | null;

    invoice_created_date: string | null;

    invoice_maturity_date: string | null;

    invoice_delivery_date: string | null;

    public_id: string;

    person: {
      name: string;

      surname?: string;

      phone: string;

      email: string;
    };

    address: {
      person?: {
        name: string;

        surname?: string;

        phone: string;

        email: string;
      };

      street: string;

      street_number?: string;

      zip: string;

      city: string;

      country: string;

      country_code: string;

      country_id: string;

      company?: string;

      delivery_address_id?: number;
    };

    payment: {
      payment_type: string;

      payment_typename: string;

      payment_price: number;

      payment_pricecurrency: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      order_tprice: number;

      order_tpricecurrency: string;

      vat_rate: number;

      discount: number;

      individual_discount: number;

      group_discount: number;

      discount_amount: number;

      paid_amount: number | null;

      paid_amount_from_wallet: number | null;

      paid_amount_from_wallet_returned?: number | null;

      card_number: string | null;
    };

    delivery: {
      person: {
        name: string;

        surname?: string;

        phone: string;

        email: string;
      };

      delivery_firm: string | null;

      delivery_addr_street: string;

      delivery_addr_street_number: string;

      delivery_addr_city: string;

      delivery_addr_zip: string;

      delivery_addr_country: string;

      delivery_addr_country_code: string | null;

      delivery_type: string;

      delivery_typename: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      posta_id: number | null;

      posta_name: string | null;

      posta_address: string | null;
    };

    quatro: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    company: {
      name?: string;

      ico?: string;

      dic?: string;

      ic_dph?: string;
    };

    items?: Array<{
      order_id: number;

      item_idx: number;

      ordernr: number;

      product_name: string;

      units: string | null;

      unitprice: number;

      vat_rate: number;

      quantity: number;

      credit_note_quantity: number | null;

      totalpoints: number;

      totalprice: number;

      currency: string;

      product_id: number;

      good_id: number;

      skladom: number | null;

      date_delivery: string | null;

      from_store_id: string | null;

      product_note: string | null;

      ean: string | null;

      product_picture: string | null;

      product_url: string | null;

      good_details?: string;

      good_details_json: {
        productName: string;

        productNote: string | null;

        units: string | null;

        ordernr: number;

        ean: string | null;

        internalcode: string | null;

        unitprice: {
          price: number;

          currency: string;

          excludeVat: number;

          vatRate: string;
        };

        onstock: number;

        onstockCount: string;

        orderAvailable: number;

        points: number;

        id: string;

        productId: string;

        productTypeId: string;

        hasSales: boolean;

        isFromSale: boolean;

        isFromGroupSale: boolean;

        isPointGood: number;

        pointAmount: number;

        availabilityId: string;

        availability: {
          name: string;

          uniqueid: string;

          id: string;

          lang: string;

          order_available: string;
        };

        attribs: Array<{
          name: string;

          unit: string;

          htmlUnit: string;

          value: string | null;

          customValue: string;

          groupName: string | null;

          attribId: string;

          valueId: number | null;

          valueGroupId: number | null;

          groupId: number | null;

          orderDepend: number;

          hexadecimal: string | null;

          attrib_ordernr: number;

          default_for_cart: number | null;
        }>;

        attribsByAttribId: {
          groupId: number;

          groupName: string;

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;
        };
      };

      is_meta_item?: boolean;
    }>;

    documents?: {
      faktura?: string;

      predfaktura?: string;

      dobropis?: string;
    };

    showPayment?: boolean;

    paymentUrl?: string;

    use_delivery_address?: boolean;

    chosen_gift_name?: string;

    chosen_gift_id?: string;

    sale_explanation?: string;

    awarded_club_user_id?: number;

    awarded_school_id?: number;

    assigned_points_school?: number;

    assigned_points_awarded_person?: number;

    deliver_to_school?: number;

    children_name?: string;

    delivery_class_name?: string;

    delivery_school_id?: number | null;

    delivery_school_ico?: string | null;

    skvelko_class?: {
      id?: number;

      clubUserId?: number;

      name?: string;

      skvelkoAwardedUserId?: number;

      awardedClubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };

      clubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    };

    disable_card_payments?: boolean;
  }> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create order
   * @method
   * @name API#createOrder
   */
  createOrder(
    id: string,
    parameters: {
      mcCid?: string;
    } = {},
    body: {
      delivery?: {};

      payment?: {};

      delivery_address?: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      billing_address: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      company: {
        name?: string;

        ico?: string;

        dic?: string;

        ic_dph?: string;
      };

      billing_company: boolean;

      use_delivery_address: boolean;

      terms_accept: boolean;

      newsletter_accept: boolean;

      company_newsletter_accept?: boolean;

      heureka: boolean;

      note: string;

      step: number;

      zasielkovna_id?: string;

      skvelko_class_id?: number;

      awarded_school_id?: number;

      consumed_points: number;

      chosen_gift?: {};
    },
  ): Promise<{
    orderPublicId: string;
  }> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    if (typeof parameters['mcCid'] !== 'undefined') {
      queryParameters['mc_cid'] = parameters['mcCid'];
    }

    queryParameters['mc_cid'] = this.transformParameter(
      queryParameters['mc_cid'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update order
   * @method
   * @name API#updateOrder
   */
  updateOrder(
    id: string,
    parameters: {} = {},
    body: {
      message?: string;

      step?: number;
    },
  ): Promise<void> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get order document
   * @method
   * @name API#orderDocument
   */
  orderDocument(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create order document
   * @method
   * @name API#generateOrderDocument_1
   */
  generateOrderDocument_1(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis' | 'karat_faktura',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get list of order statuses
   * @method
   * @name API#statuses
   */
  statuses(
    publicId: string,
    parameters: {} = {},
  ): Promise<{
    name?: {
      message?: string;

      step?: number;
    };
  }> {
    let path = '/orders/{public_id}/statuses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with information about other text and its content
   * @method
   * @name API#loadOtherTexts
   */
  loadOtherTexts(
    textId: string,
    parameters: {} = {},
  ): Promise<{
    text_id: string;

    text_name: string;

    ordering: number;

    sfpform: string;

    text_group: string;

    content: {
      id: string | number;

      created_user_id: string | null;

      sitemap_item_id: string | null;

      name: string | null;

      created_date: string;

      ordering: number | null;

      sf_form: string;

      sf_stylesheet: string;

      sf_xmlcontent: string | null;

      draft: number;

      hint: string | null;

      url: string | null;

      last_update: string;

      rss: number;

      home: number;

      publish: number;

      json_content: string | null;

      documents?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      downloads?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      photogallery?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      videos?: Array<{
        video_id?: number;

        content_id?: number;

        video_name?: string | null;

        video_description?: string | null;

        video_url?: string | null;

        ordering?: number | null;

        created_date?: string | null;

        created_user?: number | null;

        webshow?: number | null;

        video_width?: number | null;

        video_height?: number | null;
      }>;

      products?: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      }>;

      next_article?: {
        name?: string;

        url?: string;

        image?: string;

        annotation?: string;

        id?: number;
      };

      tags?: Array<{
        tag_id: string;

        tag_name?: string;
      }>;
    };
  }> {
    let path = '/other-texts/{text_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{text_id}', textId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set order analytics sended
   * @method
   * @name API#updateOrderAnalytics
   */
  updateOrderAnalytics(publicId: string, parameters: {} = {}): Promise<void> {
    let path = '/orders/analytics/{public_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Change order payment
   * @method
   * @name API#changeOrderPayment
   */
  changeOrderPayment(
    publicId: string,
    parameters: {
      paymentType?: string;
    } = {},
  ): Promise<void> {
    let path = '/orders/{public_id}/payment';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    if (typeof parameters['paymentType'] !== 'undefined') {
      queryParameters['payment_type'] = parameters['paymentType'];
    }

    queryParameters['payment_type'] = this.transformParameter(
      queryParameters['payment_type'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Trigger event to export order to chemat
   * @method
   * @name API#adminExportToChemat
   */
  adminExportToChemat(
    id: number,
    parameters: {
      checkChematStatus?: boolean;
    } = {},
  ): Promise<void> {
    let path = '/orders/export_to_chemat/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    if (typeof parameters['checkChematStatus'] !== 'undefined') {
      queryParameters['check_chemat_status'] = parameters['checkChematStatus'];
    }

    queryParameters['check_chemat_status'] = this.transformParameter(
      queryParameters['check_chemat_status'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Trigger event to export order to lamitec
   * @method
   * @name API#adminExportToLamitec
   */
  adminExportToLamitec(id: number, parameters: {} = {}): Promise<void> {
    let path = '/orders/export_to_lamitec/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send order email
   * @method
   * @name API#adminOrderSendEmail
   */
  adminOrderSendEmail(
    id: number,
    template: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/orders/{id}/send_email/{template}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{template}', template.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get chemat groups for order
   * @method
   * @name API#adminChematGroups
   */
  adminChematGroups(id: number, parameters: {} = {}): Promise<void> {
    let path = '/orders/{id}/chemat-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Generates URL and redirects to Ahoj system

**DESCRIPTION**
Generates url from order_id and redirects user to this URL.

    * @method
    * @name API#ahojLoan
    */
  ahojLoan(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/ahoj/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Change order's paid_datetime when loan from Ahoj was signed

**DESCRIPTION**
This is called automatically by Ahoj when Ahoj loan was signed. If sign is correct
and `stav` is 4 - Podpísaná order_paid_datetime is changed in eshop_order database.
If `stav` is not 4, order_paid_datetime is set to NULL.

    * @method
    * @name API#ahojLoanNotif
    */
  ahojLoanNotif(parameters: {
    idobj: string;
    cislozml: string;
    stav: string;
    sign: string;
  }): Promise<void> {
    let path = '/payment/ahoj/notif';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['idobj'] !== 'undefined') {
      queryParameters['idobj'] = parameters['idobj'];
    }

    queryParameters['idobj'] = this.transformParameter(
      queryParameters['idobj'],
    );

    if (typeof parameters['idobj'] === 'undefined') {
      throw new Error('Missing required parameter: idobj');
    }

    if (typeof parameters['cislozml'] !== 'undefined') {
      queryParameters['cislozml'] = parameters['cislozml'];
    }

    queryParameters['cislozml'] = this.transformParameter(
      queryParameters['cislozml'],
    );

    if (typeof parameters['cislozml'] === 'undefined') {
      throw new Error('Missing required parameter: cislozml');
    }

    if (typeof parameters['stav'] !== 'undefined') {
      queryParameters['stav'] = parameters['stav'];
    }

    queryParameters['stav'] = this.transformParameter(queryParameters['stav']);

    if (typeof parameters['stav'] === 'undefined') {
      throw new Error('Missing required parameter: stav');
    }

    if (typeof parameters['sign'] !== 'undefined') {
      queryParameters['sign'] = parameters['sign'];
    }

    queryParameters['sign'] = this.transformParameter(queryParameters['sign']);

    if (typeof parameters['sign'] === 'undefined') {
      throw new Error('Missing required parameter: sign');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Redirects user to finished order page on frontend after leaving Ahoj site

**DESCRIPTION**
This is called after user is finished on loan site and is being redirected back to frontend
Uses apiFrontendUrl from config file and 
redirects user to `<apiFrontendUrl>/finish-order-message/<order_public_id>`

    * @method
    * @name API#ahojLoanFinish
    */
  ahojLoanFinish(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/ahoj/finish/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to TrustPay
   * @method
   * @name API#trustPay
   */
  trustPay(
    orderHash: string,
    parameters: {
      type: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw new Error('Missing required parameter: type');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to success page
   * @method
   * @name API#trustPaySuccess
   */
  trustPaySuccess(
    orderHash: string,
    parameters: {
      resultCode: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/success/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['resultCode'] !== 'undefined') {
      queryParameters['ResultCode'] = parameters['resultCode'];
    }

    queryParameters['ResultCode'] = this.transformParameter(
      queryParameters['ResultCode'],
    );

    if (typeof parameters['resultCode'] === 'undefined') {
      throw new Error('Missing required parameter: resultCode');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to error page
   * @method
   * @name API#trustPayError
   */
  trustPayError(
    orderHash: string,
    parameters: {
      res?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/trustpay/error/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['res'] !== 'undefined') {
      queryParameters['RES'] = parameters['res'];
    }

    queryParameters['RES'] = this.transformParameter(queryParameters['RES']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Checks if payment was ok
   * @method
   * @name API#trustPayNotif
   */
  trustPayNotif(
    orderHash: string,
    parameters: {
      accountId: string;
      type: string;
      amount: string;
      currency: string;
      reference?: string;
      resultCode: string;
      paymentId: string;
      refuseReason?: string;
      orderId?: string;
      counterAccount?: string;
      counterAccountName?: string;
      signature: string;
      cardId?: string;
      cardMask?: string;
      cardExp?: string;
      authNumber?: string;
      cardRecTxSec?: string;
      acqResId?: string;
      sig2?: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/notif/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['accountId'] !== 'undefined') {
      queryParameters['AccountId'] = parameters['accountId'];
    }

    queryParameters['AccountId'] = this.transformParameter(
      queryParameters['AccountId'],
    );

    if (typeof parameters['accountId'] === 'undefined') {
      throw new Error('Missing required parameter: accountId');
    }

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['Type'] = parameters['type'];
    }

    queryParameters['Type'] = this.transformParameter(queryParameters['Type']);

    if (typeof parameters['type'] === 'undefined') {
      throw new Error('Missing required parameter: type');
    }

    if (typeof parameters['amount'] !== 'undefined') {
      queryParameters['Amount'] = parameters['amount'];
    }

    queryParameters['Amount'] = this.transformParameter(
      queryParameters['Amount'],
    );

    if (typeof parameters['amount'] === 'undefined') {
      throw new Error('Missing required parameter: amount');
    }

    if (typeof parameters['currency'] !== 'undefined') {
      queryParameters['Currency'] = parameters['currency'];
    }

    queryParameters['Currency'] = this.transformParameter(
      queryParameters['Currency'],
    );

    if (typeof parameters['currency'] === 'undefined') {
      throw new Error('Missing required parameter: currency');
    }

    if (typeof parameters['reference'] !== 'undefined') {
      queryParameters['Reference'] = parameters['reference'];
    }

    queryParameters['Reference'] = this.transformParameter(
      queryParameters['Reference'],
    );

    if (typeof parameters['resultCode'] !== 'undefined') {
      queryParameters['ResultCode'] = parameters['resultCode'];
    }

    queryParameters['ResultCode'] = this.transformParameter(
      queryParameters['ResultCode'],
    );

    if (typeof parameters['resultCode'] === 'undefined') {
      throw new Error('Missing required parameter: resultCode');
    }

    if (typeof parameters['paymentId'] !== 'undefined') {
      queryParameters['PaymentId'] = parameters['paymentId'];
    }

    queryParameters['PaymentId'] = this.transformParameter(
      queryParameters['PaymentId'],
    );

    if (typeof parameters['paymentId'] === 'undefined') {
      throw new Error('Missing required parameter: paymentId');
    }

    if (typeof parameters['refuseReason'] !== 'undefined') {
      queryParameters['RefuseReason'] = parameters['refuseReason'];
    }

    queryParameters['RefuseReason'] = this.transformParameter(
      queryParameters['RefuseReason'],
    );

    if (typeof parameters['orderId'] !== 'undefined') {
      queryParameters['OrderId'] = parameters['orderId'];
    }

    queryParameters['OrderId'] = this.transformParameter(
      queryParameters['OrderId'],
    );

    if (typeof parameters['counterAccount'] !== 'undefined') {
      queryParameters['CounterAccount'] = parameters['counterAccount'];
    }

    queryParameters['CounterAccount'] = this.transformParameter(
      queryParameters['CounterAccount'],
    );

    if (typeof parameters['counterAccountName'] !== 'undefined') {
      queryParameters['CounterAccountName'] = parameters['counterAccountName'];
    }

    queryParameters['CounterAccountName'] = this.transformParameter(
      queryParameters['CounterAccountName'],
    );

    if (typeof parameters['signature'] !== 'undefined') {
      queryParameters['Signature'] = parameters['signature'];
    }

    queryParameters['Signature'] = this.transformParameter(
      queryParameters['Signature'],
    );

    if (typeof parameters['signature'] === 'undefined') {
      throw new Error('Missing required parameter: signature');
    }

    if (typeof parameters['cardId'] !== 'undefined') {
      queryParameters['CardID'] = parameters['cardId'];
    }

    queryParameters['CardID'] = this.transformParameter(
      queryParameters['CardID'],
    );

    if (typeof parameters['cardMask'] !== 'undefined') {
      queryParameters['CardMask'] = parameters['cardMask'];
    }

    queryParameters['CardMask'] = this.transformParameter(
      queryParameters['CardMask'],
    );

    if (typeof parameters['cardExp'] !== 'undefined') {
      queryParameters['CardExp'] = parameters['cardExp'];
    }

    queryParameters['CardExp'] = this.transformParameter(
      queryParameters['CardExp'],
    );

    if (typeof parameters['authNumber'] !== 'undefined') {
      queryParameters['AuthNumber'] = parameters['authNumber'];
    }

    queryParameters['AuthNumber'] = this.transformParameter(
      queryParameters['AuthNumber'],
    );

    if (typeof parameters['cardRecTxSec'] !== 'undefined') {
      queryParameters['CardRecTxSec'] = parameters['cardRecTxSec'];
    }

    queryParameters['CardRecTxSec'] = this.transformParameter(
      queryParameters['CardRecTxSec'],
    );

    if (typeof parameters['acqResId'] !== 'undefined') {
      queryParameters['AcqResId'] = parameters['acqResId'];
    }

    queryParameters['AcqResId'] = this.transformParameter(
      queryParameters['AcqResId'],
    );

    if (typeof parameters['sig2'] !== 'undefined') {
      queryParameters['SIG2'] = parameters['sig2'];
    }

    queryParameters['SIG2'] = this.transformParameter(queryParameters['SIG2']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return URL for GoPay Payments
   * @method
   * @name API#goPayRurl
   */
  goPayRurl(parameters: {} = {}): Promise<void> {
    let path = '/payment/gopay/return/*';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to gopay
   * @method
   * @name API#goPay
   */
  goPay(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/gopay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Handles Quatro payment
   * @method
   * @name API#quatro
   */
  quatro(parameters: {
    idobj: string;
    idkod: string;
    approved: string;
    cislooz: string;
    sign: string;
  }): Promise<{}> {
    let path = '/payments/quatro';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['idobj'] !== 'undefined') {
      queryParameters['idobj'] = parameters['idobj'];
    }

    queryParameters['idobj'] = this.transformParameter(
      queryParameters['idobj'],
    );

    if (typeof parameters['idobj'] === 'undefined') {
      throw new Error('Missing required parameter: idobj');
    }

    if (typeof parameters['idkod'] !== 'undefined') {
      queryParameters['idkod'] = parameters['idkod'];
    }

    queryParameters['idkod'] = this.transformParameter(
      queryParameters['idkod'],
    );

    if (typeof parameters['idkod'] === 'undefined') {
      throw new Error('Missing required parameter: idkod');
    }

    if (typeof parameters['approved'] !== 'undefined') {
      queryParameters['approved'] = parameters['approved'];
    }

    queryParameters['approved'] = this.transformParameter(
      queryParameters['approved'],
    );

    if (typeof parameters['approved'] === 'undefined') {
      throw new Error('Missing required parameter: approved');
    }

    if (typeof parameters['cislooz'] !== 'undefined') {
      queryParameters['cislooz'] = parameters['cislooz'];
    }

    queryParameters['cislooz'] = this.transformParameter(
      queryParameters['cislooz'],
    );

    if (typeof parameters['cislooz'] === 'undefined') {
      throw new Error('Missing required parameter: cislooz');
    }

    if (typeof parameters['sign'] !== 'undefined') {
      queryParameters['sign'] = parameters['sign'];
    }

    queryParameters['sign'] = this.transformParameter(queryParameters['sign']);

    if (typeof parameters['sign'] === 'undefined') {
      throw new Error('Missing required parameter: sign');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to GPWebPay
   * @method
   * @name API#gpWebPayPayment
   */
  gpWebPayPayment(
    orderHash: string,
    parameters: {
      type: string;
    },
  ): Promise<void> {
    let path = '/payment/gpwebpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw new Error('Missing required parameter: type');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to success page
   * @method
   * @name API#gpWebPayResponse
   */
  gpWebPayResponse(
    orderHash: string,
    parameters: {
      operation: string;
      ordernumber: number;
      merordernum?: number;
      md?: string;
      prcode: number;
      srcode: number;
      resulttext?: string;
      userparam1?: string;
      addinfo?: string;
      token?: string;
      expiry?: string;
      acsres?: string;
      accode?: string;
      panpattern?: string;
      daytocapture?: string;
      tokenregstatus?: string;
      acrc?: string;
      rrn?: string;
      par?: string;
      traceid?: string;
      digest: string;
      digest1: string;
    },
  ): Promise<void> {
    let path = '/payment/gpwebpay/response/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['operation'] !== 'undefined') {
      queryParameters['OPERATION'] = parameters['operation'];
    }

    queryParameters['OPERATION'] = this.transformParameter(
      queryParameters['OPERATION'],
    );

    if (typeof parameters['operation'] === 'undefined') {
      throw new Error('Missing required parameter: operation');
    }

    if (typeof parameters['ordernumber'] !== 'undefined') {
      queryParameters['ORDERNUMBER'] = parameters['ordernumber'];
    }

    queryParameters['ORDERNUMBER'] = this.transformParameter(
      queryParameters['ORDERNUMBER'],
    );

    if (typeof parameters['ordernumber'] === 'undefined') {
      throw new Error('Missing required parameter: ordernumber');
    }

    if (typeof parameters['merordernum'] !== 'undefined') {
      queryParameters['MERORDERNUM'] = parameters['merordernum'];
    }

    queryParameters['MERORDERNUM'] = this.transformParameter(
      queryParameters['MERORDERNUM'],
    );

    if (typeof parameters['md'] !== 'undefined') {
      queryParameters['MD'] = parameters['md'];
    }

    queryParameters['MD'] = this.transformParameter(queryParameters['MD']);

    if (typeof parameters['prcode'] !== 'undefined') {
      queryParameters['PRCODE'] = parameters['prcode'];
    }

    queryParameters['PRCODE'] = this.transformParameter(
      queryParameters['PRCODE'],
    );

    if (typeof parameters['prcode'] === 'undefined') {
      throw new Error('Missing required parameter: prcode');
    }

    if (typeof parameters['srcode'] !== 'undefined') {
      queryParameters['SRCODE'] = parameters['srcode'];
    }

    queryParameters['SRCODE'] = this.transformParameter(
      queryParameters['SRCODE'],
    );

    if (typeof parameters['srcode'] === 'undefined') {
      throw new Error('Missing required parameter: srcode');
    }

    if (typeof parameters['resulttext'] !== 'undefined') {
      queryParameters['RESULTTEXT'] = parameters['resulttext'];
    }

    queryParameters['RESULTTEXT'] = this.transformParameter(
      queryParameters['RESULTTEXT'],
    );

    if (typeof parameters['userparam1'] !== 'undefined') {
      queryParameters['USERPARAM1'] = parameters['userparam1'];
    }

    queryParameters['USERPARAM1'] = this.transformParameter(
      queryParameters['USERPARAM1'],
    );

    if (typeof parameters['addinfo'] !== 'undefined') {
      queryParameters['ADDINFO'] = parameters['addinfo'];
    }

    queryParameters['ADDINFO'] = this.transformParameter(
      queryParameters['ADDINFO'],
    );

    if (typeof parameters['token'] !== 'undefined') {
      queryParameters['TOKEN'] = parameters['token'];
    }

    queryParameters['TOKEN'] = this.transformParameter(
      queryParameters['TOKEN'],
    );

    if (typeof parameters['expiry'] !== 'undefined') {
      queryParameters['EXPIRY'] = parameters['expiry'];
    }

    queryParameters['EXPIRY'] = this.transformParameter(
      queryParameters['EXPIRY'],
    );

    if (typeof parameters['acsres'] !== 'undefined') {
      queryParameters['ACSRES'] = parameters['acsres'];
    }

    queryParameters['ACSRES'] = this.transformParameter(
      queryParameters['ACSRES'],
    );

    if (typeof parameters['accode'] !== 'undefined') {
      queryParameters['ACCODE'] = parameters['accode'];
    }

    queryParameters['ACCODE'] = this.transformParameter(
      queryParameters['ACCODE'],
    );

    if (typeof parameters['panpattern'] !== 'undefined') {
      queryParameters['PANPATTERN'] = parameters['panpattern'];
    }

    queryParameters['PANPATTERN'] = this.transformParameter(
      queryParameters['PANPATTERN'],
    );

    if (typeof parameters['daytocapture'] !== 'undefined') {
      queryParameters['DAYTOCAPTURE'] = parameters['daytocapture'];
    }

    queryParameters['DAYTOCAPTURE'] = this.transformParameter(
      queryParameters['DAYTOCAPTURE'],
    );

    if (typeof parameters['tokenregstatus'] !== 'undefined') {
      queryParameters['TOKENREGSTATUS'] = parameters['tokenregstatus'];
    }

    queryParameters['TOKENREGSTATUS'] = this.transformParameter(
      queryParameters['TOKENREGSTATUS'],
    );

    if (typeof parameters['acrc'] !== 'undefined') {
      queryParameters['ACRC'] = parameters['acrc'];
    }

    queryParameters['ACRC'] = this.transformParameter(queryParameters['ACRC']);

    if (typeof parameters['rrn'] !== 'undefined') {
      queryParameters['RRN'] = parameters['rrn'];
    }

    queryParameters['RRN'] = this.transformParameter(queryParameters['RRN']);

    if (typeof parameters['par'] !== 'undefined') {
      queryParameters['PAR'] = parameters['par'];
    }

    queryParameters['PAR'] = this.transformParameter(queryParameters['PAR']);

    if (typeof parameters['traceid'] !== 'undefined') {
      queryParameters['TRACEID'] = parameters['traceid'];
    }

    queryParameters['TRACEID'] = this.transformParameter(
      queryParameters['TRACEID'],
    );

    if (typeof parameters['digest'] !== 'undefined') {
      queryParameters['DIGEST'] = parameters['digest'];
    }

    queryParameters['DIGEST'] = this.transformParameter(
      queryParameters['DIGEST'],
    );

    if (typeof parameters['digest'] === 'undefined') {
      throw new Error('Missing required parameter: digest');
    }

    if (typeof parameters['digest1'] !== 'undefined') {
      queryParameters['DIGEST1'] = parameters['digest1'];
    }

    queryParameters['DIGEST1'] = this.transformParameter(
      queryParameters['DIGEST1'],
    );

    if (typeof parameters['digest1'] === 'undefined') {
      throw new Error('Missing required parameter: digest1');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns payment methods
   * @method
   * @name API#getPaymentsInfo
   */
  getPaymentsInfo(
    publicId: string,
    parameters: {} = {},
  ): Promise<
    Array<{
      payment_id: string;

      payment_name: string;

      payment_descr: string;

      payment_priority: number;

      payment_sort: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_thanks?: string;

        payment_failure?: string;
      }>;
    }>
  > {
    let path = '/payments/{public_id}/info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>, Samo Sepesi <sepesi@bart.sk>

**Main usage**: Returns list of documents from input ids

**DESCRIPTION**
If provided with document ids, returns array of these documents.

    * @method
    * @name API#listPhotogalleryImages
    */
  listPhotogalleryImages(
    parameters: {} = {},
    body: {
      images_ids: Array<string>;
    },
  ): Promise<{}> {
    let path = '/photogallery-images';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get parameters only for products in
specific category

    * @method
    * @name API#loadProductAttribs
    */
  loadProductAttribs(
    parameters: {
      categoryId?: string;
      attribUniqueId?: string;
      brandId?: string;
      productType?: string;
      parameters?: string;
      sorter?: 'NAME' | 'LANG_ORDER_NUMBER_SORT' | 'VALUE_SORT';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    product_attribs?: Array<{
      attrib_id: number;

      type_id: number;

      attrib_uid: string | null;

      attrib_type: string | null;

      group_id: number | null;

      attrib_name: string;

      attrib_ismulti: number;

      attrib_sort: number;

      attrib_pbl: number;

      attrib_unit: string;

      attrib_list: number;

      attrib_code: string | null;

      attrib_grid: number;

      min?: number;

      max?: number;

      translations: Array<{
        attrib_id: number;

        lang_id: string;

        attrib_name: string;

        attrib_unit: string | null;
      }>;

      values: Array<{
        amount: number;

        value_id: number;

        attrib_id: number;

        attrib_value: string;

        attrib_pict: string | null;

        group_id: number | null;

        istop: boolean;

        searchfilter: number;

        value_sort: number | null;

        translations: Array<{
          value_id: number;

          lang_id: string;

          attrib_value: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/product-attribs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['attribUniqueId'] !== 'undefined') {
      queryParameters['attrib_unique_id'] = parameters['attribUniqueId'];
    }

    queryParameters['attrib_unique_id'] = this.transformParameter(
      queryParameters['attrib_unique_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['productType'] !== 'undefined') {
      queryParameters['product_type'] = parameters['productType'];
    }

    queryParameters['product_type'] = this.transformParameter(
      queryParameters['product_type'],
    );

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns product list info
   * @method
   * @name API#loadProductList
   */
  loadProductList(
    listId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    list_id: string;

    list_name: string;

    grupa: string | null;

    ordernr: number;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      isnew_valid: string;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      };

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      set_delete_warning?: boolean;

      is_user_favorite?: boolean;
    }>;
  }> {
    let path = '/product-list/{list_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{list_id}', listId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * You can filter list by currency, category and language
   * @method
   * @name API#searchProducts
   */
  searchProducts(
    parameters: {
      withoutCurrency?: '0' | '1';
      columns?: string;
      withGoods?: '0' | '1';
      withGifts?: '0' | '1';
      withAttribs?: '0' | '1';
      withBrand?: '0' | '1';
      withPublish?: '0' | '1';
      validFrom?: string;
      validTo?: string;
      onlyValidGoods?: '0' | '1';
      categoryId?: string;
      limit?: number;
      offset?: number;
      isSale?: number;
      isSaleout?: number;
      onStockSort?: number;
      isTop?: number;
      availabilityId?: number;
      brandId?: number;
      priceMin?: number;
      priceMax?: number;
      price?: number;
      hasOldprice?: number;
      productListId?: string;
      sort?: string;
      sortDir?: string;
      parameters?: Array<string>;
      ean?: string;
      plu?: string;
      internalCode?: string;
      q?: string;
      ageFilter?: string;
      onlyForSets?: boolean;
      withRetailPrice?: boolean;
      street?: string;
      city?: string;
      zip?: string;
      onlyAvailableGoods?: string;
      productSystem?: string;
      onlyWithCustomerPrice?: boolean;
      withFoundedQueries?: boolean;
      includeSkvelkoSets?: boolean;
      mostFrequentPurchased?: boolean;
      productsIds?: Array<string>;
      onlyUserFavorites?: boolean;
      isNewActive?: number;
      isDemand?: boolean;
      disableIgnoreLamitec?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    total: number;

    price_min: number;

    price_max: number;

    offset: number;

    limit: number;

    founded_queries?: Array<string>;

    products: Array<
      Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      }>
    >;

    skvelko_sets?: Array<{
      id?: number;

      chematId?: number;

      clubUserId?: number;

      awardedUserId?: number;

      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      fullName?: string;

      skvelkoSetAvailabilityId?: number;

      price?: number;

      city?: string;

      school_name?: string;

      schoolFullName?: string;

      school_street?: string;

      school_street_number?: number;

      count?: number;

      updated_at?: string;

      isDeliveryToSchoolActivated?: boolean;

      productGoodsWithCount?: Array<{
        count?: number;

        productGood?: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };
      }>;

      productsWithCount?: Array<{
        count?: number;

        product?: {
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        };
      }>;

      skvelkoSetLogs?: Array<{
        log?: {
          availability?: string | null;

          clubUsersId: number;

          created_at: string;

          created_by?: string | null;

          id: number;

          itemsCount: number;

          login?: string | null;

          price: number;

          skvelkoSetAvailabilitiesId: number;

          skvelkoSetCreatedById: number;

          skvelkoSetsId: number;
        };
      }>;

      awardedUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;

    positions?: {
      products_positions?: Array<{
        product_id?: number;

        position?: number;
      }>;

      skvelko_sets_positions?: Array<{
        id?: number;

        position?: number;
      }>;
    };
  }> {
    let path = '/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    queryParameters['without_currency'] = '0';

    if (typeof parameters['withoutCurrency'] !== 'undefined') {
      queryParameters['without_currency'] = parameters['withoutCurrency'];
    }

    queryParameters['without_currency'] = this.transformParameter(
      queryParameters['without_currency'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    queryParameters['with_goods'] = '1';

    if (typeof parameters['withGoods'] !== 'undefined') {
      queryParameters['with_goods'] = parameters['withGoods'];
    }

    queryParameters['with_goods'] = this.transformParameter(
      queryParameters['with_goods'],
    );

    queryParameters['with_gifts'] = '1';

    if (typeof parameters['withGifts'] !== 'undefined') {
      queryParameters['with_gifts'] = parameters['withGifts'];
    }

    queryParameters['with_gifts'] = this.transformParameter(
      queryParameters['with_gifts'],
    );

    queryParameters['with_attribs'] = '1';

    if (typeof parameters['withAttribs'] !== 'undefined') {
      queryParameters['with_attribs'] = parameters['withAttribs'];
    }

    queryParameters['with_attribs'] = this.transformParameter(
      queryParameters['with_attribs'],
    );

    queryParameters['with_brand'] = '1';

    if (typeof parameters['withBrand'] !== 'undefined') {
      queryParameters['with_brand'] = parameters['withBrand'];
    }

    queryParameters['with_brand'] = this.transformParameter(
      queryParameters['with_brand'],
    );

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['validFrom'] !== 'undefined') {
      queryParameters['valid_from'] = parameters['validFrom'];
    }

    queryParameters['valid_from'] = this.transformParameter(
      queryParameters['valid_from'],
    );

    if (typeof parameters['validTo'] !== 'undefined') {
      queryParameters['valid_to'] = parameters['validTo'];
    }

    queryParameters['valid_to'] = this.transformParameter(
      queryParameters['valid_to'],
    );

    if (typeof parameters['onlyValidGoods'] !== 'undefined') {
      queryParameters['only_valid_goods'] = parameters['onlyValidGoods'];
    }

    queryParameters['only_valid_goods'] = this.transformParameter(
      queryParameters['only_valid_goods'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['isSale'] !== 'undefined') {
      queryParameters['is_sale'] = parameters['isSale'];
    }

    queryParameters['is_sale'] = this.transformParameter(
      queryParameters['is_sale'],
    );

    if (typeof parameters['isSaleout'] !== 'undefined') {
      queryParameters['is_saleout'] = parameters['isSaleout'];
    }

    queryParameters['is_saleout'] = this.transformParameter(
      queryParameters['is_saleout'],
    );

    if (typeof parameters['onStockSort'] !== 'undefined') {
      queryParameters['on_stock_sort'] = parameters['onStockSort'];
    }

    queryParameters['on_stock_sort'] = this.transformParameter(
      queryParameters['on_stock_sort'],
    );

    if (typeof parameters['isTop'] !== 'undefined') {
      queryParameters['is_top'] = parameters['isTop'];
    }

    queryParameters['is_top'] = this.transformParameter(
      queryParameters['is_top'],
    );

    if (typeof parameters['availabilityId'] !== 'undefined') {
      queryParameters['availability_id'] = parameters['availabilityId'];
    }

    queryParameters['availability_id'] = this.transformParameter(
      queryParameters['availability_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['priceMin'] !== 'undefined') {
      queryParameters['price_min'] = parameters['priceMin'];
    }

    queryParameters['price_min'] = this.transformParameter(
      queryParameters['price_min'],
    );

    if (typeof parameters['priceMax'] !== 'undefined') {
      queryParameters['price_max'] = parameters['priceMax'];
    }

    queryParameters['price_max'] = this.transformParameter(
      queryParameters['price_max'],
    );

    if (typeof parameters['price'] !== 'undefined') {
      queryParameters['price'] = parameters['price'];
    }

    queryParameters['price'] = this.transformParameter(
      queryParameters['price'],
    );

    if (typeof parameters['hasOldprice'] !== 'undefined') {
      queryParameters['has_oldprice'] = parameters['hasOldprice'];
    }

    queryParameters['has_oldprice'] = this.transformParameter(
      queryParameters['has_oldprice'],
    );

    if (typeof parameters['productListId'] !== 'undefined') {
      queryParameters['product_list_id'] = parameters['productListId'];
    }

    queryParameters['product_list_id'] = this.transformParameter(
      queryParameters['product_list_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['plu'] !== 'undefined') {
      queryParameters['plu'] = parameters['plu'];
    }

    queryParameters['plu'] = this.transformParameter(queryParameters['plu']);

    if (typeof parameters['internalCode'] !== 'undefined') {
      queryParameters['internal_code'] = parameters['internalCode'];
    }

    queryParameters['internal_code'] = this.transformParameter(
      queryParameters['internal_code'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ageFilter'] !== 'undefined') {
      queryParameters['age_filter'] = parameters['ageFilter'];
    }

    queryParameters['age_filter'] = this.transformParameter(
      queryParameters['age_filter'],
    );

    if (typeof parameters['onlyForSets'] !== 'undefined') {
      queryParameters['only_for_sets'] = parameters['onlyForSets'];
    }

    queryParameters['only_for_sets'] = this.transformParameter(
      queryParameters['only_for_sets'],
    );

    if (typeof parameters['withRetailPrice'] !== 'undefined') {
      queryParameters['with_retail_price'] = parameters['withRetailPrice'];
    }

    queryParameters['with_retail_price'] = this.transformParameter(
      queryParameters['with_retail_price'],
    );

    if (typeof parameters['street'] !== 'undefined') {
      queryParameters['street'] = parameters['street'];
    }

    queryParameters['street'] = this.transformParameter(
      queryParameters['street'],
    );

    if (typeof parameters['city'] !== 'undefined') {
      queryParameters['city'] = parameters['city'];
    }

    queryParameters['city'] = this.transformParameter(queryParameters['city']);

    if (typeof parameters['zip'] !== 'undefined') {
      queryParameters['zip'] = parameters['zip'];
    }

    queryParameters['zip'] = this.transformParameter(queryParameters['zip']);

    if (typeof parameters['onlyAvailableGoods'] !== 'undefined') {
      queryParameters['only_available_goods'] =
        parameters['onlyAvailableGoods'];
    }

    queryParameters['only_available_goods'] = this.transformParameter(
      queryParameters['only_available_goods'],
    );

    if (typeof parameters['productSystem'] !== 'undefined') {
      queryParameters['product_system'] = parameters['productSystem'];
    }

    queryParameters['product_system'] = this.transformParameter(
      queryParameters['product_system'],
    );

    if (typeof parameters['onlyWithCustomerPrice'] !== 'undefined') {
      queryParameters['only_with_customer_price'] =
        parameters['onlyWithCustomerPrice'];
    }

    queryParameters['only_with_customer_price'] = this.transformParameter(
      queryParameters['only_with_customer_price'],
    );

    if (typeof parameters['withFoundedQueries'] !== 'undefined') {
      queryParameters['with_founded_queries'] =
        parameters['withFoundedQueries'];
    }

    queryParameters['with_founded_queries'] = this.transformParameter(
      queryParameters['with_founded_queries'],
    );

    if (typeof parameters['includeSkvelkoSets'] !== 'undefined') {
      queryParameters['include_skvelko_sets'] =
        parameters['includeSkvelkoSets'];
    }

    queryParameters['include_skvelko_sets'] = this.transformParameter(
      queryParameters['include_skvelko_sets'],
    );

    if (typeof parameters['mostFrequentPurchased'] !== 'undefined') {
      queryParameters['most_frequent_purchased'] =
        parameters['mostFrequentPurchased'];
    }

    queryParameters['most_frequent_purchased'] = this.transformParameter(
      queryParameters['most_frequent_purchased'],
    );

    if (typeof parameters['productsIds'] !== 'undefined') {
      queryParameters['products_ids'] = parameters['productsIds'];
    }

    queryParameters['products_ids'] = this.transformParameter(
      queryParameters['products_ids'],
    );

    if (typeof parameters['onlyUserFavorites'] !== 'undefined') {
      queryParameters['only_user_favorites'] = parameters['onlyUserFavorites'];
    }

    queryParameters['only_user_favorites'] = this.transformParameter(
      queryParameters['only_user_favorites'],
    );

    if (typeof parameters['isNewActive'] !== 'undefined') {
      queryParameters['is_new_active'] = parameters['isNewActive'];
    }

    queryParameters['is_new_active'] = this.transformParameter(
      queryParameters['is_new_active'],
    );

    if (typeof parameters['isDemand'] !== 'undefined') {
      queryParameters['is_demand'] = parameters['isDemand'];
    }

    queryParameters['is_demand'] = this.transformParameter(
      queryParameters['is_demand'],
    );

    if (typeof parameters['disableIgnoreLamitec'] !== 'undefined') {
      queryParameters['disable_ignore_lamitec'] =
        parameters['disableIgnoreLamitec'];
    }

    queryParameters['disable_ignore_lamitec'] = this.transformParameter(
      queryParameters['disable_ignore_lamitec'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * You can filter list by currency, category and language
   * @method
   * @name API#searchProducts_1
   */
  searchProducts_1(
    parameters: {
      withoutCurrency?: '0' | '1';
      columns?: string;
      withGoods?: '0' | '1';
      withGifts?: '0' | '1';
      withAttribs?: '0' | '1';
      withBrand?: '0' | '1';
      withPublish?: '0' | '1';
      validFrom?: string;
      validTo?: string;
      onlyValidGoods?: '0' | '1';
      categoryId?: string;
      limit?: number;
      offset?: number;
      isSale?: number;
      isSaleout?: number;
      onStockSort?: number;
      isTop?: number;
      availabilityId?: number;
      brandId?: number;
      priceMin?: number;
      priceMax?: number;
      price?: number;
      hasOldprice?: number;
      productListId?: string;
      sort?: string;
      sortDir?: string;
      parameters?: Array<string>;
      ean?: string;
      plu?: string;
      internalCode?: string;
      q?: string;
      ageFilter?: string;
      onlyForSets?: boolean;
      withRetailPrice?: boolean;
      street?: string;
      city?: string;
      zip?: string;
      onlyAvailableGoods?: string;
      productSystem?: string;
      onlyWithCustomerPrice?: boolean;
      withFoundedQueries?: boolean;
      includeSkvelkoSets?: boolean;
      mostFrequentPurchased?: boolean;
      productsIds?: Array<string>;
      onlyUserFavorites?: boolean;
      isNewActive?: number;
      isDemand?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    total: number;

    price_min: number;

    price_max: number;

    offset: number;

    limit: number;

    founded_queries?: Array<string>;

    products: Array<
      Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      }>
    >;

    skvelko_sets?: Array<{
      id?: number;

      chematId?: number;

      clubUserId?: number;

      awardedUserId?: number;

      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      fullName?: string;

      skvelkoSetAvailabilityId?: number;

      price?: number;

      city?: string;

      school_name?: string;

      schoolFullName?: string;

      school_street?: string;

      school_street_number?: number;

      count?: number;

      updated_at?: string;

      isDeliveryToSchoolActivated?: boolean;

      productGoodsWithCount?: Array<{
        count?: number;

        productGood?: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };
      }>;

      productsWithCount?: Array<{
        count?: number;

        product?: {
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        };
      }>;

      skvelkoSetLogs?: Array<{
        log?: {
          availability?: string | null;

          clubUsersId: number;

          created_at: string;

          created_by?: string | null;

          id: number;

          itemsCount: number;

          login?: string | null;

          price: number;

          skvelkoSetAvailabilitiesId: number;

          skvelkoSetCreatedById: number;

          skvelkoSetsId: number;
        };
      }>;

      awardedUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;

    positions?: {
      products_positions?: Array<{
        product_id?: number;

        position?: number;
      }>;

      skvelko_sets_positions?: Array<{
        id?: number;

        position?: number;
      }>;
    };
  }> {
    let path = '/admin/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    queryParameters['without_currency'] = '0';

    if (typeof parameters['withoutCurrency'] !== 'undefined') {
      queryParameters['without_currency'] = parameters['withoutCurrency'];
    }

    queryParameters['without_currency'] = this.transformParameter(
      queryParameters['without_currency'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    queryParameters['with_goods'] = '1';

    if (typeof parameters['withGoods'] !== 'undefined') {
      queryParameters['with_goods'] = parameters['withGoods'];
    }

    queryParameters['with_goods'] = this.transformParameter(
      queryParameters['with_goods'],
    );

    queryParameters['with_gifts'] = '1';

    if (typeof parameters['withGifts'] !== 'undefined') {
      queryParameters['with_gifts'] = parameters['withGifts'];
    }

    queryParameters['with_gifts'] = this.transformParameter(
      queryParameters['with_gifts'],
    );

    queryParameters['with_attribs'] = '1';

    if (typeof parameters['withAttribs'] !== 'undefined') {
      queryParameters['with_attribs'] = parameters['withAttribs'];
    }

    queryParameters['with_attribs'] = this.transformParameter(
      queryParameters['with_attribs'],
    );

    queryParameters['with_brand'] = '1';

    if (typeof parameters['withBrand'] !== 'undefined') {
      queryParameters['with_brand'] = parameters['withBrand'];
    }

    queryParameters['with_brand'] = this.transformParameter(
      queryParameters['with_brand'],
    );

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['validFrom'] !== 'undefined') {
      queryParameters['valid_from'] = parameters['validFrom'];
    }

    queryParameters['valid_from'] = this.transformParameter(
      queryParameters['valid_from'],
    );

    if (typeof parameters['validTo'] !== 'undefined') {
      queryParameters['valid_to'] = parameters['validTo'];
    }

    queryParameters['valid_to'] = this.transformParameter(
      queryParameters['valid_to'],
    );

    if (typeof parameters['onlyValidGoods'] !== 'undefined') {
      queryParameters['only_valid_goods'] = parameters['onlyValidGoods'];
    }

    queryParameters['only_valid_goods'] = this.transformParameter(
      queryParameters['only_valid_goods'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['isSale'] !== 'undefined') {
      queryParameters['is_sale'] = parameters['isSale'];
    }

    queryParameters['is_sale'] = this.transformParameter(
      queryParameters['is_sale'],
    );

    if (typeof parameters['isSaleout'] !== 'undefined') {
      queryParameters['is_saleout'] = parameters['isSaleout'];
    }

    queryParameters['is_saleout'] = this.transformParameter(
      queryParameters['is_saleout'],
    );

    if (typeof parameters['onStockSort'] !== 'undefined') {
      queryParameters['on_stock_sort'] = parameters['onStockSort'];
    }

    queryParameters['on_stock_sort'] = this.transformParameter(
      queryParameters['on_stock_sort'],
    );

    if (typeof parameters['isTop'] !== 'undefined') {
      queryParameters['is_top'] = parameters['isTop'];
    }

    queryParameters['is_top'] = this.transformParameter(
      queryParameters['is_top'],
    );

    if (typeof parameters['availabilityId'] !== 'undefined') {
      queryParameters['availability_id'] = parameters['availabilityId'];
    }

    queryParameters['availability_id'] = this.transformParameter(
      queryParameters['availability_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['priceMin'] !== 'undefined') {
      queryParameters['price_min'] = parameters['priceMin'];
    }

    queryParameters['price_min'] = this.transformParameter(
      queryParameters['price_min'],
    );

    if (typeof parameters['priceMax'] !== 'undefined') {
      queryParameters['price_max'] = parameters['priceMax'];
    }

    queryParameters['price_max'] = this.transformParameter(
      queryParameters['price_max'],
    );

    if (typeof parameters['price'] !== 'undefined') {
      queryParameters['price'] = parameters['price'];
    }

    queryParameters['price'] = this.transformParameter(
      queryParameters['price'],
    );

    if (typeof parameters['hasOldprice'] !== 'undefined') {
      queryParameters['has_oldprice'] = parameters['hasOldprice'];
    }

    queryParameters['has_oldprice'] = this.transformParameter(
      queryParameters['has_oldprice'],
    );

    if (typeof parameters['productListId'] !== 'undefined') {
      queryParameters['product_list_id'] = parameters['productListId'];
    }

    queryParameters['product_list_id'] = this.transformParameter(
      queryParameters['product_list_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['plu'] !== 'undefined') {
      queryParameters['plu'] = parameters['plu'];
    }

    queryParameters['plu'] = this.transformParameter(queryParameters['plu']);

    if (typeof parameters['internalCode'] !== 'undefined') {
      queryParameters['internal_code'] = parameters['internalCode'];
    }

    queryParameters['internal_code'] = this.transformParameter(
      queryParameters['internal_code'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ageFilter'] !== 'undefined') {
      queryParameters['age_filter'] = parameters['ageFilter'];
    }

    queryParameters['age_filter'] = this.transformParameter(
      queryParameters['age_filter'],
    );

    if (typeof parameters['onlyForSets'] !== 'undefined') {
      queryParameters['only_for_sets'] = parameters['onlyForSets'];
    }

    queryParameters['only_for_sets'] = this.transformParameter(
      queryParameters['only_for_sets'],
    );

    if (typeof parameters['withRetailPrice'] !== 'undefined') {
      queryParameters['with_retail_price'] = parameters['withRetailPrice'];
    }

    queryParameters['with_retail_price'] = this.transformParameter(
      queryParameters['with_retail_price'],
    );

    if (typeof parameters['street'] !== 'undefined') {
      queryParameters['street'] = parameters['street'];
    }

    queryParameters['street'] = this.transformParameter(
      queryParameters['street'],
    );

    if (typeof parameters['city'] !== 'undefined') {
      queryParameters['city'] = parameters['city'];
    }

    queryParameters['city'] = this.transformParameter(queryParameters['city']);

    if (typeof parameters['zip'] !== 'undefined') {
      queryParameters['zip'] = parameters['zip'];
    }

    queryParameters['zip'] = this.transformParameter(queryParameters['zip']);

    if (typeof parameters['onlyAvailableGoods'] !== 'undefined') {
      queryParameters['only_available_goods'] =
        parameters['onlyAvailableGoods'];
    }

    queryParameters['only_available_goods'] = this.transformParameter(
      queryParameters['only_available_goods'],
    );

    if (typeof parameters['productSystem'] !== 'undefined') {
      queryParameters['product_system'] = parameters['productSystem'];
    }

    queryParameters['product_system'] = this.transformParameter(
      queryParameters['product_system'],
    );

    if (typeof parameters['onlyWithCustomerPrice'] !== 'undefined') {
      queryParameters['only_with_customer_price'] =
        parameters['onlyWithCustomerPrice'];
    }

    queryParameters['only_with_customer_price'] = this.transformParameter(
      queryParameters['only_with_customer_price'],
    );

    if (typeof parameters['withFoundedQueries'] !== 'undefined') {
      queryParameters['with_founded_queries'] =
        parameters['withFoundedQueries'];
    }

    queryParameters['with_founded_queries'] = this.transformParameter(
      queryParameters['with_founded_queries'],
    );

    if (typeof parameters['includeSkvelkoSets'] !== 'undefined') {
      queryParameters['include_skvelko_sets'] =
        parameters['includeSkvelkoSets'];
    }

    queryParameters['include_skvelko_sets'] = this.transformParameter(
      queryParameters['include_skvelko_sets'],
    );

    if (typeof parameters['mostFrequentPurchased'] !== 'undefined') {
      queryParameters['most_frequent_purchased'] =
        parameters['mostFrequentPurchased'];
    }

    queryParameters['most_frequent_purchased'] = this.transformParameter(
      queryParameters['most_frequent_purchased'],
    );

    if (typeof parameters['productsIds'] !== 'undefined') {
      queryParameters['products_ids'] = parameters['productsIds'];
    }

    queryParameters['products_ids'] = this.transformParameter(
      queryParameters['products_ids'],
    );

    if (typeof parameters['onlyUserFavorites'] !== 'undefined') {
      queryParameters['only_user_favorites'] = parameters['onlyUserFavorites'];
    }

    queryParameters['only_user_favorites'] = this.transformParameter(
      queryParameters['only_user_favorites'],
    );

    if (typeof parameters['isNewActive'] !== 'undefined') {
      queryParameters['is_new_active'] = parameters['isNewActive'];
    }

    queryParameters['is_new_active'] = this.transformParameter(
      queryParameters['is_new_active'],
    );

    if (typeof parameters['isDemand'] !== 'undefined') {
      queryParameters['is_demand'] = parameters['isDemand'];
    }

    queryParameters['is_demand'] = this.transformParameter(
      queryParameters['is_demand'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns product questions
   * @method
   * @name API#loadQuestions
   */
  loadQuestions(
    parameters: {
      productId?: number;
      categoryId?: string;
      sort?: string;
      sortDir?: string;
      published?: '0' | '1';
      answered?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    questions: Array<{
      answer: string;

      answer_date: string;

      categories: {
        categories: Array<{
          category_id: number;

          category_name: string;

          lang_id: string;
        }>;
      };

      category_id: string;

      email: string;

      id: number;

      ip: string;

      lang_id: string;

      phone: string;

      product_id: number;

      published: number;

      question: string;

      question_date: string;

      username: string;
    }>;
  }> {
    let path = '/products/questions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['published'] !== 'undefined') {
      queryParameters['published'] = parameters['published'];
    }

    queryParameters['published'] = this.transformParameter(
      queryParameters['published'],
    );

    if (typeof parameters['answered'] !== 'undefined') {
      queryParameters['answered'] = parameters['answered'];
    }

    queryParameters['answered'] = this.transformParameter(
      queryParameters['answered'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * sends a product questions to the database
   * @method
   * @name API#insertQuestions
   */
  insertQuestions(
    parameters: {} = {},
    body: {
      category_id?: Array<number>;

      email: string;

      lang_id: string;

      phone: string;

      product_id: number;

      question: string;

      username: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns product question categories
   * @method
   * @name API#loadQuestionCategories
   */
  loadQuestionCategories(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    categories: Array<{
      category_id: number;

      category_name: string;

      lang_id: string;
    }>;
  }> {
    let path = '/products/questions/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Creates a new question category in the database
   * @method
   * @name API#createCategory
   */
  createCategory(
    parameters: {} = {},
    body: {
      category_name: string;

      lang_id: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns product question category detail
   * @method
   * @name API#loadCategoryDetail
   */
  loadCategoryDetail(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    category_id: number;

    category_name: string;

    lang_id: string;
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes category in the database
   * @method
   * @name API#deleteCategory
   */
  deleteCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in the database
   * @method
   * @name API#updateCategory
   */
  updateCategory(
    categoryId: number,
    parameters: {} = {},
    body: {
      category_name?: string;

      lang_id?: string;
    },
  ): Promise<{
    status?: {
      category_id: number;

      category_name: string;

      lang_id: string;
    };
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns category info
   * @method
   * @name API#loadProduct
   */
  loadProduct(
    productId: number,
    parameters: {
      onlyValidGoods?: '0' | '1';
      published?: '0' | '1';
      goodsWithSets?: '0' | '1';
      categoryId?: number;
      withUpsells?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    availability_date: string | null;

    is_in_wishlist: boolean;

    product_id: number;

    name: string;

    slogan: string | null;

    is_sale: 0 | 1;

    issaleout: 0 | 1;

    is_new: 0 | 1;

    isnew_valid: string;

    is_top: 0 | 1;

    plu: string | null;

    ean: string | null;

    url: string;

    description: string;

    created_at: string;

    rating: string;

    rating_users: number;

    picture: string | null;

    brand: {};

    name_url_encoded: string;

    qtyDiscounts?: {
      discount_pcn?: number;

      qty_from?: number;

      product_id?: number;

      lang?: string;

      final_price?: number;
    };

    goods: Array<{
      priceinfo: string;

      valid_from?: string;

      valid_to?: string;

      imagePath: string;

      final_price: number | null;

      unitprice_sale: number | null;

      sale: boolean | null;

      good_id: number;

      product_id: number;

      currency_id: string;

      unitprice: number;

      unitprice_with_discount: number | null;

      unitprice_with_discount_without_vat?: number | null;

      vat_rate: number;

      availability_id: number | null;

      oldprice: number | null;

      units: string | null;

      order_number: string | null;

      ean: string | null;

      internal_code: string | null;

      on_stock_count: number;

      is_on_stock: 0 | 1;

      point_good: 0 | 1;

      points_amount: number | null;

      sale_percentage: number | null;

      price_without_vat: number | null;

      price_without_vat_sale: number | null;

      currency: {
        id: string;

        name: string;
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      group_price?: {
        unitprice: number;

        oldprice: number;

        sale_percentage: number;
      };

      group_prices?: Array<{
        skupina_id?: number;

        good_id?: number;

        unitprice?: number;

        oldprice?: number;
      }>;

      discount_pcn?: number;

      order_quantity_limit?: number | null;

      good_sort?: number;

      onstore?: Array<{
        goodId?: number;

        storeId?: string;

        storeQty?: number;
      }>;
    }>;

    main_good: {
      priceinfo: string;

      valid_from?: string;

      valid_to?: string;

      imagePath: string;

      final_price: number | null;

      unitprice_sale: number | null;

      sale: boolean | null;

      good_id: number;

      product_id: number;

      currency_id: string;

      unitprice: number;

      unitprice_with_discount: number | null;

      unitprice_with_discount_without_vat?: number | null;

      vat_rate: number;

      availability_id: number | null;

      oldprice: number | null;

      units: string | null;

      order_number: string | null;

      ean: string | null;

      internal_code: string | null;

      on_stock_count: number;

      is_on_stock: 0 | 1;

      point_good: 0 | 1;

      points_amount: number | null;

      sale_percentage: number | null;

      price_without_vat: number | null;

      price_without_vat_sale: number | null;

      currency: {
        id: string;

        name: string;
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      group_price?: {
        unitprice: number;

        oldprice: number;

        sale_percentage: number;
      };

      group_prices?: Array<{
        skupina_id?: number;

        good_id?: number;

        unitprice?: number;

        oldprice?: number;
      }>;

      discount_pcn?: number;

      order_quantity_limit?: number | null;

      good_sort?: number;

      onstore?: Array<{
        goodId?: number;

        storeId?: string;

        storeQty?: number;
      }>;
    };

    products_in_set?: Array<{
      good_id?: number;

      package_good_id?: number;

      package_quantity?: number;

      package_price?: number;

      product_id?: number;

      package_product_id?: number;

      color?: string;

      pages?: string;

      capacity?: string;
    }>;

    street?: string;

    city?: string;

    zip?: string;

    set_delete_warning?: boolean;

    is_user_favorite?: boolean;
  }> {
    let path = '/products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['onlyValidGoods'] !== 'undefined') {
      queryParameters['only_valid_goods'] = parameters['onlyValidGoods'];
    }

    queryParameters['only_valid_goods'] = this.transformParameter(
      queryParameters['only_valid_goods'],
    );

    queryParameters['published'] = 1;

    if (typeof parameters['published'] !== 'undefined') {
      queryParameters['published'] = parameters['published'];
    }

    queryParameters['published'] = this.transformParameter(
      queryParameters['published'],
    );

    queryParameters['goods_with_sets'] = 1;

    if (typeof parameters['goodsWithSets'] !== 'undefined') {
      queryParameters['goods_with_sets'] = parameters['goodsWithSets'];
    }

    queryParameters['goods_with_sets'] = this.transformParameter(
      queryParameters['goods_with_sets'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['withUpsells'] !== 'undefined') {
      queryParameters['with_upsells'] = parameters['withUpsells'];
    }

    queryParameters['with_upsells'] = this.transformParameter(
      queryParameters['with_upsells'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns object of all connected products
   * @method
   * @name API#loadProductConnections
   */
  loadProductConnections(
    productId: number,
    parameters: {
      connectionType?: string;
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: string;
      random?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    total: number;

    price_min: number;

    price_max: number;

    offset: number;

    limit: number;

    products: Array<{
      brand: {};

      created_at: string;

      description: string;

      ean: string;

      goods: Array<{
        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            avail_name: string;

            lang: string;
          }>;
        };

        availability_id: number;

        currency: {
          id: string;

          name: string;
        };

        currency_id: string;

        discount_pcn?: number;

        ean: string;

        final_price: number;

        good_id: number;

        group_price?: {
          oldprice: number;

          sale_percentage: number;

          unitprice: number;
        };

        imagePath: string;

        internal_code: string;

        is_on_stock: '0' | '1';

        oldprice: number;

        on_stock_count: number;

        order_number: string;

        point_good: '0' | '1';

        points_amount: number;

        price_without_vat: number;

        price_without_vat_sale: number;

        product_id: number;

        sale: boolean;

        sale_percentage: number;

        unitprice: number;

        unitprice_sale: number;

        units: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate: number;
      }>;

      is_new: '0' | '1';

      is_sale: '0' | '1';

      is_top: '0' | '1';

      issaleout: '0' | '1';

      main_good: {
        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            avail_name: string;

            lang: string;
          }>;
        };

        availability_id: number;

        currency: {
          id: string;

          name: string;
        };

        currency_id: string;

        discount_pcn?: number;

        ean: string;

        final_price: number;

        good_id: number;

        group_price?: {
          oldprice: number;

          sale_percentage: number;

          unitprice: number;
        };

        imagePath: string;

        internal_code: string;

        is_on_stock: '0' | '1';

        oldprice: number;

        on_stock_count: number;

        order_number: string;

        point_good: '0' | '1';

        points_amount: number;

        price_without_vat: number;

        price_without_vat_sale: number;

        product_id: number;

        sale: boolean;

        sale_percentage: number;

        unitprice: number;

        unitprice_sale: number;

        units: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate: number;
      };

      name: string;

      name_url_encoded: string;

      picture: string;

      plu: string;

      product_id: number;

      rating: string;

      rating_users: number;

      richness?: string;

      slogan: string;

      url: string;
    }>;
  }> {
    let path = '/products/{product_id}/connections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['connectionType'] !== 'undefined') {
      queryParameters['connection_type'] = parameters['connectionType'];
    }

    queryParameters['connection_type'] = this.transformParameter(
      queryParameters['connection_type'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['random'] !== 'undefined') {
      queryParameters['random'] = parameters['random'];
    }

    queryParameters['random'] = this.transformParameter(
      queryParameters['random'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns average rating and all user ratings
   * @method
   * @name API#loadProductRatings
   */
  loadProductRatings(
    productId: number,
    parameters: {
      dateStart?: string;
      dateEnd?: string;
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: 'asc' | 'desc';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    average_rating: string;

    rating_users: number;

    ratings: Array<{
      rate?: string;

      user_id?: number;
    }>;
  }> {
    let path = '/products/{product_id}/rating';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['dateStart'] !== 'undefined') {
      queryParameters['date_start'] = parameters['dateStart'];
    }

    queryParameters['date_start'] = this.transformParameter(
      queryParameters['date_start'],
    );

    if (typeof parameters['dateEnd'] !== 'undefined') {
      queryParameters['date_end'] = parameters['dateEnd'];
    }

    queryParameters['date_end'] = this.transformParameter(
      queryParameters['date_end'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * User must be logged in to submit rating
   * @method
   * @name API#rateProduct
   */
  rateProduct(
    productId: number,
    parameters: {} = {},
    body: {
      rating: number;

      user_id: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/rating';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads data about prices of good for conrete customer
   * @method
   * @name API#loadProductGoodCustomersPrices
   */
  loadProductGoodCustomersPrices(
    productId: number,
    goodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes all customer prices for concrete good
   * @method
   * @name API#deleteAllProductGoodCustomerPrice
   */
  deleteAllProductGoodCustomerPrice(
    productId: number,
    goodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createsProductGoodCustomerPrices
   */
  createsProductGoodCustomerPrices(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_prices: Array<{
        oldprice: number;

        unitprice: number;

        user_id: number;
      }>;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads data about prices of good for conrete customer
   * @method
   * @name API#loadProductGoodCustomerPrices
   */
  loadProductGoodCustomerPrices(
    productId: number,
    goodId: number,
    customerId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices/{customer_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes all customer prices for concrete good
   * @method
   * @name API#deleteProductGoodCustomerPrice
   */
  deleteProductGoodCustomerPrice(
    productId: number,
    goodId: number,
    customerId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices/{customer_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * You must be registered user to do this
   * @method
   * @name API#watchAvailability
   */
  watchAvailability(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_id: number;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/watch-availability';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * You must be registered user to do this
   * @method
   * @name API#watchPrice
   */
  watchPrice(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_id: number;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/watch-price';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * You can filter list by currency, category and language
   * @method
   * @name API#updateStock
   */
  updateStock(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      stock_id?: string;

      quantity: number;
    },
  ): Promise<{}> {
    let path = '/products/{product_id}/{good_id}/stock';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns crossell stats of the product
   * @method
   * @name API#loadProductCrossellStats
   */
  loadProductCrossellStats(
    productId: number,
    parameters: {
      usedIco?: boolean;
      createdFrom?: string;
      createdTo?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<void> {
    let path = '/products/{product_id}/crossell-stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['usedIco'] !== 'undefined') {
      queryParameters['used_ico'] = parameters['usedIco'];
    }

    queryParameters['used_ico'] = this.transformParameter(
      queryParameters['used_ico'],
    );

    if (typeof parameters['createdFrom'] !== 'undefined') {
      queryParameters['created_from'] = parameters['createdFrom'];
    }

    queryParameters['created_from'] = this.transformParameter(
      queryParameters['created_from'],
    );

    if (typeof parameters['createdTo'] !== 'undefined') {
      queryParameters['created_to'] = parameters['createdTo'];
    }

    queryParameters['created_to'] = this.transformParameter(
      queryParameters['created_to'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns rss
   * @method
   * @name API#loadRss
   */
  loadRss(
    parameters: {
      limit?: number;
      offset?: number;
      sitemapId?: string;
      langId?: string;
    } = {},
  ): Promise<
    Array<{
      tag_id: string;

      tag_name?: string;
    }>
  > {
    let path = '/rss';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of tags
   * @method
   * @name API#loadTags
   */
  loadTags(
    parameters: {
      limit?: number;
      offset?: number;
      withIsHidden?: number;
      identificator?: string;
      query?: string;
    } = {},
  ): Promise<
    Array<{
      tag_id: string;

      tag_name?: string;
    }>
  > {
    let path = '/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withIsHidden'] !== 'undefined') {
      queryParameters['with_is_hidden'] = parameters['withIsHidden'];
    }

    queryParameters['with_is_hidden'] = this.transformParameter(
      queryParameters['with_is_hidden'],
    );

    if (typeof parameters['identificator'] !== 'undefined') {
      queryParameters['identificator'] = parameters['identificator'];
    }

    queryParameters['identificator'] = this.transformParameter(
      queryParameters['identificator'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new tag
   * @method
   * @name API#createTag
   */
  createTag(
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about concrete tag by ID
   * @method
   * @name API#loadTagDetail
   */
  loadTagDetail(
    tagId: number,
    parameters: {} = {},
  ): Promise<{
    tag_id: string;

    tag_name?: string;
  }> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update tag's details
   * @method
   * @name API#updateTag
   */
  updateTag(
    tagId: number,
    parameters: {} = {},
    body: {
      tag_name?: string;
    },
  ): Promise<void> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete tag
   * @method
   * @name API#deleteTag
   */
  deleteTag(tagId: number, parameters: {} = {}): Promise<void> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of key value pairs
   * @method
   * @name API#getSettings
   */
  getSettings(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      id: number;

      name: string;

      value: string;

      public: number;

      admin_name: string;
    }>
  > {
    let path = '/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with key value pair
   * @method
   * @name API#getSettingByName
   */
  getSettingByName(
    settingName: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      id: number;

      name: string;

      value: string;

      public: number;

      admin_name: string;
    }>
  > {
    let path = '/settings/{setting_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{setting_name}', settingName.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap tree with its content
   * @method
   * @name API#loadSitemapTree
   */
  loadSitemapTree(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    sitemap_tree: Array<{
      access?: string;

      content?: {
        author?: {
          author_id?: number;

          image?: string;

          name?: string;

          url?: string;
        };

        created_date?: string;

        created_user_id?: number;

        documents?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        downloads?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        draft?: boolean;

        hint?: string;

        home?: boolean;

        id?: number;

        is_top?: boolean;

        json_content?: {};

        lang_id?: string;

        lang_version_id?: number;

        last_update?: string;

        name?: string;

        next_article?: {
          annotation?: string;

          id?: string;

          image?: string;

          name?: string;

          url?: string;
        };

        ordering?: number;

        original_id?: number;

        photogallery?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        products?: Array<{
          attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          brand?: {
            brand_descr?: string;

            brand_id?: number;

            brand_image?: string;

            brand_name?: string;

            brand_provider?: string;

            brand_top?: boolean;

            brand_weburl?: string;

            catalog_id?: number;

            translations?: Array<{
              brand_descr?: string;

              brand_id?: number;

              brand_name?: string;

              brand_weburl?: string;

              lang_id?: string;
            }>;

            url?: string;
          };

          brand_id?: number;

          category_text?: string;

          created_at?: string;

          created_datetime?: string;

          description?: string;

          disable_discount?: boolean;

          disable_solo_order?: boolean;

          ean?: string;

          goods?: Array<{
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          }>;

          heureka?: number;

          heureka_id?: number;

          internal_code?: number;

          is_favorite?: boolean;

          is_new?: boolean;

          is_popular?: boolean;

          is_recommended?: boolean;

          is_sale?: boolean;

          isactive?: number;

          isnew_valid?: string;

          issale_buy3_pay2?: number;

          issaleout?: boolean;

          issaleout_since?: string;

          issaleout_valid?: string;

          istop?: boolean;

          locked?: number;

          main_good?: {
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          };

          max_price?: number;

          min_delivery_priority?: number;

          min_payment_priority?: number;

          min_price?: number;

          name?: string;

          name_url_encoded?: string;

          notshow?: number;

          ordered_count?: number;

          picture?: string;

          picture_hash?: string;

          picture_media_script?: string;

          plu?: string;

          product_fts?: string;

          product_id?: number;

          product_weight?: number;

          rating?: number;

          rating_users?: number;

          slogan?: string;

          type?: {
            catalog_id?: number;

            is_hidden?: number;

            store_minqty?: string;

            type_descr?: string;

            type_id?: number;

            type_inherits?: number;

            type_name?: string;
          };

          url?: string;

          valid_from?: string;

          valid_to?: string;
        }>;

        publish?: number;

        rss?: boolean;

        sf_form?: string;

        sf_stylesheet?: string;

        sf_xmlcontent?: string;

        sitemap_item_id?: number;

        tags?: Array<{
          id?: number;

          name?: string;
        }>;

        url?: string;

        videos?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          ordering?: number;

          video_description?: string;

          video_height?: number;

          video_id?: number;

          video_name?: string;

          video_url?: string;

          video_width?: number;

          webshow?: number;
        }>;
      };

      created_date?: string;

      created_user_id?: number;

      descr_content_id?: number;

      display?: string;

      id?: number;

      image?: {
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      };

      lang?: string;

      lang_version_id?: number;

      last_update?: string;

      layout_id?: string;

      link?: string;

      link_newwin?: number;

      locked?: number;

      name?: string;

      ordering?: number;

      parent_id?: number;

      private?: number;

      slogan?: string;

      title?: string;

      uniqid?: string;

      url?: string;

      webshow?: number;
    }>;
  }> {
    let path = '/sitemaps/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap with its content
   * @method
   * @name API#loadSitemap
   */
  loadSitemap(
    sitemapUid: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id?: number;

    parent_id?: number | null;

    created_user_id?: number | null;

    created_date?: string;

    custom_date?: string;

    lang?: string;

    display?: string | null;

    name?: string;

    link?: string;

    ordering?: number;

    descr_content_id?: number | null;

    link_newwin?: number;

    access?: string;

    url?: string;

    webshow?: number;

    locked?: number;

    title?: string | null;

    uniqid?: string | null;

    last_update?: string;

    slogan?: string | null;

    layout_id?: string | null;

    private?: number;

    content?: object | null;
  }> {
    let path = '/sitemaps/{sitemap_uid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap tree with its content
   * @method
   * @name API#loadSitemapSubtree
   */
  loadSitemapSubtree(
    sitemapUid: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    sitemap_tree: Array<{
      access?: string;

      content?: {
        author?: {
          author_id?: number;

          image?: string;

          name?: string;

          url?: string;
        };

        created_date?: string;

        created_user_id?: number;

        documents?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        downloads?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        draft?: boolean;

        hint?: string;

        home?: boolean;

        id?: number;

        is_top?: boolean;

        json_content?: {};

        lang_id?: string;

        lang_version_id?: number;

        last_update?: string;

        name?: string;

        next_article?: {
          annotation?: string;

          id?: string;

          image?: string;

          name?: string;

          url?: string;
        };

        ordering?: number;

        original_id?: number;

        photogallery?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        products?: Array<{
          attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          brand?: {
            brand_descr?: string;

            brand_id?: number;

            brand_image?: string;

            brand_name?: string;

            brand_provider?: string;

            brand_top?: boolean;

            brand_weburl?: string;

            catalog_id?: number;

            translations?: Array<{
              brand_descr?: string;

              brand_id?: number;

              brand_name?: string;

              brand_weburl?: string;

              lang_id?: string;
            }>;

            url?: string;
          };

          brand_id?: number;

          category_text?: string;

          created_at?: string;

          created_datetime?: string;

          description?: string;

          disable_discount?: boolean;

          disable_solo_order?: boolean;

          ean?: string;

          goods?: Array<{
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          }>;

          heureka?: number;

          heureka_id?: number;

          internal_code?: number;

          is_favorite?: boolean;

          is_new?: boolean;

          is_popular?: boolean;

          is_recommended?: boolean;

          is_sale?: boolean;

          isactive?: number;

          isnew_valid?: string;

          issale_buy3_pay2?: number;

          issaleout?: boolean;

          issaleout_since?: string;

          issaleout_valid?: string;

          istop?: boolean;

          locked?: number;

          main_good?: {
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          };

          max_price?: number;

          min_delivery_priority?: number;

          min_payment_priority?: number;

          min_price?: number;

          name?: string;

          name_url_encoded?: string;

          notshow?: number;

          ordered_count?: number;

          picture?: string;

          picture_hash?: string;

          picture_media_script?: string;

          plu?: string;

          product_fts?: string;

          product_id?: number;

          product_weight?: number;

          rating?: number;

          rating_users?: number;

          slogan?: string;

          type?: {
            catalog_id?: number;

            is_hidden?: number;

            store_minqty?: string;

            type_descr?: string;

            type_id?: number;

            type_inherits?: number;

            type_name?: string;
          };

          url?: string;

          valid_from?: string;

          valid_to?: string;
        }>;

        publish?: number;

        rss?: boolean;

        sf_form?: string;

        sf_stylesheet?: string;

        sf_xmlcontent?: string;

        sitemap_item_id?: number;

        tags?: Array<{
          id?: number;

          name?: string;
        }>;

        url?: string;

        videos?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          ordering?: number;

          video_description?: string;

          video_height?: number;

          video_id?: number;

          video_name?: string;

          video_url?: string;

          video_width?: number;

          webshow?: number;
        }>;
      };

      created_date?: string;

      created_user_id?: number;

      descr_content_id?: number;

      display?: string;

      id?: number;

      image?: {
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      };

      lang?: string;

      lang_version_id?: number;

      last_update?: string;

      layout_id?: string;

      link?: string;

      link_newwin?: number;

      locked?: number;

      name?: string;

      ordering?: number;

      parent_id?: number;

      private?: number;

      slogan?: string;

      title?: string;

      uniqid?: string;

      url?: string;

      webshow?: number;
    }>;
  }> {
    let path = '/sitemaps/{sitemap_uid}/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap parents
   * @method
   * @name API#loadSitemapParents
   */
  loadSitemapParents(
    sitemapUid: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      id?: number;

      parent_id?: number | null;

      created_user_id?: number | null;

      created_date?: string;

      custom_date?: string;

      lang?: string;

      display?: string | null;

      name?: string;

      link?: string;

      ordering?: number;

      descr_content_id?: number | null;

      link_newwin?: number;

      access?: string;

      url?: string;

      webshow?: number;

      locked?: number;

      title?: string | null;

      uniqid?: string | null;

      last_update?: string;

      slogan?: string | null;

      layout_id?: string | null;

      private?: number;

      content?: object | null;
    }>
  > {
    let path = '/sitemaps/{sitemap_uid}/parents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates new language version of sitemap available for translation

**DESCRIPTION**

Copy sitemap between two language versions. Source langugage version is determined by ID of sitemap.
Language version of sitemap is full copy of source sitemap. Connections between language version is maintained by lang_version_id.
**!!!WARNING!!! If dest. version already exists it will be first deleted than created again.**

    * @method
    * @name API#copySitemap
    */
  copySitemap(
    sitemapUid: number,
    langId: string,
    parameters: {} = {},
    body: {
      parent_id: number;

      name: string;

      url: string;
    },
  ): Promise<{}> {
    let path = '/sitemaps/{sitemap_uid}/copy/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadCategoryStatistics
   */
  loadCategoryStatistics(type: 'sales', parameters: {} = {}): Promise<void> {
    let path = '/statistics/categories/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Statistics about customers from specified period
   * @method
   * @name API#loadCustomerStatistics
   */
  loadCustomerStatistics(
    type: 'monthCount' | 'yearCount' | 'monthlyCount' | 'ratioOfCustomersType',
    parameters: {
      from?: string;
      to?: string;
    } = {},
  ): Promise<{}> {
    let path = '/statistics/customers/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    if (typeof parameters['from'] !== 'undefined') {
      queryParameters['from'] = parameters['from'];
    }

    queryParameters['from'] = this.transformParameter(queryParameters['from']);

    if (typeof parameters['to'] !== 'undefined') {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters['to'] = this.transformParameter(queryParameters['to']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadOrdersStatistics
   */
  loadOrdersStatistics(
    type: 'monthCount' | 'yearCount' | 'monthlyCount',
    parameters: {} = {},
  ): Promise<void> {
    let path = '/statistics/orders/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling brands
   * @method
   * @name API#loadTopBrands
   */
  loadTopBrands(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_brands: Array<{
      brand_id: number;

      brand_name: string;

      total: number;
    }>;
  }> {
    let path = '/statistics/top/brands';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling categories
   * @method
   * @name API#loadTopCategories
   */
  loadTopCategories(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_categories: Array<{
      category_id: number;

      category_name: string;

      total: number;
    }>;
  }> {
    let path = '/statistics/top/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling products
   * @method
   * @name API#loadTopProducts
   */
  loadTopProducts(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_products: Array<{
      product_id: number;

      product_name: string;

      number_of_orders: number;

      product_picture: string;
    }>;
  }> {
    let path = '/statistics/top/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about all shops
   * @method
   * @name API#loadShops
   */
  loadShops(
    parameters: {
      onlyAllowedPersonalPickup?: boolean;
      onlyBasicData?: boolean;
    } = {},
  ): Promise<{
    shops: Array<{
      id: number;

      code?: string;

      url: string;

      lang: string;

      company: string;

      boss: string;

      dic: string;

      ico: string;

      phone: string;

      email: string;

      name: string;

      descr_content_id: number;

      address: string;

      city: string;

      zip: string;

      state: string;

      gps_latitude: number;

      gps_longitude: number;

      allowed_personal_pickup?: boolean;

      opening_hours?: Array<{}>;

      content?: {
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;
      };
    }>;
  }> {
    let path = '/shops';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['onlyAllowedPersonalPickup'] !== 'undefined') {
      queryParameters['only_allowed_personal_pickup'] =
        parameters['onlyAllowedPersonalPickup'];
    }

    queryParameters['only_allowed_personal_pickup'] = this.transformParameter(
      queryParameters['only_allowed_personal_pickup'],
    );

    if (typeof parameters['onlyBasicData'] !== 'undefined') {
      queryParameters['only_basic_data'] = parameters['onlyBasicData'];
    }

    queryParameters['only_basic_data'] = this.transformParameter(
      queryParameters['only_basic_data'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about concrete shop by ID
   * @method
   * @name API#loadShopDetail
   */
  loadShopDetail(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/shops/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create skvelko sets
   * @method
   * @name API#createSkvelkoSet
   */
  createSkvelkoSet(
    parameters: {} = {},
    body: {
      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      awarded_user_id?: number | null;

      skvelko_set_availability_id: number;

      goods?: Array<{
        id?: number;

        good_id: number;

        count: number;
      }>;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_set/create';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update skvelko set
   * @method
   * @name API#updateSkvelkoSet
   */
  updateSkvelkoSet(
    id: number,
    parameters: {} = {},
    body: {
      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      awarded_user_id?: number | null;

      skvelko_set_availability_id?: number;

      goods?: Array<{
        id?: number;

        good_id: number;

        count: number;

        position?: number;
      }>;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_set/update/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Change skvelko set availability
   * @method
   * @name API#changeAvailabilitySkvelkoSet
   */
  changeAvailabilitySkvelkoSet(
    id: number,
    availabilityId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path =
      '/skvelko/skvelko_set/change_availability/{id}/{availability_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{availability_id}', availabilityId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Copy skvelko set
   * @method
   * @name API#copySkvelkoSet
   */
  copySkvelkoSet(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/skvelko_set/copy/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Print skvelko set
   * @method
   * @name API#printSkvelkoSet
   */
  printSkvelkoSet(
    id: number,
    parameters: {} = {},
  ): Promise<{
    base64?: string;
  }> {
    let path = '/skvelko/skvelko_set/print/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Print skvelko set color leaflet
   * @method
   * @name API#printSkvelkoSetColorLeaflet
   */
  printSkvelkoSetColorLeaflet(
    id: number,
    parameters: {} = {},
  ): Promise<{
    base64?: string;
  }> {
    let path = '/skvelko/skvelko_set/print_color_leaflet/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Print skvelko set leaflet
   * @method
   * @name API#printSkvelkoSetLeaflet
   */
  printSkvelkoSetLeaflet(
    id: number,
    parameters: {} = {},
  ): Promise<{
    base64?: string;
  }> {
    let path = '/skvelko/skvelko_set/print_leaflet/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Mass print skvelko set leaflet
   * @method
   * @name API#massPrintSkvelkoSetLeaflet
   */
  massPrintSkvelkoSetLeaflet(
    parameters: {} = {},
    body: Array<{
      set_id?: number;

      count?: number;
    }>,
  ): Promise<{
    base64?: string;
  }> {
    let path = '/skvelko/skvelko_set/mass_print_leafled';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko sets
   * @method
   * @name API#searchSkvelkoSet
   */
  searchSkvelkoSet(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
      withPrice?: boolean;
      withGoods?: boolean;
      withProducts?: boolean;
      withAwardedUser?: boolean;
      withLog?: boolean;
      withCount?: boolean;
      withSchoolDeliveryActivated?: boolean;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      chematId?: number;

      clubUserId?: number;

      awardedUserId?: number;

      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      fullName?: string;

      skvelkoSetAvailabilityId?: number;

      price?: number;

      city?: string;

      school_name?: string;

      schoolFullName?: string;

      school_street?: string;

      school_street_number?: number;

      count?: number;

      updated_at?: string;

      isDeliveryToSchoolActivated?: boolean;

      productGoodsWithCount?: Array<{
        count?: number;

        productGood?: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };
      }>;

      productsWithCount?: Array<{
        count?: number;

        product?: {
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        };
      }>;

      skvelkoSetLogs?: Array<{
        log?: {
          availability?: string | null;

          clubUsersId: number;

          created_at: string;

          created_by?: string | null;

          id: number;

          itemsCount: number;

          login?: string | null;

          price: number;

          skvelkoSetAvailabilitiesId: number;

          skvelkoSetCreatedById: number;

          skvelkoSetsId: number;
        };
      }>;

      awardedUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;
  }> {
    let path = '/skvelko/skvelko_set/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withPrice'] !== 'undefined') {
      queryParameters['with_price'] = parameters['withPrice'];
    }

    queryParameters['with_price'] = this.transformParameter(
      queryParameters['with_price'],
    );

    if (typeof parameters['withGoods'] !== 'undefined') {
      queryParameters['with_goods'] = parameters['withGoods'];
    }

    queryParameters['with_goods'] = this.transformParameter(
      queryParameters['with_goods'],
    );

    if (typeof parameters['withProducts'] !== 'undefined') {
      queryParameters['with_products'] = parameters['withProducts'];
    }

    queryParameters['with_products'] = this.transformParameter(
      queryParameters['with_products'],
    );

    if (typeof parameters['withAwardedUser'] !== 'undefined') {
      queryParameters['with_awarded_user'] = parameters['withAwardedUser'];
    }

    queryParameters['with_awarded_user'] = this.transformParameter(
      queryParameters['with_awarded_user'],
    );

    if (typeof parameters['withLog'] !== 'undefined') {
      queryParameters['with_log'] = parameters['withLog'];
    }

    queryParameters['with_log'] = this.transformParameter(
      queryParameters['with_log'],
    );

    if (typeof parameters['withCount'] !== 'undefined') {
      queryParameters['with_count'] = parameters['withCount'];
    }

    queryParameters['with_count'] = this.transformParameter(
      queryParameters['with_count'],
    );

    if (typeof parameters['withSchoolDeliveryActivated'] !== 'undefined') {
      queryParameters['with_school_delivery_activated'] =
        parameters['withSchoolDeliveryActivated'];
    }

    queryParameters['with_school_delivery_activated'] = this.transformParameter(
      queryParameters['with_school_delivery_activated'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko sets
   * @method
   * @name API#searchSkvelkoSetPublic
   */
  searchSkvelkoSetPublic(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
      withPrice?: boolean;
      schoolId?: number;
      schoolIco?: string;
      withGoods?: boolean;
      withProducts?: boolean;
      withSchoolData?: boolean;
      withSchoolDeliveryActivated?: boolean;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      chematId?: number;

      clubUserId?: number;

      awardedUserId?: number;

      name1?: string;

      name2?: string;

      name3?: string;

      note?: string;

      fullName?: string;

      skvelkoSetAvailabilityId?: number;

      price?: number;

      city?: string;

      school_name?: string;

      schoolFullName?: string;

      school_street?: string;

      school_street_number?: number;

      count?: number;

      updated_at?: string;

      isDeliveryToSchoolActivated?: boolean;

      productGoodsWithCount?: Array<{
        count?: number;

        productGood?: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };
      }>;

      productsWithCount?: Array<{
        count?: number;

        product?: {
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          isnew_valid: string;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            unitprice_with_discount: number | null;

            unitprice_with_discount_without_vat?: number | null;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            group_prices?: Array<{
              skupina_id?: number;

              good_id?: number;

              unitprice?: number;

              oldprice?: number;
            }>;

            discount_pcn?: number;

            order_quantity_limit?: number | null;

            good_sort?: number;

            onstore?: Array<{
              goodId?: number;

              storeId?: string;

              storeQty?: number;
            }>;
          };

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          set_delete_warning?: boolean;

          is_user_favorite?: boolean;
        };
      }>;

      skvelkoSetLogs?: Array<{
        log?: {
          availability?: string | null;

          clubUsersId: number;

          created_at: string;

          created_by?: string | null;

          id: number;

          itemsCount: number;

          login?: string | null;

          price: number;

          skvelkoSetAvailabilitiesId: number;

          skvelkoSetCreatedById: number;

          skvelkoSetsId: number;
        };
      }>;

      awardedUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;
  }> {
    let path = '/skvelko/skvelko_set/public_search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withPrice'] !== 'undefined') {
      queryParameters['with_price'] = parameters['withPrice'];
    }

    queryParameters['with_price'] = this.transformParameter(
      queryParameters['with_price'],
    );

    if (typeof parameters['schoolId'] !== 'undefined') {
      queryParameters['school_id'] = parameters['schoolId'];
    }

    queryParameters['school_id'] = this.transformParameter(
      queryParameters['school_id'],
    );

    if (typeof parameters['schoolIco'] !== 'undefined') {
      queryParameters['school_ico'] = parameters['schoolIco'];
    }

    queryParameters['school_ico'] = this.transformParameter(
      queryParameters['school_ico'],
    );

    if (typeof parameters['withGoods'] !== 'undefined') {
      queryParameters['with_goods'] = parameters['withGoods'];
    }

    queryParameters['with_goods'] = this.transformParameter(
      queryParameters['with_goods'],
    );

    if (typeof parameters['withProducts'] !== 'undefined') {
      queryParameters['with_products'] = parameters['withProducts'];
    }

    queryParameters['with_products'] = this.transformParameter(
      queryParameters['with_products'],
    );

    if (typeof parameters['withSchoolData'] !== 'undefined') {
      queryParameters['with_school_data'] = parameters['withSchoolData'];
    }

    queryParameters['with_school_data'] = this.transformParameter(
      queryParameters['with_school_data'],
    );

    if (typeof parameters['withSchoolDeliveryActivated'] !== 'undefined') {
      queryParameters['with_school_delivery_activated'] =
        parameters['withSchoolDeliveryActivated'];
    }

    queryParameters['with_school_delivery_activated'] = this.transformParameter(
      queryParameters['with_school_delivery_activated'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko set availabilities
   * @method
   * @name API#getSkvelkoSetAvailabilities
   */
  getSkvelkoSetAvailabilities(
    parameters: {} = {},
  ): Promise<{
    skvelkoSetAvailabilities: Array<{
      id?: number;

      name?: string;
    }>;
  }> {
    let path = '/skvelko/skvelko_set_availabilities';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add product to skvelko set
   * @method
   * @name API#addProductToSkvelkoSet
   */
  addProductToSkvelkoSet(
    skvelkoSetId: number,
    productGoodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path =
      '/skvelko/skvelko_set_product_good/add/{skvelko_set_id}/{product_good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{skvelko_set_id}', skvelkoSetId.toString());

    path = path.replace('{product_good_id}', productGoodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove product from skvelko set
   * @method
   * @name API#removeProductFromSkvelkoSet
   */
  removeProductFromSkvelkoSet(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/skvelko_set_product_good/remove/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko sets products
   * @method
   * @name API#searchSkvelkoSetProduct
   */
  searchSkvelkoSetProduct(
    parameters: {
      id?: number;
      productGoodId?: number;
      skvelkoSetId?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      skvelkoSetId?: number;

      productGoodId?: number;

      count?: number;

      productGood?: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        unitprice_with_discount: number | null;

        unitprice_with_discount_without_vat?: number | null;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        group_prices?: Array<{
          skupina_id?: number;

          good_id?: number;

          unitprice?: number;

          oldprice?: number;
        }>;

        discount_pcn?: number;

        order_quantity_limit?: number | null;

        good_sort?: number;

        onstore?: Array<{
          goodId?: number;

          storeId?: string;

          storeQty?: number;
        }>;
      }>;
    }>;
  }> {
    let path = '/skvelko/skvelko_set_product_good/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['productGoodId'] !== 'undefined') {
      queryParameters['product_good_id'] = parameters['productGoodId'];
    }

    queryParameters['product_good_id'] = this.transformParameter(
      queryParameters['product_good_id'],
    );

    if (typeof parameters['skvelkoSetId'] !== 'undefined') {
      queryParameters['skvelko_set_id'] = parameters['skvelkoSetId'];
    }

    queryParameters['skvelko_set_id'] = this.transformParameter(
      queryParameters['skvelko_set_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create bulk skvelko order
   * @method
   * @name API#createBulkSkvelkoOrder
   */
  createBulkSkvelkoOrder(
    parameters: {} = {},
    body: {
      note?: string;

      name?: string;

      phone?: string;

      email?: string;

      sets?: Array<{
        id?: number;

        count?: number;
      }>;
    },
  ): Promise<void> {
    let path = '/skvelko/bulk_order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Create skvelko awarded user
   * @method
   * @name API#createSkvelkoAwardedUser
   */
  createSkvelkoAwardedUser(
    parameters: {} = {},
    body: {
      name: string;

      surname: string;

      email: string;

      password: string;

      notify_user?: boolean;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_awarded_user/create';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Assign skvelko awarded user by email
   * @method
   * @name API#assignSkvelkoAwardedUser
   */
  assignSkvelkoAwardedUser(
    parameters: {} = {},
    body: {
      email: string;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_awarded_user/assign';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove skvelko awarded person
   * @method
   * @name API#removeSkvelkoAwardedUser
   */
  removeSkvelkoAwardedUser(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/skvelko_awarded_user/delete/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko awarded users
   * @method
   * @name API#searchSkvelkoAwardedUsers
   */
  searchSkvelkoAwardedUsers(
    parameters: {
      id?: number;
      clubUserId?: number;
      awardedClubUserId?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      clubUserId?: number;

      awardedClubUserId?: number;

      awardedUser?: {
        id: number;

        email: string;

        name: string;

        surname: string;

        trust_points: number;

        highest_trust_points_reached: number;

        awarded_user?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };
    }>;
  }> {
    let path = '/skvelko/skvelko_awarded_user/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['clubUserId'] !== 'undefined') {
      queryParameters['club_user_id'] = parameters['clubUserId'];
    }

    queryParameters['club_user_id'] = this.transformParameter(
      queryParameters['club_user_id'],
    );

    if (typeof parameters['awardedClubUserId'] !== 'undefined') {
      queryParameters['awarded_club_user_id'] = parameters['awardedClubUserId'];
    }

    queryParameters['awarded_club_user_id'] = this.transformParameter(
      queryParameters['awarded_club_user_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create skvelko class
   * @method
   * @name API#createSkvelkoClass
   */
  createSkvelkoClass(
    parameters: {} = {},
    body: {
      name: string;

      skvelkoAwardedUserId?: number;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_class/create';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update skvelko class
   * @method
   * @name API#updateSkvelkoClass
   */
  updateSkvelkoClass(
    id: number,
    parameters: {} = {},
    body: {
      name?: string;

      skvelkoAwardedUserId?: number;
    },
  ): Promise<void> {
    let path = '/skvelko/skvelko_class/update/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove skvelko class
   * @method
   * @name API#removeSkvelkoClass
   */
  removeSkvelkoClass(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/skvelko_class/delete/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko class
   * @method
   * @name API#searchSkvelkoClass
   */
  searchSkvelkoClass(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
      withAwardedUser?: boolean;
      withClubUser?: boolean;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      clubUserId?: number;

      name?: string;

      skvelkoAwardedUserId?: number;

      awardedClubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };

      clubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;
  }> {
    let path = '/skvelko/skvelko_class/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withAwardedUser'] !== 'undefined') {
      queryParameters['with_awarded_user'] = parameters['withAwardedUser'];
    }

    queryParameters['with_awarded_user'] = this.transformParameter(
      queryParameters['with_awarded_user'],
    );

    if (typeof parameters['withClubUser'] !== 'undefined') {
      queryParameters['with_club_user'] = parameters['withClubUser'];
    }

    queryParameters['with_club_user'] = this.transformParameter(
      queryParameters['with_club_user'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko class public
   * @method
   * @name API#searchSkvelkoClassPublic
   */
  searchSkvelkoClassPublic(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
      clubUserId?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    search: Array<{
      id?: number;

      clubUserId?: number;

      name?: string;

      skvelkoAwardedUserId?: number;

      awardedClubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };

      clubUser?: {
        id: number;

        meno?: string;

        priezvisko?: string;

        firma?: string;

        ico?: string;

        dic?: string;

        icdph?: string;

        platca_dph?: 0 | 1;

        ulica?: string;

        cislo?: string;

        mesto?: string;

        psc?: string;

        country?: string;

        telefon?: string;

        email?: string;

        login?: string;

        ip?: string;

        host?: string;

        registrovany?: string;

        stav?: string;

        poznamka?: string;

        nedoveryhodny?: 0 | 1;

        interna_poznamka?: string;

        has_ip_access?: 0 | 1;

        individualna_zlava?: number;

        lang?: string;

        activatecode?: string;

        activate_datetime?: string;

        guid?: string;

        login_hash?: string;

        uniqid?: string;

        card_number?: string;

        reg_discount?: 0 | 1;

        facebook_user_id?: string;

        google_user_id?: string;

        trust_points?: number;

        highest_trust_points_reached?: number;

        history_points?: {};

        contact_name?: string;

        contact_phone?: string;

        use_delivery_address?: number;

        school_id?: string;

        school_name?: string;
      };
    }>;
  }> {
    let path = '/skvelko/skvelko_class/public_search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['clubUserId'] !== 'undefined') {
      queryParameters['club_user_id'] = parameters['clubUserId'];
    }

    queryParameters['club_user_id'] = this.transformParameter(
      queryParameters['club_user_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko credit history
   * @method
   * @name API#searchSkvelkoCreditHistory
   */
  searchSkvelkoCreditHistory(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
      onlyNegative?: boolean;
    } = {},
  ): Promise<void> {
    let path = '/skvelko/skvelko_credit_history/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['onlyNegative'] !== 'undefined') {
      queryParameters['only_negative'] = parameters['onlyNegative'];
    }

    queryParameters['only_negative'] = this.transformParameter(
      queryParameters['only_negative'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get dashboard data
   * @method
   * @name API#getDashboardData
   */
  getDashboardData(
    parameters: {} = {},
  ): Promise<{
    status: string;

    skvelkoSetAvailabilities: Array<{
      skvelko_set_availability_id?: number;

      count?: number;

      name?: string;
    }>;

    classCount: number;

    awardedUsers: number;

    points: number;

    organization: {
      name: string;

      street: string;

      streetNumber: string;

      town: string;

      zip: string;

      type_sale_price?: number;

      contactPerson?: {};
    };

    contactPerson: {
      email: string;

      name: string;

      phone: string;
    };

    deliveryToSchoool: {
      active: string;

      until: string;
    };
  }> {
    let path = '/skvelko/getDashboardData';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko orders grouped by class
   * @method
   * @name API#getOrdersGroupedByClass
   */
  getOrdersGroupedByClass(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<{
    orders: Array<{
      count?: number;

      price?: number;

      skvelko_class_id?: number;

      class_name?: string;
    }>;
  }> {
    let path = '/skvelko/orders/grouped-by-class';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko orders grouped by sets
   * @method
   * @name API#getOrdersGroupedBySet
   */
  getOrdersGroupedBySet(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
      onlyWithDeliveryToSchool?: boolean;
    } = {},
  ): Promise<{
    orders: Array<{
      count?: number;

      tprice?: number;

      skvelko_set_ids?: string;

      name?: string;
    }>;
  }> {
    let path = '/skvelko/orders/grouped-by-set';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    if (typeof parameters['onlyWithDeliveryToSchool'] !== 'undefined') {
      queryParameters['only_with_delivery_to_school'] =
        parameters['onlyWithDeliveryToSchool'];
    }

    queryParameters['only_with_delivery_to_school'] = this.transformParameter(
      queryParameters['only_with_delivery_to_school'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko orders grouped by sets
   * @method
   * @name API#getOrdersGroupedBySetPrint
   */
  getOrdersGroupedBySetPrint(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
      onlyWithDeliveryToSchool?: boolean;
    } = {},
  ): Promise<{
    base64?: string;
  }> {
    let path = '/skvelko/orders/grouped-by-set-print';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    if (typeof parameters['onlyWithDeliveryToSchool'] !== 'undefined') {
      queryParameters['only_with_delivery_to_school'] =
        parameters['onlyWithDeliveryToSchool'];
    }

    queryParameters['only_with_delivery_to_school'] = this.transformParameter(
      queryParameters['only_with_delivery_to_school'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko orders grouped by awarded person
   * @method
   * @name API#getOrdersGroupedByAwardedPerson
   */
  getOrdersGroupedByAwardedPerson(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<{
    orders: Array<{
      count?: number;

      price?: number;

      user_id?: number;

      name?: string;

      surname?: string;

      email?: string;
    }>;
  }> {
    let path = '/skvelko/orders/grouped-by-awarded-person';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get skvelko orders
   * @method
   * @name API#getSkvelkoOrdersSearch
   */
  getSkvelkoOrdersSearch(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
      skvelkoClassId?: string;
      skvelkoSetId?: string;
      awardedClubUserId?: string;
      onlyWithDeliveryToSchool?: boolean;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      person: {
        name: string;

        surname?: string;

        phone: string;

        email: string;
      };

      address: {
        person?: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string;

          surname?: string;

          phone: string;

          email: string;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name?: string;

        ico?: string;

        dic?: string;

        ic_dph?: string;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      paymentUrl?: string;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;

      awarded_club_user_id?: number;

      awarded_school_id?: number;

      assigned_points_school?: number;

      assigned_points_awarded_person?: number;

      deliver_to_school?: number;

      children_name?: string;

      delivery_class_name?: string;

      delivery_school_id?: number | null;

      delivery_school_ico?: string | null;

      skvelko_class?: {
        id?: number;

        clubUserId?: number;

        name?: string;

        skvelkoAwardedUserId?: number;

        awardedClubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };

        clubUser?: {
          id: number;

          meno?: string;

          priezvisko?: string;

          firma?: string;

          ico?: string;

          dic?: string;

          icdph?: string;

          platca_dph?: 0 | 1;

          ulica?: string;

          cislo?: string;

          mesto?: string;

          psc?: string;

          country?: string;

          telefon?: string;

          email?: string;

          login?: string;

          ip?: string;

          host?: string;

          registrovany?: string;

          stav?: string;

          poznamka?: string;

          nedoveryhodny?: 0 | 1;

          interna_poznamka?: string;

          has_ip_access?: 0 | 1;

          individualna_zlava?: number;

          lang?: string;

          activatecode?: string;

          activate_datetime?: string;

          guid?: string;

          login_hash?: string;

          uniqid?: string;

          card_number?: string;

          reg_discount?: 0 | 1;

          facebook_user_id?: string;

          google_user_id?: string;

          trust_points?: number;

          highest_trust_points_reached?: number;

          history_points?: {};

          contact_name?: string;

          contact_phone?: string;

          use_delivery_address?: number;

          school_id?: string;

          school_name?: string;
        };
      };

      disable_card_payments?: boolean;
    }>;
  }> {
    let path = '/skvelko/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    if (typeof parameters['skvelkoClassId'] !== 'undefined') {
      queryParameters['skvelko_class_id'] = parameters['skvelkoClassId'];
    }

    queryParameters['skvelko_class_id'] = this.transformParameter(
      queryParameters['skvelko_class_id'],
    );

    if (typeof parameters['skvelkoSetId'] !== 'undefined') {
      queryParameters['skvelko_set_id'] = parameters['skvelkoSetId'];
    }

    queryParameters['skvelko_set_id'] = this.transformParameter(
      queryParameters['skvelko_set_id'],
    );

    if (typeof parameters['awardedClubUserId'] !== 'undefined') {
      queryParameters['awarded_club_user_id'] = parameters['awardedClubUserId'];
    }

    queryParameters['awarded_club_user_id'] = this.transformParameter(
      queryParameters['awarded_club_user_id'],
    );

    if (typeof parameters['onlyWithDeliveryToSchool'] !== 'undefined') {
      queryParameters['only_with_delivery_to_school'] =
        parameters['onlyWithDeliveryToSchool'];
    }

    queryParameters['only_with_delivery_to_school'] = this.transformParameter(
      queryParameters['only_with_delivery_to_school'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search skvelko schools
   * @method
   * @name API#searchSkvelkoSchools
   */
  searchSkvelkoSchools(
    parameters: {
      cityName?: string;
      onlyWithSets?: boolean;
    } = {},
  ): Promise<{
    schools: Array<{
      id?: number;

      name?: string;

      street?: string;

      street_number?: string;

      school_name?: string;
    }>;
  }> {
    let path = '/skvelko/schools/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['cityName'] !== 'undefined') {
      queryParameters['city_name'] = parameters['cityName'];
    }

    queryParameters['city_name'] = this.transformParameter(
      queryParameters['city_name'],
    );

    if (typeof parameters['onlyWithSets'] !== 'undefined') {
      queryParameters['only_with_sets'] = parameters['onlyWithSets'];
    }

    queryParameters['only_with_sets'] = this.transformParameter(
      queryParameters['only_with_sets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get all cities from school
   * @method
   * @name API#getSkvelkoSchoolCities
   */
  getSkvelkoSchoolCities(
    parameters: {
      univerzalSetsFirst?: boolean;
      includeSchoolsWithoutSets?: boolean;
    } = {},
  ): Promise<{
    cities: Array<string>;
  }> {
    let path = '/skvelko/schools/cities';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['univerzalSetsFirst'] !== 'undefined') {
      queryParameters['univerzal_sets_first'] =
        parameters['univerzalSetsFirst'];
    }

    queryParameters['univerzal_sets_first'] = this.transformParameter(
      queryParameters['univerzal_sets_first'],
    );

    if (typeof parameters['includeSchoolsWithoutSets'] !== 'undefined') {
      queryParameters['include_schools_without_sets'] =
        parameters['includeSchoolsWithoutSets'];
    }

    queryParameters['include_schools_without_sets'] = this.transformParameter(
      queryParameters['include_schools_without_sets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update user contact person
   * @method
   * @name API#updateContactPerson
   */
  updateContactPerson(parameters: {
    contactName: string;
    contactPhone: string;
  }): Promise<void> {
    let path = '/skvelko/contact-person/update';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['contactName'] !== 'undefined') {
      queryParameters['contact_name'] = parameters['contactName'];
    }

    queryParameters['contact_name'] = this.transformParameter(
      queryParameters['contact_name'],
    );

    if (typeof parameters['contactName'] === 'undefined') {
      throw new Error('Missing required parameter: contactName');
    }

    if (typeof parameters['contactPhone'] !== 'undefined') {
      queryParameters['contact_phone'] = parameters['contactPhone'];
    }

    queryParameters['contact_phone'] = this.transformParameter(
      queryParameters['contact_phone'],
    );

    if (typeof parameters['contactPhone'] === 'undefined') {
      throw new Error('Missing required parameter: contactPhone');
    }

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update school information
   * @method
   * @name API#updateSchoolInformation
   */
  updateSchoolInformation(
    parameters: {} = {},
    body: {
      name?: string;

      city?: string;

      street?: string;

      street_number?: string;

      zip?: string;

      phone?: string;

      email?: string;

      ico?: string;

      dic?: string;

      icdph?: string;

      contact_name?: string;
    },
  ): Promise<void> {
    let path = '/skvelko/school/update';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get school city
   * @method
   * @name API#getSkvelkoSchoolCity
   */
  getSkvelkoSchoolCity(parameters: {
    clubUserId: number;
  }): Promise<{
    city: string;
  }> {
    let path = '/skvelko/school/city';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['clubUserId'] !== 'undefined') {
      queryParameters['club_user_id'] = parameters['clubUserId'];
    }

    queryParameters['club_user_id'] = this.transformParameter(
      queryParameters['club_user_id'],
    );

    if (typeof parameters['clubUserId'] === 'undefined') {
      throw new Error('Missing required parameter: clubUserId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Export to chemat
   * @method
   * @name API#exportSkvelkoSetToChemat
   */
  exportSkvelkoSetToChemat(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/skvelko_set/export_to_chemat/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Import chemat user
   * @method
   * @name API#adminImportChematUser
   */
  adminImportChematUser(
    ico: string,
    type: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/skvelko/admin/import-chemat-user/{ico}/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{ico}', ico.toString());

    path = path.replace('{type}', type.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Import Customer card Info
   * @method
   * @name API#adminImportCustomerCardInfo
   */
  adminImportCustomerCardInfo(
    email: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/skvelko/admin/import-chemat-user-club-card/{email}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{email}', email.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Apply provision
   * @method
   * @name API#adminApplyProvision
   */
  adminApplyProvision(id: number, parameters: {} = {}): Promise<void> {
    let path = '/skvelko/admin/apply-provision/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Storno provision
   * @method
   * @name API#adminStornoProvision
   */
  adminStornoProvision(
    eshopOrderAwardId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/skvelko/admin/storno-provision/{eshop_order_award_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{eshop_order_award_id}', eshopOrderAwardId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Apply skvelko credit
   * @method
   * @name API#adminApplySkvelkoCredit
   */
  adminApplySkvelkoCredit(
    clubUserId: number,
    date: string,
    description: string,
    price: number,
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<void> {
    let path =
      '/skvelko/admin/apply_skvelko_credit/{club_user_id}/{date}/{description}/{price}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{club_user_id}', clubUserId.toString());

    path = path.replace('{date}', date.toString());

    path = path.replace('{description}', description.toString());

    path = path.replace('{price}', price.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Storno skvelko credit
   * @method
   * @name API#stornoApplySkvelkoCredit
   */
  stornoApplySkvelkoCredit(
    skvelkoCreditHistoryId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path =
      '/skvelko/admin/storno_skvelko_credit/{skvelko_credit_history_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace(
      '{skvelko_credit_history_id}',
      skvelkoCreditHistoryId.toString(),
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save search log
   * @method
   * @name API#saveSearchLog
   */
  saveSearchLog(
    searchSessionId: string,
    searchId: string,
    parameters: {} = {},
    body: {
      query?: string;

      search_log_results?: Array<{
        type?: string;

        display_result?: string;

        product_id?: number;

        skvelko_set_id?: number;

        category_id?: number;

        web_content_id?: number;
      }>;
    },
    extraHeaders?: {
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/search_log/{search_session_id}/{search_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{search_session_id}', searchSessionId.toString());

    path = path.replace('{search_id}', searchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove search logs by parameter
   * @method
   * @name API#removeSearchLogs
   */
  removeSearchLogs(
    parameters: {
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<void> {
    let path = '/search_log';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search dealers
   * @method
   * @name API#searchDealers
   */
  searchDealers(
    parameters: {
      id?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<
    Array<{
      id: string;

      dealer_id?: string;

      name?: string;

      mobile_number?: string;

      phone_number?: string;

      email?: string;

      photo_path?: string;
    }>
  > {
    let path = '/dealers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Created dealer
   * @method
   * @name API#createDealer
   */
  createDealer(
    parameters: {} = {},
    body: {
      dealer_id?: string;

      name?: string;

      mobile_number?: string;

      phone_number?: string;

      email?: string;

      photo_path?: string;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/dealers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update dealer
   * @method
   * @name API#editDealer
   */
  editDealer(
    id: number,
    parameters: {} = {},
    body: {
      id: string;

      dealer_id?: string;

      name?: string;

      mobile_number?: string;

      phone_number?: string;

      email?: string;

      photo_path?: string;
    },
  ): Promise<void> {
    let path = '/dealer/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update dealer
   * @method
   * @name API#deleteDealer
   */
  deleteDealer(id: number, parameters: {} = {}): Promise<void> {
    let path = '/dealer/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load dealer data by Club User ID
   * @method
   * @name API#loadDealerByUserId
   */
  loadDealerByUserId(
    userId: number,
    parameters: {} = {},
  ): Promise<{
    dealer?: {};
  }> {
    let path = '/users-dealer/{user_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{user_id}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load survey
   * @method
   * @name API#loadSurvey
   */
  loadSurvey(parameters: {
    orderPublicId: string;
  }): Promise<{
    questions: Array<{
      id?: number;

      question?: string;

      type?: number;

      required?: boolean;

      options?: Array<{
        id?: number;

        option?: string;

        type?: number;
      }>;
    }>;
  }> {
    let path = '/survey';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['orderPublicId'] !== 'undefined') {
      queryParameters['order_public_id'] = parameters['orderPublicId'];
    }

    queryParameters['order_public_id'] = this.transformParameter(
      queryParameters['order_public_id'],
    );

    if (typeof parameters['orderPublicId'] === 'undefined') {
      throw new Error('Missing required parameter: orderPublicId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Upload survey answers
   * @method
   * @name API#uploadSurveyAnswers
   */
  uploadSurveyAnswers(
    parameters: {} = {},
    body: {
      order_public_id: string;

      answers?: {};
    },
  ): Promise<{}> {
    let path = '/survey/answers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Check if survey exists
   * @method
   * @name API#checkSurveyStatus
   */
  checkSurveyStatus(
    orderPublicId: string,
    parameters: {} = {},
  ): Promise<{
    status?: boolean;
  }> {
    let path = '/survey/status/{order_public_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{order_public_id}', orderPublicId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return survey stats
   * @method
   * @name API#surveyStats
   */
  surveyStats(
    parameters: {
      surveyType?: string;
      createdAfter?: string;
      createdBefore?: string;
      customerType?: string;
      containSkvelkoSet?: boolean;
    } = {},
  ): Promise<{
    countSent: number;

    countFilled: number;

    unsubscribed?: number;

    questions: Array<{
      id: number;

      question: string;

      answers: Array<string>;

      options: Array<{
        id: number;

        option: string;

        count: number;

        answers: Array<string>;
      }>;
    }>;
  }> {
    let path = '/survey/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['surveyType'] !== 'undefined') {
      queryParameters['survey_type'] = parameters['surveyType'];
    }

    queryParameters['survey_type'] = this.transformParameter(
      queryParameters['survey_type'],
    );

    if (typeof parameters['createdAfter'] !== 'undefined') {
      queryParameters['created_after'] = parameters['createdAfter'];
    }

    queryParameters['created_after'] = this.transformParameter(
      queryParameters['created_after'],
    );

    if (typeof parameters['createdBefore'] !== 'undefined') {
      queryParameters['created_before'] = parameters['createdBefore'];
    }

    queryParameters['created_before'] = this.transformParameter(
      queryParameters['created_before'],
    );

    if (typeof parameters['customerType'] !== 'undefined') {
      queryParameters['customer_type'] = parameters['customerType'];
    }

    queryParameters['customer_type'] = this.transformParameter(
      queryParameters['customer_type'],
    );

    if (typeof parameters['containSkvelkoSet'] !== 'undefined') {
      queryParameters['contain_skvelko_set'] = parameters['containSkvelkoSet'];
    }

    queryParameters['contain_skvelko_set'] = this.transformParameter(
      queryParameters['contain_skvelko_set'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return survey groups
   * @method
   * @name API#loadSurveyGroups
   */
  loadSurveyGroups(
    parameters: {
      createdAfter?: string;
      createdBefore?: string;
      hasDafferAnswer?: boolean;
      onlyPositiveAnswer?: boolean;
      onlyCustomAnswer?: boolean;
      withOrder?: boolean;
      withAnswers?: boolean;
      orderType?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<void> {
    let path = '/survey/groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['createdAfter'] !== 'undefined') {
      queryParameters['created_after'] = parameters['createdAfter'];
    }

    queryParameters['created_after'] = this.transformParameter(
      queryParameters['created_after'],
    );

    if (typeof parameters['createdBefore'] !== 'undefined') {
      queryParameters['created_before'] = parameters['createdBefore'];
    }

    queryParameters['created_before'] = this.transformParameter(
      queryParameters['created_before'],
    );

    if (typeof parameters['hasDafferAnswer'] !== 'undefined') {
      queryParameters['has_daffer_answer'] = parameters['hasDafferAnswer'];
    }

    queryParameters['has_daffer_answer'] = this.transformParameter(
      queryParameters['has_daffer_answer'],
    );

    if (typeof parameters['onlyPositiveAnswer'] !== 'undefined') {
      queryParameters['only_positive_answer'] =
        parameters['onlyPositiveAnswer'];
    }

    queryParameters['only_positive_answer'] = this.transformParameter(
      queryParameters['only_positive_answer'],
    );

    if (typeof parameters['onlyCustomAnswer'] !== 'undefined') {
      queryParameters['only_custom_answer'] = parameters['onlyCustomAnswer'];
    }

    queryParameters['only_custom_answer'] = this.transformParameter(
      queryParameters['only_custom_answer'],
    );

    if (typeof parameters['withOrder'] !== 'undefined') {
      queryParameters['with_order'] = parameters['withOrder'];
    }

    queryParameters['with_order'] = this.transformParameter(
      queryParameters['with_order'],
    );

    if (typeof parameters['withAnswers'] !== 'undefined') {
      queryParameters['with_answers'] = parameters['withAnswers'];
    }

    queryParameters['with_answers'] = this.transformParameter(
      queryParameters['with_answers'],
    );

    if (typeof parameters['orderType'] !== 'undefined') {
      queryParameters['order_type'] = parameters['orderType'];
    }

    queryParameters['order_type'] = this.transformParameter(
      queryParameters['order_type'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send email template
   * @method
   * @name API#sendTestEmailTemplate
   */
  sendTestEmailTemplate(
    parameters: {} = {},
    body: {
      template_id: string;

      email_to: string;
    },
  ): Promise<{}> {
    let path = '/survey/send-test-email-template';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Send automatic response
   * @method
   * @name API#sendAutomaticResponse
   */
  sendAutomaticResponse(
    parameters: {} = {},
    body: {
      survey_answer_group_id: number;
    },
  ): Promise<{}> {
    let path = '/survey/send-automatic-response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Send custom response
   * @method
   * @name API#sendCustomResponse
   */
  sendCustomResponse(
    parameters: {} = {},
    body: {
      survey_answer_group_id: number;

      from_name?: string;

      from_email?: string;

      bcc?: string;

      subject?: string;

      body_html?: string;
    },
  ): Promise<{}> {
    let path = '/survey/send-custom-response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Send test custom response
   * @method
   * @name API#sendTestCustomResponse
   */
  sendTestCustomResponse(
    parameters: {} = {},
    body: {
      to_email: string;

      from_name?: string;

      from_email?: string;

      bcc?: string;

      subject?: string;

      body_html?: string;
    },
  ): Promise<{}> {
    let path = '/survey/send-test-custom-response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns list of locks
   * @method
   * @name API#loadLocks
   */
  loadLocks(
    parameters: {} = {},
  ): Promise<{
    locks: Array<{
      type: string;

      active: boolean;

      created_at: string;
    }>;
  }> {
    let path = '/locks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove lock
   * @method
   * @name API#removeLock
   */
  removeLock(
    type: string,
    parameters: {} = {},
  ): Promise<{
    locks: Array<{
      type: string;

      active: boolean;

      created_at: string;
    }>;
  }> {
    let path = '/locks/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of system tasks
   * @method
   * @name API#loadSystemTasks
   */
  loadSystemTasks(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      id?: number;

      name?: string;

      script_path?: string;

      state?: string;

      created_at?: string;

      started_at?: string;

      finished_at?: string;

      error_message?: string;

      auto_finish?: number;

      auto_start?: number;
    }>;

    total?: number;

    offset?: number;

    limit?: number;
  }> {
    let path = '/system-tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new system task
   * @method
   * @name API#createSystemTask
   */
  createSystemTask(
    parameters: {} = {},
    body: {
      name: string;

      script_path: string;

      auto_finish?: boolean;

      auto_start?: boolean;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/system-tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about restricted cash on deliveries
   * @method
   * @name API#loadCods
   */
  loadCods(
    parameters: {
      email?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    restricted_cods?: Array<{
      id: number;

      email: string;

      created_at: string;
    }>;
  }> {
    let path = '/restricted-cods';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create restricted cash on deliveries
   * @method
   * @name API#createCod
   */
  createCod(
    parameters: {} = {},
    body: {
      email: string;
    },
  ): Promise<{
    id: number;

    email: string;

    created_at: string;
  }> {
    let path = '/restricted-cods';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove cash on delivery
   * @method
   * @name API#deleteCod
   */
  deleteCod(id: string, parameters: {} = {}): Promise<void> {
    let path = '/restricted-cods/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send generated contest
   * @method
   * @name API#sendGeneratedContest
   */
  sendGeneratedContest(
    parameters: {} = {},
    body: {
      name?: string;

      surname?: string;

      email?: string;

      custom_value?: string;

      se_id?: string;

      se_value?: string;
    },
  ): Promise<{}> {
    let path = '/generated-contest';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Search upsells
   * @method
   * @name API#searchUpsells
   */
  searchUpsells(
    parameters: {
      id?: number;
      productIds?: Array<number>;
      isActive?: boolean;
      type?: string;
      statsCreatedFrom?: string;
      statsCreatedTo?: string;
      assignedProductsStatsCreatedFrom?: string;
      assignedProductsStatsCreatedTo?: string;
      withFirstProduct?: boolean;
      withAssignedProducts?: boolean;
      withProducts?: boolean;
      withAssignedProductsStats?: boolean;
      withImpressionsCount?: boolean;
      withProductImpressionsCount?: boolean;
      withOrdersCount?: boolean;
      withProductOrdersCount?: boolean;
      withProductOrdersSum?: boolean;
      withProductOrdersPrice?: boolean;
      sort?: string;
      sortDir?: string;
      limit?: number;
      offset?: number;
      groupUniqId?: Array<string>;
      activeAssignedProducts?: boolean;
    } = {},
  ): Promise<{
    items?: Array<{
      id: string;

      is_active?: boolean;

      product_id?: string;

      product_code?: string;

      description?: string;

      assigned_products?: Array<{
        id: string;

        product_id?: number;

        product_code?: string;
      }>;

      product?: {
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        isnew_valid: string;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          unitprice_with_discount: number | null;

          unitprice_with_discount_without_vat?: number | null;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          group_prices?: Array<{
            skupina_id?: number;

            good_id?: number;

            unitprice?: number;

            oldprice?: number;
          }>;

          discount_pcn?: number;

          order_quantity_limit?: number | null;

          good_sort?: number;

          onstore?: Array<{
            goodId?: number;

            storeId?: string;

            storeQty?: number;
          }>;
        };

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        set_delete_warning?: boolean;

        is_user_favorite?: boolean;
      };
    }>;

    limit?: number;

    offset?: number;

    total?: number;
  }> {
    let path = '/upsells';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['productIds'] !== 'undefined') {
      queryParameters['product_ids'] = parameters['productIds'];
    }

    queryParameters['product_ids'] = this.transformParameter(
      queryParameters['product_ids'],
    );

    if (typeof parameters['isActive'] !== 'undefined') {
      queryParameters['is_active'] = parameters['isActive'];
    }

    queryParameters['is_active'] = this.transformParameter(
      queryParameters['is_active'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['statsCreatedFrom'] !== 'undefined') {
      queryParameters['stats_created_from'] = parameters['statsCreatedFrom'];
    }

    queryParameters['stats_created_from'] = this.transformParameter(
      queryParameters['stats_created_from'],
    );

    if (typeof parameters['statsCreatedTo'] !== 'undefined') {
      queryParameters['stats_created_to'] = parameters['statsCreatedTo'];
    }

    queryParameters['stats_created_to'] = this.transformParameter(
      queryParameters['stats_created_to'],
    );

    if (typeof parameters['assignedProductsStatsCreatedFrom'] !== 'undefined') {
      queryParameters['assigned_products_stats_created_from'] =
        parameters['assignedProductsStatsCreatedFrom'];
    }

    queryParameters[
      'assigned_products_stats_created_from'
    ] = this.transformParameter(
      queryParameters['assigned_products_stats_created_from'],
    );

    if (typeof parameters['assignedProductsStatsCreatedTo'] !== 'undefined') {
      queryParameters['assigned_products_stats_created_to'] =
        parameters['assignedProductsStatsCreatedTo'];
    }

    queryParameters[
      'assigned_products_stats_created_to'
    ] = this.transformParameter(
      queryParameters['assigned_products_stats_created_to'],
    );

    if (typeof parameters['withFirstProduct'] !== 'undefined') {
      queryParameters['with_first_product'] = parameters['withFirstProduct'];
    }

    queryParameters['with_first_product'] = this.transformParameter(
      queryParameters['with_first_product'],
    );

    if (typeof parameters['withAssignedProducts'] !== 'undefined') {
      queryParameters['with_assigned_products'] =
        parameters['withAssignedProducts'];
    }

    queryParameters['with_assigned_products'] = this.transformParameter(
      queryParameters['with_assigned_products'],
    );

    if (typeof parameters['withProducts'] !== 'undefined') {
      queryParameters['with_products'] = parameters['withProducts'];
    }

    queryParameters['with_products'] = this.transformParameter(
      queryParameters['with_products'],
    );

    if (typeof parameters['withAssignedProductsStats'] !== 'undefined') {
      queryParameters['with_assigned_products_stats'] =
        parameters['withAssignedProductsStats'];
    }

    queryParameters['with_assigned_products_stats'] = this.transformParameter(
      queryParameters['with_assigned_products_stats'],
    );

    if (typeof parameters['withImpressionsCount'] !== 'undefined') {
      queryParameters['with_impressions_count'] =
        parameters['withImpressionsCount'];
    }

    queryParameters['with_impressions_count'] = this.transformParameter(
      queryParameters['with_impressions_count'],
    );

    if (typeof parameters['withProductImpressionsCount'] !== 'undefined') {
      queryParameters['with_product_impressions_count'] =
        parameters['withProductImpressionsCount'];
    }

    queryParameters['with_product_impressions_count'] = this.transformParameter(
      queryParameters['with_product_impressions_count'],
    );

    if (typeof parameters['withOrdersCount'] !== 'undefined') {
      queryParameters['with_orders_count'] = parameters['withOrdersCount'];
    }

    queryParameters['with_orders_count'] = this.transformParameter(
      queryParameters['with_orders_count'],
    );

    if (typeof parameters['withProductOrdersCount'] !== 'undefined') {
      queryParameters['with_product_orders_count'] =
        parameters['withProductOrdersCount'];
    }

    queryParameters['with_product_orders_count'] = this.transformParameter(
      queryParameters['with_product_orders_count'],
    );

    if (typeof parameters['withProductOrdersSum'] !== 'undefined') {
      queryParameters['with_product_orders_sum'] =
        parameters['withProductOrdersSum'];
    }

    queryParameters['with_product_orders_sum'] = this.transformParameter(
      queryParameters['with_product_orders_sum'],
    );

    if (typeof parameters['withProductOrdersPrice'] !== 'undefined') {
      queryParameters['with_product_orders_price'] =
        parameters['withProductOrdersPrice'];
    }

    queryParameters['with_product_orders_price'] = this.transformParameter(
      queryParameters['with_product_orders_price'],
    );

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['groupUniqId'] !== 'undefined') {
      queryParameters['group_uniq_id'] = parameters['groupUniqId'];
    }

    queryParameters['group_uniq_id'] = this.transformParameter(
      queryParameters['group_uniq_id'],
    );

    if (typeof parameters['activeAssignedProducts'] !== 'undefined') {
      queryParameters['active_assigned_products'] =
        parameters['activeAssignedProducts'];
    }

    queryParameters['active_assigned_products'] = this.transformParameter(
      queryParameters['active_assigned_products'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Created upsell
   * @method
   * @name API#createUpsell
   */
  createUpsell(
    parameters: {} = {},
    body: {
      is_active?: boolean;

      is_group_active?: boolean;

      product_codes?: Array<string>;

      description?: string;

      assigned_product_codes?: Array<string>;

      group_uniq_id?: string;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/upsells';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Updated upsell
   * @method
   * @name API#updateUpsell
   */
  updateUpsell(
    id: number,
    parameters: {} = {},
    body: {
      product_code?: string;

      description?: string;

      is_active?: boolean;

      is_group_active?: boolean;

      assigned_product_codes?: Array<string>;

      group_uniq_id?: string;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/upsells/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete Upsell
   * @method
   * @name API#deleteUpsell
   */
  deleteUpsell(id: number, parameters: {} = {}): Promise<void> {
    let path = '/upsells/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Upsell impression
   * @method
   * @name API#impressionUpsell
   */
  impressionUpsell(
    id: number,
    parameters: {} = {},
    body: {
      product_ids?: Array<number>;

      type?: string;

      cart_id?: string;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/upsells/{id}/impression';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Impressions order stats
   * @method
   * @name API#loadImpressionsOrdersStats
   */
  loadImpressionsOrdersStats(
    parameters: {
      type?: string;
      onlyActive?: boolean;
      createdFrom?: string;
      createdTo?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      upsell_id?: number;

      upsell_type?: string;

      orders?: number;

      unfinished_carts?: number;

      impressions?: number;
    }>;
  }> {
    let path = '/upsells/impressions/order-stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    if (typeof parameters['createdFrom'] !== 'undefined') {
      queryParameters['created_from'] = parameters['createdFrom'];
    }

    queryParameters['created_from'] = this.transformParameter(
      queryParameters['created_from'],
    );

    if (typeof parameters['createdTo'] !== 'undefined') {
      queryParameters['created_to'] = parameters['createdTo'];
    }

    queryParameters['created_to'] = this.transformParameter(
      queryParameters['created_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load dupliated products
   * @method
   * @name API#loadDuplicatedProducts
   */
  loadDuplicatedProducts(
    parameters: {
      type?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      product_id?: number;

      count?: number;
    }>;
  }> {
    let path = '/upsells/duplicated-products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load assigned products stats
   * @method
   * @name API#loadAssignedProductsStats
   */
  loadAssignedProductsStats(
    parameters: {
      upsellType?: string;
      upsellId?: number;
      ordersCreatedFrom?: string;
      ordersCreatedTo?: string;
      withProducts?: boolean;
      sort?: string;
      sortDir?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<void> {
    let path = '/upsells/assigned-products/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['upsellType'] !== 'undefined') {
      queryParameters['upsell_type'] = parameters['upsellType'];
    }

    queryParameters['upsell_type'] = this.transformParameter(
      queryParameters['upsell_type'],
    );

    if (typeof parameters['upsellId'] !== 'undefined') {
      queryParameters['upsell_id'] = parameters['upsellId'];
    }

    queryParameters['upsell_id'] = this.transformParameter(
      queryParameters['upsell_id'],
    );

    if (typeof parameters['ordersCreatedFrom'] !== 'undefined') {
      queryParameters['orders_created_from'] = parameters['ordersCreatedFrom'];
    }

    queryParameters['orders_created_from'] = this.transformParameter(
      queryParameters['orders_created_from'],
    );

    if (typeof parameters['ordersCreatedTo'] !== 'undefined') {
      queryParameters['orders_created_to'] = parameters['ordersCreatedTo'];
    }

    queryParameters['orders_created_to'] = this.transformParameter(
      queryParameters['orders_created_to'],
    );

    if (typeof parameters['withProducts'] !== 'undefined') {
      queryParameters['with_products'] = parameters['withProducts'];
    }

    queryParameters['with_products'] = this.transformParameter(
      queryParameters['with_products'],
    );

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send email template
   * @method
   * @name API#sendUpsellNewsletterTestEmail
   */
  sendUpsellNewsletterTestEmail(
    parameters: {} = {},
    body: {
      email_to: string;
    },
  ): Promise<{}> {
    let path = '/upsells/newsletter/send-test-email';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load upsell newsletter stats
   * @method
   * @name API#loadUpsellNewsletterStats
   */
  loadUpsellNewsletterStats(
    parameters: {
      createdFrom?: string;
      createdTo?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<void> {
    let path = '/upsells/newsletter/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['createdFrom'] !== 'undefined') {
      queryParameters['created_from'] = parameters['createdFrom'];
    }

    queryParameters['created_from'] = this.transformParameter(
      queryParameters['created_from'],
    );

    if (typeof parameters['createdTo'] !== 'undefined') {
      queryParameters['created_to'] = parameters['createdTo'];
    }

    queryParameters['created_to'] = this.transformParameter(
      queryParameters['created_to'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send email template
   * @method
   * @name API#upsellNewsletterApply
   */
  upsellNewsletterApply(
    parameters: {} = {},
    body: {
      name: string;

      email: string;
    },
  ): Promise<{}> {
    let path = '/upsells/newsletter/apply';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Created log
   * @method
   * @name API#createCookieConsentLog
   */
  createCookieConsentLog(
    parameters: {} = {},
    body: {
      user_agreed?: boolean;

      layout_type?: number;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/cookie-consent-log';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Consents log stats
   * @method
   * @name API#cookieConsentLogStats
   */
  cookieConsentLogStats(
    parameters: {
      createdFrom?: string;
      createdTo?: string;
    } = {},
  ): Promise<{
    user_agreed?: number;

    user_not_agreed?: number;
  }> {
    let path = '/cookie-consent-log/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['createdFrom'] !== 'undefined') {
      queryParameters['created_from'] = parameters['createdFrom'];
    }

    queryParameters['created_from'] = this.transformParameter(
      queryParameters['created_from'],
    );

    if (typeof parameters['createdTo'] !== 'undefined') {
      queryParameters['created_to'] = parameters['createdTo'];
    }

    queryParameters['created_to'] = this.transformParameter(
      queryParameters['created_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load invisible gift
   * @method
   * @name API#loadInvisibleGift
   */
  loadInvisibleGift(id: number, parameters: {} = {}): Promise<void> {
    let path = '/invisible-gifts/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update invisible gift
   * @method
   * @name API#updateInvisibleGift
   */
  updateInvisibleGift(
    id: number,
    parameters: {} = {},
    body: {
      product_code?: string;

      is_active?: boolean;

      min_orders_count?: number;
    },
  ): Promise<{
    id?: number;
  }> {
    let path = '/invisible-gifts/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns products export for particular service according path parameter in appropriate format
   * @method
   * @name API#loadExport
   */
  loadExport(
    exportName: string,
    parameters: {
      lang?: string;
      ext?: string;
    } = {},
  ): Promise<{}> {
    let path = '/export/{export_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters['ext'] = 'xml';

    if (typeof parameters['ext'] !== 'undefined') {
      queryParameters['ext'] = parameters['ext'];
    }

    queryParameters['ext'] = this.transformParameter(queryParameters['ext']);

    path = path.replace('{export_name}', exportName.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns possible updates info
   * @method
   * @name API#updateInfo
   */
  updateInfo(adminVersion: string, parameters: {} = {}): Promise<{}> {
    let path = '/update-info/{admin_version}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{admin_version}', adminVersion.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return HTML sitemap for current language
   * @method
   * @name API#getHtmlSitemap
   */
  getHtmlSitemap(lang: string, parameters: {} = {}): Promise<{}> {
    let path = '/sitemap/{lang}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lang}', lang.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Shows information about custom urls
   * @method
   * @name API#getUrlMap
   */
  getUrlMap(
    parameters: {
      url: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    type: string;

    data: {
      brand_id?: number;

      product_id?: number;

      category_id?: number;

      category_group_id?: number;

      sitemap_id?: number;

      content_id?: number;

      new_url?: string;

      web_shop_id?: number;

      club_user_id?: number;

      uniqid?: string;
    };
  }> {
    let path = '/url-map';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads info about URL redirection. If NOT 404 -> than redirects URL
   * @method
   * @name API#checkRedirect
   */
  checkRedirect(parameters: {
    url: string;
  }): Promise<{
    createdDatetime?: string;

    id?: number;

    lang?: string;

    newUrl?: string;

    oldUrl?: string;

    redirectCode?: number;
  }> {
    let path = '/redirects';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sets that contain this product
   * @method
   * @name API#loadsProductsSets
   */
  loadsProductsSets(
    productId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    type: string;

    data: {
      brand_id?: number;

      product_id?: number;

      category_id?: number;

      category_group_id?: number;

      sitemap_id?: number;

      content_id?: number;

      new_url?: string;

      web_shop_id?: number;

      club_user_id?: number;

      uniqid?: string;
    };
  }> {
    let path = '/products/{product_id}/sets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load orders that are has status INPROGRESS and are not older than 14 days
   * @method
   * @name API#loadOrders
   */
  loadOrders(
    parameters: {} = {},
  ): Promise<
    Array<{
      order_id: number;

      weight?: number;

      carrier:
        | 'cpost'
        | 'ppl'
        | 'dpd'
        | 'geis'
        | 'gibbon'
        | 'gls'
        | 'ulozenka'
        | 'ups'
        | 'zasilkovna'
        | 'intime'
        | 'toptrans'
        | 'dhl-sk'
        | 'sk-post'
        | 'personal - osobní odběr';

      carrier_product: string;

      carrier_branch_id?: string;

      priority?: number;

      recipient_name: string;

      recipient_contact: string;

      recipient_street: string;

      recipient_city: string;

      recipient_zip: string;

      recipient_country_code: string;

      recipient_phone: string;

      recipient_email: string;

      ic?: string;

      dic?: string;

      note?: string;

      cod_amount: number;

      cod_currency: string;

      cod_vs: string;

      cod_card_payment: boolean;

      ins_amount?: number;

      ins_currency?: string;

      date_source: string;

      attachments?: Array<{
        name?: string;

        href: string;
      }>;

      products: Array<{
        item_id: number;

        sku: string;

        ean: string;

        name: string;

        qty: number;

        image_url: string;

        weight: number;

        location?: string;

        note?: string;

        unit: string;
      }>;
    }>
  > {
    let path = '/touchstore/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Touchstore via this endpoint notifies system about orders that was shipped recently.
   * @method
   * @name API#sendConsignments
   */
  sendConsignments(
    parameters: {} = {},
    body: {
      list_id: number;

      carrier: string;

      consignments: Array<{
        order_id: string;

        product: string;

        track_ids: Array<string>;

        email: string;

        phone: string;

        branch_id: string;

        ref: string;

        label: string;

        barcode: string;
      }>;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/touchstore/send';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Receive error notification about order from Touchstore
   * @method
   * @name API#noticeConsignmentError
   */
  noticeConsignmentError(
    parameters: {} = {},
    body: {
      order_id: string;

      error_id: string;

      error_name: string;

      error_note: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/touchstore/error';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads Touchstore consignment by its id
   * @method
   * @name API#loadTouchstoreConsignment
   */
  loadTouchstoreConsignment(
    publicOrderId: string,
    parameters: {} = {},
  ): Promise<{
    id: number;

    list_id: number;

    carrier: string;

    product: string;

    email: string;

    phone: string;

    branch_id: string;

    ref: string;

    label: string;

    barcode: string;

    track_ids: Array<string>;

    error_id: string;

    error_name: string;

    error_note: string;
  }> {
    let path = '/touchstore/touchstore-consignment/{public_order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_order_id}', publicOrderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#openapiJson
   */
  openapiJson(parameters: {} = {}): Promise<{}> {
    let path = '/openapi.json';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#test
   */
  test(parameters: {} = {}): Promise<{}> {
    let path = '/test';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Submits contact form
   * @method
   * @name API#contactForm
   */
  contactForm(
    parameters: {} = {},
    body: {
      email?: string;

      organization?: string;

      message?: string;

      phone?: string;

      name?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/contact-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits complanin form
   * @method
   * @name API#complainForm
   */
  complainForm(
    parameters: {} = {},
    form: {
      name?: string;

      address?: string;

      phone?: string;

      email?: string;

      invoice_number?: string;

      content?: string;

      file0?: {};

      file1?: {};
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/complain-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits not included school form
   * @method
   * @name API#notIncludedSchoolsFrom
   */
  notIncludedSchoolsFrom(
    parameters: {} = {},
    body: {
      town?: string;

      school?: string;

      usage?: string;

      concern?: string;

      email?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/not-included-schools-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Validates customer card
   * @method
   * @name API#customerCardVerify
   */
  customerCardVerify(
    parameters: {} = {},
    body: {
      ean?: boolean;

      email?: boolean;

      value?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/customer-card-verify';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Sends unfinished cart notification
   * @method
   * @name API#sendUnfinishedCartNotification
   */
  sendUnfinishedCartNotification(
    parameters: {} = {},
    body: {
      cartId?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/send-unfinished-cart-email';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Sends unfinished cart notification to dealer
   * @method
   * @name API#sendUnfinishedCartNotificationDealer
   */
  sendUnfinishedCartNotificationDealer(
    parameters: {} = {},
    body: {
      cartId?: string;

      email?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/send-unfinished-cart-email-dealer';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits customer card form
   * @method
   * @name API#customerCardForm
   */
  customerCardForm(
    parameters: {} = {},
    body: {
      name?: string;

      tel?: string;

      birth_date?: string;

      email?: string;

      street?: string;

      city?: string;

      zip?: string;

      min_age_accepted?: boolean;

      gdpr_card_accepted?: boolean;

      gdpr_weekend_emails_accepted?: boolean;

      gdpr_profil_accepted?: boolean;

      gdpr_accept?: boolean;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/customer-card-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits benefit card form
   * @method
   * @name API#benefitCardForm
   */
  benefitCardForm(
    parameters: {} = {},
    body: {
      company_name?: string;

      business_id?: string;

      tax_id?: string;

      vat_registration_number?: string;

      contact_person?: string;

      tel?: string;

      email?: string;

      note?: string;

      gdpr_card_accepted?: string;

      gdpr_benefits_emails_accepted?: string;

      gdpr_profil_accepted?: string;

      gdrp_accept?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/benefit-card-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits gift card form
   * @method
   * @name API#giftCardForm
   */
  giftCardForm(
    parameters: {} = {},
    body: {
      school_name?: string;

      email?: string;

      phone?: string;

      gift_cards_count?: string;

      gift_cards_price?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/gift-card-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits request customer price form
   * @method
   * @name API#requestCustomerPriceForm
   */
  requestCustomerPriceForm(
    parameters: {} = {},
    body: {
      product_name?: string;

      produkt_plu?: string;

      quantity?: string;

      period?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/request-product-price-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Sends lost customer card email
   * @method
   * @name API#lostCustomerCardEmail
   */
  lostCustomerCardEmail(
    parameters: {} = {},
    body: {
      name?: string;

      surname?: string;

      phone?: string;

      email?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/lost-customer-card-email';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Submits request skvelkobox form
   * @method
   * @name API#requestSkvelkoboxForm
   */
  requestSkvelkoboxForm(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/request-skvelkobox';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve skvelko set widget
   * @method
   * @name API#skvelko
   */
  skvelko(parameters: { ico: string }): Promise<{}> {
    let path = '/embed/skvelko';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['ico'] !== 'undefined') {
      queryParameters['ico'] = parameters['ico'];
    }

    queryParameters['ico'] = this.transformParameter(queryParameters['ico']);

    if (typeof parameters['ico'] === 'undefined') {
      throw new Error('Missing required parameter: ico');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Shows actual memory usage of API process
   * @method
   * @name API#heap
   */
  heap(
    parameters: {} = {},
  ): Promise<{
    description?: string;

    name?: string;

    payload?: {};

    statusCode?: number;

    userinfo?: string;
  }> {
    let path = '/status/heap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send report about error occurred at frontend
   * @method
   * @name API#reportError
   */
  reportError(
    parameters: {} = {},
    body: {
      report?: string;

      cartId?: string;
    },
  ): Promise<{}> {
    let path = '/report-error';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Trigger chemat import
   * @method
   * @name API#adminTriggerChematImport
   */
  adminTriggerChematImport(
    parameters: {} = {},
    body: {
      product_plu?: string;
    },
  ): Promise<void> {
    let path = '/trigger_export_to_chemat';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Trigger lamitec import
   * @method
   * @name API#adminTriggerLamitecImport
   */
  adminTriggerLamitecImport(
    parameters: {} = {},
    body: {
      product_plu?: string;
    },
  ): Promise<void> {
    let path = '/trigger_export_to_lamitec';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Send user to smartemailing
   * @method
   * @name API#adminSendCustomerToSmartemailing
   */
  adminSendCustomerToSmartemailing(
    parameters: {} = {},
    body: {
      user_id?: number;
    },
  ): Promise<void> {
    let path = '/customer/send-to-smartemailing';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Autocomplete email address
   * @method
   * @name API#emailAddressAutocomplete
   */
  emailAddressAutocomplete(parameters: {
    email: string;
  }): Promise<Array<string>> {
    let path = '/email-address-autocomplete';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get invalid emails
   * @method
   * @name API#getInvalidEmails
   */
  getInvalidEmails(parameters: {} = {}): Promise<Array<string>> {
    let path = '/invalid-emails';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if phone is in mobile format
   * @method
   * @name API#isPhoneMobile
   */
  isPhoneMobile(parameters: { phone: string }): Promise<boolean> {
    let path = '/is-phone-mobile';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['phone'] !== 'undefined') {
      queryParameters['phone'] = parameters['phone'];
    }

    queryParameters['phone'] = this.transformParameter(
      queryParameters['phone'],
    );

    if (typeof parameters['phone'] === 'undefined') {
      throw new Error('Missing required parameter: phone');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if email has customer card in Chemat
   * @method
   * @name API#hasEmailCustomerCard
   */
  hasEmailCustomerCard(parameters: { email: string }): Promise<boolean> {
    let path = '/has-email-customer-card';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new API();
export { API };
