import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../_helpers/Image/ImagePlaceholder';
import { CloseIcon } from '../_helpers/Icon/Close';
import { Button } from '../_helpers/form/Button';
import {
  formatPrice,
  getProductPrices,
  isCustomerWarehouse,
  isDiscountInCart,
  itemIsSkvelkoAndGoodPriceEnabled,
  showPriceWithVat,
} from '../../utilities';
import Availability from '../_helpers/product/Availability';
import { ProductPreviewTitle } from '../_helpers/product/ProductPreview';
import { BlockMarginStyles } from '../_helpers/Block/Block';
import API, { ThenArg } from '../../services/API';
import {
  productInCartDelete,
  productInCartCountUpdate,
  itemGroupCountUpdate,
  itemGroupInCartDelete,
} from '../../containers/Cart/actions';
import Alert from '../Alert/Alert';
import InputSpinner from '../_helpers/form/InputSpinner';
import { getProductInCartVariant } from '../../containers/Product/actions';
import Icon from '../_helpers/Icon/Icon';
import {
  changeAmountProductOutOfStock,
  setCurrentProductForPreview,
} from '../../containers/App/actions';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import AmountProductOutOfStockModal from '../_helpers/Modal/AmountProductOutOfStockModal';
import Variant from '../_helpers/product/Variant';
import { useRef, useState } from 'react';
import { Loader } from '../_helpers/Loader/Loader';

export const CartProductListWrapperStyles = css`
  ${({ theme }) => theme.media('md')} {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  ${BlockMarginStyles};
  ${CartProductListWrapperStyles};
  margin: ${rem(15)} 0;
`;

export const Header = styled.div`
  display: none;
  font-size: ${rem(14)};
  font-weight: 700;
  white-space: nowrap;
  padding: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    display: table-header-group;
  }
`;

export const HeaderRow = styled.div`
  display: table-row;
  padding-left: 0;
`;

export const HeaderCol = styled.div`
  display: table-cell;
  padding: 0 ${rem(20)} ${rem(10)};
`;

export const HeaderColRight = styled(HeaderCol)`
  text-align: right;
`;

export const HeaderColCenter = styled(HeaderCol)`
  text-align: center;
`;

export const HeaderColProduct = styled(HeaderCol)`
  width: 100%;
  padding-left: 0;
`;

export const List = styled.div`
  ${({ theme }) => theme.media('md')} {
    display: table-row-group;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  padding: ${rem(10)} 3% ${rem(10)} 3%;
  margin-bottom: ${rem(2)};
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  ${({ theme }) => theme.transition('box-shadow')};
  ${({ theme }) => theme.media('xs')} {
    justify-content: flex-end;
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    &:hover {
      box-shadow: ${({ theme }) => theme.boxShadow.default};
    }
  }
`;

export const GroupItem = styled(Item)`
  ${({ theme }) => theme.media('md')} {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const GroupRow = styled.div`
  display: table-cell;
  max-width: 1px;
  overflow: visible;
  padding-left: ${rem(95)};
  padding-bottom: ${rem(20)};
`;

export const GroupItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  padding: ${rem(10)};
  margin-bottom: ${rem(25)};
  position: relative;
  ${({ theme }) => theme.transition('box-shadow')};
  ${({ theme }) => theme.media('xs')} {
    justify-content: flex-end;
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  }
`;

const GroupItemsListWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.color.gray87};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media('md')} {
    width: ${(props) => props.width}px;
    flex-direction: row;
  }
`;

const GroupItemsList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const GroupItemsListItem = styled.div`
  display: flex;
  padding: ${rem(5)};
  align-items: center;
`;

const GroupItemsListItemLeft = styled(GroupItemsListItem)`
  border-right: 1px solid ${({ theme }) => theme.color.gray87};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray87};
  }
`;

const GroupItemsListItemRight = styled(GroupItemsListItem)`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray87};
  }
`;

const ColStyles = css`
  padding: 0;
  display: flex;

  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    vertical-align: middle;
    padding: ${rem(20)};
  }
`;

export const InfoCol = styled.div`
  ${ColStyles};
  display: flex;
  flex: 1 0 100%;
  min-width: 1%;
  min-height: ${rem(70)};
  padding-right: ${rem(30)};
  overflow: hidden;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
    margin-bottom: 0;
  }
`;

export const InfoColItemGroup = styled(InfoCol)`
  ${({ theme }) => theme.media('md')} {
    border-bottom-left-radius: 0;
    padding-bottom: 0;
  }
`;

export const ThumbWrapperStyles = css`
  margin-right: ${rem(15)};
  width: ${rem(70)};
  min-width: ${rem(70)};

  ${({ theme }) => theme.media('md')} {
    float: left;
    width: ${rem(60)};
    min-width: ${rem(60)};
  }
`;

export const ThumbWrapperPreview = styled.a`
  ${ThumbWrapperStyles};
  display: block;
  position: relative;
  cursor: pointer;
`;

export const ThumbWrapperGroupPreview = styled(ThumbWrapperPreview)`
  width: ${rem(40)} !important;
  min-width: ${rem(40)} !important;
`;

export const ThumbWrapper = styled.div`
  ${ThumbWrapperStyles};
`;

export const Thumb = styled(ImagePlaceholder)``;

export const Info = styled.div`
  padding-top: ${rem(0)};

  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(10)};
    overflow: hidden;
  }
`;

export const Name = styled(ProductPreviewTitle)`
  white-space: normal;
  font-weight: bold;
  font-size: ${rem(15)};

  ${({ theme }) => theme.media('md')} {
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    text-overflow: ellipsis;
    font-size: ${rem(14)};
  }

  &.big {
    font-weight: bold;
    font-size: ${rem(18)};
    white-space: normal;
    ${({ theme }) => theme.media('md')} {
      max-width: ${rem(350)};
    }
  }
`;

export const GroupName = styled(Name)`
  ${({ theme }) => theme.media('md')} {
    white-space: initial;
  }
`;

export const GroupMobileName = styled(GroupName)`
  font-weight: normal;
`;

export const GroupNamePrice = styled.div`
  display: inline;
  color: ${({ theme }) => theme.color.blue};
`;

export const NameInfo = styled(Name)`
  font-size: ${rem(14)};
  font-weight: normal;

  ${({ theme }) => theme.media('md')} {
    color: ${({ theme }) => theme.color.blue};
  }
`;

export const StyledAvailability = styled(Availability)`
  font-size: ${rem(12)};
`;

export const PriceCol = styled.div`
  ${ColStyles};
  text-align: right;
  white-space: nowrap;
`;

export const GroupPriceCol = styled(PriceCol)`
  ${({ theme }) => theme.media('md')} {
    padding-bottom: 0;
  }
`;

export const Price = styled.div`
  font-weight: normal;
`;

export const BoldPrice = styled.div`
  font-weight: bold;
`;

export const PriceWithVat = styled.div``;

export const AmountCol = styled.div`
  ${ColStyles};
  text-align: center;
`;

export const GroupAmountCol = styled(AmountCol)`
  ${({ theme }) => theme.media('md')} {
    padding-bottom: 0;
  }
`;

export const TotalPriceCol = styled.div`
  ${ColStyles};
  white-space: nowrap;
  text-align: right;
`;

export const GroupTotalPriceCol = styled(TotalPriceCol)`
  ${({ theme }) => theme.media('md')} {
    padding-bottom: 0;
  }
`;

export const TotalPrice = styled.div`
  font-weight: 700;
  font-size: ${rem(16)};

  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(15)};
  }
`;

export const TotalPriceWithVat = styled.div`
  white-space: nowrap;
`;

export const TotalPriceOld = styled.div`
  font-weight: 400;
  text-decoration: line-through;
`;

export const TotalPriceNew = styled.div`
  font-weight: 700;
`;

export const TotalPriceNewTitle = styled.div`
  color: ${({ theme }) => theme.color.primary};
  text-transform: uppercase;
  font-weight: 700;
`;

export const TotalPriceDiscountTitle = styled.div`
  color: ${({ theme }) => theme.color.success};
  text-transform: uppercase;
  font-weight: 700;
`;

const RemoveCol = styled.div`
  ${ColStyles};
  align-items: center;
  ${({ theme }) => theme.media('md')} {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
`;

const RemoveColGroup = styled(RemoveCol)`
  ${({ theme }) => theme.media('md')} {
    border-bottom-right-radius: 0;
    padding-bottom: 0;
  }
`;

const RemoveButton = styled(Button)`
  line-height: 1;
  padding: ${rem(6)};
  top: ${rem(10)};
  right: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    position: static;
  }
  border-radius: 50%;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.gray95};

  &:hover {
    background-color: ${({ theme }) => theme.color.gray87};
  }
`;

const GroupButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.gray87};
  padding: ${rem(10)};
  border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};

  border-bottom-right-radius: ${(props) => (props.isOpen ? rem(0) : rem(4))};
  border-bottom-left-radius: ${(props) => (props.isOpen ? rem(0) : rem(4))};
`;

const CartItemCloseIcon = styled(CloseIcon)`
  ${({ theme }) => theme.size(13, 2)};
  &:before,
  &:after {
    background: ${({ theme }) => theme.color.text};
    ${({ theme }) => theme.transition('background-color')};
  }
  ${RemoveButton}:hover & {
    &:before,
    &:after {
      background: ${({ theme }) => theme.color.black};
    }
  }
`;

const CartItemCloseText = styled.span`
  font-size: ${rem(9)};
  text-transform: uppercase;
  margin-top: ${rem(4)};
  font-weight: 600;
  display: block;
`;

const MobilePriceContainer = styled.div`
  display: block;
  padding: 0 15px;
`;

export const PreviewIcon = styled(Icon)`
  ${({ theme }) => theme.size(16)};
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const EmptyRow = styled.div`
  display: none;
  ${({ theme }) => theme.media('md')} {
    display: table-row;
  }
`;

export const EmptyTd = styled.div`
  display: table-cell;
  padding: ${rem(5)};
  max-width: 1px;
`;

export const AlertWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

const GroupItemThumbnailCol = styled.div``;

const GroupItemNameCol = styled.div``;

const GroupItemCountCol = styled.div`
  margin-left: auto;
  margin-right: ${rem(10)};
`;

const GroupoItemRemoveCol = styled.div``;

const MobilePriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MobilePriceRowWrapper = styled.div`
  width: 100%;
`;

const MobileGroupItemsWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

const MobileGroupItemRow = styled.div`
  background: ${(props) => (props.second ? '#f9f9f9' : 'white')};

  padding-bottom: ${rem(8)};
  padding-top: ${rem(8)};
  padding-right: 3%;
  padding-left: 3%;

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.color.gray95};
  }
`;

const MobileGroupItemRowBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${rem(8)};
`;

const MobileGroupItemRowBottomRight = styled.div`
  display: flex;
  align-items: center;
`;

const MobileGroupItemRowBottomRightSpinner = styled.div`
  margin-right: ${rem(8)};
`;

const GroupButtonContent = styled.div`
  display: flex;
`;
const GroupButtonText = styled.div`
  margin-left: ${rem(10)};
`;
const GroupButtonArrow = styled.img`
  width: ${rem(10)};
`;

interface CartProductListProps {
  items: ThenArg<typeof API.getCart>['items'];
  dispatch: any;
  user: any;
  amountProductOutOfStock?: any;
  cart: any;
  settings: any;
}

const CartProductList = (props: CartProductListProps) => {
  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  const [loaderAmountId, setLoaderAmountId] = useState(-1);
  const [loaderRemoveId, setLoaderRemoveId] = useState(-1);
  const [groupItemsOpened, setGroupItemsOpened] = useState([] as string[]);
  const [closeGroup, setClosedGroup] = useState(false);

  const { dispatch, user, amountProductOutOfStock, cart, settings } = props;
  let { items } = props;
  items = items ? items : [];

  const isWarehouse = isCustomerWarehouse(user);
  const isMobile = (window && window.innerWidth) <= 992;

  const showWithVat = showPriceWithVat(user);

  const cartRef = useRef(null);

  if (!isMobile && !closeGroup && groupItemsOpened?.length === 0) {
    const uniqGroupIds: any[] = [];
    for (const item of items) {
      if (!uniqGroupIds.includes(item?.item_group_id)) {
        if (!item?.item_group_id) uniqGroupIds.push(null);
        else uniqGroupIds.push(item.item_group_id);
      }
    }

    if (!uniqGroupIds.includes(null) && uniqGroupIds?.length === 1) {
      groupItemsOpened.push(uniqGroupIds[0]);
    }
  }

  const onRemoveButtonClick = async (
    productId,
    goodId,
    itemGroupId,
    itemUniqId,
  ) => {
    await dispatch(
      productInCartDelete(productId, goodId, [], itemGroupId, itemUniqId),
    );
  };

  const onItemGroupRemoveButtonClick = async (itemGroupId) => {
    await dispatch(itemGroupInCartDelete(itemGroupId));
  };

  const onItemCountChange = async (
    productId,
    goodId,
    actualCount,
    stockCount,
    skvelkoSetId,
    skvelkoClassId,
    itemGroupId,
    count,
    otherProductsCount,
  ) => {
    const updateCount = count - actualCount;
    if (updateCount > 0 && count + otherProductsCount > stockCount) {
      dispatch(
        changeAmountProductOutOfStock({
          stockCount,
        }),
      );
      return;
    }
    if (updateCount !== 0) {
      await dispatch(
        productInCartCountUpdate(
          productId,
          goodId,
          updateCount,
          skvelkoSetId,
          skvelkoClassId,
          itemGroupId,
        ),
      );
    }
  };

  const onItemGroupCountChange = async (itemGroupId, count) => {
    setLoaderAmountId(itemGroupId);
    await dispatch(itemGroupCountUpdate(itemGroupId, count));
    setLoaderAmountId(-1);
  };

  const onCloseAmountProductOutOfStockModal = () => {
    dispatch(changeAmountProductOutOfStock(null));
  };

  const showPreview = (product) => {
    props.dispatch(setCurrentProductForPreview(product));
  };

  const amountChange = async (
    productId,
    goodId,
    actualCount,
    stockCount,
    skvelkoSetId,
    skvelkoClassId,
    itemGroupId,
    counts,
    otherProductsCount,
  ) => {
    setLoaderAmountId(goodId);
    await onItemCountChange(
      productId,
      goodId,
      actualCount,
      stockCount,
      skvelkoSetId,
      skvelkoClassId,
      itemGroupId,
      counts,
      otherProductsCount,
    );
    setLoaderAmountId(-1);
  };

  const amountCol = (item, otherProductCount) => {
    if (item.is_sale_price && item.hide_counter) {
      return <AmountCol>{item.count} ks</AmountCol>;
    } else {
      return (
        <AmountCol>
          <Loader loading={false}>
            <InputSpinner
              value={item.count}
              onChange={(count) => {
                amountChange(
                  item.good.product_id,
                  item.good.good_id,
                  item.count,
                  item.good.on_stock_count,
                  item.skvelko_set ? item.skvelko_set.id : undefined,
                  item.skvelko_class ? item.skvelko_class.id : undefined,
                  item.item_group_id ? item.item_group_id : undefined,
                  count,
                  otherProductCount,
                );
              }}
              min={
                item.good.order_quantity_limit && !item.skvelko_set
                  ? item.good.order_quantity_limit
                  : 1
              }
              inCart={true}
            />
          </Loader>
        </AmountCol>
      );
    }
  };

  const priceCol = (item) => {
    const {
      unitPrice,
      price,
      oldPrice,
      retailPrice,
      discountPrice,
      priceWithVat,
      isSale,
      isDiscount,
      isCustomerPrice,
    } = getProductPrices(item, item?.good, cart, user);

    return (
      <PriceCol>
        {isMobile ? (
          <Price>
            {formatPrice(price, 'EUR')}
            {' / '}
            {__('ks')}
          </Price>
        ) : (
          <>
            {item.gift_price_p ? (
              <>
                <BoldPrice>{formatPrice(price, 'EUR')}</BoldPrice>
              </>
            ) : isCustomerPrice ? (
              <>
                <TotalPriceOld>{formatPrice(oldPrice, 'EUR')}</TotalPriceOld>
                <BoldPrice>{formatPrice(price, 'EUR')}</BoldPrice>
              </>
            ) : isSale ? (
              <>
                <TotalPriceOld>{formatPrice(oldPrice, 'EUR')}</TotalPriceOld>
                <BoldPrice>{formatPrice(price, 'EUR')}</BoldPrice>
              </>
            ) : isDiscount ? (
              <>
                <TotalPriceOld>{formatPrice(unitPrice, 'EUR')}</TotalPriceOld>
                <BoldPrice>{formatPrice(discountPrice, 'EUR')}</BoldPrice>
              </>
            ) : isWarehouse ? (
              <>
                <TotalPriceOld>{formatPrice(retailPrice, 'EUR')}</TotalPriceOld>
                <BoldPrice>{formatPrice(price, 'EUR')}</BoldPrice>
              </>
            ) : (
              <BoldPrice>{formatPrice(price, 'EUR')}</BoldPrice>
            )}
          </>
        )}
        {!showWithVat && !isMobile ? (
          <PriceWithVat>
            {__('Cena s DPH ')}
            {formatPrice(priceWithVat, 'EUR')}
          </PriceWithVat>
        ) : (
          ''
        )}
      </PriceCol>
    );
  };

  const removeClick = async (productId, goodId, itemGroupId, itemUniqId) => {
    setLoaderRemoveId(goodId);
    await onRemoveButtonClick(productId, goodId, itemGroupId, itemUniqId);
    setLoaderRemoveId(-1);
  };

  const itemGroupRemoveClick = async (itemGroupId) => {
    setLoaderRemoveId(itemGroupId);
    await onItemGroupRemoveButtonClick(itemGroupId);
    setLoaderRemoveId(-1);
  };

  const removeCol = (item) => {
    return (
      <RemoveCol>
        <Loader loading={false}>
          <RemoveButton
            onClick={removeClick.bind(
              null,
              item.good.product_id,
              item.good.good_id,
              item.item_group_id,
              item.uniq_id,
            )}
          >
            <CartItemCloseIcon />
            <CartItemCloseText>{__('Zmaž')}</CartItemCloseText>
          </RemoveButton>
        </Loader>
      </RemoveCol>
    );
  };

  const totalPriceCol = (item) => {
    const {
      price,
      oldPrice,
      retailPrice,
      priceWithVat,
      isSale,
      isCustomerPrice,
      discountPrice,
    } = calculateItemPrices(item);
    return (
      <TotalPriceCol>
        {item.gift_price_p ? (
          <>
            <TotalPriceNewTitle>
              {itemIsSkvelkoAndGoodPriceEnabled(item, settings) &&
              item.gift_price_main_product_count_taken === 0
                ? __('Dobrá cena')
                : __('Akcia')}
            </TotalPriceNewTitle>
            <TotalPriceNew>{formatPrice(price, 'EUR')}</TotalPriceNew>
          </>
        ) : isCustomerPrice ? (
          <div>
            {!isMobile ? (
              <TotalPriceDiscountTitle>{__('Zľava')}</TotalPriceDiscountTitle>
            ) : (
              ''
            )}
            <TotalPriceOld>{formatPrice(oldPrice, 'EUR')}</TotalPriceOld>
            <TotalPriceNew>{formatPrice(price, 'EUR')}</TotalPriceNew>
          </div>
        ) : isSale ? (
          <div>
            {!isMobile ? (
              <TotalPriceNewTitle>
                {itemIsSkvelkoAndGoodPriceEnabled(item, settings) &&
                item.gift_price_main_product_count_taken === 0
                  ? __('Dobrá cena')
                  : item.action_label_text
                  ? item.action_label_text
                  : 'Akcia'}
              </TotalPriceNewTitle>
            ) : (
              ''
            )}
            <TotalPriceOld>{formatPrice(oldPrice, 'EUR')}</TotalPriceOld>
            <TotalPriceNew>{formatPrice(price, 'EUR')}</TotalPriceNew>
          </div>
        ) : isDiscountInCart(cart, user) && discountPrice ? (
          <div>
            {!isMobile ? (
              <TotalPriceDiscountTitle>{__('Zľava')}</TotalPriceDiscountTitle>
            ) : (
              ''
            )}
            <TotalPriceOld>{formatPrice(price, 'EUR')}</TotalPriceOld>
            <TotalPriceNew>{formatPrice(discountPrice, 'EUR')}</TotalPriceNew>
          </div>
        ) : isWarehouse && retailPrice ? (
          <div>
            {!isMobile ? (
              <TotalPriceDiscountTitle>{__('Zľava')}</TotalPriceDiscountTitle>
            ) : (
              ''
            )}
            <TotalPriceOld>{formatPrice(retailPrice, 'EUR')}</TotalPriceOld>
            <TotalPriceNew>{formatPrice(price, 'EUR')}</TotalPriceNew>
          </div>
        ) : (
          <TotalPrice>{formatPrice(price, 'EUR')}</TotalPrice>
        )}
        {!showWithVat && !isMobile ? (
          <PriceWithVat>
            {__('Cena s DPH ')}
            {formatPrice(priceWithVat, 'EUR')}
          </PriceWithVat>
        ) : (
          ''
        )}
      </TotalPriceCol>
    );
  };

  const calculateItemPrices = (
    item,
    defaultCount: boolean = false,
  ): {
    price: number;
    priceWithVat: number;
    oldPrice: number;
    retailPrice: number;
    isSale: boolean;
    isDiscount: boolean;
    isCustomerPrice: boolean;
    isWarehouse: boolean;
    discountPrice: number;
  } => {
    const count = defaultCount ? item.default_count : item.count;

    return getProductPrices(item, item?.good, cart, user, count);
  };

  const calculateFinalPrice = (
    items,
  ): {
    finalUnitPrice: number;
    finalUnitPriceWithVat: number;
    finalOldUnitPrice: number;
  } => {
    let finalUnitPrice = 0;
    let finalUnitPriceWithVat = 0;
    let finalOldUnitPrice = 0;

    items.forEach((item) => {
      const {
        price,
        priceWithVat,
        discountPrice,
        retailPrice,
        isDiscount,
        isSale,
        isWarehouse,
      } = calculateItemPrices(item, true);

      finalOldUnitPrice += isWarehouse ? retailPrice : price;
      finalUnitPriceWithVat += priceWithVat;

      if (!isSale && isDiscount) {
        finalUnitPrice += discountPrice;
      } else {
        finalUnitPrice += price;
      }
    });

    return { finalUnitPrice, finalUnitPriceWithVat, finalOldUnitPrice };
  };

  for (const itemGroup of cart.item_groups || []) {
    itemGroup.items = items.filter(
      (item) => item?.item_group_id === itemGroup?.item_group_id,
    );

    const {
      finalUnitPrice,
      finalUnitPriceWithVat,
      finalOldUnitPrice,
    } = calculateFinalPrice(itemGroup?.items);

    itemGroup.finalUnitPrice = finalUnitPrice;
    itemGroup.finalUnitPriceWithVat = finalUnitPriceWithVat;
    itemGroup.finalOldUnitPrice = finalOldUnitPrice;

    itemGroup.finalPrice = itemGroup.finalUnitPrice * itemGroup.count;
    itemGroup.finalPriceWithVat =
      itemGroup.finalUnitPriceWithVat * itemGroup.count;
    itemGroup.finalOldPrice = itemGroup.finalOldUnitPrice * itemGroup.count;
  }

  const itemsWithoutGroups = items.filter((item) => !item.item_group_id);

  const onGroupButtonClick = (itemGroupId: string) => {
    if (!groupItemsOpened.includes(itemGroupId)) {
      setGroupItemsOpened([...groupItemsOpened, itemGroupId]);
    } else {
      setGroupItemsOpened(groupItemsOpened.filter((i) => i !== itemGroupId));
      setClosedGroup(true);
    }
  };

  const renderGroupItem = (itemGroup, item, i) => {
    const { price, discountPrice, isSale, isDiscount } = calculateItemPrices(
      item,
      true,
    );
    const finalPrice = !isSale && isDiscount ? discountPrice : price;

    if (isMobile) {
      return (
        <MobileGroupItemRow second={i % 2 === 1}>
          <div>
            <GroupMobileName>
              {item.product.name}{' '}
              <GroupNamePrice>
                {' '}
                ({formatPrice(finalPrice, 'EUR')}){' '}
              </GroupNamePrice>{' '}
            </GroupMobileName>
          </div>
          <MobileGroupItemRowBottom>
            <div>
              <ThumbWrapperGroupPreview
                onClick={showPreview.bind(null, item.product)}
              >
                <Thumb
                  src={item.product.picture ? item.product.picture : null}
                  placeholder={placeholder}
                  retinaPlaceholder={placeholder2x}
                  w={100}
                  h={100}
                />
                <PreviewIcon icon="eye" />
              </ThumbWrapperGroupPreview>
            </div>
            <MobileGroupItemRowBottomRight>
              <MobileGroupItemRowBottomRightSpinner>
                <Loader loading={false}>
                  <InputSpinner
                    value={item.default_count}
                    onChange={(count) => {
                      amountChange(
                        item.good.product_id,
                        item.good.good_id,
                        item.default_count,
                        10000, //disable count check //item.good.on_stock_count,
                        item.skvelko_set ? item.skvelko_set.id : undefined,
                        item.skvelko_class ? item.skvelko_class.id : undefined,
                        item.item_group_id ? item.item_group_id : undefined,
                        count,
                        0,
                      );
                    }}
                    min={1}
                    inCart={true}
                    padding={rem(5)}
                  />
                </Loader>
              </MobileGroupItemRowBottomRightSpinner>
              <Loader loading={false}>
                <RemoveButton
                  onClick={() =>
                    removeClick(
                      item.good.product_id,
                      item.good.good_id,
                      item.item_group_id,
                      item.uniq_id,
                    )
                  }
                >
                  <CartItemCloseIcon />
                </RemoveButton>
              </Loader>
            </MobileGroupItemRowBottomRight>
          </MobileGroupItemRowBottom>
        </MobileGroupItemRow>
      );
    } else {
      return (
        <React.Fragment key={i}>
          <GroupItemThumbnailCol>
            <ThumbWrapperGroupPreview
              onClick={showPreview.bind(null, item.product)}
            >
              <Thumb
                src={item.product.picture ? item.product.picture : null}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
                w={100}
                h={100}
              />
              <PreviewIcon icon="eye" />
            </ThumbWrapperGroupPreview>
          </GroupItemThumbnailCol>
          <GroupItemNameCol>
            <GroupName>
              {item.product.name}{' '}
              <GroupNamePrice>
                {' '}
                ({formatPrice(finalPrice, 'EUR')}){' '}
              </GroupNamePrice>{' '}
            </GroupName>
          </GroupItemNameCol>
          <GroupItemCountCol>
            <Loader loading={false}>
              <InputSpinner
                value={item.default_count}
                onChange={(count) => {
                  amountChange(
                    item.good.product_id,
                    item.good.good_id,
                    item.default_count,
                    10000, //disable count check //item.good.on_stock_count,
                    item.skvelko_set ? item.skvelko_set.id : undefined,
                    item.skvelko_class ? item.skvelko_class.id : undefined,
                    item.item_group_id ? item.item_group_id : undefined,
                    count,
                    0,
                  );
                }}
                min={1}
                inCart={true}
                padding={rem(5)}
              />
            </Loader>
          </GroupItemCountCol>
          <GroupoItemRemoveCol>
            <Loader loading={false}>
              <RemoveButton
                onClick={() =>
                  removeClick(
                    item.good.product_id,
                    item.good.good_id,
                    item.item_group_id,
                    item.uniq_id,
                  )
                }
              >
                <CartItemCloseIcon />
              </RemoveButton>
            </Loader>
          </GroupoItemRemoveCol>
        </React.Fragment>
      );
    }
  };

  const renderGroupItems = (itemGroup) => {
    if (isMobile) {
      return (
        <>
          <GroupButton
            isOpen={groupItemsOpened.includes(itemGroup?.item_group_id)}
            onClick={() => onGroupButtonClick(itemGroup?.item_group_id)}
          >
            {groupItemsOpened.includes(itemGroup?.item_group_id) ? (
              <GroupButtonContent>
                <GroupButtonArrow src="/images/arrow_top.svg" />{' '}
                <GroupButtonText>{__('Skryť obsah zoznamu')}</GroupButtonText>{' '}
              </GroupButtonContent>
            ) : (
              <GroupButtonContent>
                <GroupButtonArrow src="/images/dropdown-arrow.svg" />{' '}
                <GroupButtonText>
                  {' '}
                  {__('Zobraziť / upraviť obsah zoznamu')}{' '}
                </GroupButtonText>{' '}
              </GroupButtonContent>
            )}
          </GroupButton>
          {groupItemsOpened.includes(itemGroup?.item_group_id) && (
            <GroupItemsListWrapper>
              {itemGroup?.items?.map((item, i) => {
                return renderGroupItem(itemGroup, item, i);
              })}
            </GroupItemsListWrapper>
          )}
        </>
      );
    } else {
      return (
        <>
          <GroupButton
            isOpen={groupItemsOpened.includes(itemGroup?.item_group_id)}
            onClick={() => onGroupButtonClick(itemGroup?.item_group_id)}
          >
            {' '}
            {groupItemsOpened.includes(itemGroup?.item_group_id) ? (
              <GroupButtonContent>
                <GroupButtonArrow src="/images/arrow_top.svg" />{' '}
                <GroupButtonText>{__('Skryť obsah zoznamu')}</GroupButtonText>{' '}
              </GroupButtonContent>
            ) : (
              <GroupButtonContent>
                <GroupButtonArrow src="/images/dropdown-arrow.svg" />{' '}
                <GroupButtonText>
                  {' '}
                  {__('Zobraziť / upraviť obsah zoznamu')}{' '}
                </GroupButtonText>{' '}
              </GroupButtonContent>
            )}
          </GroupButton>
          {groupItemsOpened.includes(itemGroup.item_group_id) && (
            <GroupItemsListWrapper
              width={
                cartRef.current ? (cartRef.current as any).offsetWidth - 117 : 0
              }
            >
              <GroupItemsList>
                {itemGroup?.items?.map((item, i) => {
                  if (i % 2 === 0) {
                    return (
                      <GroupItemsListItemLeft>
                        {' '}
                        {renderGroupItem(itemGroup, item, i)}{' '}
                      </GroupItemsListItemLeft>
                    );
                  } else {
                    return '';
                  }
                })}
              </GroupItemsList>
              <GroupItemsList>
                {itemGroup?.items?.map((item, i) => {
                  if (i % 2 !== 0) {
                    return (
                      <GroupItemsListItemRight>
                        {' '}
                        {renderGroupItem(itemGroup, item, i)}{' '}
                      </GroupItemsListItemRight>
                    );
                  } else {
                    return '';
                  }
                })}
                {itemGroup?.items?.length % 2 !== 0 && (
                  <GroupItemsListItemRight> </GroupItemsListItemRight>
                )}
              </GroupItemsList>
            </GroupItemsListWrapper>
          )}
        </>
      );
    }
  };

  return (
    <Loader loading={loaderAmountId > -1 || loaderRemoveId > -1}>
      {items.length ? (
        <Wrapper ref={cartRef}>
          <Header>
            <HeaderRow>
              <HeaderColProduct>{__('Produkt')}</HeaderColProduct>
              <HeaderColRight>{__('Cena za kus')}</HeaderColRight>
              <HeaderColCenter>{__('Množstvo')}</HeaderColCenter>
              <HeaderColRight>{__('Cena spolu')}</HeaderColRight>
              <HeaderCol />
            </HeaderRow>
          </Header>
          <List>
            {(cart.item_groups || []).map((itemGroup) => {
              return (
                <React.Fragment key={itemGroup?.item_group_id}>
                  <GroupItem>
                    <InfoColItemGroup>
                      <ThumbWrapperPreview>
                        <Thumb
                          src={`images/uni_set_0.jpg`}
                          placeholder={placeholder}
                          retinaPlaceholder={placeholder2x}
                          w={100}
                          h={100}
                        />
                      </ThumbWrapperPreview>
                      <Info>
                        <GroupMobileName>
                          <b>{__('Zoznam pomôcok Skvelko')}</b>:{' '}
                          {itemGroup?.primary_name}
                        </GroupMobileName>
                        <GroupMobileName>
                          {' '}
                          {__('Zoznam pre')} {itemGroup?.secondary_name}{' '}
                        </GroupMobileName>
                      </Info>
                    </InfoColItemGroup>

                    {isMobile ? (
                      <>
                        <MobilePriceRowWrapper>
                          <MobileGroupItemsWrapper>
                            {renderGroupItems(itemGroup)}
                          </MobileGroupItemsWrapper>
                          <MobilePriceWrapper>
                            <AmountCol>
                              <Loader loading={false}>
                                <InputSpinner
                                  value={itemGroup?.count}
                                  onChange={(count) => {
                                    onItemGroupCountChange(
                                      itemGroup?.item_group_id,
                                      count,
                                    );
                                  }}
                                  min={1}
                                  inCart={true}
                                />
                              </Loader>
                            </AmountCol>
                            <MobilePriceContainer>
                              <PriceCol>
                                <Price>
                                  {formatPrice(itemGroup.finalUnitPrice, 'EUR')}{' '}
                                  / ks
                                </Price>
                              </PriceCol>
                              {itemGroup?.finalOldPrice !==
                                itemGroup?.finalPrice && (
                                <PriceCol>
                                  <TotalPriceOld>
                                    {formatPrice(
                                      itemGroup?.finalOldPrice,
                                      'EUR',
                                    )}{' '}
                                  </TotalPriceOld>
                                </PriceCol>
                              )}
                              <TotalPriceCol>
                                <BoldPrice>
                                  {formatPrice(itemGroup?.finalPrice, 'EUR')}
                                </BoldPrice>
                              </TotalPriceCol>
                            </MobilePriceContainer>
                            <RemoveColGroup>
                              <Loader
                                loading={
                                  loaderRemoveId === itemGroup?.item_group_id
                                }
                              >
                                <RemoveButton
                                  onClick={() =>
                                    itemGroupRemoveClick(
                                      itemGroup?.item_group_id,
                                    )
                                  }
                                >
                                  <CartItemCloseIcon />
                                  <CartItemCloseText>
                                    {__('Zmaž')}
                                  </CartItemCloseText>
                                </RemoveButton>
                              </Loader>
                            </RemoveColGroup>
                          </MobilePriceWrapper>
                        </MobilePriceRowWrapper>
                      </>
                    ) : (
                      ''
                    )}

                    {!isMobile ? (
                      <>
                        <GroupPriceCol>
                          {itemGroup?.finalOldPrice !==
                            itemGroup?.finalPrice && (
                            <>
                              <TotalPriceOld>
                                {formatPrice(
                                  itemGroup?.finalOldUnitPrice,
                                  'EUR',
                                )}
                              </TotalPriceOld>
                            </>
                          )}
                          <BoldPrice>
                            {formatPrice(itemGroup?.finalUnitPrice, 'EUR')}
                          </BoldPrice>
                          {itemGroup?.finalUnitPriceWithVat && !showWithVat ? (
                            <PriceWithVat>
                              {' '}
                              {__('Cena s DPH')}{' '}
                              {formatPrice(
                                itemGroup.finalUnitPriceWithVat,
                                'EUR',
                              )}{' '}
                            </PriceWithVat>
                          ) : (
                            ''
                          )}
                        </GroupPriceCol>
                        <GroupAmountCol>
                          <Loader loading={false}>
                            <InputSpinner
                              value={itemGroup?.count}
                              onChange={(count) => {
                                onItemGroupCountChange(
                                  itemGroup?.item_group_id,
                                  count,
                                );
                              }}
                              min={1}
                              inCart={true}
                            />
                          </Loader>
                        </GroupAmountCol>
                        <GroupTotalPriceCol>
                          {itemGroup?.finalOldPrice !==
                            itemGroup?.finalPrice && (
                            <>
                              <TotalPriceDiscountTitle>
                                {__('Zľava')}
                              </TotalPriceDiscountTitle>
                              <TotalPriceOld>
                                {formatPrice(itemGroup?.finalOldPrice, 'EUR')}
                              </TotalPriceOld>
                            </>
                          )}
                          <BoldPrice>
                            {formatPrice(itemGroup?.finalPrice, 'EUR')}
                          </BoldPrice>
                          {itemGroup.finalPriceWithVat && !showWithVat ? (
                            <PriceWithVat>
                              {__('Cena s DPH')}{' '}
                              {formatPrice(itemGroup.finalPriceWithVat, 'EUR')}{' '}
                            </PriceWithVat>
                          ) : (
                            ''
                          )}
                        </GroupTotalPriceCol>
                        <RemoveColGroup>
                          <Loader
                            loading={
                              loaderRemoveId === itemGroup?.item_group_id
                            }
                          >
                            <RemoveButton
                              onClick={() =>
                                itemGroupRemoveClick(itemGroup?.item_group_id)
                              }
                            >
                              <CartItemCloseIcon />
                              <CartItemCloseText>
                                {__('Zmaž')}
                              </CartItemCloseText>
                            </RemoveButton>
                          </Loader>
                        </RemoveColGroup>
                      </>
                    ) : (
                      ''
                    )}
                  </GroupItem>

                  {!isMobile ? (
                    <>
                      <GroupItems>
                        <GroupRow>{renderGroupItems(itemGroup)}</GroupRow>
                        <EmptyTd />
                        <EmptyTd />
                        <EmptyTd />
                        <EmptyTd />
                      </GroupItems>
                      <EmptyRow>
                        <EmptyTd />
                        <EmptyTd />
                        <EmptyTd />
                        <EmptyTd />
                        <EmptyTd />
                      </EmptyRow>
                    </>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              );
            })}

            {itemsWithoutGroups.map((item: any, i) => {
              const { isSale, isCustomerPrice } = calculateItemPrices(item);
              const variant = getProductInCartVariant(item);

              const otherProducts = itemsWithoutGroups.filter(
                (itemWithoutGroup: any) => {
                  return (
                    itemWithoutGroup.product.product_id ===
                      item.product.product_id &&
                    itemWithoutGroup.idx !== item.idx
                  );
                },
              );

              const otherProductCount = otherProducts.reduce(
                (a, b) => +a + +b.count,
                0,
              );

              return (
                <React.Fragment key={i}>
                  <Item>
                    <InfoCol>
                      <ThumbWrapperPreview
                        onClick={showPreview.bind(null, item.product)}
                      >
                        <Thumb
                          src={
                            item.product.picture ? item.product.picture : null
                          }
                          placeholder={placeholder}
                          retinaPlaceholder={placeholder2x}
                          w={100}
                          h={100}
                        />
                        <PreviewIcon icon="eye" />
                      </ThumbWrapperPreview>

                      <Info>
                        <Name>{item.product.name}</Name>
                        {variant ? (
                          <Name>
                            <Variant variant={variant} />
                          </Name>
                        ) : (
                          ''
                        )}
                        {isSale && !isCustomerPrice ? (
                          itemIsSkvelkoAndGoodPriceEnabled(item, settings) ? (
                            <NameInfo>
                              {__('Na tovar označený')}{' '}
                              <i>{__('Dobrá cena')}</i>{' '}
                              {__('nie je možné uplatniť ďalšiu zľavu')}
                            </NameInfo>
                          ) : (
                            <NameInfo>
                              {__(
                                'Na akciový tovar nie je možné uplatniť ďaľšiu zľavu.',
                              )}
                            </NameInfo>
                          )
                        ) : (
                          ''
                        )}
                      </Info>
                    </InfoCol>
                    {isMobile ? (
                      <>
                        {amountCol(item, otherProductCount)}
                        <MobilePriceContainer>
                          {priceCol(item)}
                          {totalPriceCol(item)}
                        </MobilePriceContainer>
                        {removeCol(item)}
                      </>
                    ) : (
                      ''
                    )}

                    {!isMobile ? (
                      <>
                        {priceCol(item)}
                        {amountCol(item, otherProductCount)}
                        {totalPriceCol(item)}
                        {removeCol(item)}
                      </>
                    ) : (
                      ''
                    )}
                  </Item>
                  <EmptyRow>
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                  </EmptyRow>
                </React.Fragment>
              );
            })}
          </List>
          {amountProductOutOfStock && (
            <AmountProductOutOfStockModal
              onCloseButtonClick={onCloseAmountProductOutOfStockModal}
            />
          )}
        </Wrapper>
      ) : (
        <AlertWrapper>
          <Alert> {__('Váš košik je prázdny')} </Alert>
        </AlertWrapper>
      )}
    </Loader>
  );
};

const mapStateToProps = (state) => {
  return {
    amountProductOutOfStock: state.general.amountProductOutOfStock,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartProductList' })(CartProductList),
);
