import * as React from 'react';
import { getImagePath } from '../../../utilities/product';

interface ImageProps {
  src: string | null;
  placeholder: string;
  retinaPlaceholder?: string;
  className?: string;
  w?: number;
  h?: number;
  width?: number;
  height?: number;
  notLazyloading?: boolean;
  notResize?: boolean;
  notWebp?: boolean;
  onClick?: () => void;
  ignoreRequestHeight?: boolean;
  alt?: string;
  fetchPriority?: string;
  aspectRatio?: string;
  sizes?: { minWidth: number; imageWidth: number; imageHeight: number }[];
  useRetina?: boolean;
  resizeType?: string;
}

class ImagePlaceholder extends React.PureComponent<ImageProps> {
  public renderImage() {
    const {
      src,
      placeholder,
      retinaPlaceholder,
      className,
      w,
      h,
      width,
      height,
      notResize,
      notWebp,
      onClick,
      ignoreRequestHeight,
      alt,
      notLazyloading,
      fetchPriority,
      aspectRatio,
      sizes,
      useRetina,
      resizeType,
    } = this.props;
    const srcSize = { width: w ? w : 100, height: h ? h : 100 };
    const srcSetSize = { width: w ? w * 2 : 100, height: h ? h * 2 : 100 };

    const srcSetArray: (string | undefined)[] = [];

    if (useRetina) {
      srcSetArray.push(
        src &&
          src !== 'undefined.png' &&
          src.constructor !== Object &&
          retinaPlaceholder
          ? getImagePath(
              src,
              srcSetSize,
              !notResize,
              notWebp,
              false,
              ignoreRequestHeight,
              resizeType,
            ) + ' 2x'
          : retinaPlaceholder,
      );
    }

    if (sizes) {
      for (const size of sizes) {
        srcSetArray.push(
          `${getImagePath(
            src,
            { width: size.imageWidth, height: size.imageHeight },
            !notResize,
            notWebp,
            false,
            ignoreRequestHeight,
            resizeType,
          )} ${size.minWidth}w`,
        );
      }
    }

    return React.createElement('img', {
      width: width,
      height: height,
      src:
        src && src !== 'undefined.png' && src.constructor !== Object
          ? getImagePath(
              src,
              srcSize,
              !notResize,
              notWebp,
              false,
              ignoreRequestHeight,
              resizeType,
            )
          : placeholder,
      srcSet: srcSetArray.filter((o) => o).join(', '),
      sizes: sizes
        ?.map((size) => `(min-width: ${size.minWidth}px) ${size.minWidth}px`)
        .join(', '),
      className: className,
      loading: notLazyloading ? 'eager' : 'lazy',
      onClick: onClick,
      alt: alt ? alt : '',
      fetchPriority: fetchPriority,
      style: {
        aspectRatio: aspectRatio,
      },
    });
  }

  public render() {
    return this.renderImage();
  }
}

export default ImagePlaceholder;
