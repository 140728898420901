import { ButtonStyled } from '../_helpers/form/Button';
import { __ } from 'react-i18n';
import * as React from 'react';
import {
  RegistrationAlert,
  RegistrationErrorPopup,
  RegistrationFormCol,
  RegistrationFormRow,
  RegistrationHeading,
  RegistrationInput,
  RegistrationLabel,
  LoginInputPasswordWrapper,
  PasswordIcon,
  LoginInputPassword,
} from './styles';
import { Field } from 'react-final-form';
import {
  composeValidators,
  email,
  emailExist,
  ico,
  loginExist,
  password,
  passwordMatch,
  phone,
  phoneMobile,
  required,
} from '../../utilities/validators';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import {
  openBusinessConditionsModal,
  openGdprModal,
} from '../../containers/App/actions';
import API from '../../services/API';
import { cookieSaveUserCookie } from '../../utilities/cookie';
import { push } from 'react-router-redux';

export interface RegistrationProps {
  dispatch: any;
}

export const renderRegistrationSubmitButton = () => (
  <ButtonStyled className="primary" type="submit">
    {__('Odoslať žiadosť o registráciu')}
  </ButtonStyled>
);

export const renderRegistrationTermsCheckbox = (dispatch) => (
  <RegistrationFormRow>
    <Field name="terms_accept" validate={required} type="checkbox">
      {({ input, meta }) => (
        <RegistrationFormCol>
          <CheckboxStyled {...input} value="1" id="terms_accept">
            <span>
              {__('Súhlasím s')}{' '}
              <a onClick={openBusinessConditionsModal.bind(null, dispatch)}>
                {__('obchodnými podmienkami')}
              </a>{' '}
              {__('a')}{' '}
              <a onClick={openGdprModal.bind(null, dispatch)}>
                {__('spracovaním osobných údajov')}
              </a>
              .
            </span>
          </CheckboxStyled>
          {meta.error && meta.touched && (
            <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
          )}
        </RegistrationFormCol>
      )}
    </Field>
  </RegistrationFormRow>
);

export const renderRegistrationPasswordInputs = (
  handlePasswordIcon: any,
  showPassword: boolean,
) => (
  <RegistrationFormRow>
    <Field
      name="password"
      validate={composeValidators(required, password)}
      validateFields={['password_confirm']}
    >
      {({ input, meta }) => (
        <RegistrationFormCol>
          <RegistrationLabel htmlFor="password">
            {__('Zvoľte si heslo')}:
          </RegistrationLabel>
          <LoginInputPasswordWrapper>
            <LoginInputPassword
              {...input}
              type={showPassword ? 'text' : 'password'}
              name={'password'}
              placeholder={__('')}
              className={'lg'}
            />
            <PasswordIcon icon={'password'} onClick={handlePasswordIcon} />
          </LoginInputPasswordWrapper>
          {meta.error && meta.touched && (
            <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
          )}
        </RegistrationFormCol>
      )}
    </Field>
    <Field
      name="password_confirm"
      validate={composeValidators(required, passwordMatch)}
      validateFields={['password']}
    >
      {({ input, meta }) => (
        <RegistrationFormCol>
          <RegistrationLabel htmlFor="password_confirm">
            {__('Potvrďte heslo')}:
          </RegistrationLabel>
          <LoginInputPasswordWrapper>
            <LoginInputPassword
              {...input}
              type={showPassword ? 'text' : 'password'}
              name={'password'}
              placeholder={__('')}
              className={'lg'}
            />
            <PasswordIcon icon={'password'} onClick={handlePasswordIcon} />
          </LoginInputPasswordWrapper>
          {meta.error && meta.touched && (
            <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
          )}
        </RegistrationFormCol>
      )}
    </Field>
  </RegistrationFormRow>
);

export const renderRegistrationIcoInput = () => (
  <Field name="ico" validate={composeValidators(required, ico, loginExist)}>
    {({ input, meta }) => (
      <RegistrationFormCol>
        <RegistrationLabel htmlFor="ico">
          {__('IČO (slúži ako prihlasovacie meno)')}:
        </RegistrationLabel>
        <RegistrationInput {...input} type="text" />
        {meta.error && meta.touched && (
          <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
        )}
      </RegistrationFormCol>
    )}
  </Field>
);

export const referRegistrationContactPersonSection = () => (
  <>
    <RegistrationHeading>{__('Kontaktná osoba')}:</RegistrationHeading>
    <RegistrationFormRow>
      <Field name="contact_name" validate={required}>
        {({ input, meta }) => (
          <RegistrationFormCol>
            <RegistrationLabel htmlFor="contact_name">
              {__('Meno')}:
            </RegistrationLabel>
            <RegistrationInput {...input} type="text" />
            {meta.error && meta.touched && (
              <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
            )}
          </RegistrationFormCol>
        )}
      </Field>
      <Field
        name="contact_email"
        validate={composeValidators(required, email, emailExist)}
      >
        {({ input, meta }) => (
          <RegistrationFormCol>
            <RegistrationLabel htmlFor="contact_email">
              {__('Email')}:
            </RegistrationLabel>
            <RegistrationInput {...input} type="email" />
            {meta.error && meta.touched && (
              <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
            )}
          </RegistrationFormCol>
        )}
      </Field>
    </RegistrationFormRow>
    <RegistrationFormRow>
      <Field
        name="contact_phone"
        validate={composeValidators(required, phone, phoneMobile)}
      >
        {({ input, meta }) => (
          <RegistrationFormCol>
            <RegistrationLabel htmlFor="contact_phone">
              {__('Telefón')}:
            </RegistrationLabel>
            <RegistrationInput {...input} type="tel" />
            {meta.error && meta.touched && (
              <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
            )}
          </RegistrationFormCol>
        )}
      </Field>
    </RegistrationFormRow>
  </>
);

export const renderRegistrationAlert = (error) =>
  error && (
    <RegistrationAlert type="error">
      <span
        dangerouslySetInnerHTML={{
          __html: error,
        }}
      />
    </RegistrationAlert>
  );

export const onRegistrationSubmit = async (
  dispatch: any,
  setSubmitting: (boolean) => void,
  setSubmitError: (any) => void,
  body: any,
  finishedUrl: string,
) => {
  try {
    setSubmitting(true);

    const registerUserResponse: any = await API.registerUser(
      { withAccessToken: true },
      body,
    );

    if (registerUserResponse?.accessToken) {
      cookieSaveUserCookie(registerUserResponse.accessToken);
    }

    dispatch(push(finishedUrl));
  } catch (exp: any) {
    if (exp && exp.details) {
      setSubmitError(exp.details.message || exp.details.description);
    } else {
      setSubmitError(__('Pri registrácií nastala chyba'));
    }
  } finally {
    setSubmitting(false);
  }
};
