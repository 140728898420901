import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  Button,
  ButtonIcon,
  ButtonStyledStyles,
} from '../../_helpers/form/Button';
import MetaTags from '../../MetaTags/MetaTags';
import GalleryModal from '../../_helpers/Modal/GalleryModal';
import { prop } from '../../../utilities';
import { formatShop } from '../../../utilities/shops';
import { Title } from '../Page/ContentContainer';
import { Link } from 'react-router';
import Swiper from '../../_helpers/Swiper/Swiper';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

const Wrapper = styled.div`
  font-size: ${rem(14)};
`;

const StoreRow = styled.p`
  margin: 0 0 ${rem(0)};
  a {
    color: ${({ theme }) => theme.color.link};
    text-decoration: none;
  }
`;

const CustomTextWrapper = styled.div`
  margin-bottom: ${rem(15)};

  p {
    margin: 0px;
  }
`;

const BaseInfoWrapper = styled.div`
  display: flex;
`;

const BaseInfoColumn = styled.div`
  width: 100%;

  ${({ theme }) => theme.media(760)} {
    width: 50%;
  }
`;

const BaseInfoColumnRight = styled(BaseInfoColumn)`
  margin-right: ${rem(10)};
`;

const BaseInfoColumnLeft = styled(BaseInfoColumn)`
  margin-left: ${rem(10)};
  display: none;

  ${({ theme }) => theme.media(760)} {
    display: block;
  }
`;

const BaseInfoColumnContent = styled.div`
  margin-bottom: ${rem(10)};
`;

const LabelText = styled.div`
  font-size: ${rem(12)};
  color: #b3b3b3;
`;

const ContentText = styled.div`
  font-size: ${rem(18)};
`;

const TextButtonsWrapper = styled.div`
  display: flex;
  gap: ${rem(5)};
  flex-direction: column;

  ${({ theme }) => theme.media(550)} {
    gap: ${rem(10)};
    flex-direction: row;
  }
`;

const LocaleButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  ${({ theme }) => theme.media(425)} {
    flex-direction: row;
  }
`;

const ContentButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 3px 12px;
  border: 2px solid #ccc;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  text-decoration: none;
  font-size: ${rem(14)};
  color: black;
  white-space: nowrap;
`;

const ContentButtonMobileWrapper = styled.div`
  display: block;
  ${({ theme }) => theme.media(760)} {
    display: none;
  }
`;

const ContentButtonIcon = styled(ButtonIcon)`
  ${({ theme }) => theme.size(18)};
  fill: ${({ theme }) => theme.color.primary};
  margin-right: ${rem(10)};
  transform: initial;
`;

const BackButtonWrapper = styled.div`
  display: flex: 
  justify-content:center;
  text-align: center;
  margin-bottom: ${rem(15)};
`;

const BackButton = styled(Button)`
  ${ButtonStyledStyles};
`;

const BackButtonIcon = styled(ButtonIcon)`
  ${({ theme }) => theme.size(24)};
  fill: ${({ theme }) => theme.color.white};
  margin-right: ${rem(10)};
`;

const MobileMapWrapper = styled.div`
  display: block;
  ${({ theme }) => theme.media(760)} {
    display: none;
  }
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  padding: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const GallerySwiper = styled(Swiper)`
  margin-bottom: ${rem(20)};

  .swiper-pagination-bullets {
    bottom: ${rem(10)};
  }

  .swiper-pagination-bullet {
    background: #0e0e0e;
  }

  .swiper-pagination-bullet-active {
    background: black;
  }

  .custom-pagination {
    text-align: center;
    margin-top: ${rem(10)};
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const GallerySwipperWrapper = styled.div``;

const CustomPagination = styled.div``;

interface StoreDetailProps {
  detail: any;
  breadcrumbItems?: any;
  onClickBack: (id: null) => void;
}

interface StoreDetailState {
  modalVisible: boolean;
  activeIndex: number | null;
  mobileMapVisible: boolean;
}

class StoreDetail extends React.PureComponent<
  StoreDetailProps,
  StoreDetailState
> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeIndex: null,
      mobileMapVisible: false,
    };
  }

  public onThumbClick = (index) => {
    const isPC = (window && window.innerWidth) > 425;
    if (isPC) {
      this.setState({ modalVisible: true, activeIndex: index });
    }
  };

  public onShowMapClick = () => {
    this.setState({ mobileMapVisible: true });
  };

  public closeModal = () => {
    this.setState({ modalVisible: false });
  };

  public metaHandler = (shopDetail, defaultTitle) => {
    let metaTags: any = {};
    if (shopDetail && shopDetail.content.id !== null) {
      metaTags = prop(shopDetail.content.json_content, 'meta', {
        title: defaultTitle,
      });
    } else {
      metaTags.title = defaultTitle;
    }

    return metaTags;
  };

  public render() {
    let { detail, breadcrumbItems } = this.props;

    const swiperParams = {
      slidesPerView: 2,
      spaceBetween: 8,
      pagination: {
        el: '.custom-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        790: {
          slidesPerView: 2,
        },
      },
    };

    detail = formatShop(detail);

    let images = [];
    if (detail && detail.content && detail.content.photogallery) {
      images = detail.content.photogallery.map((item, i) => {
        return item.document_path;
      });
    }
    images.shift();

    const tags = this.metaHandler(
      detail,
      detail ? `Daffer.sk` : 'Daffer.sk - Predajňa',
    );

    return (
      <Wrapper>
        {detail ? (
          <React.Fragment>
            <MetaTags tags={tags} />
            <Breadcrumbs items={breadcrumbItems} />
            <Title> {detail.name} </Title>

            <BaseInfoWrapper>
              <BaseInfoColumnRight>
                <BaseInfoColumnContent>
                  <LabelText>Adresa:</LabelText>
                  <TextButtonsWrapper>
                    <ContentText>
                      {' '}
                      {detail.address}, {detail.city} {detail.zip}{' '}
                    </ContentText>
                    <LocaleButtonsWrapper>
                      {detail.iframe_map ? (
                        <ContentButtonMobileWrapper>
                          {' '}
                          <ContentButton onClick={() => this.onShowMapClick()}>
                            {' '}
                            <ContentButtonIcon icon="location2" />{' '}
                            {__('Zobraziť na mape')}
                          </ContentButton>
                        </ContentButtonMobileWrapper>
                      ) : (
                        <></>
                      )}
                      {detail.navigate_link ? (
                        <a href={detail.navigate_link}>
                          {' '}
                          <ContentButton>
                            {' '}
                            <ContentButtonIcon icon="navigation" />{' '}
                            {__('Naviguj')}
                          </ContentButton>
                        </a>
                      ) : (
                        <></>
                      )}
                    </LocaleButtonsWrapper>
                  </TextButtonsWrapper>
                </BaseInfoColumnContent>

                {this.state.mobileMapVisible ? (
                  <MobileMapWrapper>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detail.iframe_map,
                      }}
                    />
                  </MobileMapWrapper>
                ) : (
                  <></>
                )}

                {detail.opening_hours_formatted ? (
                  <BaseInfoColumnContent>
                    <LabelText>{__('Otváracia doba')}:</LabelText>
                    <ContentText>
                      {detail.opening_hours_formatted.map((oh, i) => {
                        return <StoreRow key={i}>{oh}</StoreRow>;
                      })}
                    </ContentText>

                    {typeof detail.content?.json_content
                      ?.additional_opening_hours === 'string' ? (
                      <CustomTextWrapper
                        dangerouslySetInnerHTML={{
                          __html:
                            detail.content?.json_content
                              ?.additional_opening_hours,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </BaseInfoColumnContent>
                ) : (
                  ''
                )}

                <BaseInfoColumnContent>
                  <LabelText style={{ marginBottom: 5 }}>
                    {__('Kontakt')}:
                  </LabelText>
                  <ButtonsWrapper>
                    <a href={`tel:${detail.phone}`}>
                      <ContentButton>
                        {' '}
                        <ContentButtonIcon icon="phone" /> {detail.phone}{' '}
                      </ContentButton>
                    </a>
                    <a href={`mailto:${detail.email}`}>
                      <ContentButton>
                        {' '}
                        <ContentButtonIcon icon="mail" /> {detail.email}{' '}
                      </ContentButton>
                    </a>
                  </ButtonsWrapper>
                </BaseInfoColumnContent>
              </BaseInfoColumnRight>
              <BaseInfoColumnLeft>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.iframe_map,
                  }}
                />
              </BaseInfoColumnLeft>
            </BaseInfoWrapper>
            {typeof detail.content?.json_content?.body === 'string' ? (
              <div>
                <LabelText>{__('Ďalšie informácie o predajni')}:</LabelText>
                <CustomTextWrapper
                  dangerouslySetInnerHTML={{
                    __html: detail.content?.json_content?.body,
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            <div>
              <LabelText style={{ marginBottom: 5 }}>
                {__('Fotogaléria')}:
              </LabelText>
              <GallerySwipperWrapper>
                <GallerySwiper params={swiperParams}>
                  {images.map((image, index) => {
                    return (
                      <Image
                        src={image}
                        h={350}
                        w={600}
                        resizeType={'cover'}
                        onClick={() => this.onThumbClick(index)}
                      />
                    );
                  })}
                </GallerySwiper>
              </GallerySwipperWrapper>
              <CustomPagination className="custom-pagination"></CustomPagination>
            </div>

            <BackButtonWrapper>
              <Link to={'/predajne'}>
                <BackButton className="primary lg">
                  <BackButtonIcon icon="info" />
                  {__('Prehľad predajní')}
                </BackButton>
              </Link>
            </BackButtonWrapper>

            {this.state.modalVisible && (
              <GalleryModal
                pictures={images}
                activeIndex={this.state.activeIndex}
                onCloseButtonClick={this.closeModal}
              />
            )}
          </React.Fragment>
        ) : (
          ''
        )}
      </Wrapper>
    );
  }
}

export default StoreDetail;
