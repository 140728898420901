import * as cookies from 'react-cookies';
import { generalInitialState } from './reducer';
import {
  capitalizeFirstLetter,
  cookiesExpiresDate,
  getBaseUrl,
  getSchoolNameFromSkvelkoSet,
  loadOtherText,
  normalizeString,
} from '../../utilities';
import * as actionTypes from './constants';
import {
  SET_ARTICLE_MODAL,
  SET_CMS_CONTENT_MODAL,
  SET_CURRENT_CATEGORY_GROUP_ID,
  SET_GENERAL_FREE_DELIVERY_INFO,
  SET_PREV_PATH,
  SET_STATE_CATEGORY_LIST_NEW,
} from './constants';
import { push } from 'react-router-redux';
import { setCurrentSet } from '../Home/actions';
import {
  BANNER_HOMEPAGE,
  BANNER_SCHOOL,
  PRODUCTS_PER_PAGE,
  SCHOOL_ARTICLES_UNIQID,
} from '../../constants';
import { setCart } from '../Cart/actions';

export const setMcCidCookie = (mcCid) => {
  var expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 7);

  cookies.save('mc_cid', mcCid, {
    path: '/',
    expires: expireDate,
  });
};

export const setLanguage = (lang) => {
  if (!lang) {
    lang = generalInitialState.lang;
  }
  cookies.save(actionTypes.LANG_COOKIE, lang, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: {
      lang,
    },
  };
};

export const setCurrency = (currency) => {
  if (!currency) {
    currency = generalInitialState.currency;
  }
  cookies.save(actionTypes.CURRENCY_COOKIE, currency, {
    path: '/',
    expires: cookiesExpiresDate(),
  });
  return {
    type: actionTypes.SET_CURRENCY,
    payload: {
      currency,
    },
  };
};

export const setDefaultMetaTitle = (defaultTitle) => {
  return {
    type: actionTypes.SET_DEFAULT_TITLE,
    payload: {
      defaultTitle,
    },
  };
};

export const loadDefaultSettings = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const settings = await API.getSettingByName(
        'meta_title,meta_description,meta_author,meta_keywords,infoEmail,infoPhone,eshop_minimal_cart_item_count_protection,eshop_minimal_cart_total_price_protection,cartAction_from,cartAction_to,cartAction_category_desktop_banner,cartAction_sets_desktop_banner,cartAction_modal_web_content_id,skvelko_skvelkoHomepage,newsletterCouponActive,newsletterCouponAppearSeconds,eshop_actionTypeGoodPrice',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      const defaultTitle = settings.find((s) => s.name === 'meta_title')?.value;
      const description = settings.find((s) => s.name === 'meta_description')
        ?.value;
      const author = settings.find((s) => s.name === 'meta_author')?.value;
      const keywords = settings.find((s) => s.name === 'meta_keywords')?.value;
      const contactEmail = settings.find((s) => s.name === 'infoEmail')?.value;
      const contactTel = settings.find((s) => s.name === 'infoPhone')?.value;
      const countProtection = settings.find(
        (s) => s.name === 'eshop_minimal_cart_item_count_protection',
      )?.value;
      const priceProtection = settings.find(
        (s) => s.name === 'eshop_minimal_cart_total_price_protection',
      )?.value;
      const cartActionFrom = settings.find((s) => s.name === 'cartAction_from')
        ?.value;
      const cartActionTo = settings.find((s) => s.name === 'cartAction_to')
        ?.value;
      const cartActionCategoryDesktopBanner = settings.find(
        (s) => s.name === 'cartAction_category_desktop_banner',
      )?.value;
      const cartActionSetsDesktopBanner = settings.find(
        (s) => s.name === 'cartAction_sets_desktop_banner',
      )?.value;
      const cartActionModalWebContentId = settings.find(
        (s) => s.name === 'cartAction_modal_web_content_id',
      )?.value;
      const skvelkoHomepage = settings.find(
        (s) => s.name === 'skvelko_skvelkoHomepage',
      )?.value;

      const newsletterCouponActive = settings.find(
        (s) => s.name === 'newsletterCouponActive',
      )?.value;

      const newsletterCouponAppearSeconds = settings.find(
        (s) => s.name === 'newsletterCouponAppearSeconds',
      )?.value;

      const eshop_actionTypeGoodPrice = settings.find(
        (s) => s.name === 'eshop_actionTypeGoodPrice',
      )?.value;

      dispatch(setMetaTags({ description, keywords, author }));
      dispatch(setContactInfo({ contactEmail, contactTel }));
      try {
        dispatch(
          setProtectionSettings({
            price: parseInt(priceProtection, 10),
            count: parseInt(countProtection, 10),
          }),
        );
      } catch (e) {
        console.error(e);
      }
      dispatch(setDefaultMetaTitle(defaultTitle));
      dispatch(
        setGeneralSettings({
          cartActionFrom,
          cartActionTo,
          cartActionCategoryDesktopBanner,
          cartActionSetsDesktopBanner,
          cartActionModalWebContentId,
          skvelkoHomepage,
          newsletterCouponActive,
          newsletterCouponAppearSeconds,
          eshop_actionTypeGoodPrice,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const setTopMenuItems = (menuItems) => {
  return {
    type: actionTypes.SET_TOP_MENU_ITEMS,
    payload: {
      menuItems,
    },
  };
};

export const loadTopMenuItems = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const categories = await API.loadTree({
        xAcceptLanguage: currentState.general.lang,
      });

      const menuItemsPromise = categories.map(async (category) => {
        return {
          active: false,
          name: category.category_name,
          url: category.url,
          attribs: await API.loadProductAttribs({
            categoryId: category.category_id,
            xAcceptLanguage: currentState.general.lang,
          }),
        };
      });

      const menuItems = await Promise.all(menuItemsPromise);

      dispatch(setTopMenuItems(menuItems));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setMainMenu = (mainMenu) => {
  return {
    type: actionTypes.SET_MAIN_MENU,
    payload: {
      mainMenu,
    },
  };
};

export const loadMainMenu = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const mainMenu = await API.loadSitemapSubtree('MAINMENU', {
        xAcceptLanguage: currentState.general.lang,
      });

      dispatch(setMainMenu(mainMenu));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setFooterData = (footerData) => {
  return {
    type: actionTypes.SET_FOOTER_DATA,
    payload: {
      footerData,
    },
  };
};

export const loadFooterData = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      const footerData = await API.loadSitemapSubtree('PATICKA', {
        xAcceptLanguage: currentState.general.lang,
      });

      dispatch(setFooterData(footerData));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setContactInfo = (contactInfo) => {
  return {
    type: actionTypes.SET_CONTACT_INFO,
    payload: {
      contactInfo,
    },
  };
};

export const setOtherTexts = (otherTexts) => {
  return {
    type: actionTypes.SET_OTHER_TEXTS,
    payload: {
      otherTexts,
    },
  };
};

export const setSocialUrls = (socialUrls) => {
  return {
    type: actionTypes.SET_SOCIAL_URLS,
    payload: {
      socialUrls,
    },
  };
};

export const loadSocialUrls = () => {
  return async (dispatch, getState, API) => {
    try {
      const currentState = getState();
      let socialUrls = await API.getSettingByName(
        'socials_facebook,socials_youtube',
        {
          xAcceptLanguage: currentState.general.lang,
        },
      );

      if (!socialUrls || !socialUrls.fbUrl || !socialUrls.ytUrl) {
        return;
      }
      const [{ value: fbUrl }, { value: ytUrl }] = socialUrls;

      socialUrls = {
        fbUrl,
        ytUrl,
      };

      dispatch(setSocialUrls(socialUrls));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setMetaTags = (metaTags) => {
  return {
    type: actionTypes.SET_META_TAGS,
    payload: {
      metaTags,
    },
  };
};

const requestRegisterUser = () => ({
  type: actionTypes.REQUEST_REGISTER_USER,
});

const receiveRegisterUserSuccess = (success) => ({
  type: actionTypes.RECEIVE_REGISTER_USER_SUCCESS,
  payload: {
    success,
  },
});

const receiveRegisterUserFailure = (error) => ({
  type: actionTypes.RECEIVE_REGISTER_USER_FAILURE,
  payload: {
    error,
  },
});

export const setCategoryGroup = (categoriesGroup) => ({
  type: actionTypes.SET_CATEGORY_GROUP,
  payload: {
    categoriesGroup,
  },
});

export const setMainGroupCategory = (mainGroupCategory) => ({
  type: actionTypes.SET_CATEGORY_GROUP_MAIN,
  payload: {
    mainGroupCategory,
  },
});

export const setCurrentCategoryId = (currentCategoryId) => ({
  type: actionTypes.SET_CURRENT_CATEGORY_ID,
  payload: {
    currentCategoryId,
  },
});

export const setFavoriteProdutctsCount = (favoriteProductsCount) => ({
  type: actionTypes.SET_FAVORITE_PRODUCTS_COUNT,
  payload: favoriteProductsCount,
});

export const loadsCategoryGroup = () => {
  return async (dispatch, getState, API) => {
    try {
      const categoryGroup = await API.loadCategoriesGroups({
        active: '1',
        withCategories: 1,
        categoryTree: true,
        filterEmpty: true,
        filterDisabled: true,
      });
      const catalogCategoryGroups = categoryGroup.catalog_category_groups;
      if (catalogCategoryGroups) {
        dispatch(setCategoryGroup(catalogCategoryGroups));
      }
      if (catalogCategoryGroups.length && catalogCategoryGroups[0].categories) {
        dispatch(setMainGroupCategory(catalogCategoryGroups[0].categories));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const registerUser = ({ username, password, controlPassword }) => {
  return async (dispatch, getState, API) => {
    dispatch(requestRegisterUser());
    try {
      await API.registerUser(undefined, {
        email: username,
        password,
        password_again: controlPassword,
        lang: 'sk',
      });
      dispatch(receiveRegisterUserSuccess(true));
    } catch (exp: any) {
      dispatch(receiveRegisterUserFailure(exp.details.description));
    }
  };
};

export const setSearchInitialLoaded = (searchInitialLoaded) => ({
  type: actionTypes.SET_SEARCH_INITIAL_LOADED,
  payload: {
    searchInitialLoaded,
  },
});

export const setSearchedResultsLoader = (searchedResultsLoader) => ({
  type: actionTypes.SET_SEARCHED_RESULTS_LOADER,
  payload: {
    searchedResultsLoader,
  },
});

export const setSearchedResults = (searchedResults) => ({
  type: actionTypes.SET_SEARCHED_RESULTS,
  payload: {
    searchedResults,
  },
});

export const setSearchSessionId = (searchSessionId) => ({
  type: actionTypes.SET_SEARCH_SESSION_ID,
  payload: searchSessionId,
});

export const setSearchId = (searchId) => ({
  type: actionTypes.SET_SEARCH_ID,
  payload: searchId,
});

export const setSearchedResultsPreviewLoader = (
  searchedResultsPreviewLoader,
) => ({
  type: actionTypes.SET_SEARCHED_RESULTS_PREVIEW_LOADER,
  payload: {
    searchedResultsPreviewLoader,
  },
});

export const setSearchedResultsPreview = (searchedResultsPreview) => ({
  type: actionTypes.SET_SEARCHED_RESULTS_PREVIEW,
  payload: {
    searchedResultsPreview,
  },
});

export const loadSearchedResultsPreview = (filters) => {
  return async (dispatch, getState, API) => {
    const results: any = {};

    if (!filters?.q || filters.q === '') {
      return;
    }

    filters = {
      ...filters,
      limit: 6,
      q: filters?.q,
    };

    const productsFilter = {
      availabilityId: 1,
      offset: 0,
      withFoundedQueries: true,
      includeSkvelkoSets: true,
      sort: 'sale_count',
      sort_dir: 'desc',
      ...filters,
    };

    const categoryFilter = {
      offset: 0,
      webShow: '1',
      parentCategories: true,
      isNotEmpty: true,
      ...filters,
      limit: 4,
    };

    const webContentFilter = {
      offset: 0,
      ...filters,
    };

    const {
      general: { lang },
    } = getState();

    dispatch(setSearchedResultsPreviewLoader(true));
    const [
      productsResult,
      demandProductsResult,
      categoriesResult,
      demandCategoriesResult,
      webContentResult,
      faqArticleList,
    ] = await Promise.all([
      API.searchProducts({ ...productsFilter, isDemand: false }),
      API.searchProducts({ ...productsFilter, isDemand: true }),
      API.loadCategories({ ...categoryFilter, isSchoolCategory: false }),
      API.loadCategories({ ...categoryFilter, isSchoolCategory: true }),
      API.loadWebContent(webContentFilter),
      API.loadArticles(
        { sitemapUniqueId: 'FAQ', langId: lang },
        { xAcceptLanguage: lang },
      ),
    ]);

    const faqArticleIds = faqArticleList.articles.map((item) => item.id);
    if (
      webContentResult.web_content.find((item) =>
        faqArticleIds.includes(item.id),
      )
    ) {
      const faqWebContent = await API.loadSitemap('FAQ', {
        xAcceptLanguage: lang,
      });
      if (faqWebContent) {
        let alreadyChanged = false;
        webContentResult.web_content = webContentResult.web_content
          .map((item) => {
            if (faqArticleIds.includes(item.id)) {
              if (alreadyChanged === true) {
                return null;
              }

              alreadyChanged = true;
              return faqWebContent;
            }

            return item;
          })
          .filter((o) => o);
      }
    }

    results.products = productsResult;
    results.categories = categoriesResult;
    results.demandCategories = demandCategoriesResult;
    results.webContent = webContentResult;
    results.demandProducts = demandProductsResult;

    dispatch(saveSearchLog(results, filters.q));
    dispatch(setSearchedResultsPreview(results));
    dispatch(setSearchedResultsPreviewLoader(false));
  };
};

export const loadSearchedResults = (filters) => {
  return async (dispatch, getState, API) => {
    const results: any = {};

    if (!filters?.q || filters.q === '') {
      return;
    }

    filters = {
      ...filters,
      limit: 6,
      q: filters.q,
    };

    const productsFilter = {
      availabilityId: 1,
      offset: 0,
      withFoundedQueries: true,
      includeSkvelkoSets: true,
      sort: 'sale_count',
      sort_dir: 'desc',
      ...filters,
      limit: PRODUCTS_PER_PAGE,
    };

    const categoryFilter = {
      offset: 0,
      webShow: '1',
      parentCategories: true,
      isNotEmpty: true,
      ...filters,
      limit: 4,
    };

    const webContentFilter = {
      offset: 0,
      ...filters,
    };

    const {
      general: { lang },
    } = getState();

    dispatch(setSearchedResultsLoader(true));
    const [
      productsResult,
      demandProductsResult,
      categoriesResult,
      demandCategoriesResult,
      webContentResult,
      faqArticleList,
    ] = await Promise.all([
      API.searchProducts({ ...productsFilter, isDemand: false }),
      API.searchProducts({ ...productsFilter, isDemand: true }),
      API.loadCategories({ ...categoryFilter, isSchoolCategory: false }),
      API.loadCategories({ ...categoryFilter, isSchoolCategory: true }),
      API.loadWebContent(webContentFilter),
      API.loadArticles(
        { sitemapUniqueId: 'FAQ', langId: lang },
        { xAcceptLanguage: lang },
      ),
    ]);

    const faqArticleIds = faqArticleList.articles.map((item) => item.id);
    if (
      webContentResult.web_content.find((item) =>
        faqArticleIds.includes(item.id),
      )
    ) {
      const faqWebContent = await API.loadSitemap('FAQ', {
        xAcceptLanguage: lang,
      });
      if (faqWebContent) {
        let alreadyChanged = false;
        webContentResult.web_content = webContentResult.web_content
          .map((item) => {
            if (faqArticleIds.includes(item.id)) {
              if (alreadyChanged === true) {
                return null;
              }

              item.name = faqWebContent.name;
              item.url = faqWebContent.url;
              item.json_content.name = faqWebContent.name;

              alreadyChanged = true;
              return item;
            }

            return item;
          })
          .filter((o) => o);
      }
    }

    results.products = productsResult;
    results.demandProducts = demandProductsResult;
    results.categories = categoriesResult;
    results.demandCategories = demandCategoriesResult;
    results.webContent = webContentResult;
    dispatch(saveSearchLog(results, filters.q));
    dispatch(setSearchedResults(results));
    dispatch(setSearchedResultsLoader(false));
  };
};

export const loadSearchedDemandProductResults = (filters) => {
  return async (dispatch, getState, API) => {
    dispatch(setSearchedResultsLoader(true));

    const result = await API.searchProducts({
      ...filters,
      includeSkvelkoSets: true,
      isDemand: true,
    });

    const {
      general: { searchedResults },
    } = getState();

    dispatch(
      setSearchedResults({
        ...searchedResults,
        demandProducts: result,
      }),
    );
    dispatch(setSearchedResultsLoader(false));
  };
};

export const loadSearchedProductResults = (filters) => {
  return async (dispatch, getState, API) => {
    dispatch(setSearchedResultsLoader(true));

    const result = await API.searchProducts({
      ...filters,
      includeSkvelkoSets: true,
      isDemand: false,
    });

    const {
      general: { searchedResults },
    } = getState();

    dispatch(
      setSearchedResults({
        ...searchedResults,
        products: {
          ...result,
          founded_queries: searchedResults?.products?.founded_queries,
        },
      }),
    );
    dispatch(setSearchedResultsLoader(false));
  };
};

export const loadSearchedCategoryResults = (filters) => {
  return async (dispatch, getState, API) => {
    dispatch(setSearchedResultsLoader(true));

    const result = await API.loadCategories(filters);

    const {
      general: { searchedResults },
    } = getState();

    dispatch(
      setSearchedResults({
        ...searchedResults,
        categories: result,
      }),
    );
    dispatch(setSearchedResultsLoader(false));
  };
};

export const loadSearchedDemandCategoryResults = (filters) => {
  return async (dispatch, getState, API) => {
    dispatch(setSearchedResultsLoader(true));

    const result = await API.loadCategories(filters);

    const {
      general: { searchedResults },
    } = getState();

    dispatch(
      setSearchedResults({
        ...searchedResults,
        demandCategories: result,
      }),
    );
    dispatch(setSearchedResultsLoader(false));
  };
};

export const loadSearchedWebContentResults = (filters) => {
  return async (dispatch, getState, API) => {
    if (!filters?.q || filters.q === '') {
      return;
    }

    dispatch(setSearchedResultsLoader(true));

    const result = await API.loadWebContent(filters);

    const {
      general: { lang },
    } = getState();

    const faqArticleList = await API.loadArticles(
      { sitemapUniqueId: 'FAQ', langId: lang },
      { xAcceptLanguage: lang },
    );

    const {
      general: { searchedResults },
    } = getState();

    const faqArticleIds = faqArticleList.articles.map((item) => item.id);
    if (result.web_content.find((item) => faqArticleIds.includes(item.id))) {
      const faqWebContent = await API.loadSitemap('FAQ', {
        xAcceptLanguage: lang,
      });
      if (faqWebContent) {
        let alreadyChanged = false;
        result.web_content = result.web_content
          .map((item) => {
            if (faqArticleIds.includes(item.id)) {
              if (alreadyChanged === true) {
                return null;
              }

              item.name = faqWebContent.name;
              item.url = faqWebContent.url;
              item.json_content.name = faqWebContent.name;

              alreadyChanged = true;
              return item;
            }

            return item;
          })
          .filter((o) => o);
      }
    }

    dispatch(
      setSearchedResults({
        ...searchedResults,
        webContent: result,
      }),
    );
    dispatch(setSearchedResultsLoader(false));
  };
};

export const saveSearchLog = (results, query) => {
  return async (dispatch, getState, API) => {
    let searchSessionId = getState().general.searchSessionId;
    if (!searchSessionId) {
      searchSessionId =
        Math.random().toString(36).substr(2, 5) +
        Math.random().toString(36).substr(2, 5) +
        Math.random().toString(36).substr(2, 5);
      dispatch(setSearchSessionId(searchSessionId));
    }

    const searchId =
      Math.random().toString(36).substr(2, 5) +
      Math.random().toString(36).substr(2, 5) +
      Math.random().toString(36).substr(2, 5);
    dispatch(setSearchId(searchId));

    const sl: {
      type: string;
      display_result: string;
      product_id?: number;
      category_id?: number;
      skvelko_set_id?: number;
      web_content_id?: number;
    }[] = [];

    if (results.products && results.products.products) {
      for (const product of results.products.products) {
        sl.push({
          type: 'PRODUCT',
          display_result: product.name,
          product_id: product.product_id,
        });
      }
    }

    if (results.products && results.products.skvelko_sets) {
      for (const skvelkoSet of results.products.skvelko_sets) {
        sl.push({
          type: 'SKVELKO_SET',
          display_result: getSchoolNameFromSkvelkoSet(skvelkoSet),
          skvelko_set_id: skvelkoSet.id,
        });
      }
    }

    if (
      results.categories &&
      results.categories.catalog_category &&
      results.categories.catalog_category.length
    ) {
      for (const category of results.categories.catalog_category.slice(0, 4)) {
        const categoryNames = category.parent_categories.map(
          (parentCategory) => {
            return capitalizeFirstLetter(parentCategory.category_name);
          },
        );
        categoryNames.push(capitalizeFirstLetter(category.category_name));
        sl.push({
          type: 'CATEGORY',
          display_result: categoryNames.join('/'),
          category_id: category.category_id,
        });
      }
    }

    if (
      results.webContent &&
      results.webContent.web_content &&
      results.webContent.web_content.length
    ) {
      for (const webContent of results.webContent.web_content.slice(0, 3)) {
        sl.push({
          type: 'WEB_CONTENT',
          display_result: webContent.name,
          web_content_id: webContent.id,
        });
      }
    }

    if (
      results.products &&
      results.products.founded_queries &&
      results.products.founded_queries.length
    ) {
      const foundedQueries = results.products.founded_queries;

      const canShowFoundedQueries =
        foundedQueries &&
        !foundedQueries.some((h) =>
          normalizeString(query).startsWith(normalizeString(h)),
        );
      if (canShowFoundedQueries) {
        const foundedQuery = results.products.founded_queries[0];
        if (foundedQuery !== query) {
          sl.push({
            type: 'SUGGESTION',
            display_result: foundedQueries.slice(0, 3).join(', '),
          });
        }
      }
    }

    API.saveSearchLog(
      searchSessionId,
      searchId,
      {},
      { query: query, search_log_results: sl },
    );
  };
};

export const setAddToCartModalProduct = (addToCartModalProduct) => {
  return {
    type: actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT,
    payload: {
      addToCartModalProduct,
    },
  };
};

export const setAddToCartMaxItemExceededModalProduct = (
  addToCartMaxItemExceededProduct,
) => {
  return {
    type: actionTypes.SET_ADD_TO_CART_MAX_ITEMS_EXCEEDED_MODAL_PRODUCT,
    payload: {
      addToCartMaxItemExceededProduct,
    },
  };
};

export const setAddToCartModalProductOutOfStock = (
  addToCartModalProductOutOfStock,
) => {
  return {
    type: actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT_OUT_OF_STOCK,
    payload: {
      addToCartModalProductOutOfStock,
    },
  };
};

export const setAddToCartModalProductQuantityLimit = (
  addToCartModalProductQuantityLimit,
) => {
  return {
    type: actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT_QUANTITY_LIMIT,
    payload: {
      addToCartModalProductQuantityLimit,
    },
  };
};

export const setSelectVariantModal = (selectVariantModal) => {
  return {
    type: actionTypes.SET_SELECT_VARIANT_MODAL,
    payload: {
      selectVariantModal,
    },
  };
};

export const changeAmountProductOutOfStock = (amountProductOutOfStock) => {
  return {
    type: actionTypes.CHANGE_AMOUNT_PRODUCT_OUT_OF_SOCK,
    payload: {
      amountProductOutOfStock,
    },
  };
};

export const setDeliveryPointModalPoint = (deliveryPointModalPoint) => {
  return {
    type: actionTypes.SET_DELIVERY_POINT_MODAL_POINT,
    payload: {
      deliveryPointModalPoint,
    },
  };
};

export const setPacketaDeliveryPointModalPoint = (
  packetaDeliveryPointModalPoint,
) => {
  return {
    type: actionTypes.SET_PACKETA_DELIVERY_POINT_MODAL_POINT,
    payload: {
      packetaDeliveryPointModalPoint,
    },
  };
};

export const setShopsPersonalPickupModal = (shopPersonalPickupModal) => {
  return {
    type: actionTypes.SET_SHOP_PERSONAL_PICKUP_MODAL,
    payload: {
      shopPersonalPickupModal,
    },
  };
};

export const redirect = (path) => {
  return async (dispatch) => {
    dispatch(push(path));
  };
};

export const loadOtherDefaultsTexts = () => {
  return async (dispatch, getState, API) => {
    try {
      const otherTexts = {};
      [
        'PAGE_NOT_FOUND',
        'COOKIES',
        'CART_AUTO_LOGOUT',
        'CART_MAX_ITEM_EXCEEDED',
      ].forEach(async (id) => {
        otherTexts[id] = await API.loadOtherTexts(id);
      });
      dispatch(setOtherTexts(otherTexts));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setRegistrationSuccessCMS = (registrationSuccessCMS) => {
  return {
    type: actionTypes.SET_REGISTRATION_SUCCESS_CMS,
    payload: {
      registrationSuccessCMS,
    },
  };
};

export const loadRegistrationSuccessCMS = (sitemapId) => {
  return async (dispatch, getState, API) => {
    const registrationSuccessCMS = await API.loadSitemap(sitemapId, {
      xAcceptLanguage: 'sk',
    });

    dispatch(setRegistrationSuccessCMS(registrationSuccessCMS));
  };
};

export const setCurrentProductForPreview = (currentProductForPreview) => {
  return {
    type: actionTypes.SET_CURRENT_PRODUCT_FOR_PREVIEW,
    payload: {
      currentProductForPreview,
    },
  };
};

export const setHtmlSitemap = (htmlSitemap) => {
  return {
    type: actionTypes.SET_HTML_SITEMAP,
    payload: {
      htmlSitemap,
    },
  };
};

export const loadHtmlSitemap = (lang = 'sk') => {
  return async (dispatch, getState, API) => {
    const htmlSitemap = await API.getHtmlSitemap(lang);
    await dispatch(setHtmlSitemap(htmlSitemap));
  };
};

export const setCurrentShopNetworkArticle = (shopNetworkCurrentArticle) => {
  return {
    type: actionTypes.SET_SHOP_NETWORK_CURRENT_ARTICLE,
    payload: {
      shopNetworkCurrentArticle,
    },
  };
};

export const setCurrentCategoryGroup = (currentCategoryGroup) => {
  return {
    type: actionTypes.SET_CURRENT_CATEGORY_GROUP,
    payload: {
      currentCategoryGroup,
    },
  };
};

export const setCurrentCategory = (currentCategory) => {
  return {
    type: actionTypes.SET_CURRENT_CATEGORY,
    payload: {
      currentCategory,
    },
  };
};

export const setCategoryChanged = (categoryChanged) => {
  return {
    type: actionTypes.SET_CATEGORY_CHANGED,
    payload: {
      categoryChanged,
    },
  };
};

export const setUrlMapData = (urlMapData) => {
  return {
    type: actionTypes.SET_URLMAP_DATA,
    payload: {
      urlMapData,
    },
  };
};

export const setShowPopupSetSelect = (showPopupSetSelect) => {
  return {
    type: actionTypes.SET_SHOW_POPUP_SET_SELECT,
    payload: {
      showPopupSetSelect,
    },
  };
};

export const setShowPopupSetSelect2 = (showPopupSetSelect2) => {
  return {
    type: actionTypes.SET_SHOW_POPUP_SET_SELECT2,
    payload: {
      showPopupSetSelect2,
    },
  };
};

export const loadGeneralFreeDeliveryInfo = () => {
  return async (dispatch, getState, API) => {
    try {
      const response = await API.getGeneralFreeDeliveryInfo(
        {},
        { xAcceptLanguage: 'sk' },
      );
      dispatch(setGeneralFreeDeliveryInfo(response));
    } catch (e) {
      // silence
    }
  };
};

export const setGeneralFreeDeliveryInfo = (info) => ({
  type: SET_GENERAL_FREE_DELIVERY_INFO,
  payload: {
    info,
  },
});

export const setStateCategoryListNew = (stateCategoryListNew) => ({
  type: SET_STATE_CATEGORY_LIST_NEW,
  payload: {
    stateCategoryListNew,
  },
});

export const setCurrentCategoryGroupId = (currentCategoryGroupId) => ({
  type: SET_CURRENT_CATEGORY_GROUP_ID,
  payload: {
    currentCategoryGroupId,
  },
});

export const setUndefinedCategory = () => {
  return async (dispatch, getState, API) => {
    dispatch(setCurrentCategoryGroupId(-1));
    dispatch(setCurrentCategoryGroup(undefined));
    dispatch(setCurrentCategoryId(undefined));
  };
};

export const setUndefinedSkvelkoSet = () => {
  return async (dispatch, getState, API) => {
    dispatch(setCurrentSet(null));
  };
};

export const loadAlertSchoolText = () =>
  loadOtherText('ALERT_SCHOOL_LOGGED_IN', setAlertSchoolText);

export const setAlertSchoolText = (alertSchoolText) => ({
  type: actionTypes.SET_ALERT_SCHOOL_TEXT,
  payload: {
    alertSchoolText,
  },
});

export const setCmsContentModal = (cmsContentModal) => ({
  type: SET_CMS_CONTENT_MODAL,
  payload: {
    cmsContentModal,
  },
});

export const setArticleModal = (articleModal) => ({
  type: SET_ARTICLE_MODAL,
  payload: {
    articleModal,
  },
});

export const setPrevPath = (prevPath) => ({
  type: SET_PREV_PATH,
  payload: {
    prevPath,
  },
});

export const setRegistrationText = (registrationText) => ({
  type: actionTypes.SET_REGISTRATION_TEXT,
  payload: {
    registrationText,
  },
});

export const loadRegistrationText = () =>
  loadOtherText('NEW_REGISTRATION', setRegistrationText);

export const setSkvelkoBottomText = (skvelkoBottomText) => ({
  type: actionTypes.SET_SKVELKO_BOTTOM_TEXT,
  payload: {
    skvelkoBottomText,
  },
});

export const loadSkvelkoBottomText = () =>
  loadOtherText('SKVELKO_BOTTOM', setSkvelkoBottomText);

export const setSkvelkoDescriptionText = (skvelkoDescriptionText) => ({
  type: actionTypes.SET_SKVELKO_DESCRIPTION_TEXT,
  payload: {
    skvelkoDescriptionText,
  },
});

export const loadSkvelkoDescriptionText = () =>
  loadOtherText('SKVELKO_DESCRIPTION', setSkvelkoDescriptionText);

export const loadInfoMultipleStoresText = () =>
  loadOtherText(
    'INFO_MULTIPLE_STORES',
    setInfoMultipleStoresText,
    setInfoMultipleStoresLoading,
  );

export const loadMailchimpSchoolText = () =>
  loadOtherText(
    'INFO_MAILCHIMP_SCHOOL_TEXT',
    setMailchimpSchoolText,
    setMailchimpSchoolLoading,
  );

export const loadCartProtectionText = () =>
  loadOtherText(
    'MODAL_CART_PROTECTION',
    setCartProtectionText,
    setCartProtectionTextLoading,
  );

export const setCartProtectionText = (cartProtectionText) => ({
  type: actionTypes.SET_CART_PROTECTION_TEXT,
  payload: {
    cartProtectionText,
  },
});

export const setCartProtectionTextLoading = (cartProtectionTextLoading) => ({
  type: actionTypes.SET_CART_PROTECTION_TEXT_LOADING,
  payload: {
    cartProtectionTextLoading,
  },
});

export const setCartProtectionModal = (cartProtectionModal) => ({
  type: actionTypes.SET_CART_PROTECTION_MODAL,
  payload: {
    cartProtectionModal,
  },
});

export const setCartHadUserModal = (cartHadUserModal) => ({
  type: actionTypes.SET_CART_HAD_USER_MODAL,
  payload: {
    cartHadUserModal,
  },
});

export const setInfoMultipleStoresText = (infoMultipleStoresText) => ({
  type: actionTypes.SET_INFO_MULTIPLE_STORES_TEXT,
  payload: {
    infoMultipleStoresText,
  },
});

export const setInfoMultipleStoresLoading = (infoMultipleStoresLoading) => ({
  type: actionTypes.SET_INFO_MULTIPLE_STORES_LOADING,
  payload: {
    infoMultipleStoresLoading,
  },
});

export const setInfoMultipleStoresShow = (infoMultipleStoresShow) => ({
  type: actionTypes.SET_INFO_MULTIPLE_STORES_SHOW,
  payload: {
    infoMultipleStoresShow,
  },
});

export const setGiftRemain = (giftRemain) => ({
  type: actionTypes.SET_GIFT_REMAIN,
  payload: {
    giftRemain,
  },
});

export const setMailchimpSchoolLoading = (mailchimpSchoolLoading) => ({
  type: actionTypes.SET_MAILCHIMP_SCHOOL_LOADING,
  payload: {
    mailchimpSchoolLoading,
  },
});

export const setMailchimpSchoolText = (mailchimpSchoolText) => ({
  type: actionTypes.SET_MAILCHIMP_SCHOOL_TEXT,
  payload: {
    mailchimpSchoolText,
  },
});

export const getOnStockCount = (cart, product) => {
  if (
    !product ||
    !product.main_good ||
    !product.main_good.good_id ||
    !product.main_good.on_stock_count
  ) {
    return 0;
  }
  let cartStockCount = 0;
  const productProductId = product.main_good.product_id;
  const onStockCount = product.main_good.on_stock_count;
  if (cart && cart.items && cart.items.length > 0) {
    cart.items
      .filter((item) => item.good.product_id === productProductId)
      .forEach((item) => (cartStockCount += item.count));
  }
  return onStockCount - cartStockCount;
};

export const redirectWithDispatch = (dispatch, url) => {
  if (url) dispatch(push(getBaseUrl(url)));
};

export const resetToken = () => {
  return async (dispatch, getState, API) => {
    API.setToken('');
  };
};

export const openGdprModal = (dispatch) => {
  dispatch(setCmsContentModal('GDPR'));
};

export const openBusinessConditionsModal = (dispatch) => {
  dispatch(setCmsContentModal('BUSINESS-CONDITIONS'));
};

export const openCustomerCardModal = (dispatch) => {
  dispatch(setCmsContentModal('CUSTOMER_CARD'));
};

export const loadCmsContent = (sitemapUid) => {
  return async (dispatch, getState, API) => {
    const {
      general: { cmsContent },
    } = getState();
    if (sitemapUid !== cmsContent[sitemapUid]?.uniqid) {
      try {
        dispatch(setCmsContentLoading(true));
        cmsContent[sitemapUid] = await API.loadSitemap(sitemapUid);
        dispatch(setCmsContent(cmsContent));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setCmsContentLoading(false));
      }
    }
  };
};

export const setCmsContent = (cmsContent) => ({
  type: actionTypes.SET_CMS_CONTENT,
  payload: {
    cmsContent,
  },
});

export const setCmsContentLoading = (cmsContentLoading) => ({
  type: actionTypes.SET_CMS_CONTENT_LOADING,
  payload: {
    cmsContentLoading,
  },
});

export const setGlobalMetaTags = (globalMetaTags) => {
  return {
    type: actionTypes.SET_GLOBAL_META_TAGS,
    payload: {
      globalMetaTags,
    },
  };
};

export const loadProduct = (productId) => {
  return async (dispatch, getState, API) => {
    const {
      general: { product },
    } = getState();
    if (productId !== product[productId]?.product_id) {
      try {
        dispatch(setProductLoading(true));
        product[productId] = await API.loadProduct(
          productId,
          {},
          {
            xAcceptLanguage: getState().general.lang,
          },
        );
        dispatch(setProduct(product));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setProductLoading(false));
      }
    }
  };
};

export const loadFavoriteProductsCount = () => {
  return async (dispatch, getState, API) => {
    const {
      auth: { user },
    } = getState();

    if (user?.id) {
      const response = await API.searchProducts({
        limit: 1,
        offset: 0,
        withPublish: '1',
        onlyUserFavorites: true,
      });

      if (response) {
        dispatch(setFavoriteProdutctsCount(response.total));
      }
    }
  };
};

export const setProduct = (product) => ({
  type: actionTypes.SET_PRODUCT,
  payload: {
    product,
  },
});

export const setProductLoading = (productLoading) => ({
  type: actionTypes.SET_PRODUCT_LOADING,
  payload: {
    productLoading,
  },
});

export const setProtectionSettings = (protectionSettings) => {
  return {
    type: actionTypes.SET_PROTECTION_SETTINGS,
    payload: {
      protectionSettings,
    },
  };
};

export const setGeneralSettings = (settings) => {
  return {
    type: actionTypes.SET_GENERAL_SETTINGS,
    payload: {
      settings,
    },
  };
};

export const loadInvalidEmails = () => {
  return async (dispatch, getState, API) => {
    try {
      const invalidEmails = await API.getInvalidEmails();
      dispatch(setInvalidEmails(invalidEmails));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setInvalidEmails = (invalidEmails) => {
  return {
    type: actionTypes.SET_INVALID_EMAILS,
    payload: {
      invalidEmails,
    },
  };
};

export const orderAgainAction = (orderPublicId: string, callback) => {
  return async (dispatch, getState, API) => {
    const {
      cart,
      general: { lang, currency },
      auth: { token },
    } = getState();

    if (cart.cart) {
      const cartId = cart.cart.id;
      const response = await API.copyOrder(
        cartId,
        orderPublicId,
        {
          langId: lang,
          currencyId: currency,
          xAuth: token,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(setCart(response));
      callback(response);
    }
  };
};
export const loadSchoolArticles = (offset = 0, limit = 10) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSchoolArticlesLoading(true));
      const articles = await API.loadArticles(
        {
          limit: limit,
          offset: offset,
          sitemapUniqueId: SCHOOL_ARTICLES_UNIQID,
          sort: 'custom_date',
          sortDir: 'desc',
        },
        {
          xAcceptLanguage: 'sk',
        },
      );
      dispatch(setSchoolArticles(articles));
      dispatch(setSchoolArticlesLoading(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setSchoolArticles = (schoolArticles) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLES,
    payload: {
      schoolArticles,
    },
  };
};

export const setSchoolArticlesLoading = (schoolArticlesLoading) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLES_LOADING,
    payload: {
      schoolArticlesLoading,
    },
  };
};

export const loadSchoolArticle = (contentId: number, lang: string) => {
  return async (dispatch, getState, API) => {
    const {
      general: { schoolArticle },
    } = getState();
    try {
      dispatch(setSchoolArticleLoading(true));
      const response = await API.loadArticleDetail(
        contentId,
        {},
        {
          xAcceptLanguage: lang || 'sk',
        },
      );

      schoolArticle[contentId] = response;

      dispatch(setSchoolArticle(schoolArticle));
      dispatch(setSingleSchoolArticle(response));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSchoolArticleLoading(false));
    }
  };
};

export const setSchoolArticle = (schoolArticle) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLE,
    payload: {
      schoolArticle,
    },
  };
};

export const setSingleSchoolArticle = (singleSchoolArticle) => {
  return {
    type: actionTypes.SET_SINGLE_SCHOOL_ARTICLE,
    payload: {
      singleSchoolArticle,
    },
  };
};

export const setSchoolArticleLoading = (schoolArticleLoading) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLE_LOADING,
    payload: {
      schoolArticleLoading,
    },
  };
};

export const loadSchoolArticleConnections = (
  contentId: number,
  lang: string,
) => {
  return async (dispatch, getState, API) => {
    const {
      general: { schoolArticleConnections },
    } = getState();
    if (!schoolArticleConnections[contentId]) {
      try {
        dispatch(setSchoolArticleConnectionsLoading(true));
        schoolArticleConnections[contentId] = await API.loadArticleConnections(
          contentId,
          {
            xAcceptLanguage: lang,
          },
        );
        dispatch(setSchoolArticleConnections(schoolArticleConnections));
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setSchoolArticleConnectionsLoading(false));
      }
    }
  };
};

export const setSchoolArticleConnections = (schoolArticleConnections) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLE_CONNECTIONS,
    payload: {
      schoolArticleConnections,
    },
  };
};

export const setSchoolArticleConnectionsLoading = (
  schoolArticleConnectionsLoading,
) => {
  return {
    type: actionTypes.SET_SCHOOL_ARTICLE_CONNECTIONS_LOADING,
    payload: {
      schoolArticleConnectionsLoading,
    },
  };
};

export const loadBanners = (position: string, force: boolean = false) => {
  return async (dispatch, getState, API) => {
    const {
      general: { banners },
    } = getState();

    if (!banners[position] || force) {
      banners[position] = await API.loadBanners({ position: position });
      dispatch(setBanners(banners));
    }
  };
};

export const setBanners = (banners) => ({
  type: actionTypes.SET_BANNERS,
  payload: {
    banners,
  },
});

export const clearReduxCache = () => {
  return async (dispatch, getState, API) => {
    await dispatch(setBanners([]));
  };
};

export const setCurrentUniqs = (currentUniqs) => {
  return {
    type: actionTypes.SET_CURRENT_UNIQS,
    payload: {
      currentUniqs,
    },
  };
};

export const createCookieConsentLog = (agreed: boolean) => {
  return async (dispatch, getState, API) => {
    await API.createCookieConsentLog(
      {},
      {
        user_agreed: agreed,
        layout_type: 1,
      },
    );
  };
};

export const loadSchoolBanners = () => loadBanners(BANNER_SCHOOL);
export const loadHomepageBanners = () => loadBanners(BANNER_HOMEPAGE);
export const resetSchoolBanners = () => loadBanners(BANNER_SCHOOL, true);
export const resetHomepageBanners = () => loadBanners(BANNER_HOMEPAGE, true);
