import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { rem } from 'polished';
import { Media, MediaContextProvider } from '../../../theme/media';

const SmartsuppChatImage = styled.img`
  position: fixed;
  z-index: 2147483647;
  bottom: ${rem(25)};
  right: ${rem(15)};
`;

const SmartsuppChat = () => {
  const [clicked, setClicked] = React.useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  return clicked ? (
    <Helmet>
      <script type="text/javascript">
        {`
      var _smartsupp = _smartsupp || {};
      _smartsupp.key = '31b92bcfc7d154465cfbac2499f8f7a3d2b9567e';
      window.smartsupp||(function(d) {
      var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
      s=d.getElementsByTagName('script')[0];c=d.createElement('script');
      c.type='text/javascript';c.charset='utf-8';c.async=true;
      c.onload = function() { setTimeout(function(){ 
          var iframe = document.getElementById('chat-application-iframe');
          var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
          var button = innerDoc.querySelectorAll('[data-cy]')[0];
          if(button) {
            button.click();
          }
      }, 3000);};
      c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
    })(document);
    `}
      </script>
    </Helmet>
  ) : (
    <MediaContextProvider>
      <Media lessThan="md">
        <SmartsuppChatImage
          src={'/images/chat-mobile.png'}
          loading="lazy"
          onClick={handleClick}
        />
      </Media>
      <Media greaterThanOrEqual="md">
        <SmartsuppChatImage
          src={'/images/chat.png'}
          onClick={handleClick}
          loading="lazy"
        />
      </Media>
    </MediaContextProvider>
  );
};

export default SmartsuppChat;
