import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Heading3 } from '../../components/_helpers/Heading/Heading';
import Alert from '../../components/Alert/Alert';
import {
  Body,
  Head,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from '../../components/_helpers/Table/ResponsiveTable';
import { MainBlock } from './Ucet';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadRenumerationData, setRenumerationLoader } from './actions';
import API, { ThenArg } from '../../services/API';
import {
  formatPrice,
  formatStringDate,
  isSsr,
  prop,
  scrollToClass,
} from '../../utilities';
import { InputStyled } from '../../components/_helpers/form/Input';
import {
  ButtonStyled,
  ButtonStyledStyles,
} from '../../components/_helpers/form/Button';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { ContentService } from '../../services/ContentService';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { loadCmsContent } from '../App/actions';
import { TitleSecondary } from '../../components/_layout/Page/ContentContainer';

const SubTitle = styled(Heading3)`
  margin: 0;
`;

const SubTitleSuccess = styled.span`
  color: ${({ theme }) => theme.color.success};
  font-weight: bold;
`;

const SubTitleSuccessPrice = styled.span`
  color: ${({ theme }) => theme.color.success};
`;

const TableLink = styled(Link)`
  display: inline-block;
  margin-left: ${rem(40)};
`;

const Wrapper = styled.form`
  border: ${rem(2)} solid ${({ theme }) => theme.color.purple};
`;

const Input = styled(InputStyled)`
  border-radius: 0;
  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }};
  &[type='file'] {
    display: none;
  }
  &.small {
    max-width: ${rem(80)};
  }
  &.attachment {
    ${({ theme }) => theme.media('md')} {
      width: ${rem(300)};
    }
  }
`;

const Textarea: any = styled.textarea`
  width: 100%;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  &:focus {
    border-color: ${({ theme }) => theme.color.gray80};
  }
  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }}
`;

const SubmitCss = css`
  background: ${({ theme }) => theme.color.purple};
  border-radius: 0;
`;

const Submit = styled(ButtonStyled)`
  display: block;
  margin-top: ${rem(20)};
  ${SubmitCss}
  &.primary {
    ${SubmitCss}
    &:not(:disabled):hover {
      ${SubmitCss}
    }
  }
`;

const FormHeading = styled.h4`
  width: 100%;
  margin: ${rem(0)};
  padding: ${rem(2)} ${rem(6)};
  background: ${({ theme }) => theme.color.purple};
  color: ${({ theme }) => theme.color.white};
`;

const FormLabel = styled.label`
  flex: 0 0 ${({ theme }) => theme.grid.col.col2};
  align-self: center;
  text-align: right;
  padding-right: ${rem(12)};
`;

const FormContent = styled.div`
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: ${rem(24)};
`;

const FormValueWrapper = styled.div`
  flex: 0 0 ${({ theme }) => theme.grid.col.col10};
  margin: ${rem(6)} 0;
`;

const AdditionalText = styled.p`
  display: inline-block;
  margin: 0 0 0 ${rem(8)};
`;

const LabelInputFile = styled.label`
  display: inline-block;
`;

const AttachmentButtonCss = css`
  background: ${({ theme }) => theme.color.gray80};
  border: none;
  border-radius: 0;
`;

const AttachmentButton = styled.p`
  ${ButtonStyledStyles}
  ${AttachmentButtonCss}
  display: inline-block;
  margin: 0 0 0 ${rem(8)};
  &.primary {
    ${AttachmentButtonCss}
    &:not(:disabled):hover {
      ${AttachmentButtonCss}
    }
  }
`;

const AlertWrapper = styled(Clearfix)`
  margin: ${rem(18)} ${rem(18)} 0 ${rem(18)};
`;

const sitemapUid = 'LOYALTY_POINTS';

interface CreditProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  renumerationData: {
    customerDetails: ThenArg<typeof API.getCustomerDetails>;
    creditHistory: ThenArg<typeof API.searchSkvelkoCreditHistory>;
    awardedUsers: ThenArg<typeof API.searchSkvelkoAwardedUsers>;
    skvelkoClasses: ThenArg<typeof API.searchSkvelkoClass>;
  };
  renumerationLoader: boolean;
  user?: any;
  currentPath: string;
  cmsContent?: any;
  cmsContentLoading?: boolean;
}

interface CreditStates {
  loadingForm: boolean;
  valueDate: string;
  valueDescription: string;
  valuePrice: string;
  valueAttachment: string;
  valueAttachmentFile: any;
  errorDate: string;
  errorDescription: string;
  errorPrice: string;
  errorAttachment: string;
  formSuccessfullySent: boolean;
  formSendError: string | boolean;
  historyCreditSuccessful: string | null;
  historyCreditError: string | null;
  activeTabIndex: any;
  renumerationSettingsModalOpen: any;
  renumerationSettingsModalSelectedId: any;
  renumerationSettingsModalClassId: any;
  removeSkvelkoAwardedPersonOpen: any;
  removeSkvelkoAwardedPersonId: any;
  addSkvelkoAwardedPersonOpen: any;
}

class Credit extends React.PureComponent<CreditProps, CreditStates> {
  public static async getInitialProps(props) {
    props.dispatch(loadCmsContent(sitemapUid));
    if (!props.renumerationData) {
      await props.dispatch(loadRenumerationData());
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingForm: false,
      valueDate: '',
      valueDescription: '',
      valuePrice: '',
      valueAttachment: '',
      errorDate: '',
      errorDescription: '',
      errorPrice: '',
      errorAttachment: '',
      valueAttachmentFile: null,
      formSuccessfullySent: false,
      formSendError: false,
      historyCreditSuccessful: null,
      historyCreditError: null,
      activeTabIndex: 0,
      renumerationSettingsModalOpen: false,
      renumerationSettingsModalSelectedId: null,
      renumerationSettingsModalClassId: null,
      removeSkvelkoAwardedPersonOpen: false,
      removeSkvelkoAwardedPersonId: null,
      addSkvelkoAwardedPersonOpen: false,
    };

    const title = __('Vernostné body');
    props.setTitle(title);
    props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);

    this.closeModal = this.closeModal.bind(this);
  }

  public onTabChange = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  public onSettingsAwardePersonItemClick = (classId, selectedClubUserId) => {
    this.openModal('renumerationSettings');

    this.setState({
      renumerationSettingsModalSelectedId: selectedClubUserId,
      renumerationSettingsModalClassId: classId,
    });
  };

  public openModal(type: string | null) {
    let renumerationSettingsModal = false;
    let removeSkvelkoAwardedPerson = false;
    let addSkvelkoAwardedPerson = false;

    switch (type) {
      case 'renumerationSettings':
        renumerationSettingsModal = true;
        break;
      case 'removeSkvelkoAwardedPerson':
        removeSkvelkoAwardedPerson = true;
        break;
      case 'addSkvelkoAwardedPerson':
        addSkvelkoAwardedPerson = true;
        break;
    }

    this.setState({
      renumerationSettingsModalOpen: renumerationSettingsModal,
      removeSkvelkoAwardedPersonOpen: removeSkvelkoAwardedPerson,
      addSkvelkoAwardedPersonOpen: addSkvelkoAwardedPerson,
    });
  }

  public closeModal = () => {
    this.openModal(null);
  };

  public validateForm = (e) => {
    e.preventDefault();
    let error = false;
    if (this.state.formSuccessfullySent) {
      this.setState({ formSuccessfullySent: false });
    }
    if (this.state.formSendError) {
      this.setState({ formSendError: false });
    }
    if (this.state.valueDate === '') {
      this.setState({ errorDate: __('Povinný údaj') });
      error = true;
    }
    if (this.state.valueDescription === '') {
      this.setState({ errorDescription: __('Povinný údaj') });
      error = true;
    }
    if (this.state.valuePrice === '') {
      this.setState({ errorPrice: __('Povinný údaj') });
      error = true;
    }
    const price = Number(this.state.valuePrice);
    if (price < 0) {
      this.setState({ errorPrice: __('Zadajte nezápornú hodnotu') });
      error = true;
    }
    if (
      price >
      prop(this.props.renumerationData, 'customerDetails.trust_points', 0)
    ) {
      this.setState({
        errorPrice: __(
          'Zadali ste väčšiu sumu, ako sú aktuálne dostupné vernostné body',
        ),
      });
      error = true;
    }
    if (error) {
      return;
    }

    this.props.dispatch(this.submitForm());
  };

  public submitForm = () => {
    return async (dispatch, getState, API_) => {
      this.setState({ loadingForm: true });
      this.setState({ formSendError: false });
      this.setState({ formSuccessfullySent: false });
      this.setState({ historyCreditSuccessful: null });
      this.setState({ historyCreditError: null });
      try {
        await API_.adminApplySkvelkoCredit(
          prop(this.props.renumerationData, 'customerDetails.id', 0),
          this.state.valueDate,
          this.state.valueDescription,
          Number(this.state.valuePrice),
          {},
          this.state.valueAttachmentFile
            ? { file: this.state.valueAttachmentFile }
            : {},
        );
        await this.props.dispatch(loadRenumerationData());
        this.setState({
          formSuccessfullySent: true,
          valueDate: '',
          valueDescription: '',
          valuePrice: '',
          valueAttachment: '',
          valueAttachmentFile: null,
        });
      } catch (e: any) {
        if (e && e.details && e.details.description) {
          this.setState({ formSendError: e.details.description });
        } else {
          this.setState({ formSendError: true });
        }
      }
      this.scrollToAlert();
      this.setState({ loadingForm: false });
    };
  };

  public changeDate = (e) => {
    if (this.state.errorDate !== '') {
      this.setState({ errorDate: '' });
    }
    this.setState({
      valueDate: e.target.value,
    });
  };

  public changeDescription = (e) => {
    if (this.state.errorDescription !== '') {
      this.setState({ errorDescription: '' });
    }
    this.setState({
      valueDescription: e.target.value,
    });
  };

  public changePrice = (e) => {
    if (this.state.errorPrice !== '') {
      this.setState({ errorPrice: '' });
    }
    this.setState({
      valuePrice: e.target.value,
    });
  };

  public changeAttachment = (e) => {
    if (this.state.errorAttachment !== '') {
      this.setState({ errorAttachment: '' });
    }
    if (
      e &&
      e.target &&
      e.target.value &&
      e.target.files &&
      e.target.files[0]
    ) {
      this.setState({
        valueAttachmentFile: e.target.files[0],
      });
      this.setState({
        valueAttachment: e.target.value,
      });
    }
  };

  public stornoCreditApplyClick = (id) => {
    if (
      !isSsr() &&
      window.confirm('Naozaj si želáte stornovať využitie vernostných bodov?')
    ) {
      this.props.dispatch(this.stornoCreditApply(id));
    }
  };

  public stornoCreditApply = (id) => {
    return async (dispatch, getState, _API) => {
      dispatch(setRenumerationLoader(true));
      this.setState({ historyCreditSuccessful: null });
      this.setState({ historyCreditError: null });
      this.setState({ formSendError: false });
      this.setState({ formSuccessfullySent: false });
      try {
        await _API.stornoApplySkvelkoCredit(id);
        await this.props.dispatch(loadRenumerationData());
        this.setState({
          historyCreditSuccessful: __('Stornovanie prebehlo úspešne'),
        });
      } catch (e: any) {
        if (e && e.details && e.details.description) {
          this.setState({ historyCreditError: e.details.description });
        } else {
          this.setState({
            historyCreditError: __(
              'Nepodarilo sa stornovať využitie vernostných bodov',
            ),
          });
        }
      }
      this.scrollToAlert();
      dispatch(setRenumerationLoader(false));
    };
  };

  public scrollToAlert = () => scrollToClass('.alert');

  public getFile = async (file) => {
    this.props.dispatch(setRenumerationLoader(true));
    const token = await API.getFileToken({ query: file });
    const a = document.createElement('a');
    a.href = ContentService.getFileAttachment(file, token.codeToken);
    a.target = '_blank';
    a.click();
    this.props.dispatch(setRenumerationLoader(false));
  };

  public render() {
    const {
      renumerationLoader,
      renumerationData,
      user,
      cmsContentLoading,
      cmsContent,
    } = this.props;

    const creditHistory = prop(renumerationData, 'creditHistory.search', []);
    // const awardedUsers = prop(renumerationData, 'awardedUsers.search', []);

    const cmsContentBody = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
    );

    const isAdmin = user && user.fa === true;

    return (
      <React.Fragment>
        <MainBlock className={'no-top-padding'}>
          <Loader loading={renumerationLoader}>
            <SubTitle>
              {__('Aktuálne dostupné vernostné body')}:{' '}
              <SubTitleSuccess>
                {`${formatPrice(
                  prop(renumerationData, 'customerDetails.trust_points', 0),
                  '',
                  2,
                  false,
                  true,
                )} bodov`}
              </SubTitleSuccess>
              <SubTitleSuccessPrice>
                {` = ${__('zľava')} ${formatPrice(
                  prop(renumerationData, 'customerDetails.trust_points', 0),
                  '',
                  2,
                  false,
                  true,
                )} €`}
              </SubTitleSuccessPrice>
            </SubTitle>
            <SubTitle>
              {__('Vernostné body získané za celú históriu spolupráce')}:{' '}
              <strong>
                {formatPrice(
                  prop(
                    renumerationData,
                    'customerDetails.total_trust_points_reached',
                    0,
                  ),
                  '',
                  2,
                  false,
                  true,
                )}
              </strong>
            </SubTitle>
          </Loader>
          <Loader loading={!!cmsContentLoading}>
            <div dangerouslySetInnerHTML={{ __html: cmsContentBody }} />
          </Loader>
          {isAdmin ? (
            <div>
              <Loader loading={this.state.loadingForm}>
                <Wrapper onSubmit={this.validateForm}>
                  <FormHeading>
                    {__('Využitie vernostných bodov mimo eshop')}
                  </FormHeading>

                  <AlertWrapper>
                    {this.state.formSuccessfullySent && (
                      <Alert type="success" className="alert">
                        {' '}
                        {__('Odoslanie prebehlo úspešne')}{' '}
                      </Alert>
                    )}

                    {this.state.formSendError &&
                      typeof this.state.formSendError === 'boolean' && (
                        <Alert type="error" className="alert">
                          {' '}
                          {__(
                            'Vyskytla sa chyba pri odosielani formularu',
                          )}{' '}
                        </Alert>
                      )}

                    {this.state.formSendError &&
                      typeof this.state.formSendError === 'string' && (
                        <Alert type="error" className="alert">
                          {' '}
                          {this.state.formSendError}{' '}
                        </Alert>
                      )}
                  </AlertWrapper>

                  <FormContent>
                    <FormLabel>{__('Dátum')}:</FormLabel>
                    <FormValueWrapper>
                      <Input
                        type="date"
                        name="date"
                        value={this.state.valueDate}
                        onChange={this.changeDate}
                      />
                      {this.state.errorDate !== '' ? (
                        <ErrorPopup>{this.state.errorDate}</ErrorPopup>
                      ) : (
                        ''
                      )}
                    </FormValueWrapper>

                    <FormLabel>{__('Popis')}:</FormLabel>
                    <FormValueWrapper>
                      <Textarea
                        rows={3}
                        name="description"
                        value={this.state.valueDescription}
                        onChange={this.changeDescription}
                      />
                      {this.state.errorDescription !== '' ? (
                        <ErrorPopup>{this.state.errorDescription}</ErrorPopup>
                      ) : (
                        ''
                      )}
                    </FormValueWrapper>

                    <FormLabel>{__('Suma')}:</FormLabel>
                    <FormValueWrapper>
                      <Input
                        className="small"
                        type="number"
                        name="price"
                        value={this.state.valuePrice}
                        onChange={this.changePrice}
                      />
                      <AdditionalText>
                        {`${__('max')}. `}
                        {prop(
                          renumerationData,
                          'customerDetails.trust_points',
                          0,
                        ).toFixed(2)}{' '}
                        €
                      </AdditionalText>
                      {this.state.errorPrice !== '' ? (
                        <ErrorPopup>{this.state.errorPrice}</ErrorPopup>
                      ) : (
                        ''
                      )}
                    </FormValueWrapper>

                    <FormLabel>{__('Príloha')}:</FormLabel>
                    <FormValueWrapper>
                      <Input
                        id="attachment"
                        type="file"
                        name="attachment"
                        value={this.state.valueAttachment}
                        onChange={this.changeAttachment}
                      />
                      <LabelInputFile for="attachment">
                        <Input
                          type="text"
                          name="fakeAttachment"
                          className="attachment"
                          value={this.state.valueAttachment}
                        />
                        <AttachmentButton>
                          {__('Vybrať súbor')}
                        </AttachmentButton>
                      </LabelInputFile>
                      {this.state.errorAttachment !== '' ? (
                        <ErrorPopup>{this.state.errorAttachment}</ErrorPopup>
                      ) : (
                        ''
                      )}
                    </FormValueWrapper>

                    <FormLabel />
                    <FormValueWrapper>
                      <Submit type="submit" className="primary">
                        {__('Uložiť')}
                      </Submit>
                    </FormValueWrapper>
                  </FormContent>
                </Wrapper>
              </Loader>
              {this.state.historyCreditSuccessful && (
                <Alert type="success" className="alert">
                  {' '}
                  {this.state.historyCreditSuccessful}{' '}
                </Alert>
              )}

              {this.state.historyCreditError && (
                <Alert type="error" className="alert">
                  {' '}
                  {this.state.historyCreditError}{' '}
                </Alert>
              )}
            </div>
          ) : (
            ''
          )}

          <TitleSecondary>
            {__('Prehľad využívania vernostných bodov')}
          </TitleSecondary>
          <Loader loading={renumerationLoader}>
            <TableWrapper>
              <Table>
                <Head>
                  <Tr>
                    <Th>{__('Dátum')}</Th>
                    <Th>{__('Popis')}</Th>
                    <Th>{__('Suma')}</Th>
                  </Tr>
                </Head>
                <Body>
                  {creditHistory.map((item, i) => {
                    return (
                      <Tr key={i}>
                        <Td>{formatStringDate(item.createdAt)}</Td>
                        <Td>{item.description}</Td>
                        <Td>
                          {parseFloat(item.value).toFixed(2)} {'€'}
                          {item.eshopOrderId && item.eshopPublicId ? (
                            <TableLink
                              to={`/my-account/order/${item.eshopPublicId}`}
                            >
                              {__('Detail')}
                            </TableLink>
                          ) : (
                            ''
                          )}
                          {!item.eshopOrderId && item.attachment ? (
                            <TableLink
                              onClick={this.getFile.bind(this, item.attachment)}
                            >
                              {__('Detail')}
                            </TableLink>
                          ) : (
                            ''
                          )}
                          {!item.eshopOrderId && isAdmin ? (
                            <TableLink
                              onClick={this.stornoCreditApplyClick.bind(
                                this,
                                item.id,
                              )}
                            >
                              {__('Storno')}
                            </TableLink>
                          ) : (
                            ''
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Body>
              </Table>
            </TableWrapper>
          </Loader>
          {/*<Hr />*/}
          {/*<SubTitle>{__('Provízia učiteľov')}</SubTitle>*/}
          {/*<StyledAlert icon="trendingUp" type="success">*/}
          {/*  {__('Dohodnutá provízia pre učiteľov')}:{' '}*/}
          {/*  <strong>*/}
          {/*    {prop(*/}
          {/*      renumerationData,*/}
          {/*      'customerDetails.skvelko_provision_person',*/}
          {/*      0,*/}
          {/*    )}{' '}*/}
          {/*    %*/}
          {/*  </strong>*/}
          {/*</StyledAlert>*/}
          {/*<TableWrapper>*/}
          {/*  <Table>*/}
          {/*    <Head>*/}
          {/*      <Tr>*/}
          {/*        <Th>{__('Učiteľ')}</Th>*/}
          {/*        <Th>{__('Dostupné vernostné body')}</Th>*/}
          {/*        <Th>{__('História vernostných bodov')}</Th>*/}
          {/*      </Tr>*/}
          {/*    </Head>*/}
          {/*    <Body>*/}
          {/*      {awardedUsers.map((item, i) => {*/}
          {/*        return (*/}
          {/*          <Tr key={i}>*/}
          {/*            <Td>*/}
          {/*              {this.getUserNameOrEmailFromAwardedUser(*/}
          {/*                item.awardedUser,*/}
          {/*              )}*/}
          {/*            </Td>*/}
          {/*            <Td>*/}
          {/*              <CreditAvailable className={'success'}>*/}
          {/*                {parseFloat(item.awardedUser.trust_points).toFixed(2)}{' '}*/}
          {/*                {'€'}*/}
          {/*              </CreditAvailable>*/}
          {/*            </Td>*/}
          {/*            <Td>*/}
          {/*              {parseFloat(*/}
          {/*                item.awardedUser.highest_trust_points_reached,*/}
          {/*              ).toFixed(2)}{' '}*/}
          {/*              {'€'}{' '}*/}
          {/*            </Td>*/}
          {/*          </Tr>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Body>*/}
          {/*  </Table>*/}
          {/*</TableWrapper>*/}
        </MainBlock>
      </React.Fragment>
    );
  }

  // private getUserNameOrEmailFromAwardedUser(item) {
  //   if (item.name && item.surname) {
  //     return item.name + ' ' + item.surname;
  //   } else if (item.name) {
  //     return item.name;
  //   } else if (item.surname) {
  //     return item.surname;
  //   } else {
  //     return item.email;
  //   }
  // }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    renumerationData: state.myAccount.renumerationData,
    renumerationLoader: state.myAccount.renumerationLoader,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Credit' })(Credit),
);
