import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Heading3 } from '../../components/_helpers/Heading/Heading';
import { MainBlock } from './Ucet';
import Tabs, { Tab } from '../../components/Tabs/Tabs';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import Alert from '../../components/Alert/Alert';
import { Label } from '../../components/_helpers/form/Label';
import { InputStyled } from '../../components/_helpers/form/Input';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import API, { ThenArg } from '../../services/API';
import { loadUserDetail, setUseDeliveryAddress } from './actions';
import { SelectStyled } from '../../components/_helpers/form/Select';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import { prop, scrollToError, scrollToTop } from '../../utilities';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import {
  composeValidators,
  dic,
  email,
  ico,
  phone,
  phoneMobile,
  postalCode,
  required,
  street,
} from '../../utilities/validators';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { theme } from '../../theme/theme';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { parsePostalCode } from '../../utilities/formParsers';

const SubTitle = styled(Heading3)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
  &.no-mb {
    margin-bottom: ${rem(0)};
  }
`;

const SubTitle2 = styled(Heading3)`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const StateSelect = styled(SelectStyled)`
  width: 100%;
  margin-left: ${rem(0)};
  ${({ theme }) => theme.media('xs')} {
    margin-left: ${rem(15)};
  }
`;

const Form = styled.form`
  margin-bottom: ${rem(40)};
  max-width: ${rem(430)};
`;

const Row = styled.div`
  ${({ theme }) => theme.media('xs')} {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${rem(10)};
  }
`;

const StyledLabel = styled(Label)`
  font-size: ${rem(14)};
  flex: 1 0 ${rem(140)};
`;

const InputWrapper = styled.div`
  ${({ theme }) => theme.media('xs')} {
    margin-left: ${rem(15)};
    width: ${rem(290)};
  }
`;

const StyledInputCss: any = css`
  display: block;
  width: 100%;
  ${(props: any) =>
    props.error
      ? css`
          border-color: ${({ theme }) => theme.color.error}!important;
        `
      : ''};
`;

const StyledInput: any = styled(InputStyled)`
  ${StyledInputCss};
`;

const StyledInputEmail: any = styled(EmailAutocomplete)`
  ${StyledInputCss};
`;

const StyledAlert = styled(Alert)`
  margin: ${rem(20)} 0;
`;

const AlertMessage = styled(Alert)`
  margin-top: ${rem(20)} ${({ theme }) => theme.media('sm')} {
    margin-top: ${rem(-20)};
  }

  margin-bottom: ${rem(20)};
`;

const SaveButton = styled(ButtonStyled)`
  margin-top: ${rem(20)}
  width: 100%;
  ${({ theme }) => theme.media('xs')} {
    margin-top: ${rem(15)};
    width: initial;
  }
`;

const DeliveryAddressWrapper = styled.div`
  margin-bottom: ${rem(40)};
`;

const states = [
  { name: 'Slovensko', value: 'Slovensko' },
  // { name: 'Česko', value: 'Česko' },
  // { name: 'Poľsko', value: 'Poľsko' },
];

enum TabIds {
  BASE_INFO_SKVELKO = 'base_info_skvelko',
  BASE_INFO_COMPANY = 'base_info_company',
  BASE_INFO = 'base_info',
  CONTACT_PERSON = 'contact_person',
  CHANGE_PASSWORD = 'change_password',
}

interface SettingsProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  user: ThenArg<typeof API.tokeninfo>;
  userDetail: ThenArg<typeof API.getCustomerDetails>;
  userDetailLoader: boolean;

  lastBillingAddress: any;
  lastDeliveryAddress: any;

  useDeliveryAddress: any;
  currentPath: string;
}

interface SettingsStates {
  activeTabIndex: string | null;

  passwordSuccess: boolean;
  passwordError: string | null;

  contactPersonSuccess: boolean;
  contactPersonError: string | null;

  schoolInfoSuccess: boolean;
  schoolInfoError: string | null;

  deliverySuccess: boolean;

  baseInfoSuccess: boolean;
  baseInfoError: string | null;
}

class Settings extends React.PureComponent<SettingsProps, SettingsStates> {
  public static async getInitialProps(props) {
    await props.dispatch(loadUserDetail(props.user.id));
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: null,

      passwordSuccess: false,
      passwordError: null,

      contactPersonSuccess: false,
      contactPersonError: null,

      schoolInfoSuccess: false,
      schoolInfoError: null,

      deliverySuccess: false,

      baseInfoSuccess: false,
      baseInfoError: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    const title = __('Nastavenia');
    props.setTitle(title);
    props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);
  }

  public onBaseInfoSkvelkoSubmit = async (values) => {
    try {
      const userDetail = this.props.userDetail;
      userDetail.use_delivery_address = this.props.useDeliveryAddress ? 1 : 0;
      await API.updateCustomerDetails(this.props.userDetail.id, {}, userDetail);
      if (
        values.name !== this.props.userDetail.firma ||
        values.city !== this.props.userDetail.mesto ||
        values.street !== this.props.userDetail.ulica ||
        values.street_number !== this.props.userDetail.cislo ||
        values.zip !== this.props.userDetail.psc ||
        values.ico !== this.props.userDetail.ico ||
        values.dic !== this.props.userDetail.dic ||
        (this.props.user.skvelko_type === ChematCustomerType.COMPANY &&
          values.icdph !== this.props.userDetail.icdph)
      ) {
        await API.updateSchoolInformation(
          {},
          {
            name: values.name,
            city: values.city,
            street: values.street,
            street_number: values.street_number,
            zip: values.zip,
            email: this.props.userDetail.email,
            ico: values.ico,
            dic: values.dic,
            icdph: values.icdph,
            contact_name: this.props.userDetail.contact_name,
            phone: this.props.userDetail.telefon,
          },
        );
        this.setState({
          schoolInfoSuccess: true,
          schoolInfoError: null,
        });
      }
      await this.processDeliveryAdress(values);
    } catch (e) {
      this.setState({
        schoolInfoSuccess: false,
        schoolInfoError: __(
          'Pri požiadavke na aktualizáciu údajov nastala chyba',
        ),
        deliverySuccess: false,
      });
    } finally {
      scrollToTop();
    }
  };

  public onContactPersonSubmit = async (values) => {
    try {
      if (
        values.email !== this.props.userDetail.email ||
        values.contact_name !== this.props.userDetail.contact_name ||
        values.phone !== this.props.userDetail.telefon
      ) {
        await API.updateSchoolInformation(
          {},
          {
            name: this.props.userDetail.firma,
            city: this.props.userDetail.mesto,
            street: this.props.userDetail.ulica,
            street_number: this.props.userDetail.cislo,
            zip: this.props.userDetail.psc,
            email: values.email,
            ico: this.props.userDetail.ico,
            dic: this.props.userDetail.dic,
            icdph: this.props.userDetail.icdph,
            contact_name: values.contact_name,
            phone: values.phone,
          },
        );
        this.setState({
          contactPersonSuccess: true,
          contactPersonError: null,
        });
      }
    } catch (e) {
      this.setState({
        contactPersonSuccess: false,
        contactPersonError: __(
          'Pri aktualizácií kontaktnej osoby nastala chyba',
        ),
      });
    } finally {
      scrollToTop();
    }
  };

  public onNewPasswordSubmit = async (values) => {
    const request = {
      current_password: values.old_password,
      new_password: values.new_password,
      password_check: values.new_password_confirmation,
    };

    try {
      await API.changePassword(
        this.props.user.id ? this.props.user.id.toString(10) : '',
        {},
        request,
      );
      this.setState({
        passwordSuccess: true,
        passwordError: null,
      });
    } catch (e: any) {
      this.setState({
        passwordError: e.details.description,
        passwordSuccess: false,
      });
    }
  };

  public processDeliveryAdress = async (values) => {
    if (
      this.props.useDeliveryAddress &&
      (values.delivery_street ||
        '' !== this.props.lastDeliveryAddress.delivery_street ||
        '' ||
        values.delivery_street_number ||
        '' !== this.props.lastDeliveryAddress.delivery_number ||
        '' ||
        values.delivery_zip ||
        '' !== this.props.lastDeliveryAddress.delivery_zip ||
        '' ||
        values.delivery_city ||
        '' !== this.props.lastDeliveryAddress.delivery_city ||
        '' ||
        values.delivery_country ||
        '' !== this.props.lastDeliveryAddress.country ||
        '')
    ) {
      const deliveryAddressBody: any = {
        id: 0,
        id_user: this.props.userDetail.id,
        name: '',
        surname: '',
        delivery_phone: '',
        delivery_street: values.delivery_street ? values.delivery_street : '',
        delivery_number: values.delivery_street_number
          ? values.delivery_street_number
          : '',
        delivery_zip: values.delivery_zip ? values.delivery_zip : '',
        delivery_city: values.delivery_city ? values.delivery_city : '',
        country: values.delivery_country ? values.delivery_country : '',
      };

      if (this.props.lastDeliveryAddress) {
        deliveryAddressBody.id = this.props.lastDeliveryAddress.id;
        await API.updateDeliveryAddress(
          this.props.userDetail.id,
          this.props.lastDeliveryAddress.id,
          {},
          deliveryAddressBody,
        );
      } else {
        await API.createNewDeliveryAddress(
          this.props.userDetail.id,
          {},
          deliveryAddressBody,
        );
      }
      this.setState({ deliverySuccess: true });
    }
  };

  public onBaseInfoSubmit = async (values) => {
    try {
      // user detail
      const userDetail = this.props.userDetail;
      userDetail.meno = values.name ? values.name : '';
      userDetail.priezvisko = values.surname ? values.surname : '';
      userDetail.telefon = values.phone ? values.phone : '';
      userDetail.email = values.email ? values.email : '';
      userDetail.use_delivery_address = this.props.useDeliveryAddress ? 1 : 0;

      await API.updateCustomerDetails(this.props.userDetail.id, {}, userDetail);

      await this.processDeliveryAdress(values);

      const billingAddressBody: any = {
        id: 0,
        id_user: this.props.userDetail.id,
        name: '',
        surname: '',
        billing_phone: '',
        billing_street: values.billing_street ? values.billing_street : '',
        billing_number: values.billing_street_number
          ? values.billing_street_number
          : '',
        billing_zip: values.billing_zip ? values.billing_zip : '',
        billing_city: values.billing_city ? values.billing_city : '',
        country: values.billing_country ? values.billing_country : '',
      };
      if (this.props.lastBillingAddress) {
        billingAddressBody.id = this.props.lastBillingAddress.id;
        await API.updateBillingAddress(
          this.props.userDetail.id,
          this.props.lastBillingAddress.id,
          {},
          billingAddressBody,
        );
      } else {
        await API.createNewBillingAddress(
          this.props.userDetail.id,
          {},
          billingAddressBody,
        );
      }

      this.setState({
        baseInfoSuccess: true,
        baseInfoError: null,
      });
    } catch (e: any) {
      const description =
        e && e.details && e.details.description ? e.details.description : false;
      if (description) {
        this.setState({
          baseInfoSuccess: false,
          baseInfoError: description,
        });
      } else {
        this.setState({
          baseInfoSuccess: false,
          baseInfoError: __('Údaje sa nepodarilo aktualizovať'),
        });
      }
    } finally {
      scrollToTop();
    }
  };

  public onUseDeliveryAddressChange = (e) => {
    this.props.dispatch(
      setUseDeliveryAddress(this.props.useDeliveryAddress ? false : true),
    );
  };

  public render() {
    const {
      user,
      userDetail,
      lastBillingAddress,
      lastDeliveryAddress,
      userDetailLoader,
    } = this.props;
    const {
      activeTabIndex,
      passwordSuccess,
      passwordError,
      contactPersonSuccess,
      contactPersonError,
      schoolInfoSuccess,
      schoolInfoError,
      deliverySuccess,
      baseInfoSuccess,
      baseInfoError,
    } = this.state;

    if (activeTabIndex === null && user) {
      if (user.skvelko_type === ChematCustomerType.SCHOOL) {
        this.setState({
          activeTabIndex: TabIds.BASE_INFO_SKVELKO,
        });
      } else if (user.skvelko_type === ChematCustomerType.COMPANY) {
        this.setState({
          activeTabIndex: TabIds.BASE_INFO_COMPANY,
        });
      } else {
        this.setState({
          activeTabIndex: TabIds.BASE_INFO,
        });
      }
    }

    let items: Array<{ name: string; id: string }> = [];

    if (user && user.skvelko_type === ChematCustomerType.SCHOOL) {
      items = [
        { name: 'Základné údaje', id: TabIds.BASE_INFO_SKVELKO },
        { name: 'Kontaktná osoba', id: TabIds.CONTACT_PERSON },
        { name: 'Zmena hesla', id: TabIds.CHANGE_PASSWORD },
      ];
    } else if (user.skvelko_type === ChematCustomerType.COMPANY) {
      items = [
        { name: 'Základné údaje', id: TabIds.BASE_INFO_COMPANY },
        { name: 'Kontaktná osoba', id: TabIds.CONTACT_PERSON },
        { name: 'Zmena hesla', id: TabIds.CHANGE_PASSWORD },
      ];
    } else {
      items = [
        { name: 'Základné údaje', id: TabIds.BASE_INFO },
        { name: 'Zmena hesla', id: TabIds.CHANGE_PASSWORD },
      ];
    }

    return (
      <React.Fragment>
        <Tabs
          items={items}
          activeId={activeTabIndex}
          onTabChange={this.toggleTab}
          selectedBackgroundColor={theme.color.secondary}
          selectedTextColor={theme.color.black}
        />
        <MainBlock>
          <Tab visible={activeTabIndex === TabIds.BASE_INFO_SKVELKO}>
            <SubTitle>{__('Základné údaje školského zariadenia')}</SubTitle>

            {schoolInfoSuccess && (
              <AlertMessage type="success">
                {__('Požiadavka na aktualizáciu údajov bola odoslaná')}
              </AlertMessage>
            )}

            {schoolInfoError && (
              <AlertMessage type="error">
                {__('Pri zmene údajov nastala chyba, kontaktujte podporu')}
              </AlertMessage>
            )}

            {user && userDetail && !userDetailLoader ? (
              <FinalForm
                onSubmit={this.onBaseInfoSkvelkoSubmit}
                // tslint:disable-next-line:jsx-no-lambda
                render={({ handleSubmit, submitting }) => (
                  <Loader loading={submitting}>
                    <Form onSubmit={handleSubmit}>
                      <Field
                        name="name"
                        validate={required}
                        defaultValue={userDetail.firma}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Názov školy')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="city"
                        validate={required}
                        defaultValue={userDetail.mesto}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Obec')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="street"
                        validate={composeValidators(required, street)}
                        defaultValue={userDetail.ulica}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Ulica')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="street_number"
                        validate={required}
                        defaultValue={userDetail.cislo}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Číslo')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="zip"
                        validate={composeValidators(required, postalCode)}
                        parse={parsePostalCode}
                        defaultValue={userDetail.psc}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('PSČ')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="ico"
                        validate={composeValidators(required, ico)}
                        defaultValue={userDetail.ico}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('IČO')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="dic"
                        validate={composeValidators(required, dic)}
                        defaultValue={userDetail.dic}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('DIČ')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <StyledAlert heading={__('Upozornenie')}>
                        {__(
                          'Zmena základných údajov prebehne až po kontrole v centrále Daffer.',
                        )}
                      </StyledAlert>
                      <ButtonStyled className="primary lg">
                        {__('Odoslať žiadosť o zmenu')}
                      </ButtonStyled>

                      <FormSpy
                        subscription={{ submitFailed: true, modified: false }}
                        onChange={() => {
                          scrollToError();
                        }}
                      />
                    </Form>
                  </Loader>
                )}
              />
            ) : (
              <Loader loading={true} />
            )}
          </Tab>
          <Tab visible={activeTabIndex === TabIds.BASE_INFO_COMPANY}>
            <SubTitle>{__('Základné údaje - organizácia')}</SubTitle>

            {schoolInfoSuccess && (
              <AlertMessage type="success">
                {__('Požiadavka na aktualizáciu údajov bola odoslaná')}
              </AlertMessage>
            )}

            {deliverySuccess && (
              <AlertMessage type="success">
                {__('Dodacia adresa bola úspešne uložená')}
              </AlertMessage>
            )}

            {schoolInfoError && (
              <AlertMessage type="error">
                {__('Pri zmene údajov nastala chyba, kontaktujte podporu')}
              </AlertMessage>
            )}

            {user && userDetail && !userDetailLoader ? (
              <FinalForm
                onSubmit={this.onBaseInfoSkvelkoSubmit}
                // tslint:disable-next-line:jsx-no-lambda
                render={({ handleSubmit, submitting }) => (
                  <Loader loading={submitting}>
                    <Form onSubmit={handleSubmit}>
                      <Field
                        name="name"
                        validate={required}
                        defaultValue={userDetail.firma}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Názov')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="city"
                        validate={required}
                        defaultValue={userDetail.mesto}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Obec')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="street"
                        validate={composeValidators(required, street)}
                        defaultValue={userDetail.ulica}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Ulica')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="street_number"
                        validate={required}
                        defaultValue={userDetail.cislo}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Číslo')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="zip"
                        validate={composeValidators(required, postalCode)}
                        parse={parsePostalCode}
                        defaultValue={userDetail.psc}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('PSČ')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="ico"
                        validate={composeValidators(required, ico)}
                        defaultValue={userDetail.ico}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('IČO')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field
                        name="dic"
                        validate={dic}
                        defaultValue={userDetail.dic}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('DIČ')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      <Field name="icdph" defaultValue={userDetail.icdph}>
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('IČ DPH')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <StyledAlert heading={__('Upozornenie')}>
                        {__(
                          'Zmena základných údajov prebehne až po kontrole v centrále Daffer.',
                        )}
                      </StyledAlert>

                      <SubTitle className="no-mb">
                        {__('Dodacia adresa')}
                      </SubTitle>

                      <DeliveryAddressWrapper>
                        <CheckboxStyled
                          name="use_delivery_address"
                          value="1"
                          id="use_delivery_address"
                          checked={!!this.props.useDeliveryAddress}
                          onChange={this.onUseDeliveryAddressChange}
                        >
                          {__('Dodacia adresa je iná ako fakturačná')}
                        </CheckboxStyled>

                        {this.props.useDeliveryAddress ? (
                          <React.Fragment>
                            <Field
                              name="delivery_street"
                              validate={street}
                              defaultValue={
                                lastDeliveryAddress
                                  ? lastDeliveryAddress.delivery_street
                                  : ''
                              }
                            >
                              {({ input, meta }) => (
                                <Row>
                                  <StyledLabel>{__('Ulica')}</StyledLabel>
                                  <InputWrapper>
                                    <StyledInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorPopup>{meta.error}</ErrorPopup>
                                    )}
                                  </InputWrapper>
                                </Row>
                              )}
                            </Field>

                            <Field
                              name="delivery_street_number"
                              defaultValue={
                                lastDeliveryAddress
                                  ? lastDeliveryAddress.delivery_number
                                  : ''
                              }
                            >
                              {({ input, meta }) => (
                                <Row>
                                  <StyledLabel>{__('Ulica číslo')}</StyledLabel>
                                  <InputWrapper>
                                    <StyledInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorPopup>{meta.error}</ErrorPopup>
                                    )}
                                  </InputWrapper>
                                </Row>
                              )}
                            </Field>

                            <Field
                              name="delivery_city"
                              defaultValue={
                                lastDeliveryAddress
                                  ? lastDeliveryAddress.delivery_city
                                  : ''
                              }
                            >
                              {({ input, meta }) => (
                                <Row>
                                  <StyledLabel>{__('Mesto')}</StyledLabel>
                                  <InputWrapper>
                                    <StyledInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorPopup>{meta.error}</ErrorPopup>
                                    )}
                                  </InputWrapper>
                                </Row>
                              )}
                            </Field>

                            <Field
                              name="delivery_zip"
                              validate={postalCode}
                              parse={parsePostalCode}
                              defaultValue={
                                lastDeliveryAddress
                                  ? lastDeliveryAddress.delivery_zip
                                  : ''
                              }
                            >
                              {({ input, meta }) => (
                                <Row>
                                  <StyledLabel>{__('PSČ')}</StyledLabel>
                                  <InputWrapper>
                                    <StyledInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorPopup>{meta.error}</ErrorPopup>
                                    )}
                                  </InputWrapper>
                                </Row>
                              )}
                            </Field>

                            <Field
                              name="delivery_country"
                              defaultValue={
                                lastDeliveryAddress
                                  ? lastDeliveryAddress.country
                                  : ''
                              }
                            >
                              {({ input, meta }) => (
                                <Row>
                                  <StyledLabel>{__('Štát')}</StyledLabel>
                                  <StateSelect {...input}>
                                    <option value="">
                                      {__('Vyberte štát')}
                                    </option>

                                    {states.map((state, i) => {
                                      return (
                                        <option key={i} value={state.value}>
                                          {' '}
                                          {state.name}{' '}
                                        </option>
                                      );
                                    })}
                                  </StateSelect>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </Row>
                              )}
                            </Field>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </DeliveryAddressWrapper>

                      <ButtonStyled className="primary lg">
                        {__('Odoslať žiadosť o zmenu')}
                      </ButtonStyled>

                      <FormSpy
                        subscription={{ submitFailed: true, modified: false }}
                        onChange={() => {
                          scrollToError();
                        }}
                      />
                    </Form>
                  </Loader>
                )}
              />
            ) : (
              <Loader loading={true} />
            )}
          </Tab>
          <Tab visible={activeTabIndex === TabIds.CONTACT_PERSON}>
            <SubTitle>{`${__('Kontaktná osoba')} (${__(
              'Administrátor účtu',
            )})`}</SubTitle>

            {contactPersonSuccess && (
              <AlertMessage type="success">
                {__('Požiadavka na aktualizáciu údajov bola odoslaná')}
              </AlertMessage>
            )}

            {contactPersonError && (
              <AlertMessage type="error">
                {__('Pri zmene údajov nastala chyba, kontaktujte podporu')}
              </AlertMessage>
            )}

            {user && userDetail ? (
              <FinalForm
                onSubmit={this.onContactPersonSubmit}
                // tslint:disable-next-line:jsx-no-lambda
                render={({ handleSubmit, submitting }) => (
                  <Loader loading={submitting}>
                    <Form onSubmit={handleSubmit}>
                      <Field
                        name="contact_name"
                        validate={required}
                        defaultValue={userDetail.contact_name}
                      >
                        {({ input, meta }) => (
                          <Row>
                            <StyledLabel>{__('Meno')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <Field
                        name="phone"
                        validate={composeValidators(
                          required,
                          phone,
                          phoneMobile,
                        )}
                        defaultValue={userDetail.telefon}
                      >
                        {({ input, meta }) => (
                          <Row>
                            <StyledLabel>{__('Mobilné číslo')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                type="tel"
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <Field
                        name="email"
                        validate={required}
                        defaultValue={userDetail.email}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('Email')}</StyledLabel>
                            <InputWrapper>
                              <StyledInputEmail
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <StyledAlert heading={__('Upozornenie')}>
                        {__(
                          'Zmena základných údajov prebehne až po kontrole v centrále Daffer.',
                        )}
                      </StyledAlert>

                      <SaveButton className="primary lg">
                        {__('Uložiť')}
                      </SaveButton>
                    </Form>
                  </Loader>
                )}
              />
            ) : (
              <Loader loading={true} />
            )}
          </Tab>
          <Tab visible={activeTabIndex === TabIds.CHANGE_PASSWORD}>
            <SubTitle>{__('Nastavenie nového hesla')}</SubTitle>

            {passwordSuccess && (
              <AlertMessage type="success">
                {__('Heslo bolo úspešne zmenené')}
              </AlertMessage>
            )}

            {passwordError && (
              <AlertMessage type="error">{passwordError}</AlertMessage>
            )}

            <FinalForm
              onSubmit={this.onNewPasswordSubmit}
              // tslint:disable-next-line:jsx-no-lambda
              validate={(values: any) => {
                const errors: any = {};

                if (values.new_password !== values.new_password_confirmation) {
                  errors.new_password_confirmation = __('Hesla sa nezhodujú');
                }

                return errors;
              }}
              // tslint:disable-next-line:jsx-no-lambda
              render={({ handleSubmit, submitting }) => (
                <Loader loading={submitting}>
                  <Form onSubmit={handleSubmit}>
                    <Field name="old_password" validate={required}>
                      {({ input, meta }) => (
                        <Row>
                          <StyledLabel>{__('Staré heslo')}</StyledLabel>
                          <InputWrapper>
                            <StyledInput
                              {...input}
                              error={meta.error && meta.touched}
                              type="password"
                            />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </InputWrapper>
                        </Row>
                      )}
                    </Field>

                    <Field name="new_password" validate={required}>
                      {({ input, meta }) => (
                        <Row>
                          <StyledLabel>{__('Nové heslo')}</StyledLabel>
                          <InputWrapper>
                            <StyledInput
                              {...input}
                              error={meta.error && meta.touched}
                              type="password"
                            />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </InputWrapper>
                        </Row>
                      )}
                    </Field>

                    <Field name="new_password_confirmation" validate={required}>
                      {({ input, meta }) => (
                        <Row>
                          <StyledLabel>{__('Nové heslo - znovu')}</StyledLabel>
                          <InputWrapper>
                            <StyledInput
                              {...input}
                              error={meta.error && meta.touched}
                              type="password"
                            />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </InputWrapper>
                        </Row>
                      )}
                    </Field>

                    <SaveButton className="primary lg">
                      {__('Uložiť')}
                    </SaveButton>
                  </Form>
                </Loader>
              )}
            />
          </Tab>

          <Tab visible={activeTabIndex === TabIds.BASE_INFO}>
            {baseInfoSuccess && (
              <AlertMessage type="success">
                {__('Údaje boli aktualizované')}
              </AlertMessage>
            )}

            {baseInfoError && (
              <AlertMessage type="error">{baseInfoError}</AlertMessage>
            )}

            {user && userDetail && !userDetailLoader ? (
              <FinalForm
                onSubmit={this.onBaseInfoSubmit}
                // tslint:disable-next-line:jsx-no-lambda
                render={({ handleSubmit, submitting }) => (
                  <Loader loading={submitting}>
                    <Form onSubmit={handleSubmit}>
                      <Field name="name" defaultValue={userDetail.meno}>
                        {({ input, meta }) => (
                          <Row>
                            <StyledLabel>{__('Meno')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <Field
                        name="surname"
                        defaultValue={userDetail.priezvisko}
                      >
                        {({ input, meta }) => (
                          <Row>
                            <StyledLabel>{__('Priezvisko')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <Field
                        name="email"
                        defaultValue={userDetail.email}
                        validate={email}
                      >
                        {({ input, meta }) => (
                          <Row
                            className={
                              meta.error && meta.touched ? 'error' : ''
                            }
                          >
                            <StyledLabel>{__('E-mail')}</StyledLabel>
                            <InputWrapper>
                              <StyledInputEmail
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>

                      <Field
                        name="phone"
                        validate={composeValidators(phone, phoneMobile)}
                        defaultValue={userDetail.telefon}
                      >
                        {({ input, meta }) => (
                          <Row>
                            <StyledLabel>{__('Mobilné číslo')}</StyledLabel>
                            <InputWrapper>
                              <StyledInput
                                {...input}
                                type="tel"
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputWrapper>
                          </Row>
                        )}
                      </Field>
                      {user && user.skvelko_type !== 4 && (
                        <>
                          <SubTitle2>{`${__('Fakturačná adresa')}`}</SubTitle2>

                          <Field
                            name="billing_street"
                            validate={street}
                            defaultValue={
                              lastBillingAddress
                                ? lastBillingAddress.billing_street
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Ulica')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="billing_street_number"
                            defaultValue={
                              lastBillingAddress
                                ? lastBillingAddress.billing_number
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Ulica číslo')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="billing_city"
                            defaultValue={
                              lastBillingAddress
                                ? lastBillingAddress.billing_city
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Mesto')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="billing_zip"
                            validate={postalCode}
                            parse={parsePostalCode}
                            defaultValue={
                              lastBillingAddress
                                ? lastBillingAddress.billing_zip
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('PSČ')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="billing_country"
                            defaultValue={states[0].name}
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Štát')}</StyledLabel>
                                <StateSelect {...input}>
                                  {states.map((state, i) => {
                                    return (
                                      <option key={i} value={state.value}>
                                        {' '}
                                        {state.name}{' '}
                                      </option>
                                    );
                                  })}
                                </StateSelect>
                                {meta.error && meta.touched && (
                                  <ErrorPopup>{meta.error}</ErrorPopup>
                                )}
                              </Row>
                            )}
                          </Field>

                          <div>
                            <CheckboxStyled
                              name="use_delivery_address"
                              value="1"
                              id="use_delivery_address"
                              checked={
                                this.props.useDeliveryAddress ? true : false
                              }
                              onChange={this.onUseDeliveryAddressChange}
                            >
                              {__('Dodacia adresa je iná ako fakturačná')}
                            </CheckboxStyled>
                          </div>
                        </>
                      )}
                      {this.props.useDeliveryAddress ? (
                        <React.Fragment>
                          <SubTitle2>{`${__('Dodacia adresa')}`}</SubTitle2>

                          <Field
                            name="delivery_street"
                            validate={street}
                            defaultValue={
                              lastDeliveryAddress
                                ? lastDeliveryAddress.delivery_street
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Ulica')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="delivery_street_number"
                            defaultValue={
                              lastDeliveryAddress
                                ? lastDeliveryAddress.delivery_number
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Ulica číslo')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="delivery_city"
                            defaultValue={
                              lastDeliveryAddress
                                ? lastDeliveryAddress.delivery_city
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Mesto')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="delivery_zip"
                            validate={postalCode}
                            parse={parsePostalCode}
                            defaultValue={
                              lastDeliveryAddress
                                ? lastDeliveryAddress.delivery_zip
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('PSČ')}</StyledLabel>
                                <InputWrapper>
                                  <StyledInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup>{meta.error}</ErrorPopup>
                                  )}
                                </InputWrapper>
                              </Row>
                            )}
                          </Field>

                          <Field
                            name="delivery_country"
                            defaultValue={
                              lastDeliveryAddress
                                ? lastDeliveryAddress.country
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <Row>
                                <StyledLabel>{__('Štát')}</StyledLabel>
                                <StateSelect {...input}>
                                  <option value="">{__('Vyberte štát')}</option>

                                  {states.map((state, i) => {
                                    return (
                                      <option key={i} value={state.value}>
                                        {' '}
                                        {state.name}{' '}
                                      </option>
                                    );
                                  })}
                                </StateSelect>
                                {meta.error && meta.touched && (
                                  <ErrorPopup>{meta.error}</ErrorPopup>
                                )}
                              </Row>
                            )}
                          </Field>
                        </React.Fragment>
                      ) : (
                        ''
                      )}

                      <SaveButton className="primary lg">
                        {__('Uložiť')}
                      </SaveButton>

                      <FormSpy
                        subscription={{ submitFailed: true, modified: false }}
                        onChange={() => {
                          scrollToError();
                        }}
                      />
                    </Form>
                  </Loader>
                )}
              />
            ) : (
              <Loader loading={true} />
            )}
          </Tab>
        </MainBlock>
      </React.Fragment>
    );
  }
  private toggleTab = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userDetail: state.myAccount.userDetail,
    userDetailLoader: state.myAccount.userDetailLoader,

    lastBillingAddress: state.myAccount.lastBillingAddress,
    lastDeliveryAddress: state.myAccount.lastDeliveryAddress,

    useDeliveryAddress: state.myAccount.useDeliveryAddress,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Settings' })(Settings),
);
