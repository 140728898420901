import API, { ThenArg } from '../../../../services/API';

export interface SearchResultProps {
  searchDropdownVisible: boolean;
  searchedQuery: string;
  searchedResults: any;
  searchedResultsLoader: boolean;
  onRedirect: (url) => void;
  onSubmitSearchInput: (section: string, redirectUrl?: string | null) => void;
  handleClickOutsideForm: () => void;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  settings: any;
}

export const MAX_FOUNDED_QUERIES = 3;
