import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { prop, formatPrice, isCompanyOrSchool } from '../../utilities';
import {
  HeaderInnerContainer,
  HeaderContainer,
} from '../../components/_helpers/HeaderContainer/HeaderContainer';
import { loadOrderData } from './actions';
import { getProductsPriceFromOrder } from '../Cart/actions';
import { Link } from 'react-router';
import { FlexRowCenterVertical } from '../../components/_helpers/Flexbox/Flexbox';
import { Loader } from '../../components/_helpers/Loader/Loader';
import {
  Button,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../components/_helpers/form/Button';
import { orderAgainAction } from '../App/actions';
import { push } from 'react-router-redux';

const GrayBg = styled.div`
  background: ${({ theme }) => theme.color.gray97};
`;

const Section = styled.div`
  padding: ${({ theme }) => rem(theme.grid.padding / 2)};
  p {
    margin: 0;
  }
  p + p {
    margin-top: ${rem(10)};
  }
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(30)};
  }
`;

const OrderCol = styled.div`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
    &.big {
      flex: 0 0 ${({ theme }) => theme.grid.col.col12};
    }
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
    &.big {
      flex: 0 0 ${({ theme }) => theme.grid.col.col12};
    }
  }
`;

const OrderColBorder = styled(OrderCol)`
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.white};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.white};
  margin-top: ${rem(-2)};
  margin-bottom: ${rem(-2)};
  ${({ theme }) => theme.media('sm')} {
    border-right: ${rem(2)} solid ${({ theme }) => theme.color.white};
    border-left: ${rem(2)} solid ${({ theme }) => theme.color.white};
  }
  ${({ theme }) => theme.media('md')} {
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
`;

const LabelCol = styled.div`
  width: ${({ theme }) => theme.grid.col.col7};
  &.big {
    width: ${({ theme }) => theme.grid.col.col12};
  }
  &.sale {
    color: ${({ theme }) => theme.color.purple};
  }
  &.discount {
    color: ${({ theme }) => theme.color.success};
  }
  &.info {
    color: ${({ theme }) => theme.color.info};
  }
`;

const ValueCol = styled.div`
  width: ${({ theme }) => theme.grid.col.col5};
  &.big {
    width: ${({ theme }) => theme.grid.col.col12};
  }
  &.success {
    color: ${({ theme }) => theme.color.success};
  }
  &.error {
    color: ${({ theme }) => theme.color.error};
  }
  &.sale {
    color: ${({ theme }) => theme.color.purple};
  }
  &.discount {
    color: ${({ theme }) => theme.color.success};
  }
`;

const PriceCol = styled.div`
  font-family: ${({ theme }) => theme.color.primary};
  font-size: ${rem(20)};
  font-weight: 700;
`;

const SubTitle = styled.h3`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.color.primary};
  font-size: ${rem(20)};
  margin-top: 0;
`;

const Row = styled.div`
  & + & {
    margin-top: ${rem(10)};
  }
`;

const RowSummary = styled.div`
  display: flex;
  justify-content: space-between;
  ${ValueCol} {
    text-align: right;
  }
  & + & {
    margin-top: ${rem(10)};
  }
`;

const RowBorder = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.white};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.white};
  /*
  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
  */
`;

const RowTable = styled(RowBorder)`
  flex-direction: column;
`;

const RowBorderSummary = styled(RowBorder)`
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray97};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray97};
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const TableWrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: ${rem(14)};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
`;

const OnDemandTableWrapper = styled(TableWrapper)`
  margin-top: ${rem(16)};
`;

const Table = styled.div`
  ${({ theme }) => theme.media('sm')} {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
`;

const Thead = styled.div`
  ${({ theme }) => theme.media('sm')} {
    display: table-header-group;
  }
`;

const Tbody = styled.div`
  ${({ theme }) => theme.media('sm')} {
    display: table-row-group;
  }
`;

const TableCellStyles = css`
  padding: ${rem(5)} ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    display: table-cell;
  }
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(20)};
  }
`;

const Tr = styled.div`
  border-top: ${rem(1)} solid ${({ theme }) => theme.color.background};
  padding-top: ${rem(5)};
  padding-bottom: ${rem(5)};
  ${({ theme }) => theme.media('sm')} {
    display: table-row;
  }
  ${({ theme }) => theme.media('md')} {
    padding: 0;
  }
`;

const Th = styled.div`
  ${TableCellStyles};
  font-weight: 700;
`;

const Th1perc = css`
  white-space: nowrap;
  width: 1%;
`;

const ThPrice = styled(Th)`
  ${Th1perc};
  text-align: right;
`;

const ThAmount = styled(Th)`
  ${Th1perc};
  text-align: center;
`;

const Td = styled(FlexRowCenterVertical)`
  ${TableCellStyles};
`;

const TdImage = styled.img`
  width: ${rem(40)};
  height: ${rem(40)};
  margin-right: ${rem(20)};
`;

const CellNoWrap = css``;

const TdPrice = styled(Td)`
  ${CellNoWrap};
  ${({ theme }) => theme.media('md')} {
    text-align: right;
  }
`;

const TdAmount = styled(Td)`
  ${CellNoWrap};
  ${({ theme }) => theme.media('md')} {
    text-align: center;
  }
`;

const TdTotal = styled(TdPrice)`
  font-weight: 700;
`;

const ItemLink = styled(Link)``;

const Footer = styled.div`
  margin-top: ${rem(20)};
  text-align: center;
`;

const OrderAgainButton = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
`;

interface Props {
  setTitle: (title: string, link?: string | null) => void;
  dispatch: (a: any) => void;
  setBreadcrumbsItems?: any;

  params: any;
  order: any;
  currentPath: string;
  router: any;
}

class Order extends React.PureComponent<Props> {
  public static async getInitialProps(props) {
    const { params, dispatch } = props;
    const { orderPublicId } = params;

    try {
      dispatch(loadOrderData(orderPublicId));
    } catch (exp) {
      console.error(exp);
    }
  }

  constructor(props) {
    super(props);
  }

  public onClickOrderAgain = () => {
    const publicId = this.props.params.orderPublicId;
    this.props.dispatch(
      orderAgainAction(publicId, (result) => {
        this.props.dispatch(push('/kosik'));
      }),
    );
  };

  public render() {
    const { order } = this.props;
    const orderData = prop(order, 'order', {});
    const isFetching = prop(order, 'isFetching', false);
    const schoolOrCompany = isCompanyOrSchool(orderData);

    if (this.props?.order?.order) {
      const title = this.props?.order?.order?.isDemand
        ? __('Detail žiadosti o cenu')
        : __('Detail objednávky');
      const prevUrl = '/my-account/my-orders';
      this.props.setTitle(title, this.props.router.goBack);
      this.props.setBreadcrumbsItems([
        { name: __('História objednávok'), url: prevUrl },
        { name: title, url: this.props.currentPath },
      ]);
    }

    if (isFetching) {
      return <Loader loading={true} />;
    }

    const {
      order_tprice,
      order_tpricecurrency,
      order_tprice_without_vat,
      order_nr,
      items,
      consumed_trust_points,
      giftcards,
      customer_card_price,
      delivery_school_base_info,
      children_name,
      delivery_class_name,
    } = orderData;
    const email = prop(orderData, 'person.email', '');
    const phone = prop(orderData, 'person.phone', '');
    const addressName = prop(orderData, 'address.person.name', '');
    const addressSurname = prop(orderData, 'address.person.surname', '');
    const addressStreet = prop(orderData, 'address.street', '');
    const addressNumber = prop(orderData, 'address.street_number', '');
    const addressCity = prop(orderData, 'address.city', '');
    const addressZip = prop(orderData, 'address.zip', '');
    const addressCountry = prop(orderData, 'address.country', '');
    const companyName = prop(orderData, 'company.name', '');

    const deliveryAddressName = prop(orderData, 'delivery.person.name', '');
    const deliveryAddressSurname = prop(
      orderData,
      'delivery.person.surname',
      '',
    );
    const deliveryAddressStreet = prop(
      orderData,
      'delivery.delivery_addr_street',
      '',
    );
    const deliveryAddressNumber = prop(
      orderData,
      'delivery.delivery_addr_street_number',
      '',
    );
    const deliveryAddressCity = prop(
      orderData,
      'delivery.delivery_addr_city',
      '',
    );
    const deliveryAddressZip = prop(
      orderData,
      'delivery.delivery_addr_zip',
      '',
    );
    const deliveryAddressCountry = prop(
      orderData,
      'delivery.delivery_addr_country',
      '',
    );

    const deliveryAddressSchoolName = prop(
      delivery_school_base_info,
      'name',
      '',
    );
    const deliveryAddressSchoolStreet = prop(
      delivery_school_base_info,
      'street',
      '',
    );
    const deliveryAddressSchoolNumber = prop(
      delivery_school_base_info,
      'street_number',
      '',
    );
    const deliveryAddressSchoolCity = prop(
      delivery_school_base_info,
      'city',
      '',
    );
    const deliveryAddressSchoolZip = prop(delivery_school_base_info, 'zip', '');

    const deliveryType = prop(orderData, 'delivery.delivery_typename', '');
    const paymentTypeName = prop(orderData, 'payment.payment_typename', '');

    const deliveryPrice = prop(orderData, 'payment.delivery_price', 0);
    const paymentPrice = prop(orderData, 'payment.payment_price', 0);

    const isDemand = orderData.isDemand;

    const regularItemsRows =
      items &&
      items
        .filter((item) => !item.on_demand)
        .filter((item) => !item.is_invisible_gift)
        .map((item) => {
          if (item.product_id) {
            let name = item.product_name;
            if (item.product_note && item.product_note !== '') {
              name += ' - ' + item.product_note;
            }
            const url = item.product_url.startsWith('/')
              ? item.product_url
              : '/' + item.product_url;
            return (
              <Tr key={item.product_id}>
                <Td>
                  {' '}
                  <TdImage
                    src={`${process.env.REACT_APP_API_BASE_URL}/image?query=${item.product_picture}&width=40&height=40`}
                  />{' '}
                  <ItemLink to={`${process.env.REACT_APP_URL}${url}`}>
                    {name}
                  </ItemLink>
                </Td>

                <TdPrice>{formatPrice(item.unitprice, item.currency)}</TdPrice>
                <TdAmount>{item.quantity}</TdAmount>
                <TdTotal>{formatPrice(item.totalprice, item.currency)}</TdTotal>
              </Tr>
            );
          }
          return null;
        });

    const onDemandItemsRows =
      items &&
      items
        .filter((item) => item.on_demand)
        .filter((item) => !item.is_invisible_gift)
        .map((item) => {
          if (item.product_id) {
            let name = item.product_name;
            if (item.product_note && item.product_note !== '') {
              name += ' - ' + item.product_note;
            }
            const url = item.product_url.startsWith('/')
              ? item.product_url
              : '/' + item.product_url;
            return (
              <Tr key={item.product_id}>
                <Td>
                  {' '}
                  <TdImage
                    src={`${process.env.REACT_APP_API_BASE_URL}/image?query=${item.product_picture}&width=40&height=40`}
                  />{' '}
                  <ItemLink to={`${process.env.REACT_APP_URL}${url}`}>
                    {name}
                  </ItemLink>
                </Td>
                <TdAmount>{item.quantity}</TdAmount>
              </Tr>
            );
          }
          return null;
        });

    const productsPrice = getProductsPriceFromOrder(orderData);

    return (
      <>
        <HeaderContainer>
          <GrayBg>
            <RowBorder>
              <OrderCol className={'big'}>
                <Section>
                  <Row className="row">
                    <LabelCol className="big">
                      {isDemand ? __('Číslo žiadosti') : __('Číslo objednávky')}
                      : <strong>{order_nr}</strong>
                    </LabelCol>
                  </Row>

                  {!isDemand && (
                    <>
                      <Row className="row">
                        <LabelCol className="big">
                          {__('Suma (s DPH)')}:{' '}
                          <strong>
                            {formatPrice(
                              order_tprice || 0,
                              order_tpricecurrency,
                            )}
                          </strong>
                        </LabelCol>
                      </Row>
                      <Row className="row">
                        <LabelCol className="big info">
                          {__('Doprava')}: <strong>{deliveryType}</strong>
                        </LabelCol>
                      </Row>
                      <Row className="row">
                        <LabelCol className="big info">
                          {__('Platba')}: <strong>{paymentTypeName}</strong>
                        </LabelCol>
                      </Row>
                    </>
                  )}
                </Section>
              </OrderCol>
            </RowBorder>
            <RowBorder>
              <OrderColBorder>
                <Section>
                  <SubTitle>{__('Kontaktné údaje')}</SubTitle>
                  <Row className="row">
                    <LabelCol className="col">{__('Telefón')}</LabelCol>
                    <ValueCol className="col">{phone}</ValueCol>
                  </Row>
                  <Row className="row">
                    <LabelCol className="col">{__('E-mail')}</LabelCol>
                    <ValueCol className="col">{email}</ValueCol>
                  </Row>
                </Section>
              </OrderColBorder>
              {!isDemand && (
                <>
                  <OrderColBorder>
                    <Section>
                      <SubTitle>{__('Fakturačné údaje')}</SubTitle>
                      <p>
                        <strong>
                          {schoolOrCompany
                            ? `${companyName}`
                            : `${addressName} ${addressSurname}`}
                        </strong>
                      </p>
                      <p>
                        {addressStreet} {addressNumber}, {addressCity}{' '}
                        {addressZip}
                      </p>
                      <p>{addressCountry}</p>
                    </Section>
                  </OrderColBorder>
                  <OrderColBorder>
                    <Section>
                      <SubTitle>{__('Adresa doručenia')}</SubTitle>
                      {delivery_school_base_info ? (
                        <>
                          <p>
                            <strong>{deliveryAddressSchoolName}</strong>
                          </p>
                          <p>
                            {deliveryAddressSchoolStreet}{' '}
                            {deliveryAddressSchoolNumber},{' '}
                            {deliveryAddressSchoolCity}{' '}
                            {deliveryAddressSchoolZip}
                          </p>
                          {children_name ? (
                            <p>
                              <strong>{__('Meno žiaka')}: </strong>
                              {children_name}
                            </p>
                          ) : (
                            ''
                          )}
                          {delivery_class_name ? (
                            <p>
                              <strong>{__('Trieda')}: </strong>
                              {delivery_class_name}
                            </p>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <>
                          <p>
                            <strong>
                              {deliveryAddressName} {deliveryAddressSurname}
                            </strong>
                          </p>
                          <p>
                            {deliveryAddressStreet} {deliveryAddressNumber},{' '}
                            {deliveryAddressCity} {deliveryAddressZip}
                          </p>
                          <p>{deliveryAddressCountry}</p>
                        </>
                      )}
                    </Section>
                  </OrderColBorder>
                </>
              )}
            </RowBorder>
            <RowTable>
              <Section>
                {!isDemand && <SubTitle>{__('Objednaný tovar')}</SubTitle>}
                {regularItemsRows && regularItemsRows.length ? (
                  <TableWrapper>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>{__('Názov')}</Th>

                          <ThPrice>
                            {__('Cena')}/{__('MJ')}
                          </ThPrice>

                          <ThAmount>{__('Množstvo')}</ThAmount>
                          <ThPrice>{__('Celkom s DPH')}</ThPrice>
                        </Tr>
                      </Thead>
                      <Tbody>{regularItemsRows}</Tbody>
                    </Table>
                  </TableWrapper>
                ) : (
                  <></>
                )}

                {onDemandItemsRows && onDemandItemsRows.length ? (
                  <OnDemandTableWrapper>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>{__('Produkty pre kalkuláciu')}</Th>
                          <ThAmount>{__('Množstvo')}</ThAmount>
                        </Tr>
                      </Thead>
                      <Tbody>{onDemandItemsRows}</Tbody>
                    </Table>
                  </OnDemandTableWrapper>
                ) : (
                  <></>
                )}
              </Section>
            </RowTable>
          </GrayBg>
          {!isDemand && (
            <HeaderInnerContainer>
              <SubTitle>{__('Sumarizácia')}</SubTitle>
              <RowBorderSummary>
                <OrderCol>
                  <RowSummary>
                    <LabelCol>{__('Doprava')}</LabelCol>
                    <ValueCol className={deliveryPrice === 0 ? 'success' : ''}>
                      {deliveryPrice === 0
                        ? 'Zdarma'
                        : formatPrice(deliveryPrice || 0, 'EUR')}
                    </ValueCol>
                  </RowSummary>
                  <RowSummary>
                    <LabelCol>{__('Platba')}</LabelCol>
                    <ValueCol>{formatPrice(paymentPrice || 0, 'EUR')}</ValueCol>
                  </RowSummary>
                  <RowSummary>
                    <LabelCol>{__('Objednávka')}</LabelCol>
                    <ValueCol>
                      {formatPrice(productsPrice || 0, 'EUR')}
                    </ValueCol>
                  </RowSummary>
                  {consumed_trust_points ? (
                    <RowSummary>
                      <LabelCol className="sale">
                        {__('Zľava - vernostné body')}
                      </LabelCol>
                      <ValueCol className="sale">
                        {formatPrice(consumed_trust_points * -1, 'EUR')}
                      </ValueCol>
                    </RowSummary>
                  ) : (
                    ''
                  )}
                  {giftcards ? (
                    <RowSummary>
                      <LabelCol className="discount">
                        {__('Zľavavý kupón')}
                      </LabelCol>
                      <ValueCol className="discount">
                        {formatPrice(giftcards * -1, 'EUR')}
                      </ValueCol>
                    </RowSummary>
                  ) : (
                    ''
                  )}
                  {customer_card_price ? (
                    <RowSummary>
                      <LabelCol className="discount">
                        {__('Zákaznícka karta')}
                      </LabelCol>
                      <ValueCol className="discount">
                        {formatPrice(customer_card_price * -1, 'EUR')}
                      </ValueCol>
                    </RowSummary>
                  ) : (
                    ''
                  )}
                  <RowSummary>
                    <LabelCol>{__('Celkom bez DPH')}</LabelCol>
                    <ValueCol>
                      {formatPrice(order_tprice_without_vat || 0, 'EUR')}
                    </ValueCol>
                  </RowSummary>
                  <RowSummary>
                    <LabelCol>{__('Celkom s DPH')}</LabelCol>
                    <ValueCol>{formatPrice(order_tprice || 0, 'EUR')}</ValueCol>
                  </RowSummary>
                </OrderCol>
              </RowBorderSummary>
              <RowBorder>
                <OrderCol>
                  <RowSummary>
                    <LabelCol>{__('K úhrade')}</LabelCol>
                    <PriceCol>
                      {formatPrice(order_tprice || 0, order_tpricecurrency)}
                    </PriceCol>
                  </RowSummary>
                </OrderCol>
              </RowBorder>
            </HeaderInnerContainer>
          )}
        </HeaderContainer>
        {!isDemand && (
          <Footer>
            <OrderAgainButton
              className="primary lg"
              onClick={this.onClickOrderAgain}
            >
              {' '}
              {__('Objednať znovu')}{' '}
            </OrderAgainButton>
          </Footer>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.myAccount.orderDetail,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Order' })(Order),
);
