import * as React from 'react';
import { URL_VIDEO_TUTORIALS } from '../../constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadCmsContent } from '../App/actions';
import { prop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import API, { ThenArg } from '../../services/API';

const sitemapUid = 'VIDEO_TUTORIALS';

interface VideoTutorialsProps {
  cmsContent?: any;
  cmsContentLoading?: boolean;
  userDetail?: ThenArg<typeof API.getCustomerDetails>;
  setTitle: (title: string, link?: string | null) => void;
}

class VideoTutorials extends React.PureComponent<VideoTutorialsProps> {
  public static async getInitialProps(props) {
    props.dispatch(loadCmsContent(sitemapUid));
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: 'Videonávody', url: URL_VIDEO_TUTORIALS },
    ]);
  }

  public render() {
    const { cmsContentLoading, cmsContent, setTitle } = this.props;

    setTitle(prop(cmsContent, `${sitemapUid}.content.json_content.name`, ''));

    const body = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
      '',
    );
    return (
      <Loader loading={!!cmsContentLoading}>
        <div
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'VideoTutorials' })(VideoTutorials),
);
