import * as React from 'react';
import { lazy, Suspense } from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import {
  formatPrice,
  prop,
  getProductPrices,
  getCustomerPrice,
  isDiscountInCart,
  parseTextWithShortcode,
  isOutOfStock,
  itemIsSkvelkoAndGoodPriceEnabled,
} from '../../utilities';
import {
  ButtonIcon,
  ButtonStyledStyles,
  ButtonStyles,
} from '../_helpers/form/Button';
import ImagePlaceholder from '../_helpers/Image/ImagePlaceholder';
import { Col, Row } from '../../theme/libraries/grid';
import { Link } from 'react-router';
import Icon from '../_helpers/Icon/Icon';
import { addItemToCart } from '../../containers/Cart/actions';
import InputSpinner from '../_helpers/form/InputSpinner';
import { hasProductVariants } from '../../containers/Product/actions';
import GalleryModal from '../_helpers/Modal/GalleryModal';
import {
  getOnStockCount,
  setAddToCartMaxItemExceededModalProduct,
  setAddToCartModalProductOutOfStock,
  setAddToCartModalProductQuantityLimit,
} from '../../containers/App/actions';
import { SelectStyled } from '../_helpers/form/Select';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import { Loader } from '../_helpers/Loader/Loader';
import { AttribDemand } from '../../containers/Product/Product';
import { scrollToAnchor } from '../../containers/Home/actions';
import SaleStickerProduct from '../_helpers/product/SaleStickerProduct';

interface ProductProps {
  product: any;
  user?: any;
  cart?: any;
  settings?: any;
}

interface ProductFormProps extends ProductProps {
  product: any;
  dispatch: any;
  count: number;
  onCountChange: (count: string) => void;
  showModal: (modal: boolean) => void;
  setVariationError?: (error: boolean) => void;
  cart?: any;
  goodId?: number | null;
  attribDemandObj?: Array<AttribDemand>;
}

interface ProductFormState {
  loading: boolean;
}

interface ProductClassNameProps extends ProductProps {
  className?: string;
}

interface ProductOnChangeProps extends ProductProps {
  onChange: any;
  isError?: boolean;
  attribsObject?: any;
  notAllVariantsSelected?: boolean;
  isSelected?: boolean;
}

interface ProductDemandVariantSelectProps extends ProductProps {
  onChange: any;
  isError?: boolean;
  attribDemandObj: Array<AttribDemand>;
}

interface ProductGalleryStates {
  modalVisible: boolean;
  activeImageIndex: number;
}

// =============================================================
// ================= PRODUCT_GALLERY COMPONENT ===================
// =============================================================

const GalleryWrapper = styled.div`
  max-width: ${rem(530)};
  margin: auto;
`;

const ImageWrapperStyles = css`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
`;

const Sticker = css`
  text-align: center;
  text-transform: uppercase;
  font-size: ${rem(24)};
  font-weight: 800;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  line-height: ${rem(25)};
  width: ${rem(115)};
  height: ${rem(115)};
  position: absolute;
  top: ${rem(10)};
  left: ${rem(10)};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StickerRight = css`
  ${Sticker};
  left: unset;
  right: ${rem(10)};
  line-height: ${rem(70)};
  width: ${rem(70)};
  height: ${rem(70)};
  font-size: ${rem(15)};
`;

const CustomerPriceSticker = styled.div`
  ${Sticker};
  padding: ${rem(10)};
  line-height: 1.3;
  background: ${({ theme }) => theme.color.green};
`;

const ImageHolder = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  display: block;
  ${ImageWrapperStyles};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  margin: auto;
  border-radius: ${rem(4)};
  padding: 0;
  width: 100%;
`;

const Thumbs = styled(Row)`
  margin: ${rem(5)} ${rem(-5)} ${rem(-5)};
`;

const Thumb = styled(Col)`
  padding: ${rem(5)};
  width: ${({ theme }) => theme.grid.col.col4};
`;

const ThumbWrapper = styled.div`
  display: block;
  ${ImageWrapperStyles};
  cursor: pointer;
`;

const ThumbImage = styled(ImagePlaceholder)`
  border-radius: ${rem(4)};
  padding: 0;
  width: 100%;
`;

export const ProductBody = styled.div`
  width: ${rem(530)};
  max-width: 100%;
  margin: auto;
`;

const NewsSticker = styled.div`
  ${StickerRight};
  background: #406df3;
`;

export class ProductGallery extends React.PureComponent<
  ProductProps,
  ProductGalleryStates
> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeImageIndex: 0,
    };
  }

  public showModal = (activeImageIndex) => {
    this.setState({
      modalVisible: true,
      activeImageIndex,
    });
  };

  public closeModal = () => {
    this.setState({ modalVisible: false });
  };

  public getImageId(mainImage) {
    // example: product/mainImages/30000010251.png -> 30000010251
    return !mainImage
      ? undefined
      : mainImage.substring(
          mainImage.lastIndexOf('/') + 1,
          mainImage.lastIndexOf('.'),
        );
  }

  public render() {
    const { product } = this.props;
    const placeholder = '/images/placeholder-520.jpg';
    const placeholder2x = '/images/placeholder-520@2x.jpg';
    const placeholderThumb = '/images/placeholder-200.png';
    const placeholderThumb2x = '/images/placeholder-200@2x.png';

    let photos = prop(product.productDetail, 'photogallery', []);
    let mainImage = null; //product.productDetail.picture;
    if (!mainImage && photos && photos.length) {
      mainImage = photos[0];
    }

    if (!photos.length) {
      photos = ['images/placeholder-520.jpg'];
    }
    const mainImageId = this.getImageId(mainImage);
    const mainImageIndex = mainImageId
      ? photos.findIndex((p) =>
          p ? this.getImageId(p).startsWith(mainImageId) : false,
        )
      : 0;

    const isSale = !!product.productDetail?.main_good?.oldprice;
    const isCustomerPrice = !!getCustomerPrice(
      product.productDetail?.main_good,
      this.props.user,
    );

    let isNew = false;
    if (
      !isSale &&
      (product.productDetail?.is_new || product.productDetail?.isnew) &&
      (!product.productDetail?.isnew_valid ||
        new Date(product.productDetail?.isnew_valid) > new Date())
    ) {
      isNew = true;
    }

    return (
      <GalleryWrapper>
        <ImageWrapper onClick={this.showModal.bind(this, mainImageIndex || 0)}>
          {product.productDetail ? (
            <ImageHolder>
              {product?.productDetail?.main_good?.price_create_type === 1 ? (
                <></>
              ) : (
                <>
                  {isSale ? (
                    <SaleStickerProduct
                      product={product}
                      enableGoodPrice={
                        !!this.props?.settings?.eshop_actionTypeGoodPrice
                      }
                    />
                  ) : (
                    ''
                  )}
                  {isNew ? <NewsSticker>{__('NOVÉ')}</NewsSticker> : ''}
                  {isCustomerPrice ? (
                    <CustomerPriceSticker>
                      {__('Cena pre vás')}
                    </CustomerPriceSticker>
                  ) : (
                    ''
                  )}
                </>
              )}

              <Image
                src={mainImage}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
                w={360}
                h={360}
                notLazyloading={true}
                alt={__('Hlavný obrázok produktu')}
                fetchPriority="high"
                aspectRatio="1/1"
              />
            </ImageHolder>
          ) : (
            ''
          )}
        </ImageWrapper>
        {product.productDetail && photos ? (
          <Thumbs>
            {photos &&
              photos.map((photo, i) => {
                return (
                  <Thumb key={i}>
                    <ThumbWrapper onClick={this.showModal.bind(this, i)}>
                      <ThumbImage
                        src={photo}
                        placeholder={placeholderThumb}
                        retinaPlaceholder={placeholderThumb2x}
                        w={142}
                        h={142}
                        alt={__('Obrázok produktu č.') + ' ' + i}
                        aspectRatio="1/1"
                      />
                    </ThumbWrapper>
                  </Thumb>
                );
              })}
          </Thumbs>
        ) : (
          ''
        )}
        {this.state.modalVisible && (
          <GalleryModal
            pictures={photos}
            onCloseButtonClick={this.closeModal}
            activeIndex={this.state.activeImageIndex}
          />
        )}
      </GalleryWrapper>
    );
  }
}

// =============================================================
// ================= PRODUCT_TITLE COMPONENT ===================
// =============================================================

const Title = styled.h1`
  font-size: ${rem(24)};
  font-weight: 800;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(32)};
  }
  ${({ theme }) => theme.media(1024)} {
    margin-top: 0;
  }
`;

export class ProductTitle extends React.PureComponent<ProductProps> {
  public render() {
    const { product } = this.props;
    return (
      <Title>
        {product.productDetail !== null ? product.productDetail.name : ''}
      </Title>
    );
  }
}

// =============================================================
// ================= PRODUCT_SHORT_DESCRIPTION COMPONENT =======
// =============================================================

const ShortDescription = styled.div`
  &.detail {
    font-size: ${rem(15)};
    margin-bottom: ${rem(20)};
  }
  &.preview {
    font-size: ${rem(12)};
    margin-bottom: ${rem(30)};
  }
`;

export class ProductShortDescription extends React.PureComponent<ProductClassNameProps> {
  public componentDidMount() {
    const collection = document.getElementsByClassName('anchor-link');
    for (let i = 0; i < collection.length; i++) {
      const item = collection[i];
      item.addEventListener(
        'click',
        function (event: any) {
          event.preventDefault();
          if (event.target) {
            let closestA = event.target;
            if (closestA.tagName !== 'a') {
              closestA = event.target.closest('a');
            }
            if (closestA.dataset.anchor) {
              scrollToAnchor(closestA.dataset.anchor);
            }
          }
        },
        false,
      );
    }
  }

  public render() {
    const { product } = this.props;
    if (
      product &&
      product.publish &&
      product.publish.length > 0 &&
      product.publish[0].content &&
      product.publish[0].content.json_content &&
      product.publish[0].content.json_content.short_description &&
      typeof product.publish[0].content.json_content.short_description ===
        'string'
    ) {
      return (
        <ShortDescription
          className={this.props.className}
          dangerouslySetInnerHTML={{
            __html: product.publish[0].content.json_content.short_description
              .replace(new RegExp('\n', 'g'), '<br>')
              .replace(new RegExp('</li><br>', 'g'), '</li>')
              .replace(new RegExp('<br><li>', 'g'), '<li>')
              .replace(new RegExp('</ul><br>', 'g'), '</ul>')
              .replace(new RegExp('<br></ul>', 'g'), '</ul>')
              /*
              .replace(
                'http://daffer.sk/wp-content/',
                'http://skoly.daffer.sk/wp-content/',
              )
              */
              .replace(
                'http://daffer.sk/wp-content/themes/daffer/img/icons/',
                '/images/icons/school/',
              )
              .replace(new RegExp('Čítaj ďalej.', 'g'), '</li>'),
          }}
        />
      );
    } else if (
      product &&
      product.publish &&
      product.publish.length > 0 &&
      product.publish[0].product_description
    ) {
      return (
        <ShortDescription
          className={this.props.className}
          dangerouslySetInnerHTML={{
            __html: product.publish[0].product_description
              .replace(new RegExp('\n', 'g'), '<br>')
              .replace(new RegExp('</li><br>', 'g'), '</li>')
              .replace(new RegExp('<br><li>', 'g'), '<li>')
              .replace(new RegExp('</ul><br>', 'g'), '</ul>')
              .replace(new RegExp('<br></ul>', 'g'), '</ul>')
              /*
              .replace(
                'http://daffer.sk/wp-content/',
                'http://skoly.daffer.sk/wp-content/',
              )
              */
              .replace(
                'http://daffer.sk/wp-content/themes/daffer/img/icons/',
                '/images/icons/school/',
              ),
          }}
        />
      );
    } else {
      return (
        <ShortDescription className={this.props.className}>
          {''}
        </ShortDescription>
      );
    }
  }
}

// =============================================================
// ================= PRODUCT_ALTERNATIVES COMPONENT ============
// =============================================================

const AlternativeProducts = styled.div`
  margin: ${rem(10)} ${rem(-5)} ${rem(15)};
`;

const AlternativeProductStyles = css`
  display: inline-block;
  ${ImageWrapperStyles};
  border: ${rem(2)} solid transparent;
  padding: ${rem(6)};
  margin: ${rem(5)};
`;

const AlternativeProduct = styled(Link)`
  ${AlternativeProductStyles};
  ${({ theme }) => theme.transition('border-color')};
  &:hover {
    border-color: ${({ theme }) => rgba(theme.color.primary, 0.5)};
  }
`;

const AlternativeProductCurrent = styled.span`
  ${AlternativeProductStyles};
  border-color: ${({ theme }) => theme.color.primary};
`;

const AlternativeProductThumb = styled(ImagePlaceholder)`
  ${({ theme }) => theme.size(60)};
`;

export class ProductAlternatives extends React.PureComponent<ProductProps> {
  public render() {
    const placeholder = '/images/placeholder-100.png';
    const placeholder2x = '/images/placeholder-100@2x.png';
    if (
      this.props.product.linkedProducts &&
      this.props.product.linkedProducts.length > 1
    ) {
      let isAnyAvailable = false;
      for (const linkedProduct of this.props.product.linkedProducts) {
        if (!isOutOfStock(linkedProduct)) {
          isAnyAvailable = true;
          break;
        }
      }

      return (
        <AlternativeProducts>
          {this.props.product.linkedProducts.map((product, i) => {
            const isAvailable = !isOutOfStock(product);

            if (
              this.props.product.productDetail &&
              this.props.product.productDetail.product_id ===
                product.product_id &&
              isAnyAvailable
            ) {
              return (
                <AlternativeProductCurrent key={i}>
                  <AlternativeProductThumb
                    src={product.picture}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    w={100}
                    h={100}
                  />
                </AlternativeProductCurrent>
              );
            } else if (isAvailable) {
              return (
                <AlternativeProduct
                  to={product.url}
                  key={i}
                  aria-label={product?.productDetail?.name}
                >
                  <AlternativeProductThumb
                    key={i}
                    src={product.picture}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    w={100}
                    h={100}
                  />
                </AlternativeProduct>
              );
            } else {
              return <></>;
            }
          })}
        </AlternativeProducts>
      );
    } else {
      return '';
    }
  }
}

// =============================================================
// ================= PRODUCT_PRICE COMPONENT ===================
// =============================================================

const PriceWrapper = styled.div`
  margin: ${rem(10)} 0 ${rem(20)} 0;
`;

const Price = styled.p`
  margin: 0;
`;

const PriceBlock = styled.div`
  margin-bottom: ${rem(10)};
`;

const PriceTitle = styled.p`
  margin: 0;
  font-weight: 700;
`;

const PriceTitleSale = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(18)};
  font-weight: 800;
  line-height: ${rem(32)};
`;

const PriceTitleDiscount = styled(PriceTitleSale)`
  margin: 0;
  color: ${({ theme }) => theme.color.success};
  font-size: ${rem(18)};
  font-weight: 800;
  line-height: ${rem(32)};
`;

const PriceBig = styled(Price)`
  font-size: ${rem(30)};
  font-weight: 800;
  line-height: ${rem(30)};
`;

const PriceSaleBig = styled(PriceBig)``;

const PriceDiscountBig = styled(PriceSaleBig)``;

const PriceDiscount = styled.div`
  margin-bottom: ${rem(15)};
`;

const PriceOldBig = styled(Price)`
  font-size: ${rem(20)};
  font-weight: 700;
  line-height: ${rem(20)};
  text-decoration: line-through;
`;

export const ProductPrice = (props: ProductProps) => {
  const { product, user, cart, settings } = props;

  const {
    currencyId,
    price,
    priceWithVat,
    priceWithoutVat,
    retailPrice,
    oldPrice,
    oldPriceWithoutVat,
    discountPrice,
    discountPriceWithoutVat,
    retailGroupPrice,
    isSale,
    isWarehouse,
    showWithVat,
  } = getProductPrices(
    product.productDetail,
    product.productDetail?.main_good,
    cart,
    user,
  );

  if (
    product.productDetail &&
    product.productDetail.main_good &&
    !product.productDetail.on_demand
  ) {
    if (product.productDetail?.main_good?.price_create_type === 1) {
      const unitprice = product.productDetail.main_good.unitprice;
      const unitprice_without_vat =
        product.productDetail.main_good.price_without_vat;
      const oldprice_without_vat =
        product.productDetail.main_good.price_without_vat_sale;

      return (
        <PriceWrapper>
          <>
            <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
            <PriceOldBig>
              {formatPrice(oldprice_without_vat, currencyId)}
            </PriceOldBig>
          </>

          <PriceTitleDiscount>{__('Vaša cena bez DPH')}: </PriceTitleDiscount>
          <PriceDiscountBig>
            {formatPrice(unitprice_without_vat, currencyId)}
          </PriceDiscountBig>

          <PriceTitle>
            {__('Vaša cena s DPH')}: {formatPrice(unitprice, currencyId)}
          </PriceTitle>
        </PriceWrapper>
      );
    } else {
      return (
        <PriceWrapper>
          {!showWithVat ? (
            <React.Fragment>
              {retailGroupPrice?.unitprice_without_vat !== priceWithoutVat ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(
                      retailGroupPrice.unitprice_without_vat,
                      currencyId,
                    )}
                  </PriceOldBig>
                </PriceDiscount>
              ) : (
                ''
              )}
              {isDiscountInCart(cart, user) && discountPrice && !isSale ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(priceWithoutVat, currencyId)}
                  </PriceOldBig>
                </PriceDiscount>
              ) : (
                ''
              )}
              {isSale ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(oldPriceWithoutVat, currencyId)}
                  </PriceOldBig>
                  <PriceBlock>
                    <PriceTitleSale>
                      {itemIsSkvelkoAndGoodPriceEnabled(product, settings)
                        ? __('Dobrá cena bez DPH')
                        : __('Akciová cena bez DPH')}
                      :{' '}
                    </PriceTitleSale>
                    <PriceSaleBig>
                      {formatPrice(priceWithoutVat, currencyId)}
                    </PriceSaleBig>
                  </PriceBlock>
                </PriceDiscount>
              ) : (
                <>
                  {product.productDetail?.main_good?.price_create_type === 1 ? (
                    <>
                      <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                      <PriceOldBig>
                        {formatPrice(retailPrice, currencyId)}
                      </PriceOldBig>
                    </>
                  ) : (
                    <></>
                  )}

                  <PriceTitleDiscount>
                    {__('Vaša cena bez DPH')}:{' '}
                  </PriceTitleDiscount>
                  <PriceDiscountBig>
                    {formatPrice(
                      isDiscountInCart(cart, user) && discountPrice
                        ? discountPrice
                        : priceWithoutVat,
                      currencyId,
                    )}
                  </PriceDiscountBig>
                </>
              )}

              <PriceTitle>
                {__('Vaša cena s DPH')}:{' '}
                {formatPrice(
                  isSale
                    ? priceWithVat
                    : isDiscountInCart(cart, user) && discountPriceWithoutVat
                    ? discountPriceWithoutVat
                    : priceWithVat,
                  currencyId,
                )}
              </PriceTitle>
            </React.Fragment>
          ) : isSale ? (
            <div>
              <PriceBlock>
                <PriceTitle>
                  {itemIsSkvelkoAndGoodPriceEnabled(product, settings)
                    ? __('Cena s DPH v predajni')
                    : __('Cena s DPH')}
                  :{' '}
                </PriceTitle>
                <PriceOldBig>{formatPrice(oldPrice, currencyId)}</PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleSale>
                  {itemIsSkvelkoAndGoodPriceEnabled(product, settings)
                    ? __('Cena v eshope')
                    : __('Akciová cena')}
                  :{' '}
                </PriceTitleSale>
                <PriceSaleBig>{formatPrice(price, currencyId)}</PriceSaleBig>
              </PriceBlock>
            </div>
          ) : isDiscountInCart(cart, user) && discountPrice ? (
            <div>
              <PriceBlock>
                <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
                <PriceOldBig>{formatPrice(price, currencyId)}</PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleDiscount>{__('Zľavnená cena')}: </PriceTitleDiscount>
                <PriceDiscountBig>
                  {formatPrice(discountPrice, currencyId)}
                </PriceDiscountBig>
              </PriceBlock>
            </div>
          ) : isWarehouse && retailPrice ? (
            <div>
              <PriceBlock>
                <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
                <PriceOldBig>
                  {formatPrice(retailPrice, currencyId)}
                </PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleDiscount>{__('Vaša cena')}: </PriceTitleDiscount>
                <PriceDiscountBig>
                  {formatPrice(price, currencyId)}
                </PriceDiscountBig>
              </PriceBlock>
            </div>
          ) : (
            <PriceBlock>
              <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
              <PriceBig>{formatPrice(price, currencyId)}</PriceBig>
            </PriceBlock>
          )}
        </PriceWrapper>
      );
    }
  } else {
    return <></>;
  }

  return (
    <React.Fragment>
      {product.productDetail &&
      product.productDetail.main_good &&
      !product.productDetail.on_demand ? (
        <PriceWrapper>
          {!showWithVat ? (
            <React.Fragment>
              {retailGroupPrice?.unitprice_without_vat !== priceWithoutVat &&
              product.productDetail?.main_good?.price_create_type !== 1 ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(
                      retailGroupPrice.unitprice_without_vat,
                      currencyId,
                    )}
                  </PriceOldBig>
                </PriceDiscount>
              ) : (
                ''
              )}
              {isDiscountInCart(cart, user) && discountPrice && !isSale ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(priceWithoutVat, currencyId)}
                  </PriceOldBig>
                </PriceDiscount>
              ) : (
                ''
              )}
              {isSale ? (
                <PriceDiscount>
                  <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                  <PriceOldBig>
                    {formatPrice(oldPriceWithoutVat, currencyId)}
                  </PriceOldBig>
                  <PriceBlock>
                    <PriceTitleSale>
                      {__('Akciová cena bez DPH')}:{' '}
                    </PriceTitleSale>
                    <PriceSaleBig>
                      {formatPrice(priceWithoutVat, currencyId)}
                    </PriceSaleBig>
                  </PriceBlock>
                </PriceDiscount>
              ) : (
                <>
                  {product.productDetail?.main_good?.price_create_type === 1 ? (
                    <>
                      <PriceTitle>{__('Cena bez DPH')}: </PriceTitle>
                      <PriceOldBig>
                        {formatPrice(retailPrice, currencyId)}
                      </PriceOldBig>
                    </>
                  ) : (
                    <></>
                  )}

                  <PriceTitleDiscount>
                    {__('Vaša cena bez DPH')}:{' '}
                  </PriceTitleDiscount>
                  <PriceDiscountBig>
                    {formatPrice(
                      isDiscountInCart(cart, user) && discountPrice
                        ? discountPrice
                        : priceWithoutVat,
                      currencyId,
                    )}
                  </PriceDiscountBig>
                </>
              )}

              <PriceTitle>
                {__('Vaša cena s DPH')}:{' '}
                {formatPrice(
                  isSale
                    ? priceWithVat
                    : isDiscountInCart(cart, user) && discountPriceWithoutVat
                    ? discountPriceWithoutVat
                    : priceWithVat,
                  currencyId,
                )}
              </PriceTitle>
            </React.Fragment>
          ) : isSale ? (
            <div>
              <PriceBlock>
                <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
                <PriceOldBig>{formatPrice(oldPrice, currencyId)}</PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleSale>{__('Akciová cena')}: </PriceTitleSale>
                <PriceSaleBig>{formatPrice(price, currencyId)}</PriceSaleBig>
              </PriceBlock>
            </div>
          ) : isDiscountInCart(cart, user) && discountPrice ? (
            <div>
              <PriceBlock>
                <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
                <PriceOldBig>{formatPrice(price, currencyId)}</PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleDiscount>{__('Zľavnená cena')}: </PriceTitleDiscount>
                <PriceDiscountBig>
                  {formatPrice(discountPrice, currencyId)}
                </PriceDiscountBig>
              </PriceBlock>
            </div>
          ) : isWarehouse && retailPrice ? (
            <div>
              <PriceBlock>
                <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
                <PriceOldBig>
                  {formatPrice(retailPrice, currencyId)}
                </PriceOldBig>
              </PriceBlock>
              <PriceBlock>
                <PriceTitleDiscount>{__('Vaša cena')}: </PriceTitleDiscount>
                <PriceDiscountBig>
                  {formatPrice(price, currencyId)}
                </PriceDiscountBig>
              </PriceBlock>
            </div>
          ) : (
            <PriceBlock>
              <PriceTitle>{__('Cena s DPH')}: </PriceTitle>
              <PriceBig>{formatPrice(price, currencyId)}</PriceBig>
            </PriceBlock>
          )}
        </PriceWrapper>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

// =============================================================
// ================= PRODUCT_FORM COMPONENT ====================
// =============================================================

const Form = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.media('xs')} {
    align-items: stretch;
  }
`;

const Submit = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  flex: 1 0 100%;
  line-height: 1;
  margin-top: ${rem(20)};
  height: ${rem(50)};
  ${({ theme }) => theme.media('xs')} {
    flex: 1 0 auto;
    margin-top: 0;
    margin-left: ${rem(20)};
  }
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(16)};
  }
`;

const SubmitIcon = styled(ButtonIcon)`
  ${({ theme }) => theme.size(24)};
  fill: ${({ theme }) => theme.color.white};
  margin-right: ${rem(10)};
`;

export class ProductForm extends React.PureComponent<
  ProductFormProps,
  ProductFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public onCountChange = (count) => {
    this.props.onCountChange(count);
  };

  public showItemAddedToCartModal = (bool) => {
    this.props.showModal(bool);
  };

  public setErrorNotSelectedVariant = (bool) => {
    if (this.props.setVariationError) {
      this.props.setVariationError(bool);
    }
  };

  public render() {
    return (
      <>
        {this.props?.product?.productDetail?.on_demand ? (
          <ProductOnDemandInfoText>
            {__('Zvoľte množstvo pre cenovú kalkuláciu:')}
          </ProductOnDemandInfoText>
        ) : (
          ''
        )}

        <Loader loading={this.state.loading}>
          <Form>
            <InputSpinner
              value={this.props.count}
              onChange={this.onCountChange}
            />
            <Submit
              type="button"
              onClick={
                this.props.product?.productDetail?.on_demand
                  ? this.addToCartDemand
                  : this.addToCart
              }
              className="primary"
              disabled={false}
            >
              <SubmitIcon icon="cart2" />
              {this.props.product?.productDetail?.on_demand
                ? __('Kalkulácia ceny')
                : __('Do košíka')}
            </Submit>
          </Form>
        </Loader>
      </>
    );
  }

  public addToCart = async () => {
    const { product, cart } = this.props;
    let { goodId } = this.props;
    const { productDetail } = product;
    const hasVariant = hasProductVariants(productDetail);
    if (hasVariant && !goodId && !productDetail.on_demand) {
      const goods = prop(product, 'productDetail.goods');
      if (goods && goods.length) {
        const defaultGoodVariant = goods.find(
          (g) => g.good_sort && g.good_sort === 1,
        );
        if (defaultGoodVariant && defaultGoodVariant.good_id) {
          goodId = defaultGoodVariant.good_id;
        }
      }
      if (!goodId) {
        this.setErrorNotSelectedVariant(true);
        return;
      }
    }

    if (!goodId && productDetail.on_demand && hasVariant) {
      return this.setErrorNotSelectedVariant(true);
    }

    if (
      getOnStockCount(this.props.cart, productDetail) < this.props.count &&
      !productDetail.on_demand
    ) {
      this.props.dispatch(
        setAddToCartModalProductOutOfStock({
          product: productDetail,
        }),
      );
      return;
    }

    goodId = goodId ? goodId : productDetail?.main_good.good_id;
    const quantityLimit = prop(productDetail, 'main_good.order_quantity_limit');
    const itemInCart = cart?.items?.find(
      (item) => prop(item, 'good.good_id') === goodId,
    );
    const countInCart = itemInCart && itemInCart.count ? itemInCart.count : 0;

    if (quantityLimit && quantityLimit > this.props.count + countInCart) {
      this.props.dispatch(
        setAddToCartModalProductQuantityLimit({
          product: productDetail,
          goodId,
        }),
      );
      return;
    }

    if (goodId) {
      this.setState(() => ({ loading: true }));
      try {
        await this.props.dispatch(
          addItemToCart(productDetail.product_id, goodId, this.props.count),
        );
        this.showItemAddedToCartModal(true);
      } catch (e: any) {
        if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
          await this.props.dispatch(
            setAddToCartMaxItemExceededModalProduct(true),
          );
        }
      } finally {
        this.setState(() => ({ loading: false }));
      }
      //this.setErrorNotSelectedVariant(false);
    }
  };

  public addToCartDemand = async () => {
    const { product, attribDemandObj } = this.props;
    const { productDetail } = product;
    const attribCount = product?.productDetail?.attribs?.length;

    if (attribDemandObj?.length !== attribCount) {
      this.setErrorNotSelectedVariant(true);
    } else if (attribDemandObj) {
      const goodId = productDetail.main_good.good_id;
      this.setState(() => ({ loading: true }));

      try {
        await this.props.dispatch(
          addItemToCart(
            productDetail.product_id,
            goodId,
            this.props.count,
            undefined,
            undefined,
            undefined,
            attribDemandObj,
          ),
        );
        this.showItemAddedToCartModal(true);
      } catch (e: any) {
        if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
          await this.props.dispatch(
            setAddToCartMaxItemExceededModalProduct(true),
          );
        }
      } finally {
        this.setErrorNotSelectedVariant(false);
        this.setState(() => ({ loading: false }));
      }
    }
  };
}

// =============================================================
// ============== PRODUCT_DESCRIPTION COMPONENT ================
// =============================================================

const Description = styled.div`
  margin-top: ${rem(16)};
`;

export class ProductDescription extends React.PureComponent<ProductProps> {
  public render() {
    const { product } = this.props;

    const parsedText = parseTextWithShortcode(
      product.productDetail !== null &&
        typeof product.productDetail?.publish[0]?.content?.json_content
          ?.body === 'string'
        ? product.productDetail?.publish[0]?.content?.json_content
            ?.body /*.replace(
            'http://daffer.sk/wp-content/',
            'http://skoly.daffer.sk/wp-content/',
          )*/
        : '',
    );

    return (
      <Description>
        {parsedText.map((item: any) => {
          if (item.type === 'text') {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.value,
                }}
              />
            );
          } else if (item.type === '3dmodel') {
            try {
              const parsedParameters = JSON.parse(
                item.parameters.parameters
                  .replace(/(\r\n|\n|\r)/gm, '')
                  .replace(/\<br \/\>/g, '')
                  .replace(/\<\/p\>/g, '')
                  .replace(/\<p\>/g, '')
                  .replace(/\</g, '[')
                  .replace(/\>/g, ']')
                  .replace(/ /g, ''),
              );

              if (window && window.location) {
                const Model3D = lazy(() => import('../Model3D/Model3D'));
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <Model3D
                      content_id={product.productDetail?.publish[0]?.content.id}
                      file_name={item.parameters.file_name}
                      camera_position_x={
                        item.parameters.camera_position_x
                          ? parseFloat(item.parameters.camera_position_x)
                          : undefined
                      }
                      camera_position_y={
                        item.parameters.camera_position_y
                          ? parseFloat(item.parameters.camera_position_y)
                          : undefined
                      }
                      camera_position_z={
                        item.parameters.camera_position_z
                          ? parseFloat(item.parameters.camera_position_z)
                          : undefined
                      }
                      fov={
                        item.parameters.fov
                          ? parseInt(item.parameters.fov)
                          : undefined
                      }
                      near={
                        item.parameters.near
                          ? parseFloat(item.parameters.near)
                          : undefined
                      }
                      far={
                        item.parameters.far
                          ? parseInt(item.parameters.far)
                          : undefined
                      }
                      parameters={parsedParameters}
                    />
                  </Suspense>
                );
              } else {
                <> </>;
              }
            } catch (e) {
              return <></>;
            }
          }

          return '';
        })}
      </Description>
    );
  }
}

// =============================================================
// ============== PRODUCT_DOWNLOADS COMPONENT ==================
// =============================================================

const Downloads = styled.div`
  padding-top: ${rem(16)};
`;

const Download = styled.a`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  color: inherit;
  font-size: ${rem(14)};
  &:hover {
    text-decoration: none;
  }
  &.detail {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    padding: ${rem(16)} ${rem(20)};
    box-shadow: ${({ theme }) => theme.boxShadow.small};
    ${({ theme }) => theme.transition('box-shadow')};
    & + & {
      margin-top: ${rem(10)};
    }
    &:hover {
      box-shadow: ${({ theme }) => theme.boxShadow.default};
    }
  }
  &.preview {
    font-size: ${rem(12)};
    padding: ${rem(5)} 0;
    & + & {
      border-top: ${rem(1)} solid ${({ theme }) => theme.color.gray87};
    }
  }
`;

const DownloadIconWrapper = styled.div`
  margin-right: ${rem(15)};
`;

const DownloadIcon = styled(Icon)``;

const Text = styled.div``;

export class ProductDownloads extends React.PureComponent<ProductClassNameProps> {
  public render() {
    if (
      this.props.product.productDetail &&
      this.props.product.productDetail.publish[0].content.downloads.length
    ) {
      return (
        <Downloads>
          {this.props.product.productDetail.publish[0].content.downloads.map(
            (download, i) => {
              return (
                <Download
                  href={download.document_path}
                  key={i}
                  download={true}
                  className={this.props.className}
                >
                  {this.props.className === 'detail' ? (
                    <DownloadIconWrapper>
                      <DownloadIcon icon="file" />
                    </DownloadIconWrapper>
                  ) : (
                    ''
                  )}
                  <Text>{download.document_name}</Text>
                </Download>
              );
            },
          )}
        </Downloads>
      );
    } else {
      return '';
    }
  }
}

// =============================================================
// ============== PRODUCT_VARIANT_SELECT COMPONENT =============
// =============================================================

const Select = styled(SelectStyled)`
  &.error {
    border-color: ${({ theme }) => theme.color.error};
  }
`;

const ProductOnDemandInfoText = styled.p`
  margin-top: 12px;
  margin-bottom: 12px;
  color: #4d94ff;
  font-weight: bold;
`;

export class ProductDemandVariantSelect extends React.PureComponent<ProductDemandVariantSelectProps> {
  constructor(props) {
    super(props);
    const product = prop(props, 'product');
    const goods = prop(props, 'product.goods');
    if (goods && goods.length) {
      const defaultGoodVariant = goods.find((g) =>
        this.isGoodDefault(g, product),
      );
      if (defaultGoodVariant && defaultGoodVariant.good_id) {
        props.onChange({ goodId: defaultGoodVariant.good_id });
      }
    }
  }

  public isGoodDefault(good, product) {
    return (
      hasProductVariants(product) &&
      good &&
      good.good_sort &&
      good.good_sort === 1 &&
      false // temporarily disable default goods
    );
  }

  public render() {
    const { product, onChange, isError, attribDemandObj } = this.props;

    return (
      <>
        {product?.attribs?.length ? (
          <ProductOnDemandInfoText>
            {__('Zvoľte variant pre cenovú kalkuláciu:')}
          </ProductOnDemandInfoText>
        ) : (
          ''
        )}
        {product?.attribs?.length
          ? product?.attribs.map((productAttrib) => {
              const attribName = productAttrib.attrib_name;
              const attribId = productAttrib.attrib_id;

              return (
                <div>
                  <b>{attribName}: </b>
                  <div>
                    <Select
                      onChange={onChange}
                      className={
                        isError &&
                        (!attribDemandObj ||
                          !attribDemandObj.find(
                            (item) => item.attrib_id == attribId,
                          ))
                          ? 'error'
                          : ''
                      }
                    >
                      <option hidden={true} value="">
                        {__('Zvoľte')}
                      </option>
                      {productAttrib.values.map((value, i) => {
                        return (
                          <option
                            key={i}
                            data-key={attribId}
                            selected={false}
                            value={value.value_id}
                          >
                            {` ${value.attrib_value} `}
                          </option>
                        );
                      })}
                    </Select>
                    {isError &&
                    (!attribDemandObj ||
                      !attribDemandObj.find(
                        (item) => item.attrib_id == attribId,
                      )) ? (
                      <ErrorPopup>{__('Zvoľte variant')}</ErrorPopup>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })
          : ''}
      </>
    );
  }
}
export interface State {
  selectedAttribsKeys: any;
}

export class ProductVariantSelect extends React.Component<
  ProductOnChangeProps,
  State
> {
  constructor(props) {
    super(props);
    const product = prop(props, 'product');
    const goods = prop(props, 'product.goods');
    if (goods && goods.length) {
      const defaultGoodVariant = goods.find((g) =>
        this.isGoodDefault(g, product),
      );
      if (defaultGoodVariant && defaultGoodVariant.good_id) {
        props.onChange({ goodId: defaultGoodVariant.good_id });
      }
    }

    this.state = this.getDefaultState();
  }

  public getDefaultState() {
    return {
      selectedAttribsKeys: [],
    };
  }

  public isGoodDefault(good, product) {
    return (
      hasProductVariants(product) &&
      good &&
      good.good_sort &&
      good.good_sort === 1
    );
  }

  public render() {
    const { product, isError, attribsObject, onChange } = this.props;
    const hasVariants = product ? hasProductVariants(product) : false;

    return (
      <>
        {product.on_demand && hasVariants && product?.attribs?.length ? (
          <ProductOnDemandInfoText>
            {__('Zvoľte variant pre cenovú kalkuláciu:')}
          </ProductOnDemandInfoText>
        ) : (
          ''
        )}
        {product?.attribs?.length
          ? product?.attribs.map((productAttrib) => {
              const attribName = productAttrib.attrib_name;
              const selectedVariant = Object.keys(attribsObject).includes(
                attribName,
              );
              return (
                <div>
                  <b>{attribName}: </b>
                  <div>
                    <Select
                      onChange={onChange}
                      className={isError && !selectedVariant ? 'error' : ''}
                    >
                      <option hidden={true} value="">
                        {__('Zvoľte')}
                      </option>
                      {productAttrib.values.map((value, i) => {
                        return (
                          <option
                            key={i}
                            data-key={attribName}
                            selected={false}
                            value={value.attrib_value}
                          >
                            {` ${value.attrib_value} `}
                          </option>
                        );
                      })}
                    </Select>
                    {isError && !selectedVariant ? (
                      <ErrorPopup>{__('Zvoľte variant')}</ErrorPopup>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })
          : ''}
      </>
    );
  }
}

export class SimpleProductVariantSelect extends React.Component<
  ProductOnChangeProps,
  State
> {
  constructor(props) {
    super(props);
    const product = prop(props, 'product');
    const goods = prop(props, 'product.goods');
    if (goods && goods.length) {
      const defaultGoodVariant = goods.find((g) =>
        this.isGoodDefault(g, product),
      );
      if (defaultGoodVariant && defaultGoodVariant.good_id) {
        props.onChange({ goodId: defaultGoodVariant.good_id });
      }
    }

    this.state = this.getDefaultState();
  }

  public getDefaultState() {
    return {
      selectedAttribsKeys: [],
    };
  }

  public isGoodDefault(good, product) {
    return (
      hasProductVariants(product) &&
      good &&
      good.good_sort &&
      good.good_sort === 1
    );
  }

  public render() {
    const {
      product,
      isError,
      attribsObject,
      onChange,
      isSelected,
    } = this.props;
    const hasVariants = product ? hasProductVariants(product) : false;

    return (
      <>
        {product.on_demand && hasVariants && product?.attribs?.length ? (
          <ProductOnDemandInfoText>
            {__('Zvoľte variant pre cenovú kalkuláciu:')}
          </ProductOnDemandInfoText>
        ) : (
          ''
        )}
        {product?.attribs?.length
          ? product?.attribs.map((productAttrib) => {
              const attribName = productAttrib.attrib_name;
              const selectedVariant = Object.keys(attribsObject).includes(
                attribName,
              );
              return (
                <div>
                  <div>
                    <SimpleSelect
                      onChange={onChange}
                      className={isError && !selectedVariant ? 'error' : ''}
                      style={{ color: !isSelected ? '#9b9b9b' : 'black' }}
                    >
                      <SimpleSelectOption hidden={true} value="">
                        {__('Zvoľte')}: {attribName}
                      </SimpleSelectOption>
                      {productAttrib.values.map((value, i) => {
                        return (
                          <SimpleSelectOption
                            key={i}
                            data-key={attribName}
                            selected={false}
                            value={value.attrib_value}
                          >
                            {` ${value.attrib_value} `}
                          </SimpleSelectOption>
                        );
                      })}
                    </SimpleSelect>
                    {isError && !selectedVariant ? (
                      <ErrorPopup>{__('Zvoľte variant')}</ErrorPopup>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })
          : ''}
      </>
    );
  }
}

const SimpleSelect = styled(Select)`
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  font-size: ${rem(14)};
`;

const SimpleSelectOption = styled.option`
  color: black;
`;

const FavoriteWrapper = styled.div`
  cursor: pointer;
  margin-top: ${rem(8)};
`;

const Hearth = styled.img`
  height: ${rem(20)};
  margin-right: ${rem(5)};
`;

const FavoriteText = styled.span`
  font-size: ${rem(14)};
`;
export class ProductFavorite extends React.PureComponent<{
  isFavorite;
  onAddClick;
  onRemoveClick;
}> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { isFavorite, onAddClick, onRemoveClick } = this.props;

    if (isFavorite) {
      return (
        <FavoriteWrapper onClick={onRemoveClick}>
          <Hearth src="/images/hearth-full.svg" alt={__('Ikona srdca')} />
          <FavoriteText> {__('odobrať produkt z obľubených')}</FavoriteText>
        </FavoriteWrapper>
      );
    } else {
      return (
        <FavoriteWrapper onClick={onAddClick}>
          <Hearth src="/images/hearth.svg" alt={__('Ikona srdca')} />
          <FavoriteText> {__('pridať produkt medzi obľúbené')}</FavoriteText>
        </FavoriteWrapper>
      );
    }
  }
}
