import { isIOS, isSafari } from 'react-device-detect';
import { prop } from './index';

export function getImagePath(
  filename,
  { width, height } = { width: 64, height: 80 },
  resize = true,
  ogImage = false,
  notWebp = false,
  ignoreRequestHeight = false,
  resizeType: string | null = null,
) {
  if (ogImage) {
    filename = filename.replace('mainImages', 'ogImages');
  }

  if (typeof filename === 'string' && filename?.startsWith('http')) {
    const params = new URL(filename).searchParams;
    if (params) {
      const query = params.get('query');
      if (query) {
        filename = query;
      }
    }
  }

  if (filename?.startsWith('product/product/')) {
    filename = filename.replace('product/product/', 'product/');
  }

  const webp = notWebp || ogImage || isSafari || isIOS ? '' : '&webp=1';
  if (resize) {
    let reqHeight = `&height=${height}`;
    if (ignoreRequestHeight) {
      reqHeight = '';
    }

    let type = resizeType ? `&type=${resizeType}` : '';

    return `${process.env.REACT_APP_API_BASE_URL}/image?query=${filename}&width=${width}${reqHeight}${webp}${type}`;
  } else {
    return `${process.env.REACT_APP_API_BASE_URL}/image?query=${filename}${webp}`;
  }
}

export function getAbsoluteUrl(url) {
  let baseUrl = process.env.REACT_APP_URL;
  if (baseUrl && !baseUrl.startsWith('https://www.')) {
    baseUrl = baseUrl.replace(/^https?:\/\//, 'https://www.');
  }
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${baseUrl}${url}`;
}

export function productMetaHandler(product, defaultTitle) {
  const productPublish = prop(product, 'publish', [])[0];

  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    metaTags = prop(productPublish.content.json_content, 'meta', {
      title: defaultTitle,
    });

    if (!metaTags.title.length) {
      metaTags.title = `${defaultTitle} - ${resolveProductName(product)}`;
    }
  } else {
    metaTags.title = defaultTitle;
  }

  if (
    metaTags &&
    metaTags.og_description &&
    !metaTags.og_description.length &&
    product &&
    product.publish
  ) {
    metaTags.og_description =
      product && product.publish && product.publish.length > 0
        ? product.publish[0].product_description
        : '';
  }

  if (
    metaTags &&
    metaTags.og_image &&
    !metaTags.og_image.length &&
    product &&
    product.picture
  ) {
    metaTags.og_image = product.picture
      ? getImagePath(product.picture, { width: 510, height: 510 }, false, true)
      : '';
    metaTags.og_image_width = metaTags.og_image_height = 1005;
  }

  if (metaTags && metaTags.og_title && !metaTags.og_title.length) {
    metaTags.og_title = metaTags.title;
  }

  if (metaTags && metaTags.og_type && !metaTags.og_type.length) {
    metaTags.og_type = 'website';
  }

  if (
    metaTags &&
    metaTags.og_url &&
    !metaTags.og_url.length &&
    product &&
    product.url
  ) {
    metaTags.og_url = getAbsoluteUrl(product.url);
  }

  return metaTags;
}

export function resolveProductName(product) {
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
    // publish = publish.find(el => {
    //   return el.lang_id === lang;
    // });
  }
  if (publish) {
    return prop(publish, 'product_name', null) || product.name;
  }
  return product.name;
}

export enum SearchProductType {
  PRODUCT = 'PRODUCT',
  PRODUCT_SOLD_OUT = 'PRODUCT_SOLD_OUT',
  SKVELKO_SET = 'SKVELKO_SET',
}

export interface SearchProductItem {
  type: SearchProductType;
  position: number;
  item: any;
}

export function concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
  products,
  skvelkoSets,
  soldOutProducts,
  positions,
): SearchProductItem[] {
  const items: SearchProductItem[] = [];
  if (products) {
    let defaultPosition = 1;
    for (const product of products) {
      let position = defaultPosition;
      if (positions && positions.products_positions) {
        const productPosition = positions.products_positions.find(
          (pp) => pp.product_id == product.product_id,
        );
        if (productPosition) {
          position = productPosition.position;
        }
      }

      if (!positions) {
        defaultPosition++;
      }

      items.push({
        type: SearchProductType.PRODUCT,
        position: position,
        item: product,
      });
    }
  }

  if (skvelkoSets) {
    for (const skvelkoSet of skvelkoSets) {
      let position = 999;
      if (positions && positions.skvelko_sets_positions) {
        const skvelkoSetPosition = positions.skvelko_sets_positions.find(
          (skp) => skp.id === skvelkoSet.id,
        );
        if (skvelkoSetPosition) {
          position = skvelkoSetPosition.position;
        }
      }

      items.push({
        type: SearchProductType.SKVELKO_SET,
        position: position,
        item: skvelkoSet,
      });
    }
  }

  if (soldOutProducts?.length) {
    items.forEach((item) => {
      item.position += soldOutProducts?.length;
    });
    let position = 1;
    for (const soldOutProduct of soldOutProducts) {
      items.push({
        type: SearchProductType.PRODUCT_SOLD_OUT,
        position: position,
        item: soldOutProduct,
      });
      position++;
    }
  }

  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return items;
}
