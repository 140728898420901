import cookie from 'react-cookies';
import {
  CART_COOKIE_ID,
  CHANGE_PROCESSING_STATUS,
  CLOSE_MODAL,
  OPEN_MODAL,
  RESET_CART_ID,
  SET_CART_ID,
  SET_CART_TOTAL_PRICE,
  SET_STEP,
  SHOW_3RD_STEP_PROBLEMS,
  REQUEST_FREE_DELIVERY_INFO,
  RECEIVE_FREE_DELIVERY_DATA_SUCCESS,
  RECEIVE_FREE_DELIVERY_DATA_ERROR,
  SET_CART_COUNT,
  SET_CART_ITEMS,
  REQUEST_DELIVERY_AND_PAYMENT_OPTIONS,
  RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR,
  RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS,
  RECEIVE_CREATE_NEW_CART_ERROR,
  CART_IS_FETCHING,
  RECEIVE_CREATE_NEW_CART_SUCCESS,
  SET_NEW_DELIVERY_OPTION,
  RECEIVE_UPDATE_CART_DATA_ERROR,
  CHECKBOX_CLICK,
  REQUEST_PRODUCT_IN_CART_COUNT_UPDATE,
  RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS,
  RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR,
  REQUEST_PRODUCT_IN_CART_DELETE,
  RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS,
  RECEIVE_PRODUCT_IN_CART_DELETE_ERROR,
  REQUEST_ADD_GIFTCARD,
  RECEIVE_ADD_GIFTCARD_SUCCESS,
  RECEIVE_ADD_GIFTCARD_ERROR,
  SET_NEW_PAYMENT_OPTION,
  CHANGE_CONTACT_FORM_INPUT_VALUE,
  CHANGE_COMPANY_FORM_INPUT_VALUE,
  CHANGE_BILLING_FORM_INPUT_VALUE,
  CHANGE_CHECKBOX_VALUE,
  CHANGE_CART_DATA_VALUE,
  REQUEST_CREATE_ORDER,
  RECEIVE_CREATE_ORDER_SUCCESS,
  RECEIVE_CREATE_ORDER_ERROR,
  REQUEST_GIFTS,
  RECEIVE_GIFTS_SUCCESS,
  RECEIVE_GIFTS_ERROR,
  CHANGE_GIFT,
  REQUEST_DELETE_GIFTCARD,
  RECEIVE_DELETE_GIFTCARD_SUCCESS,
  SET_CART,
  SET_CUSTOMER_DETAILS_LOADER,
  SET_CUSTOMER_DETAILS,
  RECOGNIZED_CUSTOMER_CARD,
  SHOW_RECOGNIZED_CUSTOMER_CARD,
  SET_DPD_DELIVERY_POINTS,
  SET_DPD_DELIVERY_POINT,
  SET_PACKETA_DELIVERY_POINT,
  CartProblemTypes,
  SET_SHOPS_PERSONAL_PICKUP,
  SET_SHOP_PERSONAL_PICKUP,
  ESHOP_GIFTS,
  SET_LAST_VALUES,
  SET_CART_INFO_TEXT,
  CART_RESTRICTED_COD_ID,
  SET_CART1_UPSELL,
} from './constants';
import { prop, cartRoutes, cookiesCartExpiresDate } from '../../utilities';
import { cartIdSelector } from './selectors';
import { push } from 'react-router-redux';
import APIClass from '../../services/API';
import { AttribDemand } from '../Product/Product';
import { setCartHadUserModal } from '../App/actions';
import { Ga4Item, sendAddToCartEvent } from '../../utilities/ga4_events';
var md5 = require('md5');

export const closeCartModal = () => ({
  type: CLOSE_MODAL,
  payload: {},
});

export const openCartModal = (modalData: any) => ({
  type: OPEN_MODAL,
  payload: {
    modalData,
  },
});

// const createCart = (cartId: string, currency: string) => {
//   cookie.save(CART_COOKIE_ID, cartId, {
//     path: '/',
//     expires: cookiesExpiresDate(),
//   });
//   return {
//     type: SET_CART_ID,
//     payload: {
//       cartId,
//       currency,
//     },
//   };
// };

export const setCartId = (cartId: string) => ({
  type: SET_CART_ID,
  payload: {
    cartId,
  },
});

export const setCartCount = (count: number | string) => ({
  type: SET_CART_COUNT,
  payload: {
    count,
  },
});

export const setCartItems = (items: any) => ({
  type: SET_CART_ITEMS,
  payload: {
    items,
  },
});

export const resetCartId = () => {
  cookie.remove(CART_COOKIE_ID, { path: '/' });
  return {
    type: RESET_CART_ID,
  };
};

export const setCartTotalPrice = (price: number, currency: string) => ({
  type: SET_CART_TOTAL_PRICE,
  payload: {
    price,
    currency,
  },
});

export const changeProcessing = (productId: number, processing: boolean) => ({
  type: CHANGE_PROCESSING_STATUS,
  payload: {
    processing,
    productId,
  },
});

export const addItemToCart = (
  productId: number,
  goodId: number,
  count: number,
  skvelkoSetId?: number | null,
  skvelkoClassId?: number | null,
  itemGroupId?: string | number,
  attribsDemand?: Array<AttribDemand>,
  upsellId?: number,
) => {
  return async (dispatch, getState, API) => {
    dispatch(changeProcessing(productId, true));

    const {
      general: { lang, currency },
      auth: { token, user },
      // cart,
    } = getState();

    let exist = true;
    let cartId: string | undefined = cookie.load(CART_COOKIE_ID);

    if (cartId) {
      try {
        const isCartExist = await API.isCartExist(cartId);
        exist = isCartExist?.exist;
      } catch (e) {
        console.error(e);
      }
    }

    try {
      // ak nie je kosik, alebo neexistuje, tak ho vytvorime
      if (!cartId || !exist) {
        const { id } = await API.createCart(
          {},
          token
            ? {
                xAuth: token,
                xAcceptLanguage: lang,
              }
            : { xAcceptLanguage: lang },
        );
        cartId = id;

        cookie.save(CART_COOKIE_ID, cartId, {
          expires: cookiesCartExpiresDate(),
          path: '/',
        });
      }

      const cartResponse = await API.addCartItem(
        cartId,
        goodId,
        productId,
        {
          count,
          langId: lang,
          currencyId: currency,
          xAuth: token,
          skvelkoSetId,
          skvelkoClassId,
          itemGroupId,
          upsellId,
        },
        { demand_attribs: attribsDemand ? attribsDemand : [] },
        { xAcceptLanguage: lang },
      );

      dispatch(setCart(cartResponse));

      // posleme do analytics
      const currentItem = cartResponse.items.find((item) => {
        return item.good.good_id === goodId;
      });

      if (currentItem && currentItem.good && currentItem.product) {
        sendAddToCartEvent({
          value: currentItem.good.final_price * count,
          items: [
            {
              item_id: currentItem.product.product_id,
              item_name: currentItem.product.name,
              price: currentItem.good.final_price,
              quantity: count,
            },
          ],
        });
      }

      // otvorime popup s produktom
      const product = cartResponse.items.find(
        (item: any) => item.product.product_id === productId,
      );
      dispatch(
        openCartModal({
          cartProduct: product,
          count,
        }),
      );
      dispatch(changeProcessing(productId, false));

      // old user check
      if (
        cartResponse &&
        cartResponse.had_user &&
        !cartResponse.user_id &&
        !user
      ) {
        dispatch(setCartHadUserModal(true));
      }
    } catch (exp: any) {
      dispatch(openCartModal({ error: true }));
      dispatch(changeProcessing(productId, false));

      if (exp?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        throw exp;
      }
    }
  };
};

export const addItemsToCart = (
  items: {
    good_id: number;
    product_id: number;
    count: number;
    skvelko_set_id?: number;
    skvelko_class_id?: number;
    item_group_id?: string;
    upsell_id?: number;
  }[],
) => {
  return async (dispatch, getState, API) => {
    const {
      general: { lang, currency },
      auth: { token },
      // cart,
    } = getState();

    let exist = true;
    let cartId: string | undefined = cookie.load(CART_COOKIE_ID);

    if (cartId) {
      try {
        const isCartExist = await API.isCartExist(cartId);
        exist = isCartExist?.exist;
      } catch (e) {
        console.error(e);
      }
    }

    try {
      // ak nie je kosik, alebo neexistuje, tak ho vytvorime
      if (!cartId || !exist) {
        const { id } = await API.createCart(
          {},
          token
            ? {
                xAuth: token,
                xAcceptLanguage: lang,
              }
            : { xAcceptLanguage: lang },
        );
        cartId = id;

        cookie.save(CART_COOKIE_ID, cartId, {
          expires: cookiesCartExpiresDate(),
          path: '/',
        });
      }

      const cartResponse = await API.addCartItemsBulk(
        cartId,
        {
          currencyId: currency,
        },
        items,
        { xAcceptLanguage: lang },
      );

      // posleme do analytics
      let finalValue = 0;
      const aItems: Ga4Item[] = [];

      for (const fItem of items) {
        const currentItem = cartResponse.items.find((item) => {
          return item.good.good_id === fItem.good_id;
        });

        if (currentItem && currentItem.good && currentItem.product) {
          finalValue += currentItem.good.final_price * fItem.count;
          aItems.push({
            item_id: currentItem.product.product_id,
            item_name: currentItem.product.name,
            price: currentItem.good.final_price,
            quantity: fItem.count,
          });
        }
      }

      if (aItems.length) {
        sendAddToCartEvent({
          value: finalValue,
          items: aItems,
        });
      }

      dispatch(setCart(cartResponse));
    } catch (exp: any) {
      console.error(exp);
      if (exp?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        throw exp;
      }
    }
  };
};

export const setStep = (step: number = 0) => ({
  type: SET_STEP,
  payload: {
    step,
  },
});

export const canShow3rdStepCartProblems = (canShow: boolean) => ({
  type: SHOW_3RD_STEP_PROBLEMS,
  payload: {
    canShow,
  },
});

const requestFreeDeliveryInfo = () => ({
  type: REQUEST_FREE_DELIVERY_INFO,
});

const receiveFreeDeliveryInfoSuccess = (
  freeDeliveryData, // FIXME: typ
) => ({
  type: RECEIVE_FREE_DELIVERY_DATA_SUCCESS,
  payload: {
    freeDeliveryData,
  },
});

const receiveFreeDeliveryInfoError = (error: any) => ({
  type: RECEIVE_FREE_DELIVERY_DATA_ERROR,
  payload: { error },
});

export const loadFreeDeliveryInfo = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestFreeDeliveryInfo());
      const cartId: string = cartIdSelector(getState());

      const freeDeliveryData = await API.getFreeDeliveryInfo(
        // FIXME: typ
        cartId,
      );

      dispatch(receiveFreeDeliveryInfoSuccess(freeDeliveryData));
    } catch (error) {
      dispatch(receiveFreeDeliveryInfoError(error));
    }
  };
};

const requestDeliveryAndPaymentOptions = () => ({
  type: REQUEST_DELIVERY_AND_PAYMENT_OPTIONS,
});

const receiveDeliveryAndPaymentOptionsSuccess = (
  data,
): { type: string; payload: { data } } => ({
  type: RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS,
  payload: {
    data,
  },
});

const receiveDeliveryAndPaymentOptionsError = (error: any) => ({
  type: RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR,
  payload: { error },
});

export const loadDeliveryAndPaymentOptions = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestDeliveryAndPaymentOptions());
      const cartId: string = cartIdSelector(getState());
      const response: {
        delivery; // FIXME: typ
      } = await API.getDeliveryPaymentInfo(cartId);
      dispatch(receiveDeliveryAndPaymentOptionsSuccess(response.delivery));
    } catch (e) {
      dispatch(receiveDeliveryAndPaymentOptionsError(e));
    }
  };
};

const createNewCartSuccess = (id: string) => ({
  type: RECEIVE_CREATE_NEW_CART_SUCCESS,
  payload: {
    id,
  },
});

const createNewCartError = (error: any) => ({
  type: RECEIVE_CREATE_NEW_CART_ERROR,
  payload: {
    error,
  },
});

const cartIsFetching = () => ({
  type: CART_IS_FETCHING,
});

export const createCart = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(cartIsFetching());
      const { lang } = getState().general;

      const { id } = await API.createCart({}, { xAcceptLanguage: lang });
      cookie.save(CART_COOKIE_ID, id, {
        expires: cookiesCartExpiresDate(),
        path: '/',
      });
      dispatch(createNewCartSuccess(id));
      return;
    } catch (e) {
      dispatch(createNewCartError(e));
    }
  };
};

export const checkCart = async (cartId: string): Promise<boolean> => {
  try {
    await APIClass.getCartInfo(cartId);
  } catch (e) {
    if (prop(e, 'details.status', 400) === 404) {
      return false;
    }
  }
  return true;
};

export const loadCart = (cartId) => {
  return async (dispatch, getState, API) => {
    let cartData: any;
    try {
      cartData = await API.getCart(cartId);
    } catch (e) {
      cartData = null;
    }

    /*
    if (!cartData) {
      const { lang } = getState().general;

      const createCartResponse = await API.createCart(
        {},
        {
          xAcceptLanguage: lang,
        },
      );
      cartId = createCartResponse.id;
      cookie.save(CART_COOKIE_ID, cartId, {
        expires: cookiesCartExpiresDate(),
        path: '/',
      });
      cartData = await API.getCart(cartId);
    }
    */

    dispatch(setCart(cartData));
  };
};

export const loadCartId = (cartId) => {
  return async (dispatch, getState, API) => {
    cookie.save(CART_COOKIE_ID, cartId, {
      expires: cookiesCartExpiresDate(),
      path: '/',
    });
    const cartData = await API.getCart(cartId);
    dispatch(setCart(cartData));
  };
};

export const setNewDeliveryOption = (deliveryId: string) => ({
  type: SET_NEW_DELIVERY_OPTION,
  payload: {
    deliveryId,
  },
});

export const setNewPaymentOption = (paymentId: string) => ({
  type: SET_NEW_PAYMENT_OPTION,
  payload: {
    paymentId,
  },
});

const receiveUpdatedCartError = (error: any) => ({
  type: RECEIVE_UPDATE_CART_DATA_ERROR,
  payload: {
    error,
  },
});

export const updateCart = (cart, save: boolean = true) => {
  return async (dispatch, getState, API) => {
    try {
      const { auth } = getState();
      if (auth && auth.user) {
        cart = { ...cart, user_id: auth.user.id };
      }

      cart = { ...cart, lang: 'sk' };

      // --------- removed items from cart --------
      cart.items = null;
      // ------------------------------------------

      if (cookie.load(CART_RESTRICTED_COD_ID)) {
        cart = { ...cart, restricted_cod: true };
      }

      let updatedCartData = await API.updateCart(cart.id, {}, cart);
      if (
        updatedCartData.old_customer &&
        !updatedCartData.old_customer_notified
      ) {
        updatedCartData.old_customer_notified = true;
        updatedCartData = await API.updateCart(cart.id, {}, updatedCartData);
        updatedCartData.showOldCustomerModal = true;
      }

      if (save) {
        dispatch(setCart(updatedCartData));
      }
      return updatedCartData;
    } catch (e) {
      dispatch(receiveUpdatedCartError(e));
      console.log(e);
    }
  };
};

export const loadDpdDeliveryPoints = () => {
  return async (dispatch, getState, API) => {
    try {
      const dpdDeliveryPoints = await API.loadDpdDeliveryPoints();
      dispatch(setDpdDeliveryPoints(dpdDeliveryPoints));
      return dpdDeliveryPoints;
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadShopsPersonalPickup = () => {
  return async (dispatch, getState, API) => {
    try {
      const shops = await API.loadShops({
        onlyAllowedPersonalPickup: true,
      });
      dispatch(setShopsPersonalPickup(shops?.shops));
      return shops;
    } catch (e) {
      console.log(e);
    }
  };
};

export const setShopPersonalPickupById = (shopId) => {
  return async (dispatch, getState, API) => {
    try {
      if (!shopId) {
        return;
      }

      let shops = prop(getState(), 'cart.shopsPersonalPickup');
      if (!shops) {
        await dispatch(loadShopsPersonalPickup());
        shops = prop(getState(), 'cart.shopsPersonalPickup');
      }

      const [shop] = shops.filter((shop) => shop.id === shopId);
      dispatch(setShopPersonalPickup(shop));
      return shop;
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadDpdDeliveryPointById = (pointId) => {
  return async (dispatch, getState, API) => {
    try {
      const dpdDeliveryPoint = await API.loadDpdDeliveryPointById(pointId);
      dispatch(setDpdDeliveryPoint(dpdDeliveryPoint));
      return dpdDeliveryPoint;
    } catch (e) {
      console.log(e);
    }
  };
};

export const clearDpdDeliveryPoint = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setDpdDeliveryPoint(null));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setDpdDeliveryPoints = (deliveryPoints: any) => {
  return {
    type: SET_DPD_DELIVERY_POINTS,
    payload: {
      deliveryPoints,
    },
  };
};

export const setShopsPersonalPickup = (shopsPersonalPickup: any) => {
  return {
    type: SET_SHOPS_PERSONAL_PICKUP,
    payload: {
      shopsPersonalPickup,
    },
  };
};

export const setDpdDeliveryPoint = (deliveryPoint: any) => {
  return {
    type: SET_DPD_DELIVERY_POINT,
    payload: {
      deliveryPoint,
    },
  };
};

export const setPacketaDeliveryPoint = (deliveryPoint: any) => {
  return {
    type: SET_PACKETA_DELIVERY_POINT,
    payload: {
      deliveryPoint,
    },
  };
};

export const setShopPersonalPickup = (shopPersonalPickup: any) => {
  return {
    type: SET_SHOP_PERSONAL_PICKUP,
    payload: {
      shopPersonalPickup,
    },
  };
};

export const setLastValues = (lastValues: any) => {
  return {
    type: SET_LAST_VALUES,
    payload: {
      lastValues,
    },
  };
};

export const checkboxClick = (checkboxName: string) => {
  return {
    type: CHECKBOX_CLICK,
    payload: {
      checkboxName,
    },
  };
};

const requestProductInCartCountUpdate = () => ({
  type: REQUEST_PRODUCT_IN_CART_COUNT_UPDATE,
});

const productInCartCountUpdateSuccess = (updatedCart) => ({
  type: RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS,
  payload: {
    updatedCart,
  },
});

const productInCartCountUpdateError = (error: any) => ({
  type: RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR,
  payload: {
    error,
  },
});

export const productInCartCountUpdate = (
  productId: number,
  goodId: number,
  count: number,
  skvelkoSetId?: number | null,
  skvelkoClassId?: number | null,
  itemGroupId?: string,
  isInCart?: boolean,
  demandAttribs?: any,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductInCartCountUpdate());
      const {
        cart,
        auth: { token },
      } = getState();

      if (cart.cart) {
        const cartId = cart.cart.id;
        const updatedCart = await API.addCartItem(
          cartId,
          goodId,
          productId,
          {
            count,
            currencyId: cart.cart.currency,
            langId: 'sk',
            xAuth: token,
            skvelkoSetId,
            skvelkoClassId,
            itemGroupId,
          },
          {
            demand_attribs: demandAttribs ? demandAttribs : [],
          },
          { xAcceptLanguage: 'sk' },
        );

        dispatch(setCart(updatedCart));
        dispatch(productInCartCountUpdateSuccess(updatedCart));
      }
    } catch (e) {
      dispatch(productInCartCountUpdateError(e));
    }
  };
};

export const itemGroupCountUpdate = (itemGroupId: string, count: number) => {
  return async (dispatch, getState, API) => {
    try {
      const {
        cart,
        auth: {},
      } = getState();

      if (cart.cart) {
        const cartId = cart.cart.id;
        const updatedCart = await API.updateItemGroup(cartId, itemGroupId, {
          count: count,
        });
        dispatch(setCart(updatedCart));
      }
    } catch (e) {
      dispatch(productInCartCountUpdateError(e));
    }
  };
};

const requestProductInCartDelete = () => ({
  type: REQUEST_PRODUCT_IN_CART_DELETE,
});

const productInCartDeleteSuccess = (updatedCart) => ({
  type: RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS,
  payload: {
    updatedCart,
  },
});

const productInCartDeleteError = (error: any) => ({
  type: RECEIVE_PRODUCT_IN_CART_DELETE_ERROR,
  payload: {
    error,
  },
});

export const productInCartDelete = (
  productId: number,
  goodId: number,
  demandAttribs: Array<AttribDemand>,
  itemGroupId: string,
  itemUniqId: string,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductInCartDelete());
      const { cart } = getState();
      const cartId = cart.cart ? cart.cart.id : null;

      let attribHash = null;
      if (demandAttribs?.length) {
        demandAttribs.sort((a, b) => {
          if (a.attrib_id > b.attrib_id) return 1;
          if (a.attrib_id < b.attrib_id) return -1;
          if (a.attrib_value_id > b.attrib_value_id) return 1;
          if (a.attrib_value_id < b.attrib_value_id) return -1;
          return 0;
        });
        attribHash = md5(
          JSON.stringify(
            demandAttribs.map(
              (item) => `${item.attrib_id},${item.attrib_value_id}`,
            ),
          ),
        );
      }

      const updatedCart = await API.deleteCartItem(cartId, productId, goodId, {
        attribHash: attribHash ? attribHash : undefined,
        itemGroupId: itemGroupId ? itemGroupId : undefined,
        itemUniqId: itemUniqId ? itemUniqId : undefined,
      });
      dispatch(productInCartDeleteSuccess(updatedCart));
      dispatch(setCart(updatedCart));
    } catch (e) {
      console.log(e);
      dispatch(productInCartDeleteError(e));
    }
  };
};

export const itemGroupInCartDelete = (itemGroupId: number) => {
  return async (dispatch, getState, API) => {
    dispatch(requestProductInCartDelete());
    const { cart } = getState();
    const cartId = cart.cart ? cart.cart.id : null;

    const updatedCart = await API.removeItemGroup(cartId, itemGroupId);
    dispatch(productInCartDeleteSuccess(updatedCart));
    dispatch(setCart(updatedCart));
  };
};

const requestAddGiftcard = () => ({
  type: REQUEST_ADD_GIFTCARD,
});

const addGiftcardSuccess = (updatedCart) => ({
  type: RECEIVE_ADD_GIFTCARD_SUCCESS,
  payload: {
    updatedCart,
    error: null,
  },
});

export const addGiftcardError = (error: any) => ({
  type: RECEIVE_ADD_GIFTCARD_ERROR,
  payload: {
    error,
  },
});

export const applyGiftcardOrCustomerCard = (
  giftcardString: string,
  giftcardType:
    | 'GIFTCARD'
    | 'POINTCARD'
    | 'CARD'
    | 'GIFTCARD_OR_CUSTOMERCARD' = 'GIFTCARD_OR_CUSTOMERCARD',
) => {
  return async (dispatch, getState, API) => {
    try {
      let { cart } = getState();
      cart = cart.cart;
      dispatch(requestAddGiftcard());
      const cartId: string = cart.id;
      const updatedCart = await API.addCard(
        cartId,
        {},
        { card_type: giftcardType, card_number: giftcardString },
      );
      dispatch(addGiftcardSuccess(updatedCart));
    } catch (e) {
      dispatch(addGiftcardError(e));
    }
  };
};

const customerCardRecognized = (updatedCart) => ({
  type: RECOGNIZED_CUSTOMER_CARD,
  payload: {
    updatedCart,
  },
});

export const setShowRecognizedCustomerCardModal = (showCustomerCardModal) => ({
  type: SHOW_RECOGNIZED_CUSTOMER_CARD,
  payload: {
    showCustomerCardModal,
  },
});

export const recognizeCustomerCard = (email: string) => {
  return async (dispatch, getState, API) => {
    try {
      let { cart } = getState();
      cart = cart.cart;
      const cartId: string = cart.id;
      const updatedCart = await API.recognizeCustomerCard(
        cartId,
        { email },
        {},
      );
      dispatch(setCart(updatedCart));
      dispatch(customerCardRecognized(updatedCart));
      dispatch(setShowRecognizedCustomerCardModal(true));
    } catch (e) {
      // silence
    }
  };
};

const requestDeleteGiftcard = () => ({
  type: REQUEST_DELETE_GIFTCARD,
});

const receiveDeleteGiftcardSuccess = (updatedCart) => ({
  type: RECEIVE_DELETE_GIFTCARD_SUCCESS,
  payload: {
    updatedCart,
  },
});

const receiveDeleteGiftcardError = (error) => ({
  type: REQUEST_DELETE_GIFTCARD,
  payload: {
    error,
  },
});

export const deleteGiftcard = (giftcardString: string) => {
  return async (dispatch, getState, API) => {
    try {
      let { cart } = getState();
      cart = cart.cart;
      dispatch(requestDeleteGiftcard());
      const cartId: string = cart.id;
      const updatedCart = await API.removeCard(
        cartId,
        'giftcard',
        giftcardString,
      );
      dispatch(receiveDeleteGiftcardSuccess(updatedCart));
    } catch (e) {
      dispatch(receiveDeleteGiftcardError(e));
    }
  };
};

export const changeContactFormInputValue = (
  inputName: string,
  inputValue: string,
) => ({
  type: CHANGE_CONTACT_FORM_INPUT_VALUE,
  payload: {
    inputName,
    inputValue,
  },
});

export const changeCompanyFormInputValue = (
  inputName: string,
  inputValue: string,
) => ({
  type: CHANGE_COMPANY_FORM_INPUT_VALUE,
  payload: {
    inputName,
    inputValue,
  },
});

export const changeBillingFormInputValue = (
  inputName: string,
  inputValue: string,
) => ({
  type: CHANGE_BILLING_FORM_INPUT_VALUE,
  payload: {
    inputName,
    inputValue,
  },
});

export const changeCheckboxValue = (checkboxName: string) => ({
  type: CHANGE_CHECKBOX_VALUE,
  payload: {
    checkboxName,
  },
});

export const changeCartDataValue = (name: string, value: any) => ({
  type: CHANGE_CART_DATA_VALUE,
  payload: {
    name,
    value,
  },
});

const requestCreateOrder = () => ({
  type: REQUEST_CREATE_ORDER,
});

const receiveCreateOrderSuccess = () => ({
  type: RECEIVE_CREATE_ORDER_SUCCESS,
});

const receiveCreateOrderError = (error) => ({
  type: RECEIVE_CREATE_ORDER_ERROR,
  payload: {
    error,
  },
});

export const createOrder = (updatedCart) => {
  return async (dispatch, getState, API) => {
    try {
      const { cart } = getState();
      const cartId = cart.cart ? cart.cart.id : null;
      dispatch(requestCreateOrder());

      // --------- removed items from cart --------
      updatedCart.items = null;
      // ------------------------------------------

      const mcCid = cookie.load('mc_cid');

      const { orderPublicId } = await API.createOrder(
        cartId,
        { mcCid: mcCid ? mcCid : undefined },
        updatedCart,
      );
      dispatch(receiveCreateOrderSuccess());
      cookie.remove(CART_COOKIE_ID, { path: '/' });
      dispatch(push(`/objednavka-dokoncena/${orderPublicId}/?redirect`));
    } catch (e) {
      dispatch(receiveCreateOrderError(e));
      dispatch(push(cartRoutes.THIRD_STEP));
    }
  };
};

export const getSomeSetsHasDeliveryToSchoolActivated = () => {
  return async (dispatch, getState, API) => {
    try {
      let { cart } = getState();
      const cartId: string = cart?.cart?.id;
      const result = await API.someSetsHasDeliveryToSchoolActivated(cartId);
      return !!result;
    } catch (e) {
      return false;
    }
  };
};

const requestGifts = () => ({
  type: REQUEST_GIFTS,
});

const receiveGiftsSuccess = (gifts) => ({
  type: RECEIVE_GIFTS_SUCCESS,
  payload: {
    gifts,
  },
});

const receiveGiftsError = (error) => ({
  type: RECEIVE_GIFTS_ERROR,
  payload: {
    error,
  },
});

export const loadGifts = () => {
  return async (dispatch, getState, API) => {
    try {
      const cartId: string = cartIdSelector(getState());
      dispatch(requestGifts());
      const { cart_gifts } = await API.loadCartGifts(cartId);
      dispatch(receiveGiftsSuccess(cart_gifts));
    } catch (e) {
      dispatch(receiveGiftsError(e));
    }
  };
};

export const changeGift = (gift) => ({
  type: CHANGE_GIFT,
  payload: {
    gift,
  },
});

export const loadEshopGifts = () => {
  return async (dispatch, getState, API) => {
    try {
      const { gifts } = await API.loadEshopGifts();
      dispatch(setEshopGifts(gifts));
    } catch (e) {}
  };
};

export const setEshopGifts = (eshopGifts) => ({
  type: ESHOP_GIFTS,
  payload: {
    eshopGifts,
  },
});

export const redirectToCart = () => {
  return async (dispatch, getState, API) => {
    dispatch(push('/kosik')); // FIXME: presmerovať na rooutu podľa akutálneho cartSepu
  };
};

export const setCart = (cart) => ({
  type: SET_CART,
  payload: {
    cart,
  },
});

export const getProductsPriceFromOrder = (order) => {
  let productPrice = 0;
  if (!order || !order.items) {
    return productPrice;
  }
  for (const item of order.items) {
    if (item.id && item.totalprice) {
      productPrice += item.totalprice;
    }
  }
  return productPrice;
};

export const setCustomerDetailsLoader = (customerDetailsLoader) => ({
  type: SET_CUSTOMER_DETAILS_LOADER,
  payload: {
    customerDetailsLoader,
  },
});

export const setCustomerDetails = (customerDetails) => ({
  type: SET_CUSTOMER_DETAILS,
  payload: {
    customerDetails,
  },
});

export const loadCustomerDetails = () => {
  return async (dispatch, getState, API) => {
    const state = getState();
    const userId = state?.auth?.user?.id;
    if (!userId) {
      return;
    }
    dispatch(setCustomerDetailsLoader(true));
    const customerDetails = await API.getCustomerDetails(userId);
    dispatch(setCustomerDetails(customerDetails));
    dispatch(setCustomerDetailsLoader(false));
  };
};

export const hasCustomerTrustPoints = (customerDetails) => {
  return (
    customerDetails &&
    customerDetails.trust_points &&
    customerDetails.trust_points > 0
  );
};

export const hasCustomerAppliedTrustPoints = (cart) => {
  return cart && cart.consumed_trust_points && cart.consumed_trust_points > 0;
};

export const getConsumedTrustPointsMaxForApply = (cart) => {
  return cart.consumed_trust_points_max_for_apply || 0;
};

export const isProblemTypeAvailability = (problem) => {
  return problem && problem.type === CartProblemTypes.AVAILABILITY;
};

export const hasCartAvailabilityProblems = (cart: any) => {
  if (cart) {
    const { problems } = cart;
    return (
      problems &&
      problems.length &&
      problems.filter(isProblemTypeAvailability).length
    );
  } else return false;
};

export const createCartClone = (cartCopyId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const cartData = await API.createCartClone(cartCopyId);
      cookie.save(CART_COOKIE_ID, cartData.id, {
        expires: cookiesCartExpiresDate(),
        path: '/',
      });
      dispatch(setCart(cartData));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setCartInfoText = (cartInfoText) => ({
  type: SET_CART_INFO_TEXT,
  payload: {
    cartInfoText,
  },
});

export const loadCartInfoText = () => {
  return async (dispatch, getState, API) => {
    API.loadOtherTexts('CART_INFO_TEXT').then((loadOtherTextsResult) => {
      if (loadOtherTextsResult && loadOtherTextsResult.content) {
        if (!loadOtherTextsResult.content.draft) {
          const text = prop(
            loadOtherTextsResult,
            'content.json_content.body',
            '',
          );
          if (
            text &&
            !(typeof text === 'object' && Object.keys(text).length === 0)
          ) {
            dispatch(setCartInfoText(text));
          }
        }
      }
    });
  };
};

export const setCart1Upsell = (cart1Upsell) => ({
  type: SET_CART1_UPSELL,
  payload: {
    cart1Upsell,
  },
});

export const loadCart1Upsell = () => {
  return async (dispatch, getState, API) => {
    const { cart: cart } = getState();

    let type: string = '';
    if (cart.cart) {
      switch (cart.cart.cart1_upsell_type) {
        case 'up':
          type = 'PRODUCT_CART_FIRST_STEP_UP';
          break;
        case 'down':
          type = 'PRODUCT_CART_FIRST_STEP_DOWN';
          break;
        case 'hidden':
          type = 'PRODUCT_CART_FIRST_STEP_HIDDEN';
          break;
      }
    }

    if (type) {
      const searchUpsellsResults = await API.searchUpsells({
        isActive: true,
        type: type,
        withAssignedProducts: true,
        activeAssignedProducts: true,
      });

      if (searchUpsellsResults?.items?.length) {
        const upsell = searchUpsellsResults.items[0];
        dispatch(setCart1Upsell(upsell));
      }
    }
  };
};
