import { createGlobalStyle } from 'styled-components';
import { normalize } from './libraries/normalize';
import { theme } from './theme';
import './swiperSlides.css';
import { em, rem } from 'polished';

export const GlobalStyle = createGlobalStyle`
${normalize};
// ==========================================================================
// Global styles
// ==========================================================================
html {
  background-color: ${theme.color.background};
  color: ${theme.color.text};
  font-family: ${theme.global.font.family};
  font-size: 100%;
  line-height: 1.5;
  box-sizing: border-box;
  ${
    theme.global.font.smoothing
      ? `
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;`
      : ''
  }
}

//* {
//  border: solid 1px red;
//}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  padding-top: ${rem(110)};
  ${theme.media('md')} {
    padding-top: ${(props) => (props.isSimple ? rem(90) : 0)};
    @media (min-height: ${em(720)}) {
      padding-top: ${(props) =>
        props.isCart
          ? rem(116)
          : !props.showMainMenu
          ? rem(130)
          : props.isSimple
          ? rem(90)
          : rem(166)};
    }
  }
}

@media print {
  body {
    padding-top: 0;
  }
}

// ==========================================================================
// Selection styles
// ==========================================================================
::selection {
  background: ${theme.color.selection};
  text-shadow: none;
}

// ==========================================================================
// Default img styles
// ==========================================================================
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  padding: 0;
}

a {
    color: ${theme.color.link};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

button,
input,
optgroup,
select,
textarea {
  font-family: ${theme.global.font.family};
}
`;
