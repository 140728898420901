import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Content, Title } from '../../components/_layout/Page/ContentContainer';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Buttons } from './Login';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import { Form, Field } from 'react-final-form';
import Alert from '../../components/Alert/Alert';
import API from '../../services/API';
import { Container } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { loadForgottenPasswordText } from './actions';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { composeValidators, required } from '../../utilities/validators';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { URL_LOGIN } from '../../constants';
import MetaTags from '../../components/MetaTags/MetaTags';

const Wrapper = styled(Content)`
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
`;

export const Heading = styled(Heading2)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
`;

const UsernameInput = styled(EmailAutocomplete)`
  display: block;
  width: 100%;
  margin-bottom: ${rem(10)};
  max-width: ${rem(290)};
`;

const AlertWrapper = styled.div`
  margin-top: ${rem(-10)};
  margin-bottom: ${rem(10)};
`;

const AlertEmail = styled.span`
  color: red;
  font-weight: bold;
`;

const FormWrapper = styled.div`
  margin-bottom: ${rem(30)};
`;

const FormLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: black;
`;
interface Props {
  dispatch: any;
  text?: {
    body: string;
  };
}

const ForgottenPassword = (props: Props) => {
  const [forgotPasswordError, setForgotPasswordError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [isFething, setIsFetching] = React.useState(false);
  const [responseEmail, setResponseEmail] = React.useState('');
  const [responseIco, setResponseIco] = React.useState('');

  React.useEffect(() => {
    props.dispatch(loadForgottenPasswordText());
  }, []);

  const onSubmit = async (values) => {
    setIsFetching(true);
    try {
      const response = await API.lostPassword(
        {},
        { emailOrIco: values.emailOrIco },
      );
      if (response.status) {
        setForgotPasswordError('');
        setSuccess(response.status);
        setResponseEmail(response.email ? response.email : '');
        setResponseIco(response.ico ? response.ico : '');
      } else {
        throw new Error('Vyskytla sa chyba pri žiadosti o nové heslo');
      }
    } catch (exp: any) {
      setSuccess('');
      setResponseEmail('');
      setResponseIco('');
      setForgotPasswordError(exp.details.description);
    }
    setIsFetching(false);
  };

  return (
    <Container className="base">
      <MetaTags
        tags={{
          title: __('Zabudnuté heslo | Môj účet'),
          og_title: __('Zabudnuté heslo | Môj účet'),
          description: __(
            'Pre vytvorenie nového prihlasovacieho hesla zadajte prosím email, ktorý ste použili v nastaveniach účtu.',
          ),
        }}
      />
      <Wrapper>
        <ContentContainerBlock>
          <Breadcrumbs
            items={[
              { name: 'Môj účet', url: URL_LOGIN },
              { name: 'Zabudnuté heslo', url: '/zabudnute-heslo' },
            ]}
          />
          <Title>{__('Zabudnuté heslo')}</Title>
          {props.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: props.text.body,
              }}
            />
          )}
          <Loader loading={isFething}>
            <Form
              onSubmit={onSubmit}
              // tslint:disable-next-line:jsx-no-lambda
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="emailOrIco"
                    validate={composeValidators(required)}
                  >
                    {({ input, meta }) => (
                      <FormWrapper>
                        {forgotPasswordError && (
                          <AlertWrapper>
                            <Alert type="error">{forgotPasswordError}</Alert>
                          </AlertWrapper>
                        )}

                        {success && success !== '' && (
                          <AlertWrapper>
                            <Alert type="success">
                              <div>
                                {__('Link pre obnovu hesla sme zaslali na')}{' '}
                                <AlertEmail>{responseEmail}</AlertEmail>
                              </div>
                              {responseIco ? (
                                <>
                                  <div>
                                    {__(
                                      'Ak nemáte prístup k uvedenému emailu, vyplňte, prosím, nasledovný formulár a pomôžeme Vám získať prístup do účtu Vašej organizácie.',
                                    )}
                                  </div>
                                  <div>
                                    <FormLink
                                      href={`/zabudnute-heslo-firma?ico=${responseIco}&email=${responseEmail}`}
                                    >
                                      Formulár pre získanie prístupu do účtu
                                      organizácie
                                    </FormLink>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </Alert>
                          </AlertWrapper>
                        )}

                        <UsernameInput
                          type="input"
                          {...input}
                          placeholder={__('E-mail alebo IČO')}
                          className="lg"
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </FormWrapper>
                    )}
                  </Field>

                  <Buttons>
                    <ButtonStyled className="primary lg" type="submit">
                      {__('Odoslať')}
                    </ButtonStyled>
                  </Buttons>
                </form>
              )}
            />
          </Loader>
        </ContentContainerBlock>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    text: state.login.forgottenPasswordText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ForgottenPassword' })(ForgottenPassword),
);
