import * as React from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { Container } from '../../../theme/libraries/grid';
import Searchform from './Searchform';
import { Button, ButtonDefault } from '../../_helpers/form/Button';
import { em, rem } from 'polished';
import Icon from '../../_helpers/Icon/Icon';
import { __ } from 'react-i18n/lib';
import API, { ThenArg } from '../../../services/API';
import {
  inCart,
  isTablet,
  isSsr,
  formatPrice,
  showPriceWithVat,
  inForSchools,
  isCartOnlyDemand,
  isMainMenuVisible,
} from '../../../utilities';
import { MobileOverlay } from './MobileOverlay';
import CustomCategories from './CustomCategories';
import CustomCMSMenu from './CustomCMSMenu';
import Dropdown from '../../Dropdown/Dropdown';
import MiniCart from './MiniCart';
import { redirectToCart } from '../../../containers/Cart/actions';
import NavigationNew from './NavigationNew';
import OnClickOutsideComponent from '../../_helpers/OnClickOutsideComponent/OnClickOutsideComponent';
import {
  getLoggedInMenu,
  getNotLoggedInMenu,
  MenuItems,
  URL_LOGIN,
} from '../../../constants';
import { Media, MediaContextProvider } from '../../../theme/media';
import OverlaySearch from '../../_helpers/Overlay/OverlaySearch';
import Check from '../../_helpers/Icon/Check';
import { push } from 'react-router-redux';

const Wrapper = styled.header`
  background: ${({ theme }) => theme.color.primary};
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${(props) => (rem(props.searchDropdownVisible) ? 101 : 11)};
  @media (max-width: ${em(991)}) {
    max-height: 110px;
  }
  ${({ theme }) => theme.transition('transform')};
  ${({ visible }) => {
    if (visible) {
      return css`
        transform: translate3d(0, -100%, 0);
      `;
    }
  }}
  ${({ theme }) => theme.media('md')} {
    z-index: 11;
    background: ${({ theme }) => theme.color.white};
    position: relative;
    ${({ visible }) => {
      if (visible) {
        return css`
          transform: translate3d(0, 0, 0);
        `;
      }
    }};
    margin-bottom: ${rem(24)};
    &.cart {
      margin-bottom: 0;
    }
  }
  @media screen and (min-height: ${em(720)}) {
    position: fixed;
  }

  @media print {
    position: relative;
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: ${rem(16)};
  padding-left: ${rem(9)};
  ${({ theme }) => theme.media('md')} {
    padding-right: ${({ theme }) => rem(theme.grid.padding / 2)};
    padding-left: ${({ theme }) => rem(theme.grid.padding / 2)};
  }
`;

const DesktopNav = styled.nav`
  display: none;
  width: 100%;
  ${({ theme }) => theme.media('md')} {
    display: block;
  }
`;

const HeaderButtonWrapper = styled.div`
  z-index: 1;
  display: inline-block;
  padding-right: ${(props) => (props.padding ? props.padding : rem(3))};

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  ${({ theme }) => theme.media(992)} {
    position: relative;
  }
  ${({ userDesktop }) => {
    if (userDesktop) {
      return css`
        display: none;
      `;
    }
  }}
  ${({ theme }) => theme.media('md')} {
    flex: 0 1 auto;
    ${({ menu }) => {
      if (menu) {
        return css`
          display: none;
        `;
      }
    }}
    ${({ search }) => {
      if (search) {
        return css`
          display: none;
        `;
      }
    }}
    ${({ userMobile }) => {
      if (userMobile) {
        return css`
          display: none;
        `;
      }
    }}
    ${({ userDesktop }) => {
      if (userDesktop) {
        return css`
          display: flex;
        `;
      }
    }}
  }
`;

const HeaderFavoriteWrapper = styled.div`
  display: block;
  height: ${rem(30)};
  margin-right: ${rem(20)};
  position: relative;
  cursor: pointer;
`;

const HeaderSkvelkoIconWrapper = styled.div``;

const HeaderFavoriteInnerWrapper = styled.div`
  height: 100%;
  z-index: 3;
`;

const HeaderFavoriteBottom = styled.div`
  position: absolute;
  height: 40px;
  top: -5px;
  bottom: 0;
  width: 120px;
  background: ${({ theme }) => theme.color.primary};
  z-index: 2;
  margin-left: ${rem(-5)};
  display: none;

  ${HeaderFavoriteWrapper}:hover & {
    display: block;
  }
`;

const HeaderFavoriteBottomText = styled.div`
  position: absolute;
  right: 0;
  width: min-content;
  margin-right: ${rem(10)};
  line-height: ${rem(14)};
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  font-weight: 700;
  font-size: ${rem(14)};
`;

const Hearth = styled.img`
  height: 100%;
  position: relative;
  z-index: 2;
  ${HeaderFavoriteWrapper}:hover & {
    filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
  }
`;

const SkvelkoIcon = styled.img`
  height: 26px;
  margin-right: 15px;
  position: relative;
`;

const FavoriteCountWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: ${rem(5)};
  font-size: ${rem(11)};
  z-index: 2;
  ${HeaderFavoriteWrapper}:hover & {
    color: white;
  }
`;

const MobileFavoriteCountWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: ${rem(5)};
  font-size: ${rem(11)};
  z-index: 2;
  color: white;
  top: ${rem(7)};
`;

const HeaderMobileButtonStyles = css`
  width: ${rem(40)};
  line-height: ${20 / 16};
  padding-top: ${rem(5)};
  padding-bottom: ${rem(6)};
`;

const HeaderMenu = styled(Button)`
  ${HeaderMobileButtonStyles};
`;

const HeaderButton = styled(Button)`
  width: ${rem(40)};
  line-height: ${20 / 16};
  padding-top: ${rem(5)};
  padding-bottom: ${rem(6)};
  position: relative;
  ${({ theme }) => theme.media('md')} {
    display: flex;
    width: auto;
  }
`;

const DivLink = styled.div`
  color: inherit;
  width: ${rem(40)};
  line-height: ${20 / 16};
  padding-top: ${rem(5)};
  padding-bottom: ${rem(6)};
  position: relative;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  &.center {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  ${({ theme }) => theme.media('md')} {
    display: flex;
    width: auto;
  }
`;
/*
const HeaderLink = styled(Link)`
  color: inherit;
  width: ${rem(40)};
  line-height: ${20 / 16};
  padding-top: ${rem(5)};
  padding-bottom: ${rem(6)};
  position: relative;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  &.center {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  ${({ theme }) => theme.media('md')} {
    display: flex;
    width: auto;
  }
`;
*/

const HeaderButtonIcon = styled(Icon)`
  ${({ theme }) => theme.size(30)};
  fill: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.media('md')} {
    flex: 1 0 auto;
    fill: ${({ theme }) => theme.color.gray14};
    margin-right: ${rem(9)};
  }
`;

const HeaderUserLinks = styled.div`
  vertical-align: middle;
  padding-left: ${rem(4)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 30px;
`;

const HeaderButtonText = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(10)};
  line-height: ${20 / 16};
  font-weight: 700;
  letter-spacing: ${rem(-0.5)};
  text-transform: uppercase;
  padding-top: ${rem(1)};
  ${({ theme }) => theme.media('md')} {
    display: none;
  }
`;

const HeaderButtonTextDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.media('md')} {
    display: block;
    flex: 1 0 auto;
    font-size: ${rem(15)};
    font-weight: 700;
  }
`;

const HeaderButtonLink = styled(Link)`
  display: block;
  color: inherit;
  font-size: ${rem(14)};
  font-weight: 700;
  line-height: 1;
  margin-right: ${rem(30)};
  padding: ${rem(2)} 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${rem(130)};

  &.secondary {
    font-weight: normal;
  }
`;

const HeaderButtonIconLink = styled(Link)``;

const HeaderCartBadge = styled.span`
  display: none;
  background: ${({ theme }) => theme.color.secondary};
  border-radius: ${rem(2)};
  font-size: ${rem(14)};
  font-weight: 700;
  padding: 0 ${rem(9)};
  position: absolute;
  top: ${rem(25)};
  right: 0;
  ${({ theme }) => theme.media('md')} {
    display: inline-block;
  }
`;

const HeaderCartBadgeMobile = styled(HeaderButtonText)`
  font-weight: 600;
  font-size: ${rem(10)};
  color: ${({ theme }) => theme.color.black};
  letter-spacing: 0;
  background: ${({ theme }) => theme.color.secondary};
  border-radius: ${rem(5)};
  padding: 0 ${rem(5)};
`;

const LogoCol = styled.div`
  flex: 1 1 auto;
  padding: 0 ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    flex: 0 1 auto;
    padding-top: ${rem(12)};
    padding-bottom: ${rem(12)};
  }

  @media only screen and (max-width: 350px) {
    padding: 0px 6px;
  }
`;

const LogoLink = styled(Link)`
  display: block;
  max-width: ${rem(210)};
  // margin: auto;
  ${({ theme }) => theme.media('xs')} {
    max-width: ${rem(280)};
    margin: 0;
  }
`;

const Logo = styled.img`
  width: ${rem(111)};
  height: ${rem(32)};
  ${({ theme }) => theme.media('md')} {
    width: ${rem(188)};
    height: ${rem(54)};
  }
`;

const SearchCol = styled.div`
  display: none;

  ${({ theme }) => theme.media('md')} {
    display: block;
    flex: 1 1 auto;
    padding-right: ${rem(15)};
    padding-left: ${rem(66)};
  }

  @media only screen and (max-width: 1050px) {
    padding-right: 15px;
    padding-left: 32px;
  }
`;

const MenuToggle = styled(ButtonDefault)`
  background: #bada55;
  padding: 0;
  ${({ theme }) => theme.media(1024)} {
    display: none;
  }
`;

export const MenuIconStyles = css`
  display: inline-block;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(2)};
  ${({ theme }) => theme.size(30, 3)};
  vertical-align: middle;
  position: relative;
  transform: translate3d(0, -100%, 0);
  ${({ theme }) => theme.transition('background-color')};
  &:before,
  &:after {
    display: block;
    content: '';
    background: ${({ theme }) => theme.color.white};
    border-radius: ${rem(2)};
    ${({ theme }) => theme.size(30, 3)};
    position: absolute;
    left: 0;
    ${({ theme }) => theme.transition('transform')};
  }
  &:before {
    bottom: ${rem(10)};
  }
  &:after {
    top: ${rem(10)};
  }
`;

export const MenuIconActiveStyles = css`
  background: transparent;
  &:before {
    transform: translateY(${rem(10)}) rotate(-45deg);
  }
  &:after {
    transform: translateY(${rem(-10)}) rotate(45deg);
  }
`;

const MenuIconWrapper = styled.span`
  display: inline-block;
  ${({ theme }) => theme.size(30)};
  padding-top: ${rem(6.5)};
`;

const MenuIcon = styled.span`
  ${MenuIconStyles};
  ${MenuToggle}.active & {
    ${MenuIconActiveStyles};
  }
`;

const HeaderDropdown = styled(Dropdown)`
  display: block;
  width: ${rem(300)};
  top: 100%;
  right: ${rem(10)};
  ${({ theme }) => theme.media(340)} {
    width: ${rem(320)};
  }
  ${({ theme }) => theme.media(1340)} {
    left: 0;
    right: auto;
    transform: translateY(0);
  }
`;

const StyledOnClickOutsideComponent = styled(OnClickOutsideComponent)`
  display: inline-block;
`;

interface HeaderProps {
  visible: boolean;
  mainMenu: any[];
  dispatch: any;
  cart: ThenArg<typeof API.getCart>;
  user: any;
  searchDropdownVisible: boolean;
  searchDropdownMobileVisible: boolean;
  searchedQuery: string;
  searchedResults: any[];
  searchedResultsLoader: boolean;
  onChangeSearchInput: (q: string) => void;
  onSubmitSearchInput: (section: string, redirectUrl?: string | null) => void;
  onClickOutsideSearchForm: () => void;
  onClickOutsideSearchMobileForm: () => void;
  onRedirect: (url) => void;
  onSearchFocus: (isFocus: boolean) => void;
  currentPath: string;
  categoriesGroup: any[];
  favoriteProductsCount: number;
  currentUniqs: string[];
  location: any;
  settings: any;
}

interface HeaderStates {
  minicartVisible: boolean;
  mobileNavVisible: boolean;
  userMetaVisible: boolean;
  searchVisible: boolean;
}

class Header extends React.PureComponent<HeaderProps, HeaderStates> {
  constructor(props) {
    super(props);

    this.state = {
      minicartVisible: false,
      mobileNavVisible: false,
      userMetaVisible: false,
      searchVisible: false,
    };
  }

  public redirectToCart = () => {
    this.props.dispatch(redirectToCart());
  };

  public onClickFavorite = () => {
    this.props.dispatch(push('/my-account/favorite-products'));
  };

  handleURLChange = () => {
    this.setState({
      mobileNavVisible: false,
    });
  };

  // Check for URL changes in componentDidUpdate
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleURLChange();
    }
  }

  public render() {
    const logo = '/images/logo.svg';

    const user = this.props.user;
    const showWithVat = showPriceWithVat(user);

    const dropdownItems: MenuItems = getLoggedInMenu(user);
    const dropdownItemsNotLogged: MenuItems = getNotLoggedInMenu();

    const {
      minicartVisible,
      mobileNavVisible,
      userMetaVisible,
      searchVisible,
    } = this.state;
    const {
      dispatch,
      cart,
      visible,
      categoriesGroup,
      mainMenu,
      currentPath,
      searchDropdownVisible,
      searchedQuery,
      searchedResults,
      searchedResultsLoader,
      onChangeSearchInput,
      onClickOutsideSearchForm,
      onRedirect,
      onSubmitSearchInput,
      onSearchFocus,
      currentUniqs,
      settings,
    } = this.props;

    const tablet = isTablet();

    const getCartTotalPrice = () => {
      let cartTotalPrice = 0;

      if (cart && cart.total_items_price_with_discount && showWithVat) {
        cartTotalPrice = cart.total_items_price_with_discount;
      } else if (cart && cart.total_items_price_without_vat) {
        if (cart.customer_card_discount && cart.customer_card_discount > 0) {
          cartTotalPrice =
            cart.total_items_price_with_discount_without_vat || 0;
        } else {
          cartTotalPrice = cart.total_items_price_without_vat;
        }
      }

      return formatPrice(cartTotalPrice, 'EUR');
    };

    if (visible && tablet) {
      this.setAllBooleanStatesFalse();
    }

    const isOnlyDemand = isCartOnlyDemand(cart);

    const cmsTree =
      mainMenu && mainMenu.find((item) => item.uniqid === 'SCHOOLS')
        ? mainMenu.find((item) => item.uniqid === 'SCHOOLS').children
        : [];

    const showMainMenu = isMainMenuVisible(currentPath, currentUniqs);

    return (
      <MediaContextProvider>
        {searchDropdownVisible && tablet && <OverlaySearch />}
        <Wrapper
          searchDropdownVisible={searchDropdownVisible}
          visible={visible}
          userMetaVisible={userMetaVisible}
          className={inCart(currentPath) ? 'cart' : ''}
        >
          <Media greaterThanOrEqual="lg">
            <HeaderContainer className="base">
              <DesktopNav>
                <NavigationNew
                  items={mainMenu}
                  currentPath={currentPath}
                  onClickLink={this.handleMenuToggle}
                  categoriesGroup={categoriesGroup}
                  user={user}
                  dispatch={dispatch}
                />
              </DesktopNav>
              <HeaderButtonWrapper menu={true}>
                <HeaderMenu onClick={this.toggleMobileNav}>
                  <MenuIconWrapper>
                    <MenuIcon />
                  </MenuIconWrapper>
                  <HeaderButtonText>{__('Menu')}</HeaderButtonText>
                </HeaderMenu>
              </HeaderButtonWrapper>
              <LogoCol>
                <LogoLink to="/">
                  <Logo src={logo} alt="Daffer.sk" width={188} height={54} />
                </LogoLink>
              </LogoCol>
              <SearchCol>
                <Searchform
                  searchDropdownVisible={searchDropdownVisible}
                  searchedQuery={searchedQuery}
                  searchedResults={searchedResults}
                  searchedResultsLoader={searchedResultsLoader}
                  onChangeSearchInput={onChangeSearchInput}
                  onClickOutsideSearchForm={onClickOutsideSearchForm}
                  onRedirect={onRedirect}
                  onSubmitSearchInput={onSubmitSearchInput}
                  user={user}
                  cart={cart}
                  settings={settings}
                />
              </SearchCol>
              <HeaderButtonWrapper userMobile={true}>
                <HeaderButton onClick={this.toggleUserMeta}>
                  <HeaderButtonIcon icon="user2" />
                  <HeaderButtonText>{__('Účet')}</HeaderButtonText>
                </HeaderButton>
              </HeaderButtonWrapper>
              {user === null ? (
                <HeaderButtonWrapper userDesktop={true}>
                  <HeaderButtonIconLink
                    to={URL_LOGIN}
                    aria-label={__('Prihlásenie')}
                  >
                    <HeaderButtonIcon icon="user2" />
                  </HeaderButtonIconLink>
                  <HeaderUserLinks>
                    <HeaderButtonLink to={URL_LOGIN}>
                      {__('Prihlásenie')}
                    </HeaderButtonLink>
                    <HeaderButtonLink to={URL_LOGIN}>
                      {__('Registrácia')}
                    </HeaderButtonLink>
                  </HeaderUserLinks>
                </HeaderButtonWrapper>
              ) : (
                <>
                  {user.skvelko_type === 3 ? (
                    <HeaderSkvelkoIconWrapper>
                      <Link to={'/my-account/sets'}>
                        <SkvelkoIcon src="/images/skvelko-logo.svg" />
                      </Link>
                    </HeaderSkvelkoIconWrapper>
                  ) : (
                    <></>
                  )}

                  <HeaderFavoriteWrapper onClick={this.onClickFavorite}>
                    <HeaderFavoriteBottom>
                      <HeaderFavoriteBottomText>
                        {__('Obľúbené produkty')}
                      </HeaderFavoriteBottomText>
                    </HeaderFavoriteBottom>
                    <HeaderFavoriteInnerWrapper>
                      <FavoriteCountWrapper>
                        {this.props.favoriteProductsCount}
                      </FavoriteCountWrapper>
                      <Hearth src="/images/hearth-black.svg" />
                    </HeaderFavoriteInnerWrapper>
                  </HeaderFavoriteWrapper>

                  <StyledOnClickOutsideComponent
                    handleClickOutside={
                      tablet
                        ? null
                        : this.setAllBooleanStatesFalse.bind(null, true, false)
                    }
                  >
                    <HeaderButtonWrapper
                      userDesktop={true}
                      onClick={this.toggleUserMeta}
                    >
                      <HeaderButtonIconLink>
                        <HeaderButtonIcon icon="user2" />
                      </HeaderButtonIconLink>
                      <HeaderUserLinks>
                        <HeaderButtonLink>{__('Prihlásený:')}</HeaderButtonLink>
                        {user.display_name ? (
                          <HeaderButtonLink className={'secondary'}>
                            {user.display_name}
                          </HeaderButtonLink>
                        ) : (
                          ''
                        )}
                      </HeaderUserLinks>
                      {userMetaVisible && (
                        <HeaderDropdown
                          items={dropdownItems}
                          onItemClick={this.setAllBooleanStatesFalse}
                        />
                      )}
                    </HeaderButtonWrapper>
                  </StyledOnClickOutsideComponent>
                </>
              )}
              <StyledOnClickOutsideComponent
                handleClickOutside={
                  tablet
                    ? null
                    : this.setAllBooleanStatesFalse.bind(null, false)
                }
              >
                <HeaderButtonWrapper padding={'20px'}>
                  <DivLink onClick={this.toggleMinicart}>
                    <HeaderButtonIcon icon="cart2" />
                    <HeaderButtonText>
                      {isOnlyDemand ? __('Kalkulácia') : __('Košík')}
                    </HeaderButtonText>
                    <HeaderButtonTextDesktop>
                      {isOnlyDemand ? __('Kalkulácia') : __('Košík')}
                    </HeaderButtonTextDesktop>
                    <HeaderCartBadge>
                      {isOnlyDemand && cart
                        ? cart.items.length === 1
                          ? cart.items.length + ' ' + __('produkt')
                          : cart.items.length > 1 && cart.items.length < 5
                          ? cart.items.length + ' ' + __('produkty')
                          : cart.items.length + ' ' + __('produktov')
                        : getCartTotalPrice()}
                    </HeaderCartBadge>
                  </DivLink>
                  {minicartVisible && (
                    <MiniCart
                      closeModal={this.setAllBooleanStatesFalse}
                      redirectToCart={this.redirectToCart}
                      cart={cart}
                      user={user}
                      isOnlyDemand={isOnlyDemand}
                      dispatch={dispatch}
                    />
                  )}
                </HeaderButtonWrapper>
              </StyledOnClickOutsideComponent>

              {showMainMenu ? (
                <>
                  {!inCart(currentPath) &&
                  !inForSchools(currentPath, currentUniqs, cmsTree) &&
                  categoriesGroup &&
                  categoriesGroup.length ? (
                    <CustomCategories
                      dispatch={dispatch}
                      categoriesGroup={categoriesGroup}
                      user={user}
                    />
                  ) : (
                    ''
                  )}

                  {inForSchools(currentPath, currentUniqs, cmsTree) ? (
                    <CustomCMSMenu
                      cmsTree={cmsTree}
                      currentUniqs={currentUniqs}
                    />
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <></>
              )}
            </HeaderContainer>
          </Media>
          <Media lessThan="lg">
            <HeaderContainer className="base">
              <HeaderButtonWrapper menu={true}>
                <HeaderMenu onClick={this.toggleMobileNav}>
                  <MenuIconWrapper>
                    <MenuIcon />
                  </MenuIconWrapper>
                  <HeaderButtonText>{__('Menu')}</HeaderButtonText>
                </HeaderMenu>
              </HeaderButtonWrapper>
              <LogoCol>
                <LogoLink to="/">
                  <Logo src={logo} alt="Daffer.sk" width={188} height={32} />
                </LogoLink>
              </LogoCol>
              {user ? (
                <HeaderButtonWrapper userMobile={true}>
                  <HeaderButton onClick={this.onClickFavorite}>
                    <HeaderButtonIcon icon="hearth" />
                    <HeaderButtonText>{__('Obľúb.')}</HeaderButtonText>
                    <MobileFavoriteCountWrapper>
                      {' '}
                      {this.props.favoriteProductsCount}{' '}
                    </MobileFavoriteCountWrapper>
                  </HeaderButton>
                </HeaderButtonWrapper>
              ) : (
                ''
              )}
              <HeaderButtonWrapper userMobile={true}>
                <HeaderButton onClick={this.toggleUserMeta}>
                  <HeaderButtonIcon icon="user2" />
                  {user ? <Check /> : ''}
                  <HeaderButtonText>{__('Účet')}</HeaderButtonText>
                </HeaderButton>
              </HeaderButtonWrapper>
              <HeaderButtonWrapper padding={'0px'}>
                <DivLink onClick={this.toggleMinicart} className={'center'}>
                  <HeaderButtonIcon icon="cart2" />
                  <HeaderCartBadgeMobile>
                    {getCartTotalPrice()}
                  </HeaderCartBadgeMobile>
                </DivLink>
              </HeaderButtonWrapper>
            </HeaderContainer>
            <Searchform
              searchDropdownVisible={!visible && searchDropdownVisible}
              searchedQuery={searchedQuery}
              searchedResults={searchedResults}
              searchedResultsLoader={searchedResultsLoader}
              onChangeSearchInput={onChangeSearchInput}
              onSubmitSearchInput={onSubmitSearchInput}
              onClickOutsideSearchForm={onClickOutsideSearchForm}
              onRedirect={onRedirect}
              onSearchClick={this.setAllBooleanStatesFalse}
              onSearchFocus={onSearchFocus}
              isMobile={true}
              searchVisible={searchVisible}
              user={user}
              cart={cart}
              settings={settings}
            />
            <MobileOverlay
              title={__('Menu')}
              visible={mobileNavVisible}
              onClose={this.toggleMobileNav}
            >
              <NavigationNew
                items={
                  mainMenu &&
                  mainMenu.concat([
                    {
                      id: '-3',
                      url: null,
                      name: __('Môj účet'),
                      uniqid: 'ACCOUNT',
                      children: user ? dropdownItems : dropdownItemsNotLogged,
                    },
                  ])
                }
                currentPath={currentPath}
                onClickLink={this.toggleMobileNav}
                categoriesGroup={categoriesGroup}
                user={user}
                dispatch={dispatch}
              />
            </MobileOverlay>
            <MobileOverlay
              title={__('Môj účet')}
              visible={userMetaVisible}
              onClose={this.toggleUserMeta}
            >
              <NavigationNew
                items={user ? dropdownItems : dropdownItemsNotLogged}
                onClickLink={this.toggleUserMeta}
                currentPath={currentPath}
                user={user}
                dispatch={dispatch}
              />
            </MobileOverlay>
          </Media>
        </Wrapper>
      </MediaContextProvider>
    );
  }

  public toggleMobileNav = () => {
    this.setAllBooleanStatesFalse();
    this.setState({
      mobileNavVisible: !this.state.mobileNavVisible,
    });
  };

  private toggleUserMeta = () => {
    this.setAllBooleanStatesFalse();
    this.setState({
      userMetaVisible: !this.state.userMetaVisible,
    });
  };

  private toggleMinicart = () => {
    if (isTablet()) {
      this.redirectToCart();
      return;
    }
    this.setAllBooleanStatesFalse();
    this.setState({
      minicartVisible: !this.state.minicartVisible,
    });
    if (!isSsr) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    }
  };

  private setAllBooleanStatesFalse = (
    userMetaVisible = true,
    minicartVisible = true,
  ) => {
    if (this.state.minicartVisible && minicartVisible) {
      this.setState({
        minicartVisible: false,
      });
    }
    if (this.state.userMetaVisible && userMetaVisible) {
      this.setState({
        userMetaVisible: false,
      });
    }
    if (this.state.mobileNavVisible) {
      this.setState({
        mobileNavVisible: false,
      });
    }
  };

  private handleMenuToggle = () => {
    this.setState({
      mobileNavVisible: !this.state.mobileNavVisible,
    });
  };
}

export default Header;
