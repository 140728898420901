import * as React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import API from '../../services/API';
import {
  getBaseUrl,
  getUrl,
  parseTextWithShortcode,
  prop,
  propByKey,
  slugify,
} from '../../utilities';
import styled from 'styled-components';
import { rem } from 'polished';
import { ContentService } from '../../services/ContentService';
import Gallery from '../../components/_helpers/Gallery/Gallery';
import { Title } from '../../components/_layout/Page/ContentContainer';
import { ContentWithSidebar } from '../../theme/libraries/grid';
import StoreList from '../../components/_layout/Stores/StoreList';
import StoreDetail from '../../components/_layout/Stores/StoreDetail';
import { CustomerCardForm } from './CustomerCardForm';
import { BenefitCardForm } from './BenefitCardForm';
import ArticleList from '../../components/_layout/Article/ArticleList';
import PictureTitleList from '../../components/_layout/Article/PictureTitleList';
import { ImageService } from '../../services/ImageService';
import MetaTags from '../../components/MetaTags/MetaTags';
import { setCurrentShopNetworkArticle, setCurrentUniqs } from '../App/actions';
import ArticleSingle from '../../components/_layout/Article/ArticleSingle';
import Contact from '../Contact/Contact';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Faq from '../../components/_layout/Faq/Faq';
import { loadFaq } from '../HelpAndSupport/actions';
import LostCustomerCardForm from '../LostCustomerCardForm/LostCustomerCardForm';
import { Loader } from '../../components/_helpers/Loader/Loader';
import SidebarContainer from '../../components/_layout/Sidebar/Sidebar';
import ContentContainerWithSidebar from '../../components/_layout/Page/ContentContainerWithSidebar';
import ComplaintForm from './ComplaintForm';
import { Location } from 'history';
import CookiesChooser from './CookiesChooser';
import FlipBook from '../../components/FlipBook/FlipBook';
import { GiftCardForm } from './GiftCardForm';
import MenuList from './MenuList';
import ContestGeneratedForm from '../../components/ContestGeneratedForm/ContestGeneratedForm';
import { __ } from 'react-i18n';

export const Content = styled.div`
  margin-bottom: ${rem(5)};
`;

const Header = styled.header``;

const GalleryWrapper = styled.div``;

export interface CmsProps {
  sitemaps: any;
  sitemapTree: any;
  itemapId: any;
  parentSitemapId: any;
  parentSitemap: any;
  parentSitemapObject: any;
  isError: boolean;
  defaultTitle: string;
  articles: any;
  articlesList: any;
  articlesNextLink: string | null;
  articlesPreviousLink: string | null;
  isArticle: boolean;
  connectedProducts: any;
  contentData: any;
  draftContent: boolean;
  breadcrumbsItems: any;
  currentPage: number;
  totalPages: number;

  allImages: string[];
  dispatch: (action: any) => void;
  shops: any;
  shop: any;
  faq: any;
  location: Location;
  currentUniqs: string[];
}

interface State {
  newsletterEmail: string;
  loadingNewsletter: boolean;
  newsletterError: string;
  newsletterErrorType: 'error' | 'success';

  mainImage: string;
  modal: any;

  galleryIndex: any;

  shop: any | null;

  shopLoader: boolean;
  articleLoader: boolean;
  customerCardSuccess: boolean;
  faqPageContent: any;
}

class Cms extends React.Component<CmsProps, State> {
  public static async getInitialProps({
    parentProps,
    sitemapId,
    contentId,
    lang,
  }) {
    try {
      const sitemaps = await API.loadSitemap(sitemapId, {
        xAcceptLanguage: lang,
      });
      const itemapId = propByKey(sitemaps, 'id', -1);
      const parentSitemapId = propByKey(sitemaps, 'parent_id', -1);

      if (itemapId === 85) {
        await parentProps.dispatch(loadFaq());
      }

      const parentSitemapObject = await API.loadSitemap(
        parentSitemapId,
        {},
        {
          xAcceptLanguage: lang,
        },
      );

      const breadcrumbsItems = (
        await API.loadSitemapParents(
          itemapId,
          {},
          {
            xAcceptLanguage: lang,
          },
        )
      ).slice(1);

      const parentSitemapUniqs = breadcrumbsItems
        .map((bi) => bi.uniqid)
        .filter((o) => o);
      parentProps.dispatch(setCurrentUniqs(parentSitemapUniqs));

      const parentSitemap = (
        await API.loadSitemapSubtree(
          parentSitemapUniqs.includes('REFERENCES') ? 181 : parentSitemapId,
          {},
          {
            xAcceptLanguage: lang,
          },
        )
      ).sitemap_tree.map((sitemapInTree) => {
        function parseItem(item) {
          return {
            id: item.id,
            text: item.name,
            url: item.url,
            active: item.id === sitemapId,
            uniqid: item.uniqid,
            children:
              item.children && item.children.length
                ? item.children.map((child) => parseItem(child))
                : [],
          };
        }

        return parseItem(sitemapInTree);
      });

      const sitemapTree = (
        await API.loadSitemapSubtree(
          itemapId,
          {},
          {
            xAcceptLanguage: lang,
          },
        )
      ).sitemap_tree.map((sitemapInTree) => {
        return {
          text: sitemapInTree.name,
          url: sitemapInTree.url,
          active: sitemapInTree.id === sitemapId,
        };
      });

      const shops = (await API.loadShops()).shops;
      let shop: any = null;

      if (
        parentProps &&
        parentProps.location &&
        parentProps.location.query &&
        parentProps.location.query.shop
      ) {
        const urlShopName = parentProps.location.query.shop;
        shops.forEach((currentShop) => {
          if (slugify(currentShop.name) === urlShopName) {
            shop = currentShop;
          }
        });
      }

      // ------
      // List of articles
      let articlesList: any = [];
      let articlesNextLink: string | null = null;
      let articlesPreviousLink: string | null = null;
      let currentPage: number = 1;
      let totalPages: number = 1;
      if (
        sitemaps.uniqid === 'SHOP_NETWORK_NEWS' ||
        sitemaps.uniqid?.includes('!PT!')
      ) {
        // pagination stuff
        const limitValue = 12;
        let page: number = 0;
        let offsetValue: number = 0;
        let pathName: string | null = null;
        if (parentProps && parentProps.location) {
          if (
            parentProps.location.query &&
            parentProps.location.pathname &&
            parentProps.location.query.page &&
            +parentProps.location.query.page > 0
          ) {
            pathName = parentProps.location.pathname;
            currentPage = +parentProps.location.query.page;
            page = currentPage - 1;
            offsetValue = page === 0 ? 0 : page * limitValue;
          }
          if (parentProps.location.pathname) {
            pathName = parentProps.location.pathname;
          }
        }

        // load articles by pagination

        const loadArticlesResponse = await API.loadArticles(
          {
            sitemapId: itemapId,
            isPublished: '1',
            limit: limitValue,
            offset: offsetValue,
            sort: 'custom_date',
            sortDir: 'desc',
          },
          {
            xAcceptLanguage: lang,
          },
        );

        const loadedArticles: any[] = loadArticlesResponse.articles
          ? loadArticlesResponse.articles
          : [];

        articlesList = loadedArticles.map((article) => {
          article.image = ImageService.getImage(article.image) as string;
          article.url = ContentService.getAppRelativeUrl(article.url) as string;
          return article;
        });

        const loadArticlesResponseList = await API.loadArticles(
          {
            sitemapId: itemapId,
            isPublished: '1',
            limit: limitValue,
            offset: offsetValue + limitValue,
            sort: 'custom_date',
            sortDir: 'desc',
          },
          {
            xAcceptLanguage: lang,
          },
        );

        const articlesListNext = loadArticlesResponseList.articles
          ? loadArticlesResponseList.articles
          : [];

        // check if pagination next articles exist
        if (pathName && currentPage) {
          if (articlesListNext.length !== 0) {
            articlesNextLink = pathName + '?page=' + Number(currentPage + 1);
          }
          if (currentPage - 1 > 0) {
            articlesPreviousLink =
              pathName + '?page=' + Number(currentPage - 1);
          }
        }

        totalPages = loadArticlesResponseList.total
          ? Math.ceil(loadArticlesResponseList.total / limitValue)
          : 1;
      }
      // ------

      const returnProps: any = {
        sitemaps,
        itemapId,
        parentSitemap,
        parentSitemapObject,
        parentSitemapId,
        sitemapTree,
        shops,
        shop,
        articlesList,
        articlesNextLink,
        articlesPreviousLink,
        breadcrumbsItems,
        currentPage,
        totalPages,
      };

      if (!contentId) {
        returnProps.isArticle = false;
        returnProps.articles = await API.loadArticles({
          sitemapId,
        });

        if (sitemaps.content) {
          returnProps.contentData = sitemaps.content;
          returnProps.allImages = returnProps.contentData.photogallery.map(
            (imageData) => {
              return (
                // process.env.REACT_APP_API_BASE_URL +
                imageData.document_path
              );
            },
          );
        } else {
          returnProps.draftContent = true;
        }
      } else {
        returnProps.isArticle = true;
        returnProps.articles = [];
        returnProps.contentData = await API.loadArticleDetail(
          contentId,
          {},
          {
            xAcceptLanguage: lang,
          },
        );

        const articleName = prop(returnProps, 'contentData.json_content.name');
        const articleUrl = prop(returnProps, 'contentData.url');
        if (
          articleName &&
          articleUrl &&
          Array.isArray(returnProps.breadcrumbsItems)
        ) {
          returnProps.breadcrumbsItems.push({
            name: articleName,
            url: getUrl(articleUrl),
          });
        }

        returnProps.connectedProducts = await API.loadArticleConnections(
          contentId,
          {
            xAcceptLanguage: lang,
          },
        );
        parentProps.dispatch(
          setCurrentShopNetworkArticle(returnProps.contentData),
        );

        returnProps.allImages = returnProps.contentData.photogallery.map(
          (imageData) => {
            return (
              // process.env.REACT_APP_API_BASE_URL +
              imageData.document_path
            );
          },
        );
      }

      return returnProps;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.isError === true || props.draftContent) {
      return null;
    }

    if (props.sitemaps.id !== state.sid) {
      return {
        mainImage: props.allImages[0],
        sid: props.sitemaps.id,
        shop: props.shop,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    let contenFaq = '';
    if (props.sitemaps && props.sitemaps.id === 85) {
      contenFaq = prop(props.sitemaps, 'content.json_content.body', '');
    }

    this.state = {
      newsletterEmail: '',
      newsletterError: '',
      loadingNewsletter: false,
      newsletterErrorType: 'error',
      mainImage: '',
      modal: undefined,
      galleryIndex: 0,
      shop: props.shop,
      shopLoader: false,
      articleLoader: false,
      customerCardSuccess: false,
      faqPageContent: contenFaq,
    };
  }

  public componentWillUnmount() {
    this.props.dispatch(setCurrentUniqs([]));
  }

  public getMetaTags = (article) => {
    let metaTags: any = {};
    if (article && article.meta) {
      metaTags = article.meta;
    }
    if (!metaTags.title.length) {
      metaTags.title = `${this.props.defaultTitle} - ${article.name}`;
    }
    return metaTags;
  };

  public onShopClick = (url) => {
    this.props.dispatch(push(getBaseUrl(url)));
  };

  public onArticleClick = () => {
    this.setState({ articleLoader: true });
  };

  public onArticleLoaded = () => {
    this.setState({ articleLoader: false });
  };

  public onCustomerCardSuccess = (status: boolean) => {
    this.setState({ customerCardSuccess: status });
  };

  public render() {
    const {
      contentData,
      allImages,
      articlesList,
      articlesNextLink,
      articlesPreviousLink,
      isArticle,
      connectedProducts,
      breadcrumbsItems,
      parentSitemap,
      location,
    } = this.props;

    if (this.props.sitemaps?.uniqid === 'LOST_CUSTOMER_CARD') {
      let customerCardMenuIndex: number = -1;
      Object.keys(parentSitemap).forEach((key) => {
        if (parentSitemap[key].uniqid === 'CUSTOMER_CARD') {
          if (customerCardMenuIndex !== -1) {
            parentSitemap[key].active = true;
          } else {
            customerCardMenuIndex = Number(key);
          }
        }

        if (parentSitemap[key].uniqid === 'LOST_CUSTOMER_CARD') {
          if (customerCardMenuIndex !== -1) {
            parentSitemap[customerCardMenuIndex].active = true;
          } else {
            customerCardMenuIndex = Number(key);
          }
        }
      });
    }

    const isArticleSingle =
      this.props.itemapId === 87 && isArticle && contentData.name;

    const metaTags = prop(contentData, 'json_content.meta', {});

    if (contentData?.draft) {
      metaTags['robots'] = 'noindex';
    }

    if (this.props.draftContent) {
      this.props.dispatch(push('/'));
      return null;
    } else {
      const categoryChainArr = [
        {
          name: this.props.parentSitemap?.name,
          url: '/' + this.props.parentSitemap?.url,
        },
        {
          name: this.props.sitemaps?.name,
          url: '/' + this.props.sitemaps?.url,
        },
      ];

      if (this.props.isArticle) {
        categoryChainArr.push({
          name: this.props.contentData?.name,
          url: '/' + this.props.contentData?.url,
        });
      }

      return (
        <React.Fragment>
          <MetaTags tags={metaTags} />
          <ContentContainerWithSidebar>
            {(this.props.sitemapTree && this.props.sitemapTree.length) ||
            (this.props.parentSitemap &&
              this.props.parentSitemap.length &&
              this.props.parentSitemapId !== 81) ? (
              <>
                {!this.props.parentSitemapObject ||
                this.props.parentSitemapObject.uniqid !== 'SCHOOLS' ? (
                  this.props.currentUniqs.includes('REFERENCES') ? (
                    <MenuList
                      dispatch={this.props.dispatch}
                      sitemapTree={this.props.parentSitemap}
                      breadcrumbItems={this.props.breadcrumbsItems}
                      user={null}
                    />
                  ) : (
                    <SidebarContainer
                      items={
                        this.props.parentSitemapId !== 81
                          ? this.props.parentSitemap &&
                            this.props.parentSitemap.filter(
                              (item) => item.uniqid !== 'LOST_CUSTOMER_CARD',
                            )
                          : this.props.sitemapTree
                      }
                      staticItems={
                        ['SHOP_NETWORK', 'ORGANIZATIONS'].includes(
                          this.props.parentSitemapObject?.uniqid,
                        )
                          ? [{ url: '/', text: __('Internetový obchod') }]
                          : []
                      }
                    />
                  )
                ) : (
                  ''
                )}
                <ContentWithSidebar
                  fullWidth={
                    !(
                      !this.props.parentSitemapObject ||
                      this.props.parentSitemapObject.uniqid !== 'SCHOOLS'
                    )
                  }
                >
                  {breadcrumbsItems ? (
                    <Breadcrumbs items={breadcrumbsItems} />
                  ) : (
                    ''
                  )}
                  {contentData.json_content.name &&
                  contentData.json_content.name !== '' &&
                  (!(
                    isArticle &&
                    (this.props.itemapId === 87 || this.props.itemapId === 168)
                  ) ||
                    (isArticle &&
                      this.props.sitemaps.uniqid.includes('!PT!'))) ? (
                    <Header>
                      <Title>{contentData.json_content.name}</Title>
                    </Header>
                  ) : (
                    ''
                  )}
                  {typeof contentData?.json_content?.body === 'string' &&
                  this.props.itemapId != 85 ? (
                    <React.Fragment>
                      {!this.state.customerCardSuccess ? (
                        isArticleSingle ? (
                          <ArticleSingle
                            contentData={contentData}
                            connectedProducts={connectedProducts}
                            location={this.props.location}
                          />
                        ) : (
                          <>
                            {parseTextWithShortcode(
                              contentData?.json_content?.body,
                            ).map((item: any) => {
                              if (item.type === 'text') {
                                return (
                                  <Content
                                    dangerouslySetInnerHTML={{
                                      __html: item.value,
                                    }}
                                  />
                                );
                              } else if (item.type === 'flipbook') {
                                return (
                                  <FlipBook
                                    contentId={contentData.id}
                                    fileName={item.parameters.name}
                                    pages={item.parameters.pages}
                                  />
                                );
                              } else if (item.type === 'formular-sutaz') {
                                return (
                                  <ContestGeneratedForm
                                    nameJson={item.parameters.name}
                                    customJson={item.parameters.custom}
                                  />
                                );
                              }

                              return '';
                            })}
                            <div>
                              {allImages?.length ? (
                                <GalleryWrapper>
                                  <Gallery images={allImages} />
                                </GalleryWrapper>
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        )
                      ) : null}

                      {this.props.sitemaps &&
                      this.props.sitemaps.uniqid === 'CUSTOMER_CARD' ? (
                        <CustomerCardForm
                          onSendSuccess={this.onCustomerCardSuccess}
                        />
                      ) : (
                        ''
                      )}

                      {this.props.sitemaps &&
                      this.props.sitemaps.uniqid &&
                      this.props.sitemaps.uniqid === 'BENEFIT_CARD' ? (
                        <BenefitCardForm />
                      ) : (
                        ''
                      )}

                      {this.props.sitemaps &&
                      this.props.sitemaps.uniqid &&
                      this.props.sitemaps.uniqid.includes('GIFT_CARD_FORM') ? (
                        <GiftCardForm />
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {this.props.sitemaps &&
                  this.props.sitemaps.uniqid === 'LOST_CUSTOMER_CARD' ? (
                    <LostCustomerCardForm />
                  ) : (
                    ''
                  )}
                  {this.props.sitemaps &&
                  this.props.sitemaps.uniqid === 'COMPLAIN' ? (
                    <ComplaintForm location={location} />
                  ) : (
                    ''
                  )}
                  {this.props.itemapId === 87 && !isArticle ? (
                    <Loader loading={this.state.articleLoader}>
                      <ArticleList
                        articles={articlesList}
                        paginationNextLink={articlesNextLink}
                        paginationPreviousLink={articlesPreviousLink}
                        onClick={this.onArticleClick}
                        onLoaded={this.onArticleLoaded}
                      />
                    </Loader>
                  ) : (
                    ''
                  )}
                  {this.props.itemapId === 88 ? (
                    <Loader loading={this.state.shopLoader}>
                      {this.state.shop ? (
                        <StoreDetail
                          detail={this.state.shop}
                          onClickBack={this.onShopClick}
                        />
                      ) : (
                        <StoreList
                          items={this.props.shops}
                          onShopClick={this.onShopClick}
                        />
                      )}
                    </Loader>
                  ) : (
                    ''
                  )}
                  {this.props.itemapId === 123 ? (
                    <React.Fragment>
                      <Contact
                        dispatch={this.props.dispatch}
                        shops={this.props.shops}
                      />
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  {this.props.itemapId === 85 ? (
                    <Faq
                      faq={this.props.faq}
                      content={this.state.faqPageContent}
                    />
                  ) : (
                    ''
                  )}

                  {this.props.sitemaps &&
                  this.props.sitemaps.uniqid === 'COOKIES_INFO' ? (
                    <CookiesChooser />
                  ) : (
                    ''
                  )}

                  {this.props.sitemaps &&
                  this.props.sitemaps.uniqid &&
                  this.props.sitemaps.uniqid.includes('!PT!') &&
                  !isArticle ? (
                    <PictureTitleList
                      articles={articlesList}
                      currentPage={this.props.currentPage}
                      totalPages={this.props.totalPages}
                      pathName={
                        this.props.location.pathname
                          ? this.props.location.pathname
                          : ''
                      }
                      onClick={this.onArticleClick}
                      onLoaded={this.onArticleLoaded}
                      dispatch={this.props.dispatch}
                    />
                  ) : (
                    ''
                  )}
                </ContentWithSidebar>
              </>
            ) : (
              <React.Fragment>
                <Content
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof contentData?.json_content?.body === 'string'
                        ? contentData.json_content.body
                        : '',
                  }}
                />
                {allImages?.length ? (
                  <GalleryWrapper>
                    <Gallery images={allImages} />
                  </GalleryWrapper>
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
          </ContentContainerWithSidebar>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  defaultTitle: state.general.title,
  lang: state.general.lang,
  faq: state.helpAndSupport.faq,
  currentUniqs: prop(state, 'general.currentUniqs'),
});

export default connect(mapStateToProps)(Cms);
