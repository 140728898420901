import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import {
  ContactHeading,
  MapImg,
} from '../../components/_helpers/Contact/ContactStyles';
import ContactForm from '../../components/_helpers/Contact/ContactForm';
import StoreListContact from '../../components/_layout/Stores/StoreListContact';
import ContentContainer from '../../components/_layout/Page/ContentContainer';
import ContactPersons from '../../components/_helpers/Contact/ContactPersons';
import ContactInfo from '../../components/_helpers/Contact/ContactInfo';
import API from '../../services/API';
import { prop } from '../../utilities';
import { useState } from 'react';

interface Props {
  dispatch: any;
  shops: any;
}

const Contact = (props: Props) => {
  const myRef = React.useRef(null);

  const [openingHours, setOpeningHours] = useState<string>('');

  const personsEquipment = [
    {
      name: 'PaedDr. Ján Cmarko',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 40',
      phone2: '+421 907 358 802',
      email: 'cmarko@daffer.sk',
      regions:
        'Bánovce nad Bebravou, Hlohovec, Nitra, Partizánske, Šaľa, Topoľčany',
      picture: '/images/contact/cmarko.png',
    },
    {
      name: 'Juraj Čičmanec',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 61',
      phone2: '+421 903 284 775',
      email: 'cicmanec@daffer.sk',
      regions: 'Dunajská Streda, Ilava, Myjava, Nové Mesto nad Váhom, Púchov',
      picture: '/images/contact/cicmanec.png',
    },
    {
      name: 'PaedDr. Zuzana Kissová',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 60',
      phone2: '+421 911 191 133',
      email: 'kissova@daffer.sk',
      regions:
        'Banská Bystrica, Brezno, Galanta, Košice, Liptovský Mikuláš, Revúca, Rimavská Sobota, Rožňava, Ružomberok',
      picture: '/images/contact/kissova.png',
    },
    {
      name: 'Dana Krajčovičová',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 38',
      phone2: '+421 907 738 369',
      email: 'krajcovicova@daffer.sk',
      regions:
        'Banská Štiavnica, Detva, Komárno, Krupina, Levice, Lučenec, Nové Zámky, Poltár, Trenčín, Turčianske Teplice, Veľký Krtíš, Zlaté Moravce, Zvolen, Žarnovica, Žiar nad Hronom',
      picture: '/images/contact/danakraj.png',
    },
    {
      name: 'Mgr. Roman Pospíšil',
      position: 'obchodný zástupca',
      phone: '+421 903 902 161',
      email: 'rpromanpospisil@gmail.com',
      regions:
        'Bardejov, Gelnica, Humenné, Kežmarok, Levoča, Medzilaborce, Michalovce, Poprad, Prešov, Sabinov, Snina, Sobrance, Spišská Nová Ves, Stará Ľubovňa, Stropkov, Svidník, Trebišov, Vranov nad Topľou',
      picture: '/images/contact/pospisil.png',
    },
    {
      name: 'Zuzana Kršková',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 43',
      phone2: '+421 908 784 270',
      email: 'krskova@daffer.sk',
      regions:
        'Bytča, Čadca, Dolný Kubín, Kysucké Nové Mesto, Martin, Námestovo, Považská Bystrica, Tvrdošín, Žilina',
      picture: '/images/contact/krskova.png',
    },
    {
      name: 'Mgr. Ján Krajčovič',
      position: 'obchodný zástupca',
      phone: '046 / 519 87 62',
      phone2: '+421 907 597 080',
      email: 'jan.krajcovic@daffer.sk',
      regions:
        'Bratislava, Malacky, Pezinok, Piešťany, Senec, Senica, Skalica, Trnava',
      picture: '/images/contact/jankraj.png',
    },
    {
      name: 'Eva Baťová',
      position: 'asistentka pre komplexné vybavenie škôl',
      phone: '046 / 519 87 19',
      phone2: '+421 948 284 008',
      email: 'batova@daffer.sk',
      picture: '/images/contact/batova.png',
    },
    {
      name: 'Zlatica Mikušková',
      position: 'asistentka pre predaj školských potrieb',
      phone: '046 / 519 87 46',
      phone2: '+421 948 284 008',
      email: 'mikuskova@daffer.sk',
      picture: '/images/contact/mikuskova.png',
    },
    {
      name: 'Ing. Katarína Šatarová',
      position: 'projektový manažér',
      phone: '046 / 519 87 49',
      phone2: '+421 903 284 701',
      email: 'satarova@daffer.sk',
      picture: '/images/contact/satarova.png',
    },
  ];
  const personsOrganization = [
    {
      name: 'Eva Melišková',
      position: 'obchodný zástupca',
      phone2: '+421 907 436 011',
      phone: '046 / 519 87 21',
      email: 'meliskova@daffer.sk',
      picture: '/images/contact/meliskova.png',
    },
    {
      name: 'Zuzana Vršková',
      position: 'obchodný zástupca',
      phone2: '+421 903 284 799',
      phone: '046 / 519 87 37',
      email: 'vrskova@daffer.sk',
      picture: '/images/contact/vrskova.png',
    },
    {
      name: 'Mgr. Martin Pružinec',
      position: 'obchodný zástupca',
      phone2: '+421 903 284 873',
      phone: '046 / 519 87 14',
      email: 'pruzinec@daffer.sk ',
      picture: '/images/contact/pruzinec.png',
    },
    {
      name: 'Ľubica Melová',
      position: 'asistentka pre predaj firmám a organizáciám',
      phone: '046 / 519 87 16',
      email: 'melova@daffer.sk',
      picture: '/images/contact/melova.png',
    },
  ];

  React.useEffect(() => {
    // Scroll to contact form
    if (window && window.location && window.location.href) {
      if (window.location.href.includes('?scrollToContactForm=true')) {
        if (myRef.current !== null) {
          // @ts-ignore
          myRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    API.loadOtherTexts('CONTACT_OPENING_HOURS').then((loadOtherTextsResult) => {
      if (loadOtherTextsResult && loadOtherTextsResult.content) {
        if (loadOtherTextsResult.content.draft !== 1) {
          const text = prop(
            loadOtherTextsResult,
            'content.json_content.body',
            '',
          );
          if (text) {
            setOpeningHours(text);
          }
        }
      }
    });
  }, []);

  return (
    <ContentContainer>
      <ContactInfo dispatch={props.dispatch} openingHours={openingHours} />

      <iframe
        src="https://www.google.com/maps/d/embed?mid=1BgZ-2cKrwYASfLWrNzXjfkcZDwoviQTt"
        width="100%"
        height="280"
      />

      <ContactHeading>
        <strong>{__('Sieť predajní Daffer')}</strong>
        {` - ${__('Dobré papiernictvo')}`}
      </ContactHeading>

      {props.shops && props.dispatch ? (
        <StoreListContact items={props.shops} dispatch={props.dispatch} />
      ) : (
        ''
      )}

      <ContactHeading>
        {`${__('Starostlivosť o')} `}
        <strong>{__('školské zariadenia')}</strong>
      </ContactHeading>

      <ContactPersons
        persons={personsEquipment}
        contentBottom={
          <MapImg src={'/images/contact/mapa-obchodny-zastupcovia.jpg'} />
        }
      />

      <ContactHeading>
        {`${__('Starostlivosť o')} `}
        <strong>{__('firmy a organizácie')}</strong>
      </ContactHeading>

      <ContactPersons persons={personsOrganization} />
      <p ref={myRef} />
      <ContactForm dispatch={props.dispatch} />
    </ContentContainer>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Contact' })(Contact),
);
